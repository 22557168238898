import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { contentTypes } from "../../config";
import List from "../global/list";
import { getString } from "../../utilities";
import { useWidthSize } from "../../hooks";

export default function FeatureList({ data, gradeNumber }) {
  const gameList = useMemo(() => {
    if (data) {
      return data.items.map((item) => ({
        type: contentTypes.game,
        title: item.game.fullname,
        path: item?.game?.localeShortname ? item.game.localeShortname : item.game.shortname,
        image: item.game.miniBanner ? item.game.miniBanner : null,
        grades: item.game.grades,
        mobile: item.game.mobileActive,
        searchable: item.game.searchable,
      }));
    }
    return [];
  }, [data]);

  const showFullTitle = useWidthSize(["xxs", "xs"]);

  // if browser width size is "sm" (640px) or higher show full title
  const featureTitle = !showFullTitle ? data?.title : getString("grades.featured");

  return (
    <div className="feature-list-container">
      <List title={featureTitle} items={gameList} className={`feature-list grade-feature-${gradeNumber}`} />
    </div>
  );
}

FeatureList.propTypes = {
  data: PropTypes.object,
  gradeNumber: PropTypes.number,
};
