import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getVideoPlacementUrl, loadPrerollVideoDependencies } from "../../utilities/head";
import {
  clickToPlayVideoSelector,
  messageOriginSelector,
  setClickToPlayVideo,
  setMessageOrigin,
} from "../../redux/slices/preContentVideoSlice";
import { sendResponse } from "../../utilities";

export default function ClickToPlayVideo() {
  const [loaded, setLoaded] = useState(false);
  const clickToPlayVideo = useSelector(clickToPlayVideoSelector);
  const messageOrigin = useSelector(messageOriginSelector);
  const dispatch = useDispatch();
  const location = useLocation();

  const timer = useRef(null);
  const instanceRef = useRef(null);

  const resetClickToPlay = () => {
    dispatch(setClickToPlayVideo({ clickToPlayVideo: false }));
    dispatch(setMessageOrigin({ messageOrigin: null }));
  };

  const resumeGame = () => {
    const origin = messageOrigin;
    const message = { type: "resumeGame" };
    resetClickToPlay();
    console.log(
      `sending message to game to resume game play - message: ${JSON.stringify(message)}, origin: ${JSON.stringify(
        origin,
      )}`,
    );
    sendResponse(message, origin);
  };

  const destroy = (playerInstance) => {
    try {
      document.getElementById("optimera-player") && document.getElementById("optimera-player").remove();
      playerInstance?.dispose();
      instanceRef.current = null;
      console.log(`destroyed instance: ${JSON.stringify(playerInstance)}`);
      console.log("instanceRef.current: ", instanceRef.current);
      console.log(window.videojs.players);
    } catch (e) {
      console.log(`Error disposing of video instance: ${e}`);
    }
  };

  const loadedFunction = async () => {
    const adPlacementUrl = await getVideoPlacementUrl();
    console.log("ad placement URL: ", adPlacementUrl);

    if (adPlacementUrl) {
      // custom ABCya edited click-to-play-ovp.js changed args
      // 1. divContainerId: The container div ID
      // 2. placeHolderVideo: An object containing the type and source of the blank MP4
      // 3. adPlacementUrl: The adserver URL for the video placement you're going to show.
      window.ovpjs.addVideoPlayerAdOnly(
        "preroll-video-container",
        {
          type: "video/mp4",
          src: "/static/assets/blankVideo5s.mp4",
        },
        adPlacementUrl,
      );

      console.log("window.videojs.players: ", window.videojs.players);
      console.log("window.ovpjs 2: ", window.ovpjs);

      instanceRef.current = window.videojs.players["optimera-player"];
      console.log("instanceRef.current: ", instanceRef.current);

      instanceRef.current?.ready(function () {
        this.on("adsready", function () {
          // IMA SDK Events For d Playing status:
          // https://github.com/googleads/videojs-ima/blob/596c76f95b175ba7483a9490d58a0c2335aa8942/dist/videojs.ima.js#L1450

          this.ima.addEventListener(window.google.ima.AdEvent.Type.COMPLETE, function () {
            destroy(instanceRef.current);
            resumeGame();
            console.log("preContentVideo - COMPLETE");
          });

          this.ima.addEventListener(window.google.ima.AdEvent.Type.SKIPPED, () => {
            destroy(instanceRef.current);
            resumeGame();
            console.log("preContentVideo - SKIPPED");
          });
        });
      });

      // Player events
      instanceRef.current?.on("adserror", () => {
        destroy(instanceRef.current);
        resumeGame();
        console.log("preContentVideo - adserror");
      });

      instanceRef.current?.on("contentended", () => {
        destroy(instanceRef.current);
        resumeGame();
        console.log("preContentVideo - contentended");
      });

      const playPromise = instanceRef.current?.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Playback started!
          })
          .catch((error) => {
            // console.log("autoplay prevented");
            // Auto-play was prevented
          });
      }
    } else {
      destroy(instanceRef.current);
      resumeGame();
      console.log("failed to retrieve video placement url");
    }
  };

  useEffect(() => {
    if (loaded) {
      loadedFunction();
    }
  }, [loaded]);

  useEffect(() => {
    loadPrerollVideoDependencies(clickToPlayVideo);
    window.ovpReady = () => {};
    console.log("window.ovpjs 1: ", window.ovpjs);
    setLoaded(false);

    const checkForOvp = () => {
      if (window.ovpjs) {
        setLoaded(true);
        clearInterval(timer.current);
        timer.current = null;
      }
    };

    timer.current = setInterval(checkForOvp, 200);

    return () => {
      console.log("ClickToPlayVideo location UNMOUNT");

      // in case user leaves page before timer goes off
      clearInterval(timer.current);
      timer.current = null;
      if (instanceRef.current) {
        // in case user leaves page before ad completes/unmounts
        destroy(instanceRef.current);
      }

      resetClickToPlay();
    };
  }, [location]);

  return <div className="a-prerollvideo-container" id="preroll-video-container" />;
}
