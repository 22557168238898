import { createSlice } from "@reduxjs/toolkit";

export const timerSlice = createSlice({
  name: "timer",
  initialState: {
    showAlertModal: false,
    isOn: false,
    remainingSeconds: 0,
  },
  reducers: {
    setTimer: (prevState, action) => {
      const state = prevState;
      if (!state.isOn) {
        let seconds = 0;
        if (action.payload.hours) {
          seconds += action.payload.hours * 3600;
        }
        if (action.payload.minutes) {
          seconds += action.payload.minutes * 60;
        }
        state.remainingSeconds = seconds;
      }
    },
    timerToggle: (prevState) => {
      const state = prevState;
      if (state.isOn === true) {
        state.remainingSeconds = 0;
      }
      state.isOn = !state.isOn;
    },
    alertToggle: (prevState) => {
      const state = prevState;
      state.showAlertModal = !state.showAlertModal;
    },
    decrementSeconds: (prevState) => {
      const state = prevState;
      --state.remainingSeconds;
      if (state.remainingSeconds === 0) {
        state.showAlertModal = true;
        state.isOn = false;
      }
    },
  },
  selectors: {
    isOnSelector: (state) => state.isOn,
    remainingSecondsSelector: (state) => state.remainingSeconds,
    showAlertModalSelector: (state) => state.showAlertModal,
  },
});

export const { setTimer, timerToggle, alertToggle, decrementSeconds } = timerSlice.actions;
export const { isOnSelector, remainingSecondsSelector, showAlertModalSelector } = timerSlice.selectors;

export default timerSlice.reducer;
