import React from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { metaDefault } from "../../config/meta";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";

export default function NotFound({
  item = getString("notfound.item"),
  cta = getString("notfound.gohome"),
  route = "HOME",
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`${routeCodes[route]}`, { replace: true });
  };

  return (
    <div className="wrapper padded">
      {metaDefault({ path: location.pathname, title: getString("notfound.title"), noCrawl: true, notFound: true })}
      <h1>{getString("notfound.title")}</h1>

      <div className="container-small">
        <h3>{getString("notfound.sorry", { replace: [item] })}</h3>
        <button type="button" className="button-flat-color cyan round auto" onClick={handleButtonClick}>
          {cta}
        </button>
      </div>
      <div className="wrapper header-shadow" />
    </div>
  );
}

NotFound.propTypes = {
  route: PropTypes.string,
  item: PropTypes.string,
  cta: PropTypes.string,
};
