import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { getString } from "../../utilities";
import { breakpoints, getLangCode, getSearchParamString, language } from "../../config";
import FlareShimmer from "../global/FlareShimmer";
import routeCodes from "../../config/routeCodes";
import { gtmJoinClick } from "../../utilities/gtm";
import { dataSelector } from "../../redux/slices/dataSlice";

import onboardingFamilyMdPng from "../../../assets/img/onboardingFamilyMd.png";
import onboardingFamilyXlPng from "../../../assets/img/onboardingFamilyXl.png";
import onboardingFamilyMdWebp from "../../../assets/img/onboardingFamilyMd.webp";
import onboardingFamilyXlWebp from "../../../assets/img/onboardingFamilyXl.webp";
import onboardingTeacherLaMdPng from "../../../assets/img/onboardingTeacherLaMd.png";
import onboardingTeacherLaXlPng from "../../../assets/img/onboardingTeacherLaXl.png";
import onboardingTeacherLaMdWebp from "../../../assets/img/onboardingTeacherLaMd.webp";
import onboardingTeacherLaXlWebp from "../../../assets/img/onboardingTeacherLaXl.webp";
import onboardingTeacherMdPng from "../../../assets/img/onboardingTeacherMd.png";
import onboardingTeacherXlPng from "../../../assets/img/onboardingTeacherXl.png";
import onboardingTeacherMdWebp from "../../../assets/img/onboardingTeacherMd.webp";
import onboardingTeacherXlWebp from "../../../assets/img/onboardingTeacherXl.webp";
import Controller from "../../../assets/svg/controller.svg";
import GameAccessTeacher from "../../../assets/svg/gameAccessTeacher.svg";
import GameAccessFamily from "../../../assets/svg/gameAccessFamily.svg";
import NoAds from "../../../assets/svg/noAds.svg";
import NoAdsGlobal from "../../../assets/svg/noAdsGlobal.svg";
import Shimmer from "../../../assets/img/shimmer.png";
import { metaDefault } from "../../config/meta";

export default function UserType({ userType }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [gotw, setGotw] = useState("");
  const data = useSelector(dataSelector);

  useEffect(() => {
    if (data?.gotw) {
      setGotw(data.gotw);
    }
  }, [data]);

  const art = {
    family: {
      [getLangCode()]: {
        md: {
          png: onboardingFamilyMdPng,
          webp: onboardingFamilyMdWebp,
        },
        xl: {
          png: onboardingFamilyXlPng,
          webp: onboardingFamilyXlWebp,
        },
      },
    },
    teacher: {
      en: {
        md: {
          png: onboardingTeacherMdPng,
          webp: onboardingTeacherMdWebp,
        },
        xl: {
          png: onboardingTeacherXlPng,
          webp: onboardingTeacherXlWebp,
        },
      },
      la: {
        md: {
          png: onboardingTeacherLaMdPng,
          webp: onboardingTeacherLaMdWebp,
        },
        xl: {
          png: onboardingTeacherLaXlPng,
          webp: onboardingTeacherLaXlWebp,
        },
      },
    },
  };

  const renderSources = () => {
    const artObject = art[userType][getLangCode()];
    const artArray = Object.keys(artObject);
    let keyCount = 0;

    return artArray.map((artKey, index) => {
      const sourceObject = artObject[artKey];
      const sourceArray = Object.keys(sourceObject);

      const media = index === artArray.length - 1 ? "" : `(max-width: ${breakpoints.width[artKey]}px)`;

      return sourceArray.map((sourceKey) => {
        keyCount++;
        return (
          <source
            key={`artKey${keyCount}`}
            srcSet={sourceObject[sourceKey]}
            media={media}
            type={`image/${sourceKey}`}
          />
        );
      });
    });
  };

  const renderNoAdsArt = () => {
    if (getLangCode() === language.default) {
      return <NoAds />;
    }
    return <NoAdsGlobal />;
  };

  const renderGameAccessArt = () => {
    if (userType === "family") {
      return <GameAccessFamily />;
    }
    return <GameAccessTeacher />;
  };

  return (
    <>
      {metaDefault({ path: location.pathname, noCrawl: true })}

      <div className="wrapper padded odd">
        <div className="onboarding-usertype container">
          <h1>{getString("onboarding.welcome")}</h1>
          <h2>{getString(`onboarding.userType.subtitle.${userType}`)}</h2>

          <picture>
            {renderSources()}

            <img className="onboarding-header-art" width={1024} height={372} src="" alt="" />
          </picture>

          <ul className="onboarding-perks">
            <li>
              <div className="onboarding-perks-icon">
                <Controller />
              </div>
              <span>{getString("onboarding.userType.play")}</span>
            </li>
            <li>
              <div className="onboarding-perks-icon">{renderGameAccessArt()}</div>
              <span>
                {getString("onboarding.userType.unlock.copy", {
                  replace: [getString(`onboarding.userType.unlock.amount.${userType}`)],
                })}
              </span>
            </li>
            <li>
              <div className="onboarding-perks-icon">{renderNoAdsArt()}</div>
              <span>{getString("onboarding.userType.ads")}</span>
            </li>
          </ul>

          <button
            type="button"
            onClick={() => {
              gtmJoinClick(location.pathname, "header-link");
              navigate({
                pathname: routeCodes.MANAGE,
                search: `?${getSearchParamString("subscription")}=${getString(userType === "family" ? "family" : "classroom").toLowerCase()}`,
              });
            }}
            className="join-button premium-join"
          >
            <FlareShimmer filePath={Shimmer} />
            {getString("subscribe.title.0")}
          </button>

          <Link to={`${routeCodes.GAMES}${gotw}`}>{getString("onboarding.userType.notNow")}</Link>
        </div>
      </div>
    </>
  );
}

UserType.propTypes = {
  userType: PropTypes.string.isRequired,
};
