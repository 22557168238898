import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";

import { metaDefault } from "../../config/meta";
import { getString } from "../../utilities";
import routeCodes from "../../config/routeCodes";

import { SET_USER_TYPE_PREFERENCE } from "../../graphql/mutations";
import TeacherArt from "../../../assets/img/onboarding-teacher.svg";
import ParentArt from "../../../assets/img/onboarding-parent.svg";
import StudentArt from "../../../assets/img/onboarding-student.svg";

export default function Onboarding() {
  const navigate = useNavigate();

  const [_, setUserTypePreference] = useMutation(SET_USER_TYPE_PREFERENCE);

  const selectionHandler = (role) => {
    // handle logic based on role
    const path = role === "teacher" ? routeCodes.ONBOARDING_TEACHER : routeCodes.ONBOARDING_FAMILY;

    setUserTypePreference({ userType: role.toUpperCase() });

    navigate(path);
  };

  return (
    <>
      {metaDefault({ path: location.pathname, noCrawl: true })}

      <div className="onboarding">
        <div className="wrapper padded odd">
          <h1>{getString("onboarding.welcome")}</h1>
          <h2>{getString("onboarding.using.subtitle")}</h2>
        </div>

        <div className="wrapper onboarding-selection-wrapper">
          <div
            className="onboarding-selection teacher"
            role="link"
            onClick={() => {
              selectionHandler("teacher");
            }}
          >
            <div className="onboarding-selection-art">
              <img src={TeacherArt} width="328.44px" height="337.14px" alt={getString("onboarding.using.teacher")} />
            </div>
            <div className="onboarding-selection-text">{getString("onboarding.using.teacher")}</div>
          </div>
          <div
            className="onboarding-selection parent"
            role="link"
            onClick={() => {
              selectionHandler("parent");
            }}
          >
            <div className="onboarding-selection-art">
              <img src={ParentArt} width="327.91px" height="333.45px" alt={getString("onboarding.using.parent")} />
            </div>
            <div className="onboarding-selection-text">{getString("onboarding.using.parent")}</div>
          </div>
          <div
            className="onboarding-selection student"
            role="link"
            onClick={() => {
              selectionHandler("student");
            }}
          >
            <div className="onboarding-selection-art">
              <img src={StudentArt} width="370.7px" height="282.52px" alt={getString("onboarding.using.student")} />
            </div>
            <div className="onboarding-selection-text">{getString("onboarding.using.student")}</div>
          </div>
        </div>
      </div>
    </>
  );
}
