import React from "react";
import { useNavigate } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import Arrow from "../../../assets/svg/arrowRight.svg";

export default function PlansGiftCardCTA() {
  const navigate = useNavigate();

  return (
    <p>
      {`${getString("subscribe.giftcard.buying")} `}
      <button
        type="button"
        onClick={() => {
          navigate(routeCodes.GIFTCARD);
        }}
        className="button-flat-color pt-green round auto"
      >
        {getString("giftcard.purchase.0")}
        <Arrow className="inline-arrow" />
      </button>
    </p>
  );
}
