import { createSlice } from "@reduxjs/toolkit";

const fullPageSlice = createSlice({
  name: "fullPage",
  initialState: {
    mobileGame: false,
    gameProxy: false,
  },
  reducers: {
    setMobileGame: (prevState, action) => {
      const state = prevState;
      state.mobileGame = action.payload.mobileGame;
    },
    setGameProxy: (prevState, action) => {
      const state = prevState;
      state.gameProxy = action.payload.gameProxy;
    },
  },
  selectors: {
    mobileGameSelector: (state) => state.mobileGame,
    gameProxySelector: (state) => state.gameProxy,
  },
});

export const { mobileGameSelector, gameProxySelector } = fullPageSlice.selectors;
export const { setMobileGame, setGameProxy } = fullPageSlice.actions;

export default fullPageSlice.reducer;
