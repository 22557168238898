import React from "react";
import PropTypes from "prop-types";

export default function PlansPromoDescription({ promo, pRole }) {
  if (promo && promo.description && promo.planGroups.includes(pRole.toUpperCase())) {
    return <div className="plans-promo-description">{promo.description}</div>;
  }

  return null;
}

PlansPromoDescription.propTypes = {
  pRole: PropTypes.string,
  promo: PropTypes.object,
};
