import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { BLOG_POST_QUERY } from "../../graphql/queries";
import { metaType } from "../../config/meta";
import { getString, sortedArrayByItem, convertISODate, purify, purifiedParsedString } from "../../utilities";
import LoadingAnim from "../global/loadingAnim";
import BlogMinibanner from "./blogMinibanner";
import ErrorMessage from "../global/errorMessage";
import { assetUrl } from "../../config";
import NotFound from "../global/notFound";
import { findLinks } from "../../utilities/blog";
import { breakpoints } from "../../config";

import DefaultXxlWebp from "../../../assets/img/defaultBlogPost_xxl.webp";
import DefaultXxlJpg from "../../../assets/img/defaultBlogPost_xxl.jpg";

import DefaultXlWebp from "../../../assets/img/defaultBlogPost_xl.webp";
import DefaultXlJpg from "../../../assets/img/defaultBlogPost_xl.jpg";

import DefaultMdWebp from "../../../assets/img/defaultBlogPost_md.webp";
import DefaultMdJpg from "../../../assets/img/defaultBlogPost_md.jpg";

import DefaultXsWebp from "../../../assets/img/defaultBlogPost_xs.webp";
import DefaultXsJpg from "../../../assets/img/defaultBlogPost_xs.jpg";
import WebP from "../global/WebP";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import { useLazyQuery } from "../../hooks";

export default function BlogPost() {
  const location = useLocation();
  const { id } = useParams();

  const loading = useSelector(loadingSelector);
  const data = useSelector(dataSelector);

  const [recentPosts, setRecentPosts] = useState(null);
  const [post, setPost] = useState(null);
  const [postBody, setPostBody] = useState(null);
  const [error, setError] = useState(null);

  const onCompleted = (data) => {
    let nextPostBody = data.post ? data.post.body : null;

    if (!nextPostBody) {
      setError(new Error(getString("blog.bodyError")));
    }

    nextPostBody = purify(nextPostBody);

    const externalLinkIndices = findLinks(new RegExp('href="http', "gi"), nextPostBody);
    const splitOnExternal = [0, ...externalLinkIndices].map((linkIndex, index, indices) =>
      nextPostBody.slice(linkIndex, indices[index + 1]),
    );
    nextPostBody = splitOnExternal.join('rel="noopener noreferrer" target="_blank" ');
    setPostBody(purifiedParsedString(nextPostBody));
  };

  const onError = (e) => setError(e);

  const { executeQuery: getPost, loading: apiLoading } = useLazyQuery(BLOG_POST_QUERY, onCompleted, onError);

  useEffect(() => {
    if (!loading && data.posts) {
      setError(null);
      const currentPost = data.posts.find((current) => current.id === id);
      // grab 3 most recent to display
      const recentPosts = sortedArrayByItem(data.posts, "postDate", true).reduce((recentPostsArray, p) => {
        if (recentPostsArray.length < 3 && p.id !== id) {
          // insert 3 most recent psots that aren't post the visitor is viewing
          recentPostsArray.push(p);
        }

        return recentPostsArray;
      }, []);
      setPost(currentPost);
      setRecentPosts(recentPosts);
      if (currentPost) {
        getPost({ id });
      }
    }
  }, [loading, data, id]);

  const renderRecent = () => {
    if (recentPosts && recentPosts.length > 0) {
      return (
        <div className="wrapper padded blog-posts-wrapper">
          <h2>{getString("blog.recentTitle")}</h2>

          <div className="container-medium blog-posts-container">
            {recentPosts.map((post, index) => {
              const rpmbKey = `rpmbKey${index}`;
              return <BlogMinibanner key={rpmbKey} post={post} />;
            })}
          </div>
        </div>
      );
    }

    return null;
  };

  const renderPostBody = () => {
    if (apiLoading) {
      return <LoadingAnim />;
    }
    if (error) {
      return <ErrorMessage error={error} />;
    }

    return postBody;
  };

  if (loading) {
    return (
      <div className="wrapper padded">
        <LoadingAnim className="background-white" />
        <div className="wrapper header-shadow" />
      </div>
    );
  }

  const meta = post
    ? metaType(
        "BlogPosting",
        {
          metaTitle: post.title,
          metaDescription: post.briefDescription,
          metaKeywords: getString("head.keywords"),
          image: post?.image ? `${assetUrl}${post.image}` : DefaultXxlJpg,
        },
        location.pathname,
      )
    : null;

  const renderContent = () => {
    if (post) {
      const postImage = post.image ? (
        <WebP
          secret={`${assetUrl}${post.image}`}
          imgProps={{ width: 877, height: 585, className: "blog-post-image", alt: post.title }}
        />
      ) : (
        <picture>
          <source srcSet={DefaultXsWebp} media={`(max-width: ${breakpoints.width.xs}px)`} type="image/webp" />
          <source srcSet={DefaultXsJpg} media={`(max-width: ${breakpoints.width.xs}px)`} type="image/jpeg" />

          <source srcSet={DefaultMdWebp} media={`(max-width: ${breakpoints.width.md}px)`} type="image/webp" />
          <source srcSet={DefaultMdJpg} media={`(max-width: ${breakpoints.width.md}px)`} type="image/jpeg" />

          <source srcSet={DefaultXlWebp} media={`(max-width: ${breakpoints.width.xl}px)`} type="image/webp" />
          <source srcSet={DefaultXlJpg} media={`(max-width: ${breakpoints.width.xl}px)`} type="image/jpeg" />

          <source srcSet={DefaultXxlWebp} type="image/webp" />
          <source srcSet={DefaultXxlJpg} type="image/jpeg" />

          <img width="877" height="585" className="blog-post-image" src="" alt={post.title} />
        </picture>
      );

      return (
        <>
          <div className="wrapper padded">
            <div className="container">
              <h1>{post.title}</h1>
              <div className="pt-date-caption">
                {getString("blog.posted", { replace: [convertISODate(post.postDate)] })}
              </div>
            </div>
          </div>

          <div className="wrapper even">
            <div className="container-medium blog-medium-container">{postImage}</div>
          </div>

          <div className="wrapper padded">
            <div className="container-medium blog-post-body">{renderPostBody()}</div>
          </div>
        </>
      );
    }

    return <NotFound item={getString("blog.title.3")} route="BLOG" cta={getString("blog.go")} />;
  };

  return (
    <>
      {meta}

      <div className="blog-post">
        {renderContent()}
        {renderRecent()}
      </div>
      <div className="wrapper header-shadow" />
    </>
  );
}
