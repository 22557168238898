import React from "react";
import PropTypes from "prop-types";

import { contentTypes } from "../../config";
import List from "../global/list";
import { sortedArrayByItem } from "../../utilities";

export default function PrintableCategories({ categoryLists, printables }) {
  const renderLists = () => {
    if (categoryLists) {
      const lists = [];
      sortedArrayByItem(categoryLists, "order").forEach((list) => {
        const categoryList = list.items.map((item) => {
          const itemPrintable = printables.find((printable) => printable.id === item.printable);
          return {
            type: contentTypes.printable,
            title: itemPrintable.fullname,
            path: itemPrintable.localeShortname || itemPrintable.shortname,
            image: itemPrintable.miniBanner ? itemPrintable.miniBanner : null,
            grades: itemPrintable.grades ? itemPrintable.grades : null,
            style: "mb-portrait",
          };
        });
        lists.push(
          <List
            key={list.shortname}
            title={list.title}
            items={categoryList}
            className={`feature-list ${list.shortname}`}
          />,
        );
      });
      return lists;
    }
    return null;
  };

  return (
    <div data-testid="printable-feature-lists" className="printable-feature-lists">
      {renderLists()}
    </div>
  );
}

PrintableCategories.propTypes = {
  categoryLists: PropTypes.array,
  printables: PropTypes.array,
};
