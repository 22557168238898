import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { isIOS } from "react-device-detect";
import PropTypes from "prop-types";

import { metaType } from "../../config/meta";
import { getString } from "../../utilities";
import { routeCodes } from "../../config/routes";
import { getMediaUrl, variousURLs, arcademicsIntroDuration, motionGames } from "../../config";
import LoadingAnim from "../global/loadingAnim";
import Modal from "../global/modal";
import Confirm from "../global/confirm";
import Rotate from "./rotate";
import MobileGameButtons from "./mobileGameButtons";
import ArcademicsHTML5 from "./arcademicsHTML5";
import { setMobileGame } from "../../redux/slices/fullPageSlice";

export default function MobileGame({ shortname }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [direction, setDirection] = useState("");
  const [game, setGame] = useState(null);
  const [arcademics, setArcademics] = useState(false);
  const [homeModal, setHomeModal] = useState(false);

  const motionRequest = () => {
    if (
      motionGames.includes(game?.shortname || shortname) &&
      window.location.protocol === "https:" &&
      DeviceMotionEvent &&
      typeof DeviceMotionEvent.requestPermission === "function"
    ) {
      // only perform if the game is included in the list of games that require motion controls

      DeviceMotionEvent.requestPermission();
    }
  };

  useEffect(() => {
    const nextGame = location.state ? location.state.game : null;

    if (nextGame && nextGame.mobileActive) {
      const nextArcademics = Object.prototype.hasOwnProperty.call(location.state, "arcademics")
        ? location.state.arcademics
        : false;

      if (game !== nextGame) {
        setTimeout(() => {
          // set global variable for GTM tracking
          window.local_data.grades = nextGame.grades;
          window.local_data.categories = nextGame.categories;
        }, 100);

        setGame(nextGame);
        setArcademics(nextArcademics);
      }
    } else {
      // fallback if someone navigates directly to mobile game
      // and there is no location state present
      // game data comes from location state
      navigate(`${routeCodes.GAMES}${shortname}`, { replace: true });
    }
    motionRequest();
  }, [location]);

  const updateDimensions = () => {
    let currentDirection = window.screen?.orientation?.type;

    if (currentDirection) {
      // supported
      currentDirection = currentDirection.includes("landscape") ? "horizontal" : "vertical";
    } else {
      const currentWidth = isIOS ? document.documentElement.clientWidth : window.screen.width;
      const currentHeight = isIOS ? document.documentElement.clientHeight : window.screen.height;

      // assign the direction based on width & height of browser window
      // this is considering square as vertical
      currentDirection = currentWidth > currentHeight ? "horizontal" : "vertical";
    }

    setDirection(currentDirection);
  };

  useEffect(() => {
    updateDimensions();
    dispatch(setMobileGame({ mobileGame: true }));
    motionRequest();

    if (screen.orientation) {
      screen.orientation.onchange = updateDimensions;
    } else {
      window.addEventListener("resize", updateDimensions);
    }

    return () => {
      // component will unmount
      // reset/remove global variable for GTM tracking
      delete window.local_data.grades;
      delete window.local_data.categories;

      dispatch(setMobileGame({ mobileGame: false }));
      if (screen.orientation) {
        screen.orientation.onchange = null;
      } else {
        window.removeEventListener("resize", updateDimensions);
      }
    };
  }, []);

  const toggleHomeModal = () => {
    setHomeModal((prev) => !prev);
  };

  const renderContent = () => {
    if (arcademics) {
      return (
        <ArcademicsHTML5
          gameName={game.arcademicsName}
          settings={{
            introURL: variousURLs.arcademics.intro,
            introDuration: arcademicsIntroDuration,
            endGameURL: `//${window.location.host}`,
          }}
        />
      );
    }

    return (
      <iframe
        id="abcya-game-iframe"
        title={game.shortname}
        frameBorder="0"
        scrolling="no"
        allow="accelerometer *;magnetometer *;gyroscope *;"
        src={`${getMediaUrl()}/games/${game.shortname}/html/index.html`}
      />
    );
  };

  if (game) {
    const gameOrientation =
      (game.mobileOrientation && game.mobileOrientation === "portrait") || game.orientation === "portrait"
        ? "vertical"
        : "horizontal";

    const rotate = direction !== gameOrientation;

    return (
      <>
        {metaType("VideoGame", game, location.pathname)}

        <div className="game-mobile-game">{renderContent()}</div>

        <Rotate doDisplay={rotate} orientation={gameOrientation} />

        <MobileGameButtons game={game} home={toggleHomeModal} />

        <Modal doShow={homeModal} closeOnEscape={toggleHomeModal}>
          <Confirm
            confirm={() => {
              navigate(`${routeCodes.GAMES}${game.localeShortname || game.shortname}`);
            }}
            cancel={toggleHomeModal}
            button={getString("yes.0")}
            cancelButton={getString("no.0")}
          >
            <div className="modal-content-header">{getString("game.mobile.exit")}</div>
          </Confirm>
        </Modal>
      </>
    );
  }

  return <LoadingAnim position="fixed" />;
}

MobileGame.propTypes = {
  shortname: PropTypes.string.isRequired,
};
