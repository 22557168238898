import React from "react";
import { gtmGAEvent } from "../../utilities/gtm";
import { getString } from "../../utilities";
import { getLangCode, variousURLs } from "../../config";
import IXLLogo from "../../../assets/img/ixlLogo.svg";
import RosettaLogo from "../../../assets/img/logoRosetta.svg";
import VocabularyLogo from "../../../assets/img/logoVocabularyCom.svg";
import EducationLogo from "../../../assets/img/logoEducationCom.svg";
import WyzantLogo from "../../../assets/img/logoWyzant.svg";
import SpanishDictLogo from "../../../assets/img/logoSpanishDictCom.svg";
import InglesLogo from "../../../assets/img/logoInglesCom.svg";
import EmmersionLogo from "../../../assets/img/logoEmmersion.svg";
import TPTLogo from "../../../assets/img/logoTpt.svg";

export default function MultiBrand() {
  const languageCode = getLangCode();

  const renderSpanishIngles = () => {
    if (languageCode === "la") {
      return (
        <a
          className="footer-copyright-brand"
          onClick={() => {
            gtmGAEvent({
              eventCategory: "Inglés.com footer",
              eventAction: "button-click",
              eventLabel: location.pathname,
            });
          }}
          href={variousURLs.ingles}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="fcb-image">
            <img
              src={InglesLogo}
              loading="lazy"
              alt={getString("spanishIngles.title")}
              width="121"
              height="24"
              data-testid="ingles.com"
            />
          </div>

          <div className="fcb-text">{getString("footer.brands.spanishIngles")}</div>
        </a>
      );
    }

    return (
      <a
        className="footer-copyright-brand"
        onClick={() => {
          gtmGAEvent({
            eventCategory: "SpanishDictionary.com footer",
            eventAction: "button-click",
            eventLabel: location.pathname,
          });
        }}
        href={variousURLs.spanishDict}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="fcb-image">
          <img
            src={SpanishDictLogo}
            loading="lazy"
            alt={getString("spanishIngles.title")}
            width="152"
            height="13"
            data-testid="spanishdict.com"
          />
        </div>

        <div className="fcb-text">{getString("footer.brands.spanishIngles")}</div>
      </a>
    );
  };

  return (
    <div className="footer-copyright-brands container">
      <a
        className="footer-copyright-brand"
        onClick={() => {
          gtmGAEvent({
            eventCategory: "IXL footer",
            eventAction: "button-click",
            eventLabel: location.pathname,
          });
        }}
        href={`${variousURLs.ixl.base[languageCode]}/?partner=abcya&adGroup=footer&utm_source=abcya.com&utm_medium=partner&utm_campaign=footer`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="fcb-image">
          <img src={IXLLogo} loading="lazy" alt={getString("ixl.title")} width="65" height="32" />
        </div>

        <div className="fcb-text">{getString("footer.brands.ixl")}</div>
      </a>

      <a
        className="footer-copyright-brand"
        onClick={() => {
          gtmGAEvent({
            eventCategory: "Rosetta Stone footer",
            eventAction: "button-click",
            eventLabel: location.pathname,
          });
        }}
        href={variousURLs.rosettaStone[languageCode]}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="fcb-image">
          <img src={RosettaLogo} loading="lazy" alt={getString("rosetta.title")} width="112" height="21" />
        </div>

        <div className="fcb-text">{getString("footer.brands.rosetta")}</div>
      </a>

      <a
        className="footer-copyright-brand"
        onClick={() => {
          gtmGAEvent({
            eventCategory: "Vocabulary.com footer",
            eventAction: "button-click",
            eventLabel: location.pathname,
          });
        }}
        href={variousURLs.vocabulary}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="fcb-image">
          <img src={VocabularyLogo} loading="lazy" alt={getString("vocabulary.title")} width="109" height="19" />
        </div>

        <div className="fcb-text">{getString("footer.brands.vocabulary")}</div>
      </a>

      <a
        className="footer-copyright-brand"
        onClick={() => {
          gtmGAEvent({
            eventCategory: "Education.com footer",
            eventAction: "button-click",
            eventLabel: location.pathname,
          });
        }}
        href={variousURLs.education}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="fcb-image">
          <img src={EducationLogo} loading="lazy" alt={getString("education.title")} width="123" height="26" />
        </div>

        <div className="fcb-text">{getString("footer.brands.education")}</div>
      </a>

      <a
        className="footer-copyright-brand"
        onClick={() => {
          gtmGAEvent({
            eventCategory: "Wyzant footer",
            eventAction: "button-click",
            eventLabel: location.pathname,
          });
        }}
        href={variousURLs.wyzant}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="fcb-image">
          <img src={WyzantLogo} loading="lazy" alt={getString("wyzant.title")} width="82" height="20" />
        </div>

        <div className="fcb-text">{getString("footer.brands.wyzant")}</div>
      </a>

      {renderSpanishIngles()}

      <a
        className="footer-copyright-brand"
        onClick={() => {
          gtmGAEvent({
            eventCategory: "Emmersion footer",
            eventAction: "button-click",
            eventLabel: location.pathname,
          });
        }}
        href={variousURLs.emmersion}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="fcb-image">
          <img src={EmmersionLogo} loading="lazy" alt={getString("emmersion.title")} width="111" height="20" />
        </div>

        <div className="fcb-text">{getString("footer.brands.emmersion")}</div>
      </a>
      <a
        className="footer-copyright-brand"
        onClick={() => {
          gtmGAEvent({
            eventCategory: "TPT footer",
            eventAction: "button-click",
            eventLabel: location.pathname,
          });
        }}
        href={variousURLs.tpt}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="fcb-image">
          <img src={TPTLogo} loading="lazy" alt={getString("tpt.title")} width="93" height="25" />
        </div>

        <div className="fcb-text">{getString("footer.brands.tpt")}</div>
      </a>
    </div>
  );
}
