import React from "react";
import { useSelector } from "react-redux";
import OurContent from "./ourContent";
import ParentsTeachers from "./parentsTeachers";
import Help from "./help";
import About from "./about";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";

export default function Navigation() {
  const isLoggedIn = useSelector(isLoggedInSelector);

  const loggedStyle = isLoggedIn ? "" : "isLoggedIn";

  return (
    <div className={`footer-nav ${loggedStyle}`}>
      <OurContent />
      <ParentsTeachers />
      <Help />
      <About />
    </div>
  );
}
