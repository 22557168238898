import React from "react";
import PropTypes from "prop-types";
import { getString } from "../../utilities";
import Modal from "./modal";
import ModalClose from "./modalClose";

export default function NotAppliedModal({ doShow, type = "giftCard", close }) {
  const header = getString(`notApplied.${type}.header`);
  const cta = getString(`notApplied.${type}.cta`);
  const instructions = getString(`notApplied.${type}.instructions`);

  return (
    <Modal doShow={doShow} closeOnEscape={close}>
      <ModalClose close={close} />
      <div className="modal-content-header">{header}</div>
      <p>{cta}</p>
      <p>{instructions}</p>

      <button type="button" className="button-flat-color pt-green" onClick={close}>
        {getString("cancel.1")}
      </button>
    </Modal>
  );
}

NotAppliedModal.propTypes = {
  doShow: PropTypes.bool.isRequired,
  type: PropTypes.string,
  close: PropTypes.func.isRequired,
};
