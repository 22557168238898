import React from "react";
import PropTypes from "prop-types";
import { getString } from "../../utilities";
import NoSymbol from "../../../assets/svg/noSymbol.svg";
import Lock from "../../../assets/svg/unlock.svg";
import Devices from "../../../assets/svg/devices.svg";
import SubscriptionCard from "./subscriptionCard";
import BackToClassroom from "./backToClassroom";

export default function SchoolPlans({ plan, planData }) {
  return (
    <div className="wrapper plans-wrapper school">
      <div className="plans-title">{getString("subscribe.plans.school.title.0")}</div>

      <ul className="plans-perks">
        <li>
          <NoSymbol className="icon" />
          {getString("subscribe.plans.school.perksStripe.0")}
        </li>
        <li>
          <Lock className="icon" />
          {getString("subscribe.plans.school.perksStripe.1")}
        </li>
        <li>
          <Devices className="icon" />
          {getString("subscribe.plans.school.perksStripe.2")}
        </li>
      </ul>

      <div className="plans-subs">
        <SubscriptionCard planData={planData} plan={plan} subscription="m12" />
      </div>

      <BackToClassroom />
    </div>
  );
}

SchoolPlans.propTypes = {
  plan: PropTypes.string,
  planData: PropTypes.object,
};
