/* eslint-disable camelcase */
// window.dataLayer instantiated in js/client.jsx

import { userRoles } from "../config";
import store from "../redux/store";
import { hasTouch, mobile } from "./detection";

const getUserType = () => {
  const { role } = store().getState().login;
  switch (role) {
    case userRoles.free:
      return "Free Subscriber";
    case userRoles.classroom:
      return "Paid Classroom";
    case userRoles.school:
      return "Paid School";
    case userRoles.family:
      return "Paid Family";
    case userRoles.student:
      return "Student";
    default:
      return "Free Subscriber";
  }
};

export const gtmGAEvent = ({ eventCategory, eventAction, eventLabel, eventValue }) => {
  // eventCategory and eventAction **REQUIRED**
  // others optional

  const eventData = {
    event: "GAEvent",
    eventCategory,
    eventAction,
  };

  if (eventLabel) eventData.eventLabel = eventLabel;
  if (eventValue) eventData.eventValue = eventValue;
  window.dataLayer.push(eventData);
};

export const gtmPageView = (path) => {
  setTimeout(() => {
    // call in a timeout to allow Helmet to correctly set the page title
    const title = document.title;
    const { isLoggedIn } = store().getState().login;

    const pvData = {
      ...(isLoggedIn && { user_type: getUserType() }),
      event: "pageview",
      page: {
        path,
        title,
      },
      mobile,
      hasTouch,
    };

    window.dataLayer.push(pvData);
  }, 100);
};

export const gtmSignUp = (id, options) => {
  // GTM for free/public subscribe

  const dataObject = {
    event: "sign_up",
    user_id: id,
    ...(options?.module && { module: options.module }),
  };
  window.dataLayer.push(dataObject);
};

export const gtmSubscribeNow = (plan) => {
  window.dataLayer.push({
    ecommerce: {
      add: {
        products: [
          {
            name: plan.name,
            id: plan.planId,
            price: plan.amount,
            category: "subscriptions",
            quantity: 1,
          },
        ],
      },
    },
    event: "ecSubscribeNow",
    attributes: {
      planType: plan.name,
    },
  });
};

export const gtmSubscribed = (plan, options) => {
  // GTM - subscribed event successful
  const { isLoggedIn } = store().getState().login;
  window.dataLayer.push({
    ...(isLoggedIn && { user_type: getUserType() }),
    ecommerce: {
      purchase: {
        actionField: {
          ...(options?.invoiceId && { id: options.invoiceId }),
          affiliation: "",
          revenue: plan.amount,
          tax: "",
          shipping: "",
          coupon: "",
        },
        products: [
          {
            name: plan.name,
            id: plan.planId,
            price: plan.amount,
            category: "subscriptions",
            variant: "",
            quantity: 1,
            coupon: "",
          },
        ],
      },
    },
    event: "ecSubscribed",
    attributes: {
      planType: plan.name,
    },
  });
};

export const gtmDeleteCancelSub = (name, del) => {
  // GTM - canceled subscription event successful
  const gtmEvent = del ? "deleteSub" : "cancelSub";

  window.dataLayer.push({
    event: gtmEvent,
    attributes: {
      planType: name,
    },
  });
};

export const gtmLogInOut = (user) => {
  // GTM - subscriber is logged in or out of web-client

  const ioData = user
    ? {
        isLoggedIn: true,
        role: user.role,
        billingPlatform: user.billingPlatform,
      }
    : {
        isLoggedIn: false,
      };

  window.dataLayer.push(ioData);
};

export const gtmJoinClick = (path, category) => {
  // GTM - Join Now (Subscribe) Page Clicks

  window.dataLayer.push({
    page: path,
    category,
    event: "join-click",
  });
};

export const gtmFcp = ({ value, rating, delta, id }) => {
  window.dataLayer.push({
    event: "FCP",
    FCP: {
      value,
      rating,
      delta,
      id,
    },
  });
};

export const gtmTtfb = ({
  value,
  rating,
  delta,
  id,
  attribution: { waitingDuration, dnsDuration, connectionDuration, requestDuration },
}) => {
  window.dataLayer.push({
    event: "TTFB",
    TTFB: {
      value,
      rating,
      delta,
      id,
      waitingTime: waitingDuration,
      dnsTime: dnsDuration,
      connectionTime: connectionDuration,
      requestTime: requestDuration,
    },
  });
};

export const gtmLcp = ({
  value,
  rating,
  delta,
  id,
  attribution: { element, resourceLoadDelay, resourceLoadDuration, elementRenderDelay },
}) => {
  window.dataLayer.push({
    event: "LCP",
    LCP: {
      value,
      rating,
      delta,
      id,
      element,
      resourceLoadDelay,
      resourceLoadTime: resourceLoadDuration,
      elementRenderDelay,
    },
  });
};

export const gtmCls = ({ value, rating, delta, id, attribution: { largestShiftTarget, largestShiftValue } }) => {
  window.dataLayer.push({
    event: "CLS",
    CLS: {
      value,
      rating,
      delta,
      id,
      largestShiftTarget,
      largestShiftValue,
    },
  });
};
