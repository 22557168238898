import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { getString } from "../../utilities";
import { routeCodes } from "../../config/routes";

export default function Invoiced({ status = "", resetCardHandler }) {
  const navigate = useNavigate();

  const titleStrings = {
    invoiced: getString("redeem.card.invoiced.title"),
    friends: getString("redeem.card.friends.title"),
  };

  const messageStrings = {
    invoiced: getString("redeem.card.invoiced.message", { html: true }),
    friends: getString("redeem.card.friends.message", { html: true }),
  };

  return (
    <>
      <h2>{titleStrings[status]}</h2>

      <div>{messageStrings[status]}</div>

      <div className="redeem-card-form-buttons">
        <button
          type="button"
          className="button-flat-color green"
          onClick={() => {
            navigate(routeCodes.CONTACT, { replace: true });
          }}
        >
          {getString("contact.1")}
        </button>

        <button type="button" className="button-flat-color orange" onClick={resetCardHandler}>
          {getString("redeem.card.anotherEmail")}
        </button>
      </div>
    </>
  );
}

Invoiced.propTypes = {
  status: PropTypes.string,
  resetCardHandler: PropTypes.func.isRequired,
};
