import { isSafari, isFirefox, browserVersion } from "react-device-detect";
import {
  printSaveCleanup,
  setEventLabel,
  setIsPrint,
  setLoading,
  setPrintCleanModal,
  setPrintData,
  setPrintPDFModal,
  setPrintSaveCallback,
  setSaveData,
  toggleShowPrintSaveErrorModal,
} from "../redux/slices/printSaveSlice";
import store from "../redux/store";
import { asyncTimeout, fullscreenSupported, sendResponse } from "./";
import { fullScreen } from "./games";
import { mobile } from "./detection";
import { gtmGAEvent } from "./gtm";

const numOfTries = 7;
let queryCounter = 0;
const url = window.URL || window.webkitURL;

const getBlobFromUrl = async (fileURL, resolve, reject) => {
  try {
    // console.log('queryCounter: ', queryCounter)
    await asyncTimeout(queryCounter * 1000);
    ++queryCounter;

    const url = `${fileURL}?cache=${new Date().getTime()}`;
    // console.log('****down load url: ', url)
    const request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.responseType = "blob";
    request.onload = () => {
      resolve(request.response);
    };
    request.onerror = (error) => {
      if (queryCounter <= numOfTries) {
        getBlobFromUrl(fileURL, resolve, reject);
      } else {
        reject(error);
      }
    };
    request.send();
  } catch (error) {
    if (queryCounter <= numOfTries) {
      // console.log(`fetch catch onerror ${queryCounter}: '${error}`)
      getBlobFromUrl(fileURL, resolve, reject);
    } else {
      // console.log('**fetch catch error final: ', error)
      reject(error);
    }
  }
};

const getDataFromBlob = (fileBlob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(fileBlob);
  });
};

export function parseBlob(fileData, fileType) {
  return new Promise(async (resolve) => {
    try {
      const b64Reg = new RegExp("data:(.*?);base64,(.*)");
      // const b64Reg = `/data:(.*?);base64,(.*)/`
      let match = await fileData.match(b64Reg);

      if (!match) {
        // not a base64 file
        // fetch file then convert to base64
        // const fileBlob = await getBlobFromUrl(fileData)
        const fileBlob = await new Promise((resolve, reject) => {
          getBlobFromUrl(fileData, resolve, reject);
        });

        queryCounter = 0; // reset to reuse

        const b64Data = await getDataFromBlob(fileBlob);

        if (b64Data) {
          match = b64Data.match(b64Reg);
        }

        if (!b64Data || !match) {
          return resolve(null);
        }
      }

      const byteString = atob(match[2]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      const type = fileType || match[1];

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return resolve(new Blob([ab], { type }));
    } catch (error) {
      // console.log('parseBlob error: ', error)
      queryCounter = await 0; // reset to reuse
      return resolve(null);
    }
  });
}

export const waitForPrint = async () => {
  const dispatch = store().dispatch;
  const { printData } = store().getState().printSave;
  for (let i = 0; i < 5 && printData; ++i) {
    await asyncTimeout(i * 500);
  }
  dispatch(setPrintData({ printData: null }));
};

export const exitFullScreen = async () => {
  if (fullscreenSupported() && store().getState().userClient.fullScreenActive) {
    // on all accounts a game is full screen
    // close it and wait for it to close to carry on
    fullScreen.exit();

    // wait 1.5 seconds for browsers to animate out of fullscreen
    await asyncTimeout(1500);
    // console.log('fullscreen should be exited')
  }

  // on all accounts a game is NOT full screen
  // do nothing and carry on

  return true;
};

export const cleanupPrintSave = (status = "complete") => {
  // console.log('Save Cleanup!')
  const { printSave } = store().getState();
  const { eventLabel, isPrint } = printSave;

  // GA event push
  gtmGAEvent({
    eventCategory: isPrint ? "print" : "download",
    eventAction: "button-click",
    eventLabel,
  });

  // postMessage to game for success/fail
  const { origin, message } = printSave;
  if (origin && message) {
    const parsedMessage = JSON.parse(message);
    parsedMessage.payload = null;
    parsedMessage.status = status;
    sendResponse(parsedMessage, origin);
  }

  store().dispatch(printSaveCleanup());
};

export const setPrint = async (print) => {
  await exitFullScreen();

  try {
    store().dispatch(setIsPrint({ isPrint: true }));
    if (print.origin) {
      store().dispatch(setPrintSaveCallback({ origin: print.origin, message: print.message }));
    }

    store().dispatch(setEventLabel({ eventLabel: print.eventLabel }));
    store().dispatch(setSaveData({ saveData: null }));

    // if print is not null
    // printCleanup first
    // wait for print to be null
    await waitForPrint();

    if (print.fileType && print.fileType === "pdf") {
      if (isFirefox || mobile) {
        // pdf printing is not supported
        // use regular file path to open in new window
        store().dispatch(setPrintData({ printData: { ...print, pdfPrint: false } }));
        store().dispatch(setPrintPDFModal({ printPDFModal: true }));
      } else {
        // pdf printing kick off
        store().dispatch(setLoading({ loading: true }));

        const printBlob = await parseBlob(print.fileData, "application/pdf");

        store().dispatch(setLoading({ loading: false }));

        if (printBlob) {
          const fileData = url.createObjectURL(printBlob);
          // console.log('print fileData: ', fileData)
          store().dispatch(setPrintData({ printData: { ...print, fileData, pdfPrint: true } }));
        }
      }
    } else {
      // defaulting to jpg (image)
      // safari 12 specific bug fix
      const safari12orOlder = isSafari && browserVersion < 13;
      store().dispatch(setPrintCleanModal({ printCleanModal: mobile || safari12orOlder }));
      store().dispatch(setPrintData({ printData: print }));
    }
  } catch (e) {
    if (print.silent) {
      cleanupPrintSave("failed");
    } else {
      store().dispatch(toggleShowPrintSaveErrorModal());
    }
  }
};

export const setSave = async (save) => {
  try {
    store().dispatch(setIsPrint({ isPrint: false }));
    if (save.origin) {
      store().dispatch(setPrintSaveCallback({ origin: save.origin, message: save.message }));
    }

    store().dispatch(setEventLabel({ eventLabel: save.eventLabel }));
    store().dispatch(setPrintData({ printData: null }));
    await exitFullScreen();

    // console.log('save.fileType: ', save.fileType)
    if (window.Modernizr.adownload) {
      store().dispatch(setLoading({ loading: true }));

      const saveBlob = await parseBlob(save.fileData);

      store().dispatch(setLoading({ loading: false }));

      // console.log('saveBlob: ', saveBlob)

      if (saveBlob) {
        const fileData = url.createObjectURL(saveBlob);
        store().dispatch(setSaveData({ saveData: { ...save, fileData } }));
      }
    } else {
      store().dispatch(setSaveData({ saveData: save }));
    }
  } catch (e) {
    if (save.silent) {
      cleanupPrintSave("failed");
    } else {
      store().dispatch(toggleShowPrintSaveErrorModal());
    }
  }
};
