import { isTesting } from "../utilities/detection";

export const isSiteDown = false;

export const ports = {
  en: 3000,
  la: 3003,
};

export const getLangCode = () => {
  return process.env.LANG_CODE;
};

export const apiList = {
  development: "https://local.abcya.com:4000",
  staging: "https://staging-api.abcya.com",
  production: "https://api.abcya.com",
};

/* mimics web-client"s scss variables _app.scss */
export const abcyaMainColorPalette = {
  lightGrey: "#e5e2df",
  grey: "#848479",
  darkGrey: "#3d3d3a",
  green: "#99dd17",
  darkGreen: "#16c41f",
  lightGreen: "#C9F414",
  lightCyan: "#38E57E",
  cyan: "#12cca8",
  darkCyan: "#108C99",
  yellow: "#ffc41d",
  orange: "#ffaa00",
  darkOrange: "#f77116",
  lightOrange: "#FFD527",
  red: "#e24829",
  darkRed: "#CE2E2E",
  dark: "#001E27",
  darkDark: "#001723",
  lightDark: "#0C3439",
  purple: "#A05CF7",
  darkPurple: "#6B42D1",
  lightPurple: "#C471FF",
  ptSteel: "#91B3B7",
  ptCyan: "#00A39B",
  ptGrey: "#f4f4f4",
};

export const variousURLs = {
  abcya: {
    development: {
      en: `https://local.abcya.com:${ports.en}`,
      la: `https://local.abcya.com:${ports.la}`,
    },
    testing: { en: "https://testing.abcya.com", la: "testing.abcya.com" },
    staging: { en: "https://staging.abcya.com", la: "https://la-staging.abcya.com" },
    production: { en: "https://www.abcya.com", la: "https://la.abcya.com" },
  },
  affiliateMarketing: "http://app.impact.com/campaign-campaign-info-v2/ABCya.brand",
  kidsafe: "https://www.kidsafeseal.com/certifiedproducts/abcya.html",
  kidsafeImg: "https://www.kidsafeseal.com/sealimage/389116890385031067/abcya_large_darktm.png",
  facebook: "https://www.facebook.com/ABCyaGames/",
  twitter: "https://twitter.com/abcyagames",
  instagram: "https://www.instagram.com/abcyagames/",
  vimeo: "https://vimeo.com/abcya",
  ixl: {
    base: { en: "https://www.ixl.com", la: "https://la.ixl.com" },
    manage: {
      family: {
        en: "https://www.ixl.com/membership/family/manage",
        la: "https://la.ixl.com/afiliacion/familiar/manage",
      },
    },
  },
  rosettaStone: { en: "https://www.rosettastone.com/", la: "https://espanol.rosettastone.com/" },
  vocabulary: "https://www.vocabulary.com/",
  education: "https://www.education.com/",
  wyzant: "https://www.wyzant.com/",
  spanishDict: "https://www.spanishdict.com/",
  ingles: "https://www.ingles.com/",
  emmersion: "https://emmersion.ai/",
  tpt: "https://www.teacherspayteachers.com/",
  arcademics: {
    intro: "https://ideas.arcademics.com/ideas/affiliates/affiliateAbcyaLogo2019.gif",
    game: "https://static.arcademics.com/games-html5",
  },
  nativeManage: {
    IOS: "https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/manageSubscriptions",
    ANDROID: "https://play.google.com/store/account/subscriptions",
    AMAZON: "https://www.amazon.com/gp/mas/your-account/myapps/yoursubscriptions",
  },
  nativeDownload: {
    IOS: "https://apps.apple.com/us/app/abcya-games/id1079974015",
    ANDROID: "https://play.google.com/store/apps/details?id=com.abcya.android.games",
    AMAZON: "https://www.amazon.com/ABCya-com-LLC-ABCya-Games/dp/B075M2VXLX",
  },
  productOfferings: {
    abcyaBingo: {
      IOS: "https://apps.apple.com/us/app/abcya-bingo-collection/id1617645138",
      ANDROID: "https://play.google.com/store/apps/details?id=com.abcya.android.bingo2",
      AMAZON: "https://www.amazon.com/ABCya-com-LLC-ABCya-BINGO-Collection/dp/B09X6B5VHL/",
    },
    createACar: {
      IOS: "https://apps.apple.com/us/app/create-a-car/id388173036",
    },
    donutDrop: {
      IOS: "https://apps.apple.com/us/app/donut-drop-by-abcya/id1355825388",
      ANDROID: "https://play.google.com/store/apps/details?id=com.abcya.donutdrop",
      AMAZON: "https://www.amazon.com/ABCya-com-LLC-Donut-Drop-ABCya/dp/B07BGF1HBL",
    },
    dreidel: {
      IOS: "https://apps.apple.com/us/app/dreidel-by-abcya/id1322013425",
    },
    mathBingo: {
      IOS: "https://apps.apple.com/us/app/math-bingo/id371338715",
    },
    wordBingo: {
      IOS: "https://apps.apple.com/us/app/word-bingo/id406264088",
    },
  },
  schema: "https://schema.org",
  logo: { en: "https://www.abcya.com/static/assets/logo.png", la: "https://la.abcya.com/static/assets/logo.png" },
  adDetect: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
  abcyaStore: "https://abcya.myspreadshop.com",
  // adDetect: "https://px.moatads.com/pixel.gif"
};

export const assetsUrls = {
  development: "https://assets-development.abcya.com/",
  staging: "https://assets-staging.abcya.com/",
  production: "https://assets.abcya.com/",
};

export const assetUrl = assetsUrls[process.env.NODE_ENV] || assetsUrls.development;

export const mediaUrls = {
  development: "content-staging.abcya.com",
  testing: "content-testing.abcya.com",
  staging: "content-staging.abcya.com",
  production: "media.abcya.com",
};

const originList = {
  test: [variousURLs.abcya.development.en],
  development: [
    `http://local.abcya.com:${ports[getLangCode()]}`,
    `http://localhost:${ports[getLangCode()]}`,
    variousURLs.abcya.development[getLangCode()],
    `https://localhost:${ports[getLangCode()]}`,
    "http://localhost:8080",
    `https://${mediaUrls.development}`,
    "https://local.abcya.com:8888",
  ],
  staging: [
    variousURLs.abcya.staging[getLangCode()],
    variousURLs.abcya.testing[getLangCode()],
    `https://${mediaUrls.testing}`,
    `https://${mediaUrls.staging}`,
  ],
  production: ["https://abcya.com", variousURLs.abcya.production[getLangCode()], `https://${mediaUrls.production}`],
};

export const language = {
  default: "en",
  la: "la",
  codes: {
    en: "en_US",
    la: "es_US",
  },
  stripeLocales: {
    // https://docs.stripe.com/js/appendix/supported_locales
    la: "es-419",
  },
  resetHash: "#resetLang",
  preferenceToken: "abcya_lang_pref",
};

export const startupData = {
  development: [
    `${originList.development[2]}/abcyaStartup${
      getLangCode() !== language.default ? `_${getLangCode()}` : ""
    }_dev_web.json`,
    `${originList.development[2]}/abcyaStartup${
      getLangCode() !== language.default ? `_${getLangCode()}` : ""
    }_dev_web_games.json`,
    `${originList.development[2]}/abcyaStartup${
      getLangCode() !== language.default ? `_${getLangCode()}` : ""
    }_dev_web_printables.json`,
  ],
  staging: [
    `${assetsUrls.staging}abcyaStartup${
      getLangCode() !== language.default ? `_${getLangCode()}` : ""
    }_staging_web.json`,
    `${assetsUrls.staging}abcyaStartup${
      getLangCode() !== language.default ? `_${getLangCode()}` : ""
    }_staging_web_games.json`,
    `${assetsUrls.staging}abcyaStartup${
      getLangCode() !== language.default ? `_${getLangCode()}` : ""
    }_staging_web_printables.json`,
  ],
  production: [
    `${assetsUrls.production}abcyaStartup${getLangCode() !== language.default ? `_${getLangCode()}` : ""}_web.json`,
    `${assetsUrls.production}abcyaStartup${
      getLangCode() !== language.default ? `_${getLangCode()}` : ""
    }_web_games.json`,
    `${assetsUrls.production}abcyaStartup${
      getLangCode() !== language.default ? `_${getLangCode()}` : ""
    }_web_printables.json`,
  ],
};

export const startupDataKey = "startupData";

export const validAge = 18;

export const publicFavLimit = 3;

export const arcademicsIntroDuration = 3400;

export const giftCardAmount = {
  minimum: 10,
  maximum: 500,
  increment: 5,
};

// user planGroups/roles
export const userRoles = {
  free: "PUBLIC",
  student: "STUDENT",
  classroom: "CLASSROOM",
  school: "SCHOOL",
  family: "FAMILY",
};

// content types mostly used regarding minibanners and search
export const contentTypesData = {
  all: { en: "all", la: "todo" },
  blog: { en: "blog" },
  faq: { en: "faq", la: "preguntas-frecuentes" },
  game: { en: "game", la: "juego" },
  link: { en: "link", la: "enlace" },
  standard: { en: "standard" },
  printable: { en: "printable", la: "imprimible" },
};

export const contentTypes = {
  all: contentTypesData.all[language.default],
  blog: contentTypesData.blog[language.default],
  faq: contentTypesData.faq[language.default],
  game: contentTypesData.game[language.default],
  link: contentTypesData.link[language.default],
  standard: contentTypesData.standard[language.default],
  printable: contentTypesData.printable[language.default],
};

// content (video) ad types
export const contentAdTypes = {
  preroll: "PREROLL",
  clickToPlay: "CLICK_TO_PLAY",
  none: "NONE",
};

export const getContentType = (key, options = {}) => {
  const { langCode } = options;

  const contentObject = contentTypesData[key];
  const lc = langCode || language.default;

  if (contentObject) {
    return contentObject[lc] || contentObject[language.default];
  }

  return undefined;
};

export const getDefaultContentType = (typeValue) => {
  const contentObject = Object.values(contentTypesData).find((typeObject) => {
    return Object.values(typeObject).includes(typeValue);
  });

  if (contentObject) {
    return contentObject[language.default];
  }

  return undefined;
};

export const categoryTypes = ["games", "coloring-pages", "activity-pages", "worksheets", "teacher-resources"];

// games that require motion controls
// request api permission in mobile safari
export const motionGames = ["math_stack"];

export const tokenName = "abctoken";

// same as the API schema enum BillingPlatform
// web will only ever use the one, rest are for native platforms
export const billingPlatform = "STRIPE";

// native billing platforms
export const nativePlatforms = ["AMAZON", "ANDROID", "IOS"];

// for gift card code length see common shared files
// utilities/forms/rules.js { validGiftcard }
export const giftcardMax = 500;

export const giftcardAmounts = [10, 25, 50, 100];

export const giftcardQuantity = 10;

export const blogPosts = {
  initial: 6,
  additional: 3,
};

export const breakpoints = {
  // breakpoints should mimic breakpoints also defined in stylesheets
  width: {
    xs: 360,
    sm: 640,
    md: 768,
    lg: 1024,
    // adsLG: 1250, // 1024  - 800px with 2 skyscraper ads (160 x 600) and padding
    adsLG: 1330, // 1024  - 800px with 2 skyscraper ads (160 x 600) and padding
    xl: 1440,
    adsXL: 1554, // 1440 - 1024 with 2 skyscraper ads (160 x 600) and padding
    xxl: 1920,
  },
};

// sky right data backup
/* sky: {
      left: "div-gpt-ad-1657882894107-0",
      
    }, */
export const adData = {
  divIds: {
    leader: {
      desktop: [
        "div-gpt-ad-1657882815693-0",
        "div-gpt-ad-1692042934308-0",
        "div-gpt-ad-1692042942697-0",
        "div-gpt-ad-1692906502273-0",
        "div-gpt-ad-1721151742876-0",
        "div-gpt-ad-1721151781079-0",
      ],
      mobile: [
        "div-gpt-ad-1658848596005-0",
        "div-gpt-ad-1692042934308-1",
        "div-gpt-ad-1692042942697-1",
        "div-gpt-ad-1692906502273-1",
      ],
    },
    sky: {
      left: "div-gpt-ad-1657882894107-0",
      right: "div-gpt-ad-1657882930745-0",
    },
    billboard: "div-gpt-ad-1692042951104-0",
  },
  headScripts: [
    {
      src: "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
    },
    {
      src: {
        en: "https://qd.admetricspro.com/js/abcya/layout-all.js",
        la: "https://qd.admetricspro.com/js/abcya/layout-all-spanish.js",
      },
    },
    {
      src: "https://qd.admetricspro.com/js/abcya/cmp.js",
    },
    {
      src: "https://qd.admetricspro.com/js/abcya/uspcmp.js",
    },
    {
      src: "https://qd.admetricspro.com/js/abcya/prebid.js",
    },
    {
      src: "https://qd.admetricspro.com/js/abcya/engine.js",
    },
    {
      type: "text/javascript",
      src: "https://cadmus.script.ac/d1fnntfgak3e9u/script.js",
    },
  ],
  adPlacementUrl: {
    en: "https://pubads.g.doubleclick.net/gampad/ads?iu=/22404337467,22570185246/ABCya-Games-Pre-Roll&description_url=https%3A%2F%2Fwww.abcya.com%2Fgames&tfcd=1&npa=1&sz=480x360%7C640x480%7C1280x960&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&vpa=auto&impl=s&correlator=",
    la: "https://pubads.g.doubleclick.net/gampad/ads?iu=/22404337467,22570185246/ABCya-LA-Games-Pre-Roll&description_url=https%3A%2F%2Fla.abcya.com%2Fjuegos&tfcd=1&npa=1&sz=320x240%7C480x360%7C640x480%7C1280x960&min_ad_duration=5000&max_ad_duration=30000&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=",
  },
  preContentVideoDependencies: {
    styles: [
      "https://vjs.zencdn.net/7.11.4/video-js.css",
      "https://cdnjs.cloudflare.com/ajax/libs/videojs-contrib-ads/6.7.0/videojs.ads.css",
      "https://cdnjs.cloudflare.com/ajax/libs/videojs-ima/1.10.1/videojs.ima.css",
    ],
    sources: [
      "https://imasdk.googleapis.com/js/sdkloader/ima3.js",
      "https://vjs.zencdn.net/7.11.4/video.min.js",
      "https://cdnjs.cloudflare.com/ajax/libs/videojs-ima/1.10.1/videojs.ima.min.js",
      "https://cdnjs.cloudflare.com/ajax/libs/videojs-contrib-ads/6.0.0/videojs.ads.min.js",
      // client side, "/static/ovp.js" or "/static/click-to-play-ovp.js"
    ],
  },
};

export const stripePublishableKeys = {
  test: "pk_test_rcKmM9Ij6bqWmLsJnutJEgGH",
  development: "pk_test_rcKmM9Ij6bqWmLsJnutJEgGH",
  staging: "pk_test_rcKmM9Ij6bqWmLsJnutJEgGH",
  production: "pk_live_N7s0PurhLVSw4SZoBqNnAkX9",
};

export const getAPIEndpoint = () => {
  return apiList[process.env.NODE_ENV];
};

export const getStartupDataUrls = () => {
  return startupData[process.env.NODE_ENV];
};

export const getGradeInfoUrl = (number) => {
  const originUrl = {
    development: `${originList.development[2]}/gradeSeo/`,
    staging: assetsUrls.staging,
    production: assetsUrls.production,
  };

  return `${originUrl[process.env.NODE_ENV]}gradeSeo_${getLangCode()}_${number}.json`;
};

export const getGameInfoUrl = (shortname) => {
  const originUrl = {
    development: `${originList.development[2]}/gameSeo/`,
    staging: assetsUrls.staging,
    production: assetsUrls.production,
  };

  return `${originUrl[process.env.NODE_ENV]}gameSeo_${getLangCode()}_${shortname}.json`;
};

export const getMediaUrl = () => {
  if (isTesting()) {
    return `https://${mediaUrls.testing}`;
  }
  return `https://${mediaUrls[process.env.NODE_ENV]}`;
};

export const getAbcyaUrl = (desiredCode) => {
  const languageCode = desiredCode || getLangCode();
  const envObject = variousURLs.abcya[process.env.NODE_ENV] || variousURLs.abcya.development;

  return envObject[languageCode];
};

export const getOriginList = () => {
  return originList[process.env.NODE_ENV];
};

export const recaptchaSiteKey =
  process.env.NODE_ENV === "production"
    ? `6LcJf8AUAAAAAI2DThqV39DDKF_4ldDw1qjOsFSw`
    : `6Lf-_MAUAAAAAEGaPkmpOUwidujr0rOdKoyyHVAu`;

export const recaptchaScriptSrc = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`;

// must match error string used in api
// nexus/config/default.json -> errors.authorized
export const AUTH_ERROR = "Not Authorized";

export const HOME_LIST_COUNT = 5;

export const EMAIL_CHAR_LIMIT = 320;

export const PASSWORD_CHAR_LIMIT = 64;

export const PASSWORD_CHAR_MINIMUM = 6;

export const ACCESS_NAME_CHAR_LIMIT = 256;

export const TEXT_FIELD_CHAR_LIMIT = 1000;

export const DEVICE_COUNT_CHAR_LIMIT = 7;

export const STUDENT_COUNT_CHAR_LIMIT = 5;

export const FREE_MOBILE_GAMES_LIST_COUNT = 6;

export const searchParamData = {
  name: { en: "name", la: "nombre" },
  code: { en: "code", la: "codigo" },
  path: { en: "path", la: "ruta" },
  term: { en: "term", la: "condicion" },
  id: { en: "id", la: "identificacion" },
  type: { en: "type", la: "tipo" },
  promo: { en: "promo", la: "promocion" },
  grade: { en: "grade", la: "grado" },
  subscription: { en: "subscription", la: "suscripcion" },
};

export const getSearchParamString = (key) => {
  const paramObject = searchParamData[key];

  if (paramObject) {
    return paramObject[getLangCode()] || paramObject[language.default];
  }

  return undefined;
};

export const getSearchParamArray = (key) => {
  const paramObject = searchParamData[key];
  return paramObject ? Object.values(paramObject) : [];
};

export const getParamValue = (key, options) => {
  const location = options?.location || window.location;
  const searchParams = new URLSearchParams(location.search);

  const paramValues = getSearchParamArray(key).reduce((paramArray, param) => {
    const paramValue = searchParams.get(param);
    if (paramValue) {
      paramArray.push(paramValue);
    }
    return paramArray;
  }, []);

  if (paramValues.length > 0) {
    // return last entry if more than one provided
    return paramValues[paramValues.length - 1];
  }

  return undefined;
};
