import React from "react";
import { Link } from "react-router-dom";

import { getString } from "../../utilities";
import Arrow from "../../../assets/svg/arrowRight.svg";
import { routeCodes } from "../../config/routes";

export default function SchoolSubCTA() {
  return (
    <>
      <p>{`${getString("subscribe.plans.school.want")} `}</p>

      <div className="sub-cta-links-container">
        <Link
          data-testid="schoolSubCtaRFQ"
          type="button"
          to={routeCodes.SUBSCRIBE_SCHOOL}
          className="planbutton button-flat-color pt-light-blue round auto sub-cta-link"
        >
          {getString("subscribe.plans.school.requestQuote").toUpperCase()}
          <Arrow className="inline-arrow" />
        </Link>

        <Link
          data-testid="schoolSubCtaPurchase"
          type="button"
          to={routeCodes.SUBSCRIBE_ABCYA_SCHOOL}
          className="planbutton button-flat-color pt-light-blue round auto sub-cta-link"
        >
          {getString("subscribe.plans.school.purchase")}
          <Arrow className="inline-arrow" />
        </Link>
      </div>
    </>
  );
}
