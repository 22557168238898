import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import { CHECK_PROMO_QUERY } from "../../graphql/queries";
import TextViewHook from "../global/textViewHook";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import { getString, currencyConvert } from "../../utilities";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { TEXT_FIELD_CHAR_LIMIT, getLangCode, getParamValue } from "../../config";
import { useLazyQuery } from "../../hooks";

export const getOffText = (promo) => {
  let offText = getString("promo.title.0");

  if (promo.amountOff > 0) {
    offText = getString("promo.off", { replace: [currencyConvert(promo.amountOff)] });
  } else if (promo.percentOff > 0) {
    offText = getString("promo.off", { replace: [`${promo.percentOff * 100}%`] });
  }

  return offText;
};

export default function PromoField({
  promo = null,
  setPromo,
  hasPromoNotApplied = true,
  setHasPromoNotApplied,
  planGroup,
  isDisabled = false,
}) {
  const [appliedPromo, setAppliedPromo] = useState(promo ? promo.shortname : "");

  const location = useLocation();

  const {
    formState: { errors },
  } = useFormContext();

  const getInitialPromoCode = () => {
    const sn = promo ? promo.shortname : null;

    if (sn) {
      return sn;
    }
    return getParamValue("promo", { location }) || "";
  };

  const [promoCode, setPromoCode] = useState(getInitialPromoCode());
  const [submitErrors, setSubmitErrors] = useState(null);

  const onCompleted = (data) => {
    if (data && data.checkPromo) {
      setPromo(data.checkPromo);
      setAppliedPromo(data.checkPromo.shortname);
    } else {
      // came back null (invalid promo)
      // supply error message
      setSubmitErrors({ message: getString("promo.invalid") });
    }
  };

  const onError = (error) => {
    setSubmitErrors(error);
  };

  const { executeQuery: checkPromo, loading: querying } = useLazyQuery(CHECK_PROMO_QUERY, onCompleted, onError);

  const promoQuery = () => {
    if (!querying && promoCode) {
      // only call query if the promoCode has a codeEntered
      checkPromo({ shortname: promoCode, giftCard: false, ...(planGroup && { planGroup }) });
    }
  };

  useEffect(() => {
    if (!promo) {
      promoQuery();
    }
  }, []);

  useEffect(() => {
    if (promo) {
      if (hasPromoNotApplied) {
        setHasPromoNotApplied(false);
      }
    } else if (promoCode) {
      // only if it's a field that hasn't been applied
      // otherwise comp promo state will be filled

      if (!hasPromoNotApplied) {
        setHasPromoNotApplied(true);
      }
    } else if (hasPromoNotApplied) {
      setHasPromoNotApplied(false);
    }
  }, [promo, promoCode]);

  const renderButtonText = () => {
    if (promo) {
      return (
        <>
          {getOffText(promo)}
          <br />
          {getString("applied")}
        </>
      );
    }

    return <>{getString("apply")}</>;
  };

  if (querying) {
    return (
      <LoadingAnim>
        <p>{getString("promo.applying")}</p>
      </LoadingAnim>
    );
  }

  const handlePromoChanged = (e) => {
    setAppliedPromo(e.target.value);
    setSubmitErrors(null);
    setPromoCode(e.target.value.trim().toLowerCase());
  };

  return (
    <>
      <OptionallyDisplayed doDisplay={!!submitErrors}>
        <ErrorMessage error={submitErrors} />
      </OptionallyDisplayed>

      <div className={`giftcard-field ${promo ? "redeemed" : ""}`}>
        <TextViewHook
          name="promoCode"
          isReadOnly={!!promo}
          isDisabled={isDisabled}
          label={getString("promo.code.0")}
          placeholder={getString("promo.code.0")}
          errors={errors}
          onChange={handlePromoChanged}
          defaultValue={appliedPromo}
        />

        <button
          type="button"
          disabled={isDisabled || !promoCode || !!promo || promoCode?.length > TEXT_FIELD_CHAR_LIMIT}
          className={`button-flat-color pt-steel apply-button ${promo ? "redeemed" : ""} ${getLangCode()}`}
          onClick={promoQuery}
          data-testid="promo-apply-button"
        >
          {renderButtonText()}
        </button>
      </div>
    </>
  );
}

PromoField.propTypes = {
  planGroup: PropTypes.string.isRequired, // family,classroom,school form
  promo: PropTypes.object, // subscribe index
  setPromo: PropTypes.func, // subscribe index
  // *** NEW, to be passed down ***
  hasPromoNotApplied: PropTypes.bool, // family,classroom,school form
  setHasPromoNotApplied: PropTypes.func, // family,classroom,school form
  isDisabled: PropTypes.bool,
};
