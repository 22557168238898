import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { startupDataFetcher } from "../../utilities/data";
import { startupDataKey } from "../../config";

const defaultStartupData = { billboards: [], games: [], lists: [], posts: [], printables: [], standards: [] };
let cachedStartupData = null;
const isGameProxy = window.location.pathname.startsWith("/gameproxy");
try {
  let parsedStartupData = [];
  if (!isGameProxy) {
    try {
      parsedStartupData = JSON.parse(window.localStorage.getItem(startupDataKey));
    } catch (e) {}
  }
  if (!Array.isArray(parsedStartupData)) {
    cachedStartupData = parsedStartupData;
    Object.keys(defaultStartupData).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(cachedStartupData, key)) {
        cachedStartupData[key] = [];
      }
    });
  }
} catch (e) {}

export const fetchStartupData = createAsyncThunk("data/fetchStartupData", startupDataFetcher);

const dataSlice = createSlice({
  name: "data",
  initialState: {
    loading: !cachedStartupData,
    data: cachedStartupData || defaultStartupData,
    error: null,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStartupData.fulfilled, (prevState, action) => {
        const state = prevState;
        state.loading = false;
        state.data = action.payload.reduce((allData, dataChunk) => {
          return { ...allData, ...dataChunk };
        }, {});
        if (!isGameProxy) {
          try {
            window.localStorage.setItem(startupDataKey, JSON.stringify(state.data));
          } catch (e) {}
        }
      })
      .addCase(fetchStartupData.rejected, (prevState, action) => {
        const state = prevState;
        state.loading = false;
        state.error = action.error;
      });
  },
  selectors: {
    loadingSelector: (state) => state.loading,
    dataSelector: (state) => state.data,
    errorSelector: (state) => state.error,
  },
});

export const { loadingSelector, dataSelector, errorSelector } = dataSlice.selectors;

export default dataSlice.reducer;
