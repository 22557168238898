import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { mobile } from "../../utilities/detection";
import { gtmJoinClick, gtmGAEvent } from "../../utilities/gtm";
import BillboardArrow from "../global/billboardArrow";
import { assetUrl, userRoles } from "../../config";
import defaultArt from "../../../assets/img/placeholderBillboard.jpg";
import { routeCodes } from "../../config/routes";
import { isLoggedInWithRole, isLoggedInWithoutRole } from "../../utilities";
import WebP from "../global/WebP";
import { isLoggedInSelector, roleSelector } from "../../redux/slices/loginSlice";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import { leaveSiteHandler } from "../../redux/slices/leavingSiteSlice";
import BillboardAd from "../ads/Billboard";

export default function Billboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [billboards, setBillboards] = useState(null);
  const [billboardID, setBillboardID] = useState(0);
  const [previousID, setPreviousID] = useState(null);
  const [transitioning, setTransitioning] = useState(false);
  const [direction, setDirection] = useState(null);

  // Ref needed for clickHandler because clickHandler is called within setTimeout.
  // SetTimeout refers to the state of the component at the time setTimeout was invoked,
  // not when the callback passed to setTimeout is invoked. So the value of billboards can
  // be outdated.
  const billboardsRef = useRef(billboards);
  billboardsRef.current = billboards;
  const transitioningRef = useRef(transitioning);
  transitioningRef.current = transitioning;

  const data = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);

  const role = useSelector(roleSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);

  useEffect(() => {
    if (!loading && data.billboards) {
      if (data.billboards.length > 0) {
        // filter billboards based on viewableBy and isLoggedIn role
        const nextBillboards = data.billboards.reduce((billArray, bill) => {
          const { viewableBy } = bill;

          const isForAll = viewableBy === "BOTH";
          const isForPublic = viewableBy === "PUBLIC" && (!isLoggedIn || isLoggedInWithRole(userRoles.free));
          const isForSubs = viewableBy === "SUBSCRIBER" && isLoggedInWithoutRole(userRoles.free);
          const isForAdults = viewableBy === "ADULT" && (!isLoggedIn || role !== userRoles.student);
          if (isForAll || isForPublic || isForSubs || isForAdults) {
            billArray.push(bill);
          }

          return billArray;
        }, []);

        setBillboards(nextBillboards);
      } else {
        setBillboards(null);
      }
    }
  }, [data, loading]);

  const clickHandler = (id) => {
    if (!transitioningRef.current && id !== billboardID && billboardsRef.current && billboardsRef.current.length > 1) {
      // don't perform if clicked on same index identifier
      // OR if it's already animating... try to keep it to one at a time
      const lastBill = billboardsRef.current.length - 1;
      const idType = typeof id;
      // defaults assume it's a number provided to function
      // clicking on index indicators
      let newID = id;
      let newDir = id < billboardID ? "left" : "right";

      if (idType === "string") {
        // if id is a string from arrows
        newDir = id;
        if (id === "left") {
          // left minus id by 1 or if 0 make it length of billboards -1
          newID = billboardID === 0 ? lastBill : billboardID - 1;
        } else {
          // right plus id by or if length of billboards - 1 set to 0
          newID = billboardID === lastBill ? 0 : billboardID + 1;
        }
      }

      setBillboardID(newID);
      setPreviousID(billboardID);
      setTransitioning(true);
      setDirection(newDir);
    }
  };

  useEffect(() => {
    const animationSpeed = 500;
    const billboardTimer = setTimeout(() => {
      clickHandler("right");
    }, 5000);
    const transitionTimer = setTimeout(() => {
      setTransitioning(false);
    }, animationSpeed);
    return () => {
      clearTimeout(billboardTimer);
      clearTimeout(transitionTimer);
    };
  }, [billboardID]);

  const renderContent = () => {
    return billboards.map((bill, index) => {
      const billKey = `billKey${index}`;
      const billArt = bill.image && bill.image ? `${assetUrl}${bill.image}` : defaultArt;
      // *** MUST MATCH CSS ANIMATION SPEEDS
      // *** _home.scss -> ($hbc-animation-speed)
      let activeStyle = "";

      if (index === billboardID) {
        // active styles
        if (!direction) {
          activeStyle = " active";
        } else if (direction === "left") {
          activeStyle = " active-left";
        } else {
          activeStyle = " active-right";
        }
      }

      if (index === previousID) {
        // previously active styles
        if (direction === "left") {
          activeStyle = " prev-active-left";
        } else {
          activeStyle = " prev-active-right";
        }
      }

      return (
        <div
          key={billKey}
          className={`billboard-content${activeStyle} ${bill.style}`}
          onClick={() => {
            let billLink = bill.link && bill.link.trim().length > 0 ? bill.link : location.pathname;

            const externalLink = !!(bill.link.startsWith("http") || bill.link.startsWith("www"));

            if (externalLink) {
              // linking to IXL pages, send event
              if (billLink.includes("ixl.com")) {
                gtmGAEvent({
                  eventCategory: "IXL Billboard",
                  eventAction: "billboard-click",
                  eventLabel: location.pathname,
                  eventValue: bill.headline,
                });
              }

              dispatch(leaveSiteHandler({ url: billLink }));
            } else {
              if (!billLink.startsWith("/")) billLink = `/${billLink}`;

              // if the link is to the subscribe route
              // push event to GTM
              if ([routeCodes.SUBSCRIBE, routeCodes.SUBSCRIBE.slice(0, -1)].includes(billLink)) {
                gtmJoinClick(location.pathname, "carousel-image");
              }

              navigate(billLink);
            }
          }}
        >
          <WebP secret={billArt} imgProps={{ className: "image" }} />
          <div className="headline">{bill.headline}</div>
          <div className="body">{bill.body}</div>
        </div>
      );
    });
  };

  const renderIDs = () => {
    return (
      <div className="billboard-content-ids">
        {billboards.map((bill, index) => {
          const idKey = `idKey${index}`;
          const idClass = billboardID === index ? " active" : "";
          return (
            <div
              key={idKey}
              className={`ids${idClass}`}
              onClick={() => {
                clickHandler(index);
              }}
            />
          );
        })}
      </div>
    );
  };

  if (!mobile) {
    if (!isLoggedIn || isLoggedInWithRole(userRoles.free)) {
      return (
        <div className="billboard-ad">
          <BillboardArrow />
          <div className="billboard-content-container">
            <BillboardAd />
          </div>
          <BillboardArrow className="right" />
        </div>
      );
    } else if (billboards && billboards.length > 0) {
      return (
        <div className="billboard">
          <BillboardArrow
            clickHandler={() => {
              clickHandler("left");
            }}
          />
          <div className="billboard-content-container">
            {renderContent()}
            {renderIDs()}
          </div>
          <BillboardArrow
            className="right"
            clickHandler={() => {
              clickHandler("right");
            }}
          />
        </div>
      );
    }
  }

  return null;
}
