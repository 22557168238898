import React from "react";
import PropTypes from "prop-types";
import { mobile } from "../../utilities/detection";
import Modal from "./modal";
import ModalClose from "./modalClose";
import { getString } from "../../utilities";

export default function StandardsModal({ standards, doShow, close, header, click, children }) {
  const renderStandards = () => {
    if (standards && standards.length > 0) {
      return (
        <div className="standards-modal-container">
          {standards.map((s) => {
            const mobileStyle = mobile ? "" : " not-mobile";
            return (
              <div className={`standard${mobileStyle}`} key={s.id}>
                <div className="tag">{s.tag}</div>
                <div className="tip">{s.description}</div>
              </div>
            );
          })}
        </div>
      );
    }

    return <p>{getString("standards.noresults.0")}</p>;
  };

  return (
    <Modal doShow={doShow} closeOnEscape={close} className="modal-content-visible">
      <ModalClose close={close} />

      <div className="modal-content-header">{header}</div>

      {renderStandards()}

      <div>
        <button type="button" onClick={click} className="button-flat-color pt-cyan round auto">
          {children}
        </button>
      </div>
    </Modal>
  );
}

StandardsModal.propTypes = {
  standards: PropTypes.array.isRequired,
  click: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  doShow: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  header: PropTypes.string.isRequired,
};
