import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { mobile } from "../../utilities/detection";
import LoadingAnim from "../global/loadingAnim";
import { getString, isLoggedInWithoutRole } from "../../utilities";
import { userRoles, contentTypes, FREE_MOBILE_GAMES_LIST_COUNT } from "../../config";
import PremiumPlanet from "./premiumPlanet";
import List from "../global/list";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";

export default function MobileFree() {
  const [mobileFreeList, setMobileFreeList] = useState([]);
  const [loading, setLoading] = useState(true);

  const dataLoading = useSelector(loadingSelector);
  const data = useSelector(dataSelector);

  useEffect(() => {
    if (!dataLoading) {
      if (data.lists) {
        const nextMobileFreeList = data.lists.find((list) => {
          return list.platform === "MOBILE" && list.group === "free";
        });
        if (nextMobileFreeList?.items?.length > FREE_MOBILE_GAMES_LIST_COUNT) {
          nextMobileFreeList.items = nextMobileFreeList.items.slice(0, FREE_MOBILE_GAMES_LIST_COUNT);
        }

        setMobileFreeList(nextMobileFreeList);
        setLoading(dataLoading);
      }
    }
  }, [dataLoading, data]);

  const renderList = () => {
    if (loading) {
      return <LoadingAnim />;
    }
    if (mobileFreeList?.items.length > 0) {
      return (
        <List
          items={mobileFreeList.items.reduce((itemArray, item) => {
            const itemGame = data.games ? data.games.find((g) => g.id === item.game) : null;

            if (itemGame) {
              itemArray.push({
                type: contentTypes.game,
                title: itemGame.fullname,
                path: itemGame.localeShortname || itemGame.shortname,
                image: itemGame.miniBanner ? itemGame.miniBanner : null,
                grades: itemGame.grades,
                order: item.order,
                mobile: itemGame.mobileActive,
                searchable: itemGame.searchable,
              });
            }

            return itemArray;
          }, [])}
          platform={mobileFreeList.platform}
          sort="order"
        />
      );
    }

    return null;
  };

  if (mobile && !isLoggedInWithoutRole(userRoles.free)) {
    return (
      <>
        <div className="home-mobile-free">
          <div className="home-mobile-free-title">{getString("freeMobileGames.title")}</div>
          <PremiumPlanet />
        </div>
        <div className="home-mobile-free">
          <div className="free-mobile-games-list-container">{renderList()}</div>
        </div>
      </>
    );
  }

  return null;
}
