import { gtmSubscribeNow } from "./gtm";
import { getString } from "./";

export const submitError = (error, setSubmitErrors, setSubmitting) => {
  if (setSubmitting) setSubmitting(false);
  setSubmitErrors({ message: error.toString() });
  window.scrollTo(0, 0);
};

export const processSubscription = async ({ plan, mutation, variables }) => {
  if (plan) {
    await gtmSubscribeNow(plan);
  }
  mutation(variables);
};

export const subscribeSubmit = async ({
  data,
  setSubmitErrors,
  cardElement,
  mutation,
  creditNotRequired,
  setSubmitting,
  plan,
  myVariables,
  creditComplete,
  action,
  stripe,
}) => {
  const { success, message, key } = data.reCaptchaVerify;

  if (success && key && key.length > 0) {
    // if success and a valid key was provided
    // carry on with proper subscribe submission

    const verifyKey = key;
    const verifyValue = action;

    if (creditNotRequired && !creditComplete) {
      // giftcards are applied and credit card is NOT complete
      const variables = { ...myVariables, verifyKey, verifyValue };
      await processSubscription({ plan, mutation, variables });
    } else {
      // Attempt to validate credit card information.
      // giftcards may or may not be supplied
      try {
        const { token, error } = await stripe.createToken(cardElement);
        if (error) {
          submitError(error.message, setSubmitErrors, setSubmitting);
        } else if (token) {
          const variables = { ...myVariables, source: token.id, verifyKey, verifyValue };
          await processSubscription({ plan, mutation, variables });
        } else {
          submitError(getString("subscribe.error.stripeInputError"), setSubmitErrors, setSubmitting);
        }
      } catch (error) {
        submitError(error, setSubmitErrors, setSubmitting);
      }
    }
  } else {
    console.log("verification failed: ", message);
    // Verification failed
    submitError(message, setSubmitErrors, setSubmitting);
  }
};
