import React from "react";
import PropTypes from "prop-types";

export default function RoleButton({ type, role, text, setRole }) {
  const planButtonSyle = () => {
    const active = type === role ? " active" : "";
    return `button-flat-color pt-feedback ${active}`;
  };

  return (
    <button
      type="button"
      className={planButtonSyle()}
      onClick={() => {
        if (type !== role) setRole(type);
      }}
    >
      {text}
    </button>
  );
}

RoleButton.propTypes = {
  type: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  setRole: PropTypes.func.isRequired,
};
