import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import { getSearchParamString } from "../../config";

export default function StandardsButton({ url, number }) {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className="button-flat-color round dimensional cyan auto standards"
      onClick={() => {
        const location = {
          pathname: routeCodes.STANDARDS,
          search: `?${getSearchParamString("grade")}=${number}`,
        };
        navigate(location);
      }}
    >
      {getString("grades.standards", { replace: [url.toUpperCase()] })}
    </button>
  );
}

StandardsButton.propTypes = {
  url: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
};
