import React from "react";
import PropTypes from "prop-types";

export default function OptionallyDisplayed({ children, doDisplay = false }) {
  return doDisplay ? <>{children}</> : null;
}

OptionallyDisplayed.propTypes = {
  doDisplay: PropTypes.bool,
  children: PropTypes.any.isRequired,
};
