import React from "react";
import { mobile } from "../../utilities/detection";
// import AdFreeLink from "./adFreeLink";
import { useWidthSize } from "../../hooks";
import { useSelector } from "react-redux";
import { adsSelector } from "../../redux/slices/loginSlice";
import { getLangCode, language } from "../../config";

export default function Sky160600Static() {
  const skyShow = useWidthSize(["adsLG", "xl", "xxl"]);
  const ads = useSelector(adsSelector);

  if (ads && !mobile && skyShow) {
    // only shown if screen is wider than "adsLg(1180px)"
    //  see config breakpoints

    return (
      <div data-testid="sky-ad-static" className="a-skyscraper">
        <div className={`a-skyscraper-container static-sky ${getLangCode() === language.la ? "la" : ""}`} />
      </div>
    );
  }

  return null;
}
