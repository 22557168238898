import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getString, getAge, isAFunction } from "../../utilities";
import { getLangCode, language, validAge } from "../../config";

export default function AgeGateForm({ closeFunction, submitFunction }) {
  const langCode = getLangCode();
  const yearValue = getString("ageGate.select.year").toLowerCase();
  const dayValue = getString("ageGate.select.day").toLowerCase();
  const monthValue = getString("ageGate.select.month").toLowerCase();

  const [month, setMonth] = useState(monthValue);
  const [day, setDay] = useState(dayValue);
  const [year, setYear] = useState(yearValue);

  useEffect(() => {
    return () => {
      setMonth(monthValue);
      setDay(dayValue);
      setYear(yearValue);
    };
  }, []);

  const checkAge = (e) => {
    const age = getAge(year, month, day);

    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (age >= validAge && age <= 100) {
      submitFunction();
    }

    if (isAFunction(closeFunction)) {
      closeFunction();
    }
  };

  const renderYears = () => {
    const yearOptions = [
      <option value={yearValue} key="0" disabled>
        {getString("ageGate.select.year")}
      </option>,
    ];
    const currentYear = new Date().getFullYear();
    for (let y = 0; y < 120; y++) {
      const myYear = currentYear - y;
      yearOptions.push(
        <option value={myYear} key={y + 1}>
          {myYear}
        </option>,
      );
    }
    return yearOptions;
  };

  const renderDays = () => {
    const dayOptions = [
      <option value={dayValue} key="0" disabled>
        {getString("ageGate.select.day")}
      </option>,
    ];
    for (let d = 1; d <= 31; d++) {
      const myDay = d > 9 ? `${d}` : `0${d}`;
      dayOptions.push(
        <option value={myDay} key={d}>
          {myDay}
        </option>,
      );
    }
    return dayOptions;
  };

  const renderMonths = () => {
    const monthOptions = [
      <option value={monthValue} key="0" disabled>
        {getString("ageGate.select.month")}
      </option>,
    ];
    for (let m = 0; m < 12; m++) {
      monthOptions.push(
        <option value={m} key={m + 1}>
          {getString(`months.${m}`)}
        </option>,
      );
    }
    return monthOptions;
  };

  const yearSelect = (
    <select
      value={year}
      data-testid="birthday-year"
      onChange={(e) => {
        setYear(e.target.value);
      }}
    >
      {renderYears()}
    </select>
  );

  const monthSelect = (
    <select
      className="birthday-months"
      data-testid="birthday-month"
      value={month}
      onChange={(e) => {
        setMonth(e.target.value);
      }}
    >
      {renderMonths()}
    </select>
  );

  const daySelect = (
    <select
      value={day}
      data-testid="birthday-day"
      onChange={(e) => {
        setDay(e.target.value);
      }}
    >
      {renderDays()}
    </select>
  );

  const thirdSelect = yearSelect;
  let firstSelect = monthSelect;
  let secondSelect = daySelect;

  if (langCode === language.la) {
    firstSelect = daySelect;
    secondSelect = monthSelect;
  }

  return (
    <>
      <p>{getString("ageGate.message")}</p>

      <form onSubmit={checkAge}>
        <div className="birthday">
          {firstSelect}/{secondSelect}/{thirdSelect}
        </div>

        <div>
          <button type="submit" className="button-flat-color pt-green">
            {getString("submit.1")}
          </button>
        </div>
      </form>
    </>
  );
}

AgeGateForm.propTypes = {
  closeFunction: PropTypes.func,
  submitFunction: PropTypes.func.isRequired,
};
