import React from "react";
import PropTypes from "prop-types";
import { getString, currencyConvert } from "../../utilities";
import CheckMark from "../../../assets/img/checkmarkGreen.svg";
import PromoBurst from "./promoBurst";
import { getLangCode, language } from "../../config";

export default function Features(
  props = {
    familyData: null,
    classroomData: null,
    promo: null,
  },
) {
  const langCode = getLangCode();
  const renderPrice = (type) => {
    const { familyData, classroomData } = props;

    if (familyData && classroomData) {
      // Annual (12 month) total devided by 12
      // price per month
      const myData = props[`${type}Data`].m12;
      const price = myData.amount;
      const promoPrice = myData.promoAmount;

      if (promoPrice) {
        return (
          <>
            <div className="slashed-text">{currencyConvert(price / 12)}</div>

            <div>
              {currencyConvert(promoPrice / 12)}
              <span className="term">{getString("subscribe.features.columns.7")}</span>
            </div>
          </>
        );
      }

      return (
        <>
          {currencyConvert(price / 12)}
          <span className="term">{getString("subscribe.features.columns.7")}</span>
        </>
      );
    }

    return getString("error.0");
  };

  const { promo } = props;
  const free = getString("free.0");
  const family = getString("family");
  const classroom = getString("classroom");

  let priceFamily = family;
  let priceClassroom = classroom;
  let priceFamilyTdClass = "";
  let priceFamilyThClass = "";
  let priceClassroomTdClass = "";
  let priceClassroomThClass = "";

  if (promo && promo.planGroups) {
    if (promo.planGroups.includes("FAMILY")) {
      priceFamilyTdClass = `sft-td-promo ${langCode}`;
      priceFamilyThClass = "sft-th-promo";
      if (promo.amountOff > 0) {
        priceFamily += `&#xa; ${getString("promo.off", { replace: [currencyConvert(promo.amountOff)] })}`;
      } else if (promo.percentOff > 0) {
        priceFamily += `\n${getString("promo.off", { replace: [`${promo.percentOff * 100}%`] })}`;
      }
    }

    if (promo.planGroups.includes("CLASSROOM")) {
      priceClassroomTdClass = `sft-td-promo ${langCode}`;
      priceClassroomThClass = "sft-th-promo";
      if (promo.amountOff > 0) {
        priceClassroom += `&#xa; ${getString("promo.off", { replace: [currencyConvert(promo.amountOff)] })}`;
      } else if (promo.percentOff > 0) {
        priceClassroom += `\n${getString("promo.off", { replace: [`${promo.percentOff * 100}%`] })}`;
      }
    }
  }

  return (
    <div className="wrapper visible odd">
      <div className="subscribe-features-container">
        <table className="subscribe-features-table">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>{getString("subscribe.plans.free.title.1")}</th>
              <th className={priceFamilyThClass}>
                <div className="sft-th-promo-wrapper">
                  {getString("subscribe.plans.family.title.2")}
                  <PromoBurst promo={promo} />
                </div>
              </th>
              <th className={priceClassroomThClass}>
                <div className="sft-th-promo-wrapper">
                  {getString(
                    langCode === language.la
                      ? "subscribe.plans.classroom.title.3"
                      : "subscribe.plans.classroom.title.2",
                  )}
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr data-testid="feature-row">
              <td data-th="">{getString("subscribe.features.rows.0")}</td>
              <td data-th={free} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
              <td data-th={family} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
              <td data-th={classroom} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
            </tr>

            <tr data-testid="feature-row">
              <td data-th="">{getString("subscribe.features.rows.1")}</td>
              <td data-th={free} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
              <td data-th={family} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
              <td data-th={classroom} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
            </tr>

            <tr data-testid="feature-row">
              <td data-th="">{getString("subscribe.features.rows.2")}</td>
              <td data-th={free} className="better">
                <div className="text">{getString("subscribe.features.columns.0")}</div>
              </td>
              <td data-th={family} className="best">
                <div className="text">{getString("subscribe.features.columns.1")}</div>
              </td>
              <td data-th={classroom} className="best">
                <div className="text">{getString("subscribe.features.columns.1")}</div>
              </td>
            </tr>

            <tr data-testid="feature-row">
              <td data-th="">{getString("subscribe.features.rows.3")}</td>
              <td data-th={free}>&nbsp;</td>
              <td data-th={family} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
              <td data-th={classroom} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
            </tr>

            <tr data-testid="feature-row">
              <td data-th="">{getString("subscribe.features.rows.4")}</td>
              <td data-th={free} className="better">
                <div className="text">{getString("subscribe.features.columns.2")}</div>
              </td>
              <td data-th={family} className="best">
                <div className="text">{getString("subscribe.features.columns.3")}</div>
              </td>
              <td data-th={classroom} className="best">
                <div className="text">{getString("subscribe.features.columns.3")}</div>
              </td>
            </tr>

            <tr data-testid="feature-row">
              <td data-th="">{getString("subscribe.features.rows.5")}</td>
              <td data-th={free}>&nbsp;</td>
              <td data-th={family} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
              <td data-th={classroom} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
            </tr>

            <tr data-testid="feature-row">
              <td data-th="">{getString("subscribe.features.rows.6")}</td>
              <td data-th={free}>&nbsp;</td>
              <td data-th={family} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
              <td data-th={classroom} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
            </tr>

            <tr data-testid="feature-row">
              <td data-th="">{getString("subscribe.features.rows.7")}</td>
              <td data-th={free}>&nbsp;</td>
              <td data-th={family} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
              <td data-th={classroom} className="best">
                <img src={CheckMark} width="52.24" height="37.86" className="checkmark" alt="" loading="lazy" />
              </td>
            </tr>

            <tr data-testid="feature-row">
              <td data-th="">{getString("subscribe.features.rows.8")}</td>
              <td data-th={free} className="good">
                <div className="text">{getString("subscribe.features.columns.4")}</div>
              </td>
              <td data-th={family} className="better">
                <div className="text">{getString("subscribe.features.columns.5")}</div>
              </td>
              <td data-th={classroom} className="best">
                <div className="text">{getString("subscribe.features.columns.6")}</div>
              </td>
            </tr>

            <tr data-testid="feature-row">
              <td data-th="">{getString("subscribe.features.rows.9")}</td>
              <td data-th={free}>
                <div className="text price">{free}</div>
              </td>
              <td data-th={priceFamily} className={priceFamilyTdClass}>
                <div className="text price">{renderPrice("family")}</div>
              </td>
              <td data-th={priceClassroom} className={priceClassroomTdClass}>
                <div className="text price">{renderPrice("classroom")}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

Features.propTypes = {
  familyData: PropTypes.object,
  classroomData: PropTypes.object,
  promo: PropTypes.object,
};
