import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../global/modal";
import ModalClose from "../global/modalClose";
import ListArrow from "../global/listArrow";
import { getString } from "../../utilities";
import PreviewCard from "./previewCard";

export default function PreviewModal({ close, giftCards, doShow, amount, designSecret = null }) {
  const [giftCardId, setGiftCardId] = useState(0);

  const closePreview = () => {
    setGiftCardId(0);
    close();
  };

  const arrowClickHandler = (dir) => {
    if (dir === "left") {
      if (giftCardId !== 0) {
        setGiftCardId((prev) => prev - 1);
      }
    }

    if (dir === "right") {
      if (giftCardId !== giftCards.length - 1) {
        setGiftCardId((prev) => prev + 1);
      }
    }
  };

  const rightArrowStyle = giftCards.length > 1 && giftCardId !== giftCards.length - 1 ? " active" : "";
  const leftArrowStyle = giftCardId !== 0 ? " active" : "";
  const currentGiftCard = giftCards[giftCardId];
  const message = currentGiftCard && currentGiftCard.message ? currentGiftCard.message.trim() : "";

  return (
    <Modal doShow={doShow} closeOnEscape={closePreview}>
      <ModalClose close={closePreview} />

      <div className="modal-content-header">{getString("giftcard.preview")}</div>

      <PreviewCard designSecret={designSecret} message={message} amount={amount}>
        <ListArrow
          className={`list-arrow left${leftArrowStyle}`}
          clickHandler={() => {
            arrowClickHandler("left");
          }}
        />

        <ListArrow
          className={`list-arrow right${rightArrowStyle}`}
          clickHandler={() => {
            arrowClickHandler("right");
          }}
        />
      </PreviewCard>
    </Modal>
  );
}

PreviewModal.propTypes = {
  doShow: PropTypes.bool.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  designSecret: PropTypes.string,
  giftCards: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
};
