import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { getString } from "../../utilities";
import { routeCodes } from "../../config/routes";

export default function NotFound({ email, resetCardHandler }) {
  const navigate = useNavigate();

  return (
    <>
      <h2>{getString("redeem.card.notFound.title")}</h2>

      <div>{getString("redeem.card.notFound.message", { replace: [email], html: true })}</div>

      <div className="redeem-card-form-buttons">
        <button
          type="button"
          className="button-flat-color green"
          onClick={() => {
            navigate(routeCodes.SUBSCRIBE, { replace: true });
          }}
        >
          {getString("join.5")}
        </button>

        <button type="button" className="button-flat-color orange" onClick={resetCardHandler}>
          {getString("redeem.card.anotherEmail")}
        </button>
      </div>
    </>
  );
}

NotFound.propTypes = {
  email: PropTypes.string.isRequired,
  resetCardHandler: PropTypes.func.isRequired,
};
