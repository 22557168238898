import { createSlice } from "@reduxjs/toolkit";

export const userClientSlice = createSlice({
  name: "userClient",
  initialState: {
    adBlocking: false,
    fullScreenActive: false,
  },
  reducers: {
    setAdBlocking: (prevState, action) => {
      const state = prevState;
      state.adBlocking = action.payload.adBlocking;
    },
    toggleFullScreenActive: (prevState) => {
      const state = prevState;
      state.fullScreenActive = !state.fullScreenActive;
    },
    setFullScreenActive: (prevState, action) => {
      const state = prevState;
      state.fullScreenActive = action.payload.fullScreenActive;
    },
  },
  selectors: {
    adBlockingSelector: (state) => state.adBlocking,
    fullScreenActiveSelector: (state) => state.fullScreenActive,
  },
});

export const { setAdBlocking, toggleFullScreenActive, setFullScreenActive } = userClientSlice.actions;
export const { adBlockingSelector, fullScreenActiveSelector } = userClientSlice.selectors;

export default userClientSlice.reducer;
