import React from "react";
import strings from "../../strings/";
import { getAbcyaUrl, language } from "../../config";
import { setLangPreference } from "../../utilities";

export default function LanguageMenu() {
  return Object.keys(strings.footer.languageMenu).map((langKey) => {
    const stringValue = strings.footer.languageMenu[langKey];

    return (
      <a
        data-testid={`footerLangLink_${langKey}`}
        role="link"
        key={stringValue}
        onClick={() => {
          setLangPreference();
        }}
      >
        {stringValue}
      </a>
    );
  });
}
