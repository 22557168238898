import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import Arrow from "../../../assets/svg/arrowRight.svg";
import StandardsModal from "./standardsModal";

export default function Standards({ standards }) {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  if (standards.length > 0) {
    return (
      <div className="gv-title-buttons-container">
        <button
          type="button"
          className="button-dimensional-icon standard"
          data-testid="commmon-core-standards-button"
          onClick={() => setModal((prev) => !prev)}
        >
          <span>{getString("standards.title.4")}</span>
        </button>

        <StandardsModal
          standards={standards}
          click={() => {
            navigate(routeCodes.STANDARDS);
          }}
          close={() => setModal((prev) => !prev)}
          doShow={modal}
          header={getString("standards.title.2")}
        >
          {getString("standards.page.0")}
          <Arrow className="inline-arrow" />
        </StandardsModal>
      </div>
    );
  }
  return null;
}

Standards.propTypes = {
  standards: PropTypes.array.isRequired,
};
