import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({ children }) {
  const prevPathname = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (pathname !== prevPathname.current) {
      prevPathname.current = pathname;
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return children;
}
