import { createSlice } from "@reduxjs/toolkit";

const desktopSignupSlice = createSlice({
  name: "desktopSignup",
  initialState: {
    showDesktopSignupModal: false,
    canEscape: true,
  },
  reducers: {
    setShowDesktopSignupModal: (prevState, action) => {
      const state = prevState;
      state.showDesktopSignupModal = action.payload.showDesktopSignupModal;
    },
    setCanEscape: (prevState, action) => {
      const state = prevState;
      state.canEscape = action.payload.canEscape;
    },
  },
  selectors: {
    showDesktopSignupModalSelector: (state) => state.showDesktopSignupModal,
    canEscapeSelector: (state) => state.canEscape,
  },
});

export const { setShowDesktopSignupModal, setCanEscape } = desktopSignupSlice.actions;
export const { showDesktopSignupModalSelector, canEscapeSelector } = desktopSignupSlice.selectors;

export default desktopSignupSlice.reducer;
