import { gql } from "urql";

export const ME_QUERY = gql`
  query {
    me {
      id
      email
      subscription {
        billingPlatform
        planGroup
        planId
      }
      games {
        id
        shortname
      }
      hiddenCategories {
        id
      }
    }
  }
`;

export const SUBSCRIPTIONS_PLAN_QUERY = gql`
  query ($billingPlatform: BillingPlatform, $planGroup: String) {
    subscriptions(where: { OR: [{ billingPlatform: $billingPlatform }, { planGroup: $planGroup }] }) {
      id
      months
      planId
      planGroup
      amount
      active
      name
    }
  }
`;

export const CHECK_GIFTCARD_QUERY = gql`
  query ($id: ID!) {
    checkGiftCard(id: $id) {
      id
      amount
      redeemed
      message
      designFileSecret
    }
  }
`;

export const CHECK_PROMO_QUERY = gql`
  query ($shortname: String!, $planGroup: String, $giftCard: Boolean) {
    checkPromo(shortname: $shortname, planGroup: $planGroup, giftCard: $giftCard) {
      id
      amountOff
      percentOff
      giftCard
      fullname
      description
      disclaimer
      shortname
      planGroups
    }
  }
`;

export const CHECK_PASSWORD_QUERY = gql`
  query ($password: String!) {
    checkPasswordForToken(password: $password) {
      success
      message
    }
  }
`;

export const ACCOUNT_INFO_QUERY = gql`
  query {
    accountInfo {
      id
      periodEnd
      accessName
      accessCode
      accountBalance
      sourceExists
      canceled
      subscription {
        name
      }
    }
  }
`;

export const BLOG_POST_QUERY = gql`
  query ($id: ID!) {
    post(where: { id: $id }) {
      id
      body
    }
  }
`;

export const GIFTCARD_DESIGNS_QUERY = gql`
  query {
    giftCardDesigns(where: { active: true }) {
      id
      name
      file {
        secret
      }
    }
  }
`;

export const DEFAULT_GIFTCARD_DESIGN_QUERY = gql`
  query {
    giftCardDesigns(where: { default: true }) {
      id
      file {
        secret
      }
    }
  }
`;

export const RECAPTCHA_VERIFY_QUERY = gql`
  query ($token: String!, $action: String!, $verify: Boolean!) {
    reCaptchaVerify(token: $token, action: $action, verify: $verify) {
      success
      message
      key
    }
  }
`;

export const GET_EMAIL_SUBSCRIBER_STATUS = gql`
  query ($id: String) {
    getEmailSubscriberStatus(id: $id) {
      status
    }
  }
`;
