import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { assetUrl, contentTypes, getDefaultContentType, getParamValue, getSearchParamString } from "../../config";
import defaultMiniBanner from "../../../assets/img/defaultMiniBanner.jpg";
import { metaDefault } from "../../config/meta";
import LoadingAnim from "../global/loadingAnim";
import faqStrings from "../../strings/faq";
import { getString, sortedArrayByItem } from "../../utilities";
import { getAllGames } from "../../utilities/games";
import { searchArray } from "../../utilities/search";
import { getPrintableCategory } from "../../utilities/printables";
import { handleAnswerListeners } from "../../utilities/faq";
import { routeCodes } from "../../config/routes";
import Result from "./result";
import FilterNav from "./filterNav";
import PageNav from "./pageNav";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";

const RENDER_COUNT = 10;

export default function Search() {
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState(""); // eslint-disablelin-
  const [searchResults, setSearchResults] = useState([]);
  const [pageId, setPageId] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const location = useLocation();

  const data = useSelector(dataSelector);
  const dataLoading = useSelector(loadingSelector);

  const handleSearch = async () => {
    const searchTerm = getParamValue("term", { location });
    let pageId = getParamValue("id", { location });
    let searchType = getParamValue("type", { location });
    searchType = getDefaultContentType(searchType);

    if (!dataLoading && data) {
      searchType =
        searchType &&
        [
          contentTypes.game,
          contentTypes.standard,
          contentTypes.blog,
          contentTypes.faq,
          contentTypes.printable,
        ].includes(searchType)
          ? searchType
          : contentTypes.all;

      // faqs not in startup data, in faqStrings
      const faqs = faqStrings.sections.reduce((faqsArray, section) => {
        const faqSection = section.title;
        section.data.forEach((obj) => {
          faqsArray.push({
            question: obj.question,
            answer: obj.answer,
            section: faqSection,
          });
        });
        return faqsArray;
      }, []);

      const searchedGames =
        data.games && searchTerm && [contentTypes.all, contentTypes.game].includes(searchType)
          ? (await searchArray(getAllGames(data.games), searchTerm, contentTypes.game)).map((game) => {
              const image = game.miniBanner ? game.miniBanner : null;

              return {
                type: contentTypes.game,
                title: game.fullname,
                description: game.description,
                path: `${routeCodes.GAMES}${game.localeShortname || game.shortname}`,
                image: image ? `${assetUrl}${image}` : defaultMiniBanner,
              };
            })
          : [];

      const searchedStandards =
        data.standards && searchTerm && [contentTypes.all, contentTypes.standard].includes(searchType)
          ? (await searchArray(data.standards, searchTerm, contentTypes.standard)).map((standard) => {
              return {
                type: contentTypes.standard,
                title: standard.tag,
                description: standard.description,
                games: standard.games,
                path: `${routeCodes.STANDARDS}?${getSearchParamString("id")}=${standard.id}`,
              };
            })
          : [];

      const searchedPosts =
        data.posts && searchTerm && [contentTypes.all, contentTypes.blog].includes(searchType)
          ? (await searchArray(data.posts, searchTerm, contentTypes.blog)).map((post) => {
              const image = post.image ? post.image : null;

              return {
                type: contentTypes.blog,
                title: post.title,
                description: post.briefDescription || "",
                path: `${routeCodes.BLOG}${post.id}`,
                image: image ? `${assetUrl}${image}` : defaultMiniBanner,
              };
            })
          : [];

      const searchedFaqs =
        searchTerm && [contentTypes.all, contentTypes.faq].includes(searchType)
          ? (await searchArray(faqs, searchTerm, contentTypes.faq)).map((faq) => {
              return {
                type: contentTypes.faq,
                title: faq.question,
                description: faq.answer,
                section: faq.section,
              };
            })
          : [];

      // prettier-ignore
      const searchedPrintables =
        data.printables && searchTerm && [contentTypes.all, contentTypes.printable].includes(searchType)
          ? (await searchArray(data.printables, searchTerm, contentTypes.printable)).map((page) => {
              const image = page.miniBanner ? page.miniBanner : null;

              return {
                type: contentTypes.printable,
                title: page.fullname,
                description: page.description,
                shortname: page.shortname,
                image: image ? `${assetUrl}${image}` : defaultMiniBanner,
                category: getPrintableCategory(page.categories),
                path: `${routeCodes.PRINTABLES}${page.localeShortname || page.shortname}`,
              };
            })
          : [];

      const searchResults = sortedArrayByItem(
        [...searchedGames, ...searchedStandards, ...searchedPosts, ...searchedFaqs, ...searchedPrintables],
        "title",
      );

      const pageCount = Math.ceil(searchResults.length / RENDER_COUNT);

      pageId = pageId ? parseInt(pageId, 10) : 0;

      if (pageId < 0) {
        pageId = 0;
      } else if (pageId >= pageCount) {
        pageId = pageCount - 1;
      }

      setLoading(dataLoading);
      setSearchResults(searchResults);
      setPageId(pageId);
      setPageCount(pageCount);
      setSearchTerm(searchTerm);
      setSearchType(searchType);
    }
  };

  useEffect(() => {
    handleAnswerListeners(location);
  });

  useEffect(() => {
    handleSearch();
  }, [dataLoading, data, location]);

  const renderResults = () => {
    const srLength = searchResults.length;

    if (srLength > 0) {
      const renderId = pageId >= pageCount ? (pageCount - 1) * RENDER_COUNT : pageId * RENDER_COUNT;
      let renderEndId = renderId + RENDER_COUNT;
      if (renderEndId > srLength - 1) renderEndId = srLength;

      const renderResults = searchResults.slice(renderId, renderEndId);

      return renderResults.map((obj, index) => {
        const reskey = `reskey${index}`;
        return <Result key={reskey} result={obj} tabIndex={String(index + 1)} />;
      });
    }

    return null;
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingAnim />;
    }
    if (!loading && searchResults.length > 0) {
      return (
        <>
          {renderResults()}

          <PageNav searchState={{ pageCount, pageId, searchTerm, searchType }} />
        </>
      );
    }

    return <div className="search-noresults">{getString("search.noresults")}</div>;
  };

  return (
    <>
      {metaDefault({
        path: location.pathname,
        title: getString("search.title.0", { replace: [searchTerm] }),
        noCrawl: true,
      })}

      <div className="wrapper search">
        <div className="container search-container">
          <FilterNav searchState={{ pageCount, pageId, searchResults, searchTerm, searchType }} />
          {renderContent()}
        </div>
      </div>

      <div className="wrapper header-shadow" />
    </>
  );
}
