import React from "react";
import { useLocation } from "react-router-dom";
import { metaDefault } from "../../config/meta";
import { getString } from "../../utilities";
import Leader from "../ads/leader";
import ParteaNav from "./parteaNav";
import RecentBlogs from "./recentBlogs";
import { getLangCode, language } from "../../config";

export default function Partea() {
  const location = useLocation();

  const renderRecentBlogs = () => {
    if (getLangCode() !== language.default) {
      return null;
    }
    return <RecentBlogs />;
  };

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("parentsTeachers.title.1") })}

      <div className="partea">
        <div className="wrapper padded">
          <div className="container">
            <h1>{getString("parentsTeachers.title.0")}</h1>

            <ParteaNav />
          </div>
        </div>

        <Leader className="pt-steel" />

        {renderRecentBlogs()}
      </div>

      <div className="wrapper header-shadow" />
    </>
  );
}
