import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import defaultMiniBanner from "../../../assets/img/defaultMiniBanner.jpg";
import { offset } from "../../utilities";
import { assetUrl } from "../../config";
import WebP from "../global/WebP";

export default function DesignBanner({ name, isActive, image, clickHandler }) {
  const db = image ? `${assetUrl}${image}` : defaultMiniBanner;
  const [offscreen, setOffscreen] = useState(false);
  const design = useRef(db);

  const updateOffscreen = () => {
    const dbParent = design.current.offsetParent;
    const dbParentOffset = offset(dbParent);
    const dbParentPos = dbParentOffset.left;
    const dbParentWidth = dbParent.offsetWidth;

    const dbOffset = offset(design.current);
    const dbPos = dbOffset.left;
    const dbWidth = design.current.offsetWidth;

    const isOff = dbPos < dbParentPos || dbPos + dbWidth > dbParentWidth + dbParentPos;

    setOffscreen(isOff);
  };

  useEffect(() => {
    // check position plus width vs browser width
    // set offscreen state to adjust opacity
    window.addEventListener("resize", updateOffscreen);
    design.current.offsetParent.addEventListener("scroll", updateOffscreen);

    return () => {
      window.removeEventListener("resize", updateOffscreen);
      if (design.current) {
        design.current.offsetParent.removeEventListener("scroll", updateOffscreen);
      }
    };
  }, []);

  useEffect(() => {
    // check position plus width vs browser width
    // set offscreen state to adjust opacity
    if (design.current) {
      updateOffscreen();
    }
  });

  const dbStyle = isActive ? "active" : "";
  const offStyle = offscreen ? { opacity: ".25" } : {};

  return (
    <a className={`giftcard-designbanner ${dbStyle}`} onClick={clickHandler} style={offStyle} ref={design}>
      <WebP
        secret={db}
        imgProps={{ width: 214, height: 133, className: "designbanner-art", alt: name, draggable: false }}
      />
    </a>
  );
}

DesignBanner.propTypes = {
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
};
