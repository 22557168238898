import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { hasTouch, mobile } from "../../utilities/detection";
import LoadingAnim from "../global/loadingAnim";
import { getString, isLoggedInWithRole, offset, setLangPreference } from "../../utilities";
import { userRoles, assetUrl, contentTypes, getLangCode, language, adData } from "../../config";
import NotFound from "../global/notFound";
import GradeNav from "../global/gradeNav";
import AddFav from "./addFav";
import { metaType } from "../../config/meta";
import { mobileFreeGame } from "../../utilities/games";
import Sky160600 from "../ads/sky160600";
import Sky160600Static from "../ads/sky160600Static";
import Leader from "../ads/leader";
import GameContent from "./gameContent";
import MobilePublicModal from "./mobilePublicModal";
import List from "../global/list";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import AdFree from "../global/adFree";
import FullScreen from "./fullScreen";
import defaultMiniBanner from "../../../assets/img/defaultMiniBanner.jpg";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import GameInfo from "./GameInfo";
import { educatorInfoDataFetcher } from "../../utilities/data";
import GameStandards from "./GameStandards";
import routeCodes from "../../config/routeCodes";

export default function Game() {
  const infoRef = useRef(null);
  const previousGameRef = useRef(null);

  const { games: dataGames, lists: dataLists } = useSelector(dataSelector);
  const dataLoading = useSelector(loadingSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);

  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const nextShortname = params.shortname || "";

  const [game, setGame] = useState(null);
  const [isMobileFree, setIsMobileFree] = useState(false);
  const [isMobileActive, setIsMobileActive] = useState(true);
  const [mobilePublicModal, setMobilePublicModal] = useState(false);
  const [isArcademics, setIsArcademics] = useState(false);
  const [gameInfoData, setGameInfoData] = useState(null);

  const cleanup = () => {
    // reset/remove global variable for GTM tracking
    delete window.local_data?.grades;
    delete window.local_data?.categories;
  };

  const publicModalToggle = () => {
    setMobilePublicModal((prev) => !prev);
  };

  const initGame = async () => {
    const nextGame = dataGames.find(
      (game) => game?.localeShortname === nextShortname || game?.shortname === nextShortname,
    );
    setGame(nextGame);

    if (nextGame) {
      if (nextGame.localeShortname && nextGame.localeShortname !== nextShortname) {
        navigate(`${routeCodes.GAMES}${nextGame.localeShortname}`, { replace: true });
      }

      const nextMobileFree = mobileFreeGame(dataLists, nextGame.id);

      window.local_data.grades = nextGame.grades;
      window.local_data.categories = nextGame.categories;

      setIsMobileFree(nextMobileFree);
      setIsMobileActive(nextGame.mobileActive);
      setIsArcademics(!!(nextGame.arcademicsName && nextGame.arcademicsName.length > 0));

      if (
        nextGame.localeShortname !== previousGameRef.current?.localeShortname ||
        nextGame.shortname !== previousGameRef.current?.shortname
      ) {
        // Game Seo Educators Info
        try {
          const gameInfoDataResponse = await educatorInfoDataFetcher(nextGame.shortname);

          const nextGameInfoData = Object.entries(gameInfoDataResponse).reduce((gameInfoDataObject, infoArray) => {
            // parse out objects that don't have a value
            const [key, value] = infoArray;
            const cleanValue = value?.length > 0 ? value.trim() : value;

            if (cleanValue) {
              return { ...gameInfoDataObject, [key]: cleanValue };
            }

            return gameInfoDataObject;
          }, {});

          if (Object.values(nextGameInfoData).length > 0) {
            setGameInfoData(nextGameInfoData);
          } else {
            setGameInfoData(null);
          }
        } catch (error) {
          setGameInfoData(null);
          console.log(error);
        }
      }
    }

    previousGameRef.current = nextGame;
  };

  useEffect(() => {
    if (!dataLoading) {
      initGame();
    }
  }, [nextShortname, dataLoading, dataGames, dataLists]);

  useEffect(() => {
    return cleanup;
  }, []);

  const mobileStyle = mobile ? " game-mobile" : "";
  const goAdFree = !isLoggedIn || isLoggedInWithRole(userRoles.free);
  const mb = game?.miniBanner ? `${assetUrl}${game.miniBanner}` : defaultMiniBanner;

  const leaderSizeArray = ["xxs", "xs", "sm", "md", "lg"];

  const renderContent = () => {
    // if game is not found initGame will set it as undefined
    if (game === undefined) {
      return <NotFound item={getString("game.title.0")} route="GAMES" cta={getString("game.go")} />;
    }

    const similarGames =
      game?.similarGames.reduce((sgArray, sg) => {
        const gameData = dataGames.find((gd) => gd.id === sg);

        if (gameData) {
          sgArray.push({
            type: contentTypes.game,
            title: gameData.fullname,
            path: gameData.shortname,
            image: gameData.miniBanner ? gameData.miniBanner : null,
            grades: gameData.grades,
            mobile: gameData.mobileActive,
            searchable: gameData.searchable,
          });
        }

        return sgArray;
      }, []) || [];

    const renderRightSky = () => {
      if (!hasTouch) {
        return <Sky160600 />;
      }
      return <Sky160600Static />;
    };

    const renderStandardInfoButton = () => {
      if (gameInfoData) {
        return (
          <div className="gv-title-buttons-container">
            <button
              type="button"
              className="button-dimensional-icon educatorInfo"
              data-testid="educator-info-button"
              onClick={() => {
                if (infoRef.current) {
                  const header = document.getElementById("header");
                  const cookieBanner = document.getElementById("cookiebanner");

                  let headHeight = header.offsetHeight;
                  const cbHeight = cookieBanner ? cookieBanner.getBoundingClientRect().height : 0;

                  headHeight = headHeight + cbHeight;

                  const infoPosition = offset(infoRef.current).top - headHeight;
                  window.scroll({ left: 0, top: infoPosition });
                }
              }}
            >
              <span>{getString("educatorInfo")}</span>
            </button>
          </div>
        );
      }

      // no educator info fallback to standards info
      if (getLangCode() === language.default) {
        return <GameStandards game={game} />;
      }

      return null;
    };

    const renderChangeLangButton = () => {
      if (getLangCode() === language.default && !game?.activeLa) {
        return null;
      }
      return (
        <div className="gv-title-buttons-container">
          <button
            type="button"
            className="button-dimensional-icon change-lang"
            onClick={() => {
              const gamePath = getLangCode() === language.default ? "juegos" : "games";
              const path = `/${gamePath}/${game.shortname}`;
              setLangPreference(path);
            }}
          >
            <span>{getString("playInLocale.1")}</span>
          </button>
        </div>
      );
    };

    const renderWideScreenLeader = (adId) => {
      if (mobile) {
        return null;
      }
      return <Leader sizeArray={["adsLG", "xl", "xxl"]} adId={adId} className="widescreen-leader" />;
    };

    return (
      <>
        {metaType("VideoGame", { ...game, image: mb }, location.pathname)}

        <div className={`wrapper game${mobileStyle}`}>
          <Leader sizeArray={leaderSizeArray} className={!mobile ? "a-leader-asteroid" : ""} />

          <h1 className="game-title">{game?.fullname || getString("loading")}</h1>

          <div className="gv-title-buttons">
            <AddFav game={game || {}} />

            {renderStandardInfoButton()}

            <FullScreen />

            <AdFree doDisplay={goAdFree} path={location.pathname} desc="game-button" />

            {renderChangeLangButton()}
          </div>

          {renderWideScreenLeader(adData.divIds.leader.desktop[4])}

          <div className="game-a-wrapper">
            <OptionallyDisplayed doDisplay={dataLoading}>
              <LoadingAnim position="absolute" className="background-transparent-pt-dark-space orange" />
            </OptionallyDisplayed>

            <Sky160600 isLeft={true} />

            <GameContent
              game={game || {}}
              isMobileFree={isMobileFree}
              isMobileActive={isMobileActive}
              isArcademics={isArcademics}
              publicModalToggle={publicModalToggle}
            />

            {/* <Sky160600 /> */}
            {renderRightSky()}
          </div>

          <OptionallyDisplayed doDisplay={!dataLoading}>
            <List className="similar-list" title={getString("game.similar")} items={similarGames} sort="title" />
          </OptionallyDisplayed>

          {renderWideScreenLeader(adData.divIds.leader.desktop[5])}

          <GameInfo gameInfoData={gameInfoData} infoRef={infoRef} />
        </div>
      </>
    );
  };

  return (
    <>
      <GradeNav />

      {renderContent()}

      <MobilePublicModal doShow={mobilePublicModal} close={publicModalToggle} />
    </>
  );
}
