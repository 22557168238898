import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements, CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "urql";
import { metaDefault } from "../../config/meta";
import { CREATE_GIFTCARDS_MUTATION } from "../../graphql/mutations";
import { getString, currencyConvert, handlePostRequest } from "../../utilities";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import DesignList from "./designList";
import Details from "./details";
import Giftcards from "./giftcards";
import OKModal from "../global/okModal";
import PaymentInfo from "./paymentInfo";
import PreviewModal from "./previewModal";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import PurchaseConfirm from "./purchaseConfirm";
import { subscribeSubmit, submitError } from "../../utilities/subscribe";
import { recaptchaSiteKey, stripePublishableKeys } from "../../config";
import { useLazyQuery } from "../../hooks";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";
import { getStripeLocale } from "../../utilities/stripe";

function Giftcard() {
  const [designId, setDesignId] = useState("");
  const [designName, setDesignName] = useState("");
  const [designSecret, setDesignSecret] = useState(null);
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [submitErrors, setSubmitErrors] = useState(null);
  const [creditComplete, setCreditComplete] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [giftCards, setGiftCards] = useState([]);

  const location = useLocation();

  const { getValues, handleSubmit, setValue, register, reset, trigger, control, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      purchaserName: "",
      purchaserEmail: "",
      emailVerify: "",
      giftCards: [{ recipientEmail: "", message: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "giftCards",
  });

  const { purchaserName, purchaserEmail, emailVerify } = getValues();

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const prevQuantity = fields.length;
    const diff = quantity - prevQuantity;
    if (diff < 0) {
      for (let i = 0; i < Math.abs(diff); ++i) {
        remove(prevQuantity - 1 - i);
      }
    } else {
      for (let i = 0; i < diff; ++i) {
        append({ recipientEmail: "", message: "" });
      }
    }
  }, [quantity]);

  const getVariables = () => {
    return {
      quantity,
      purchaserName,
      purchaserEmail,
      emailVerify,
      amount,
      designId,
      giftCards,
    };
  };

  const giftCardsReset = async () => {
    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      cardElement.clear();
    }

    reset({
      purchaserName: "",
      purchaserEmail: "",
      emailVerify: "",
      giftCards: [{ recipientEmail: "", message: "" }],
    });
    setAmount(0);
    setQuantity(1);
    setSubmitErrors(null);
    setCreditComplete(false);
    setSuccessModal(false);
  };

  const designClickHandler = (id, secret, name) => {
    if (id !== designId) {
      setDesignId(id);
      setDesignSecret(secret);
      setDesignName(name);
    }
  };

  const renderSuccessMessage = () => {
    const recipientEmailArray = giftCards.reduce((emailArray, gc) => {
      const re = gc.recipientEmail.trim();
      if (re.length > 0) {
        emailArray.push(re);
      }
      return emailArray;
    }, []);

    const include = recipientEmailArray.length > 0;
    const emailList = recipientEmailArray.reduce((es, email, index) => {
      let returnString = es;
      if (index === 0) {
        returnString = email;
      } else {
        returnString = `${es}, ${email}`;
      }
      return returnString;
    }, "");
    return (
      <>
        <p>{getString("giftcard.success.message.receipt", { replace: [purchaserEmail] })}</p>
        <OptionallyDisplayed doDisplay={include}>
          <p>{getString("giftcard.success.message.recipients", { replace: [amount, emailList] })}</p>
        </OptionallyDisplayed>
        <p>{getString("giftcard.success.message.contact", { html: true })}</p>
      </>
    );
  };

  const renderErrors = () => {
    if (submitErrors) {
      return <ErrorMessage error={submitErrors} />;
    }

    return null;
  };

  const total = currencyConvert(quantity * amount);

  const onCreateError = (error) => {
    console.log(error);
    setSubmitErrors(error);
    window.scrollTo(0, 0);
  };

  const [createResult, createGiftCards] = useMutation(CREATE_GIFTCARDS_MUTATION);
  const { fetching: mutating } = createResult;

  const action = "Gift_Card_Payment_Form_Submission";

  const onRecaptchaCompleted = async (data) => {
    const myVariables = getVariables();
    const cardElement = elements.getElement(CardElement);
    await subscribeSubmit({
      data,
      setSubmitErrors,
      cardElement,
      mutation: (variables) =>
        createGiftCards(variables).then((result) =>
          handlePostRequest(result, () => setSuccessModal(true), onCreateError),
        ),
      creditNotRequired: false,
      myVariables,
      creditComplete,
      action,
      stripe,
    });
  };

  const onRecaptchaError = (error) => {
    console.log("recaptcha error: ", error);
    submitError(error, setSubmitErrors, null);
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onRecaptchaCompleted,
    onRecaptchaError,
  );

  const loading = mutating || recaptchaLoading;

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("giftcard.title.0") })}

      <>
        <div className="giftcard">
          <div className="wrapper padded">
            <div className="container">
              <h1>{getString("giftcard.title.0")}</h1>
            </div>

            {renderErrors()}
          </div>

          <DesignList designId={designId} designClickHandler={designClickHandler} />

          <FormProvider
            handleSubmit={handleSubmit}
            register={register}
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
            formState={formState}
          >
            <Details
              amount={amount}
              setAmount={setAmount}
              quantity={quantity}
              setQuantity={setQuantity}
              submitErrors={submitErrors}
              setSubmitErrors={setSubmitErrors}
            />

            <Giftcards fields={fields} submitErrors={submitErrors} setSubmitErrors={setSubmitErrors} />

            <PaymentInfo
              isLoading={loading}
              total={total}
              setCreditComplete={setCreditComplete}
              setGiftCards={setGiftCards}
              setPreviewModal={setPreviewModal}
              setPurchaseModal={setPurchaseModal}
              quantity={quantity}
              submitErrors={submitErrors}
              setSubmitErrors={setSubmitErrors}
            />
          </FormProvider>
        </div>

        <div className="wrapper header-shadow" />

        <PurchaseConfirm
          doShow={purchaseModal}
          submit={() => {
            setPurchaseModal((prev) => !prev);

            // execute recaptcha to get token
            window.grecaptcha.ready(() => {
              window.grecaptcha.execute(recaptchaSiteKey, { action }).then((token) => {
                // if token call api query to validate token with Google
                recaptchaVerify({ token, action, verify: true });
              });
            });
          }}
          cancel={() => {
            setPurchaseModal((prev) => !prev);
          }}
          purchaseData={{
            total,
            amount: currencyConvert(amount),
            quantity,
            designName,
            recipients: giftCards.reduce((recArray, obj) => {
              const re = obj.recipientEmail.trim();
              if (re && re.length > 0 && !recArray.includes(re)) {
                recArray.push(re);
              }
              return recArray;
            }, []),
          }}
        />

        {loading && (
          <LoadingAnim position="fixed" className="background-transparent-white">
            <h4>{getString("purchasing")}</h4>
          </LoadingAnim>
        )}
      </>

      <PreviewModal
        doShow={previewModal}
        amount={amount}
        designSecret={designSecret}
        giftCards={giftCards}
        close={() => {
          setPreviewModal((prev) => !prev);
        }}
      />

      <OKModal doShow={successModal} close={giftCardsReset} title={getString("giftcard.success.title")}>
        {renderSuccessMessage()}
      </OKModal>
    </>
  );
}

export default (props) => {
  const stripePromise = useMemo(() => loadStripe(stripePublishableKeys[process.env.NODE_ENV]), []);

  return (
    <Elements stripe={stripePromise} options={{ locale: getStripeLocale() }}>
      <Giftcard {...props} />
    </Elements>
  );
};
