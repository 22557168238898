import { adData, variousURLs, tokenName, recaptchaScriptSrc, userRoles, getLangCode, getAbcyaUrl } from "../config";
import store from "../redux/store";
import { getString, isLoggedInWithRole, isLoggedInWithoutRole } from "./";

let abcyaLoadAdScriptsInit = false;
let preContentVideoSourceScripts = [];

export const loadReCaptchaScript = () => {
  if (!window.grecaptcha) {
    const appHead = document.getElementsByTagName("head")[0];
    const recaptchaScript = document.createElement("script");

    recaptchaScript.src = recaptchaScriptSrc;
    appHead.appendChild(recaptchaScript);
  }
};

export const removePrerollVideoDependencies = () => {
  console.log("**removePrerollVideoDependencies called**");
  window.ovpjs = undefined;

  preContentVideoSourceScripts.forEach((script) => {
    script?.parentNode?.removeChild(script);
  });
  preContentVideoSourceScripts = [];
  preContentVideoSourceScripts.length = 0;
};

export const loadPrerollVideoDependencies = (clickToPlayVideo) => {
  const { shouldReload } = store().getState().preContentVideo;
  console.log("shouldReload: ", shouldReload);

  if (shouldReload || !window.ima) {
    removePrerollVideoDependencies();

    const appHead = document.getElementsByTagName("head")[0];

    const ovpScript = clickToPlayVideo ? "/static/click-to-play-ovp.js" : "/static/ovp.js";

    const styles = adData.preContentVideoDependencies.styles;

    const sources = [...adData.preContentVideoDependencies.sources, ovpScript];

    console.log("sources: ", sources);

    let styleIndex = 0;
    let sourceIndex = 0;

    // Recursively load styles and scripts on load.
    const loadAdPlayerDeps = () => {
      if (styleIndex < styles.length) {
        const styleSheet = document.createElement("link");
        const style = styles[styleIndex];
        styleSheet.setAttribute("rel", "stylesheet");
        styleSheet.setAttribute("type", "text/css");
        styleSheet.setAttribute("href", style);
        styleSheet.onload = loadAdPlayerDeps;
        ++styleIndex;
        appHead.appendChild(styleSheet);
      } else if (sourceIndex < sources.length) {
        const script = document.createElement("script");
        const source = sources[sourceIndex];
        script.src = source;
        script.async = false;
        script.onload = loadAdPlayerDeps;
        ++sourceIndex;
        appHead.appendChild(script);
        preContentVideoSourceScripts.push(script);
      }
    };

    loadAdPlayerDeps();
  }
};

export const getSchema = (schema) => {
  const keywords = Array.isArray(schema.keywords) ? schema.keywords.join(", ") : schema.keywords;

  const innerHTML = {
    "@context": variousURLs.schema,
    "@type": schema.type,
    url: `${getAbcyaUrl()}${schema.url}`,
    name: schema.name,
    description: schema.description,
    keywords,
    alternateName: getString("head.defaultTitle"),
    logo: variousURLs.logo[getLangCode()],
    sameAs: [variousURLs.facebook, variousURLs.twitter, variousURLs.instagram, variousURLs.vimeo],
  };

  if (schema.uploadDate && schema.uploadDate.length > 0) {
    innerHTML.uploadDate = schema.uploadDate;
  }

  if (schema.thumbnailUrl && schema.thumbnailUrl.length > 0) {
    innerHTML.thumbnailUrl = [schema.thumbnailUrl];
  }

  const ss = {
    type: "application/ld+json",
    innerHTML: JSON.stringify(innerHTML),
  };
  return [ss];
};

export const getMeta = (metas) => {
  return metas.map((item) => {
    const itemKeys = Object.keys(item);

    const meta = {};

    itemKeys.forEach((prop) => {
      if (prop === "content") {
        meta.content = Array.isArray(item.content) ? item.content.join(", ") : item.content;
      } else {
        meta[prop] = item[prop];
      }
    });

    return meta;
  });
};

const loadAdScripts = (adScriptsReady) => {
  if (!adScriptsReady && !abcyaLoadAdScriptsInit) {
    abcyaLoadAdScriptsInit = true;
    let scriptsCounter = 0;
    const appHead = document.getElementsByTagName("head")[0];

    const loadScript = () => {
      const adScript = adData.headScripts[scriptsCounter];
      const headScript = document.createElement("script");

      if (adScript.type) {
        headScript.type = adScript.type;
      }

      headScript.async = false;
      headScript.src =
        typeof adScript.src === "string" || adScript.src instanceof String ? adScript.src : adScript.src[getLangCode()];

      if (scriptsCounter++ < adData.headScripts.length - 1) {
        headScript.onload = loadScript;
      }

      const scpt = appHead.appendChild(headScript);
    };

    loadScript();
  }
};

export const handleAdScriptsLoading = () => {
  const adScriptsReady = Object.prototype.hasOwnProperty.call(window, "gAllSlotData");

  let cachedToken = "";
  try {
    cachedToken = window.localStorage.getItem(tokenName);
  } catch (e) {}
  if ((!cachedToken || isLoggedInWithRole(userRoles.free)) && !adScriptsReady) {
    // no token present, always loads ad scripts
    loadAdScripts(adScriptsReady);
  } else if (adScriptsReady && isLoggedInWithoutRole(userRoles.free)) {
    window.location.reload();
  }
};

export const refreshAds = async (adIdArray) => {
  // buffer to hopefully allow ad engine script to load
  const numOfTries = 10;
  let retryCounter = 0;

  const refreshFunction = () => {
    if (window.amp_refreshSlots && typeof window.amp_refreshSlots === "function") {
      window.amp_refreshSlots(adIdArray);
    } else if (retryCounter <= numOfTries) {
      setTimeout(
        () => {
          retryCounter++;
          refreshFunction();
        },
        !retryCounter ? 0 : 500,
      );
    }
  };

  refreshFunction();
};

export const getVideoPlacementUrl = () => {
  return new Promise((resolve) => {
    const numOfTries = 10;
    let retryCounter = 0;

    const refreshFunction = () => {
      if (window.amp_getVideoPlacementURL && typeof window.amp_getVideoPlacementURL === "function") {
        return resolve(window.amp_getVideoPlacementURL());
      } else if (retryCounter <= numOfTries) {
        setTimeout(
          () => {
            retryCounter++;
            refreshFunction();
          },
          !retryCounter ? 0 : 500,
        );
      } else {
        return resolve(undefined);
      }
    };

    refreshFunction();
  });
};
