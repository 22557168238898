export const publicPath = "/";

export default {
  ABOUT: `${publicPath}about/`,
  BLOG: `${publicPath}blog/`,
  CATEGORYGAMES: `${publicPath}games/category/`,
  CATEGORYPRINTABLES: `${publicPath}printables/category/`,
  COMPLETESIGNUP: `${publicPath}subscribe/complete/`,
  COMPLETESIGNUP_STUDENT: `${publicPath}subscribe/complete/student/`,
  CONTACT: `${publicPath}support/`,
  EMAIL_PREFERENCES: `${publicPath}email/preferences/`,
  FAQ: `${publicPath}faq/`,
  FEEDBACK: `${publicPath}feedback/`,
  FORGOT: `${publicPath}forgot/`,
  GAMES: `${publicPath}games/`,
  GIFTCARD: `${publicPath}giftcard/`,
  GRADES: `${publicPath}grades/`,
  HOME: `${publicPath}`,
  LOGIN: `${publicPath}login/`,
  MANAGE: `${publicPath}manage/`,
  MOBILEGAMES: `${publicPath}games/mobile/`,
  GAMEPROXY: `${publicPath}gameproxy/`,
  NOTFOUND: `${publicPath}notfound/`,
  ONBOARDING: `${publicPath}onboarding/`,
  ONBOARDING_FAMILY: `${publicPath}onboarding/family`,
  ONBOARDING_TEACHER: `${publicPath}onboarding/teacher`,
  PARTEA: `${publicPath}parents_teachers/`,
  PAYTERMS: `${publicPath}payterms/`,
  PRINTABLES: `${publicPath}printables/`,
  PRIVACY: `${publicPath}privacy/`,
  PRIVACYMOBILE: `${publicPath}privacy-mobile/`,
  PRODUCTOFFERINGS: `${publicPath}product-offerings/`,
  REDEEM: `${publicPath}redeem/`,
  REDEEMCARD: `${publicPath}redeem-card`,
  SEARCH: `${publicPath}search/`,
  STANDARDS: `${publicPath}standards/`,
  STANDARDS_GAMES: `${publicPath}standards/games/`,
  STANDARDS_PRINTABLES: `${publicPath}standards/printables/`,
  STORY: `${publicPath}story/`,
  SUBSCRIBE: `${publicPath}subscribe/`,
  SUBSCRIBE_ABCYA_SCHOOL: `${publicPath}subscribe/abcyaschool`,
  SUBSCRIBE_FREE: `${publicPath}subscribe/free`,
  SUBSCRIBE_FAMILY: `${publicPath}subscribe/family`,
  SUBSCRIBE_CLASSROOM: `${publicPath}subscribe/classroom`,
  SUBSCRIBE_SCHOOL: `${publicPath}subscribe/school`,
  TEAM: `${publicPath}team/`,
  TERMS: `${publicPath}terms/`,
  TESTIMONIALS: `${publicPath}testimonials/`,
  TOOLS: `${publicPath}tools/`,
  TRYMOBILE: `${publicPath}faq/trymobile`,
  UPDATEPASSWORD: `${publicPath}forgot/password`,
  VERIFY: `${publicPath}verify/`,
};
