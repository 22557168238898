import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { gtmJoinClick } from "../../utilities/gtm";
import { getString } from "../../utilities";
import { getLangCode, language } from "../../config";

export default function AdFreeLink({ isSky = false }) {
  const location = useLocation();
  const adStringPath = getLangCode() !== language.default && isSky ? "ads.adSky" : "ads.ad";

  return (
    <>
      {getString(adStringPath)}
      <a
        href={routeCodes.SUBSCRIBE}
        onClick={() => {
          gtmJoinClick(location.pathname, "advertisement-link");
        }}
      >
        {getString("ads.go")}
      </a>
    </>
  );
}

AdFreeLink.propTypes = {
  isSky: PropTypes.bool,
};
