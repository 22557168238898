import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { dataSelector } from "../../redux/slices/dataSlice";
import Standards from "../global/Standards";

export default function PrintableStandards({ page }) {
  const { standards } = useSelector(dataSelector);

  const standardsCondensed = page?.standards
    ? page.standards.reduce((acc, standardId) => {
        const nextAcc = acc;
        const standardData = standards.find((standard) => standard.id === standardId);
        if (standardData) {
          nextAcc.push(standardData);
        }
        return nextAcc;
      }, [])
    : [];

  if (standards) {
    return <Standards standards={standardsCondensed} />;
  }
}

PrintableStandards.propTypes = {
  page: PropTypes.object.isRequired,
};
