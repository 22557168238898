import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { metaDefault } from "../../config/meta";
import { getString } from "../../utilities";
import Leader from "../ads/leader";
import ToolsNav from "./toolsNav";
import Timer from "./timer";
import CategoryControl from "./categoryControl";
import FavoriteGames from "./favoriteGames";

export default function Tools() {
  const [updating, setUpdating] = useState(false);
  const location = useLocation();

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("tools.title.1"), noCrawl: true })}

      <div className="tools">
        <div className="wrapper padded">
          <div className="container">
            <h1>{getString("tools.title.0")}</h1>

            <ToolsNav />
          </div>
        </div>

        <Leader className="pt-steel" />

        <Timer />

        <CategoryControl isUpdating={updating} setUpdating={setUpdating} />

        <FavoriteGames isUpdating={updating} setUpdating={setUpdating} />
      </div>

      <div className="wrapper header-shadow" />
    </>
  );
}
