import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { contentTypes } from "../../config";
import { routeCodes } from "../../config/routes";
import { getString, purifiedParsedString } from "../../utilities";
import WebP from "../global/WebP";
import { dataSelector } from "../../redux/slices/dataSlice";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";
import { renderDesktopSignupModal } from "../../utilities/printables";

export default function Result({ result, tabIndex }) {
  const navigate = useNavigate();

  const data = useSelector(dataSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);

  const renderImage = () => {
    if (result.image && result.image.length > 0) {
      return (
        <div className="search-result-image">
          <WebP
            secret={result.image}
            imgProps={{
              width: "146.25",
              height: "auto",
              className: "search-result-image-image",
              alt: result.title,
              draggable: "false",
            }}
          />
        </div>
      );
    }
    return null;
  };

  const renderLinks = () => {
    if (result.type === contentTypes.standard && result.games.length > 0) {
      return (
        <div className="search-result-text-links">
          <strong>{`${getString("games.title.0")}:`}</strong>

          {result.games.reduce((linkArray, game, index) => {
            const linkKey = `linkKey${index}`;
            const resultGame = data.games.find((g) => g.id === game);

            if (resultGame) {
              linkArray.push(
                <Link
                  key={linkKey}
                  to={`${routeCodes.GAMES}${resultGame.localeShortname || resultGame.shortname}`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {resultGame.fullname}
                </Link>,
              );
            }

            return linkArray;
          }, [])}
        </div>
      );
    }
    return null;
  };

  const renderTitle = () => {
    if (result.type === contentTypes.faq) {
      return (
        <>
          <div className="search-result-text-qna">
            {getString("search.faq.question", { replace: [result.section] })}
          </div>
          {result.title}
        </>
      );
    }

    return result.title;
  };

  const renderDescription = () => {
    if (result.type === contentTypes.faq) {
      return (
        <>
          <div className="search-result-text-qna">{getString("search.faq.answer")}</div>
          {purifiedParsedString(result.description)}
        </>
      );
    }

    return result.description;
  };

  const clickHandler = () => {
    if (result.path && result.path.length > 0) {
      if (result.type !== contentTypes.printable || isLoggedIn) {
        navigate(result.path);
      } else {
        renderDesktopSignupModal();
      }
    }
  };

  return (
    <div className={`search-result result-type-${result.type}`} tabIndex={tabIndex} onClick={clickHandler}>
      {renderImage()}

      <div className="search-result-text">
        <div className="search-result-text-title">{renderTitle()}</div>
        {renderDescription()}

        {renderLinks()}
      </div>
    </div>
  );
}

Result.propTypes = {
  result: PropTypes.object.isRequired,
  tabIndex: PropTypes.string.isRequired,
};
