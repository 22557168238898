import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { tokenName, userRoles } from "../../config";

let cachedToken = null;
let cachedRole = userRoles.free;
let cachedCategories = null;
let cachedGames = null;
let cachedPlanId = "";
let cachedBillingPlatform = "";
const isGameProxy = window.location.pathname.startsWith("/gameproxy");
if (!isGameProxy) {
  try {
    cachedToken = window.localStorage.getItem(tokenName);
    if (cachedToken) {
      cachedRole = jwtDecode(cachedToken).role;
    }
  } catch (e) {}

  try {
    const parsedGames = JSON.parse(window.localStorage.getItem("games"));
    if (Array.isArray(parsedGames)) {
      cachedGames = parsedGames;
    }
  } catch (e) {}

  try {
    const parsedCategories = JSON.parse(window.localStorage.getItem("hiddenCategories"));
    if (Array.isArray(parsedCategories)) {
      cachedCategories = parsedCategories;
    }
  } catch (e) {}

  try {
    cachedPlanId = window.localStorage.getItem("planId");
    cachedBillingPlatform = window.localStorage.getItem("billingPlatform");
  } catch (e) {}
}

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoggedIn: !!cachedToken,
    role: cachedRole,
    ads: cachedRole === userRoles.free,
    planId: isGameProxy ? "" : cachedPlanId,
    billingPlatform: isGameProxy ? "" : cachedBillingPlatform,
    games: cachedGames || [],
    hiddenCategories: cachedCategories || [],
    loginModal: false,
    upgradeModal: false,
    email: "",
    path: null,
    children: null,
    showRevalidateModal: false,
    id: "",
  },
  reducers: {
    login: (prevState, action) => {
      const state = prevState;
      const {
        role: nextRole,
        planId: nextPlanId,
        billingPlatform: nextBillingPlatform,
        games: nextGames,
        hiddenCategories: nextHiddenCategories,
        email: nextEmail,
        id: nextId,
      } = action.payload;

      state.isLoggedIn = true;
      state.ads = nextRole === userRoles.free;
      state.role = nextRole;
      state.planId = nextPlanId;
      state.billingPlatform = nextBillingPlatform;
      state.games = nextGames || [];
      state.hiddenCategories = nextHiddenCategories || [];
      state.email = nextEmail;
      state.path = null;
      state.id = nextId;
    },
    logout: (prevState) => {
      const state = prevState;
      state.isLoggedIn = false;
      state.ads = true;
      state.role = "";
      state.planId = "";
      state.billingPlatform = "";
      state.games = [];
      state.hiddenCategories = [];
      state.email = "";
      state.path = null;
      state.id = "";
    },
    modalToggle: (prevState, action) => {
      const state = prevState;
      state.loginModal = !state.loginModal;
      state.children = action.payload?.children ? action.payload.children : null;
      if (action.payload?.path) {
        state.path = action.payload.path;
      }
    },
    upgradeModalToggle: (prevState) => {
      const state = prevState;
      state.upgradeModal = !state.upgradeModal;
    },
    setShowRevalidateModal: (prevState) => {
      const state = prevState;
      state.showRevalidateModal = true;
    },
    setGames: (prevState, action) => {
      const state = prevState;
      state.games = action.payload.games;
    },
    setHiddenCategories: (prevState, action) => {
      const state = prevState;
      state.hiddenCategories = action.payload.hiddenCategories;
    },
  },
  selectors: {
    isLoggedInSelector: (state) => state.isLoggedIn,
    adsSelector: (state) => state.ads,
    showRevalidateModalSelector: (state) => state.showRevalidateModal,
    loginModalSelector: (state) => state.loginModal,
    gamesSelector: (state) => state.games,
    upgradeModalSelector: (state) => state.upgradeModal,
    hiddenCategoriesSelector: (state) => state.hiddenCategories,
    roleSelector: (state) => state.role,
    childrenSelector: (state) => state.children,
    emailSelector: (state) => state.email,
    billingPlatformSelector: (state) => state.billingPlatform,
    planIdSelector: (state) => state.planId,
    pathSelector: (state) => state.path,
    idSelector: (state) => state.id,
  },
});

export const { login, logout, modalToggle, upgradeModalToggle, setShowRevalidateModal, setGames, setHiddenCategories } =
  loginSlice.actions;

export const {
  isLoggedInSelector,
  adsSelector,
  showRevalidateModalSelector,
  loginModalSelector,
  gamesSelector,
  upgradeModalSelector,
  hiddenCategoriesSelector,
  roleSelector,
  childrenSelector,
  emailSelector,
  billingPlatformSelector,
  planIdSelector,
  pathSelector,
  idSelector,
} = loginSlice.selectors;

export default loginSlice.reducer;
