import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FormProvider } from "react-hook-form";

import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import LoadingAnim from "../global/loadingAnim";
import ModalClose from "../global/modalClose";
import { gtmJoinClick } from "../../utilities/gtm";
import { useLoginForm } from "../../hooks";
import LoginFields from "./LoginFields";
import { modalToggle } from "../../redux/slices/loginSlice";
import { getParamValue } from "../../config";

export default function LoginForm() {
  const {
    error,
    errors,
    handleSubmit,
    loading,
    loginMutation,
    loginSubmit,
    recaptchaLoading,
    register,
    setValue,
    submitErrors,
    submitting,
  } = useLoginForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const accessLogin = async (accessName, accessCode) => {
      setValue("email", accessName);
      setValue("password", accessCode);
      await handleSubmit((data) => loginMutation({ emailAddress: data.email, password: data.password }))();
    };

    // login routes to homepage, if the location is '/login'
    // then someone wants the login form to auto pop up
    // if someone supplies a name and code in the search params of /login
    // they want the students to auto login with those credentials

    const loginPage = location.pathname.startsWith(routeCodes.LOGIN.slice(0, -1));
    const accessName = getParamValue("name", { location });
    const accessCode = getParamValue("code", { location });

    if (loginPage && (accessName || accessCode)) {
      accessLogin(accessName, accessCode);
    }
  }, []);

  return (
    <FormProvider handleSubmit={handleSubmit} register={register}>
      <div className="login">
        <ModalClose close={() => dispatch(modalToggle())} />
        <div className="modal-content-header">{getString("login.title")}</div>
        <LoginFields error={error} errors={errors} loginSubmit={loginSubmit} submitErrors={submitErrors} />
        <p>
          <Link
            to={routeCodes.FORGOT}
            onClick={() => {
              dispatch(modalToggle());
            }}
          >
            {getString("login.forgot")}
          </Link>
        </p>

        <div className="form-terms">{getString("recaptcha", { html: true })}</div>

        <div className="modal-content-footer">
          <p>
            <strong>{getString("login.notSubscriber")}</strong>
          </p>
          <button
            type="button"
            className="button-flat-color pt-orange"
            onClick={() => {
              gtmJoinClick(location.pathname, "login-modal-link");
              navigate(routeCodes.SUBSCRIBE);
              dispatch(modalToggle());
            }}
          >
            {getString("join.2")}
          </button>
        </div>

        {(loading || recaptchaLoading || submitting) && (
          <LoadingAnim position="absolute">
            <h4>{getString("login.loading")}</h4>
          </LoadingAnim>
        )}
      </div>
    </FormProvider>
  );
}
