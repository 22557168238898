import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useMutation } from "urql";

import { metaDefault } from "../../config/meta";
import { COMPLETE_SIGNUP_MUTATION } from "../../graphql/mutations";
import ErrorMessage from "../global/errorMessage";
import TextViewHook from "../global/textViewHook";
import LoadingAnim from "../global/loadingAnim";
import { getString, handlePostRequest } from "../../utilities";
import SubscribedModal from "./subscribedModal";
import StudentFields from "../global/studentFields";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { getMaxLengthValidator, getMinLengthValidator, validateEmail } from "../../utilities/forms/rules";
import {
  EMAIL_CHAR_LIMIT,
  getLangCode,
  language,
  PASSWORD_CHAR_LIMIT,
  PASSWORD_CHAR_MINIMUM,
  TEXT_FIELD_CHAR_LIMIT,
} from "../../config";

export default function CompleteSignup({ isStudent }) {
  const langCode = getLangCode();
  const [token, setToken] = useState("");
  const [submitErrors, setSubmitErrors] = useState(null);

  const location = useLocation();

  const { formState, getValues, handleSubmit, register } = useForm();
  const { errors } = formState;

  const completeSignupSuccess = (data) => {
    setToken(data.completeSignup.token);
    setSubmitErrors(null);
  };

  const [result, completeSignup] = useMutation(COMPLETE_SIGNUP_MUTATION);

  const { fetching: loading } = result;

  const onSubmit = (data) => {
    const { email, password, passwordVerify, code, accessName, accessCode } = data;

    const myVariables = {
      email,
      password,
      passwordVerify,
      code,
      student: isStudent,
    };

    if (isStudent) {
      myVariables.accessCode = accessCode;
      myVariables.accessName = accessName;
    }

    completeSignup(myVariables).then((result) =>
      handlePostRequest(result, completeSignupSuccess, (error) => setSubmitErrors(error)),
    );
  };

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("subscribe.complete.title.0"), noCrawl: true })}

      <div className="complete-signup">
        <div className="wrapper padded">
          <h1>{getString("subscribe.complete.title.0")}</h1>
        </div>

        <div className="wrapper padded">
          <div className="container-small">
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormProvider register={register}>
                <OptionallyDisplayed doDisplay={!!submitErrors}>
                  <ErrorMessage error={submitErrors} />
                </OptionallyDisplayed>

                <div className="cta">{getString("forms.email.0")}</div>

                <div className="cta-caption">{getString("subscribe.complete.emailcta")}</div>

                <TextViewHook
                  rules={{
                    required: getString("forms.errorMessages.isRequired.0", {
                      replace: [getString("forms.email.2")],
                    }),
                    validate: (value) => validateEmail(value, getString("forms.email.2")),
                    maxLength: getMaxLengthValidator(getString("forms.email.2"), EMAIL_CHAR_LIMIT),
                  }}
                  errors={errors}
                  className="col1"
                  name="email"
                  placeholder={getString("forms.email.0")}
                  label={getString("forms.email.0")}
                />

                <div className="cta">{getString("forms.code.0")}</div>

                <div className="cta-caption">{getString("subscribe.complete.codecta")}</div>

                <TextViewHook
                  rules={{
                    required: getString("forms.errorMessages.isRequired.0", {
                      replace: [getString("subscribe.complete.code")],
                    }),
                    maxLength: getMaxLengthValidator(getString("subscribe.complete.code"), TEXT_FIELD_CHAR_LIMIT),
                  }}
                  errors={errors}
                  className="col1"
                  name="code"
                  placeholder={getString("subscribe.complete.code")}
                  label={getString("subscribe.complete.code")}
                />

                <TextViewHook
                  rules={{
                    required: getString("forms.errorMessages.isRequired.0", {
                      replace: [getString("forms.password.0")],
                    }),
                    maxLength: getMaxLengthValidator(
                      getString(langCode === language.la ? "forms.password.4" : "forms.password.0"),
                      PASSWORD_CHAR_LIMIT,
                    ),
                    minLength: getMinLengthValidator(
                      getString(langCode === language.la ? "forms.password.4" : "forms.password.0"),
                      PASSWORD_CHAR_MINIMUM,
                    ),
                  }}
                  errors={errors}
                  className="col2"
                  name="password"
                  type="password"
                  placeholder={getString("forms.password.0")}
                  label={getString("forms.password.0")}
                />

                <TextViewHook
                  rules={{
                    validate: (value) =>
                      value === getValues().password ||
                      getString("forms.errorMessages.mustMatch.0", { replace: [getString("forms.password.0")] }),
                  }}
                  errors={errors}
                  className="col2 right"
                  name="passwordVerify"
                  type="password"
                  placeholder={getString("forms.passwordVerify")}
                  label={getString("forms.passwordVerify")}
                />

                <OptionallyDisplayed doDisplay={isStudent}>
                  <FormProvider register={register} formState={formState}>
                    <StudentFields />
                  </FormProvider>
                </OptionallyDisplayed>

                <button type="submit" className="button-flat-color green" disabled={loading}>
                  {getString("subscribe.complete.title.1")}
                </button>
              </FormProvider>
            </form>

            {loading && (
              <LoadingAnim position="fixed" className="background-transparent-white">
                <h4>{getString("subscribe.complete.loading")}</h4>
              </LoadingAnim>
            )}
          </div>
        </div>
      </div>

      <div className="wrapper header-shadow" />

      <SubscribedModal
        accessName={getValues().accessName}
        accessCode={getValues().accessCode}
        token={token}
        isComplete={true}
        isStudent={isStudent}
      />
    </>
  );
}

CompleteSignup.propTypes = {
  isStudent: PropTypes.bool,
};
