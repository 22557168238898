import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import aboutStrings from "../../strings/about";
import { getLangCode, language, variousURLs } from "../../config";
import LanguageMenu from "./LanguageMenu";
import { leaveSiteHandler } from "../../redux/slices/leavingSiteSlice";

export default function About() {
  const dispatch = useDispatch();

  const renderMobileAppPrivacyButton = () => {
    if (getLangCode() !== language.default) {
      return null;
    }
    return (
      <Link data-testid="mobile-app-privacy-link" to={routeCodes.PRIVACYMOBILE}>
        {getString("privacymobile.title.1")}
      </Link>
    );
  };

  return (
    <div className="footer-nav-col">
      <div className="title">
        <span>{getString("about.title.0")}</span>
      </div>

      <Link to={routeCodes.STORY}>{getString("story.title.1", { strings: aboutStrings })}</Link>
      <Link to={routeCodes.TEAM} className="nocap">
        {getString("team.title.1", { strings: aboutStrings })}
      </Link>
      <Link to={routeCodes.TESTIMONIALS}>{getString("testimonials.title.1", { strings: aboutStrings })}</Link>
      <Link to={routeCodes.PRODUCTOFFERINGS}>{getString("productOfferings.title.1", { strings: aboutStrings })}</Link>
      <Link to={routeCodes.TERMS} className="nocap">
        {getString("terms.title.1")}
      </Link>
      <Link to={routeCodes.PRIVACY}>{getString("privacy.title.1")}</Link>
      {renderMobileAppPrivacyButton()}
      <Link to={routeCodes.PAYTERMS}>{getString("payterms.title.1")}</Link>

      <a
        onClick={() => {
          dispatch(leaveSiteHandler({ url: variousURLs.affiliateMarketing }));
        }}
      >
        {getString("footer.affiliate")}
      </a>

      <LanguageMenu />
    </div>
  );
}
