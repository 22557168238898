import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { mobile } from "../../utilities/detection";
import LoadingAnim from "../global/loadingAnim";
import { getString } from "../../utilities";
import { contentTypes, assetUrl, breakpoints, getLangCode, language } from "../../config";
import NotFound from "../global/notFound";
import PrintablesNav from "./printablesNav";
import { metaType } from "../../config/meta";
import Sky160600 from "../ads/sky160600";
import Sky160600Static from "../ads/sky160600Static";
import Leader from "../ads/leader";
import List from "../global/list";
import PrintIcon from "../../../assets/img/colorPrint.webp";
import PrintIconFallback from "../../../assets/img/colorPrint.png";
import defaultMiniBanner from "../../../assets/img/defaultMiniBanner.jpg";
import OptionallyDisplayed from "../global/optionallyDisplayed";

import defaultHorizontalWebp from "../../../assets/img/defaultPrintableHorizontal_xxl.webp";
import defaultHorizontalXlWebp from "../../../assets/img/defaultPrintableHorizontal_xl.webp";
import defaultHorizontalSmWebp from "../../../assets/img/defaultPrintableHorizontal_sm.webp";
import defaultHorizontalXsWebp from "../../../assets/img/defaultPrintableHorizontal_xs.webp";

import defaultHorizontalJpg from "../../../assets/img/defaultPrintableHorizontal_xxl.jpg";
import defaultHorizontalXlJpg from "../../../assets/img/defaultPrintableHorizontal_xl.jpg";
import defaultHorizontalSmJpg from "../../../assets/img/defaultPrintableHorizontal_sm.jpg";
import defaultHorizontalXsJpg from "../../../assets/img/defaultPrintableHorizontal_xs.jpg";

import defaultVerticalWebp from "../../../assets/img/defaultPrintableVertical_xxl.webp";
import defaultVerticalXlWebp from "../../../assets/img/defaultPrintableVertical_xl.webp";
import defaultVerticalXsWebp from "../../../assets/img/defaultPrintableVertical_xs.webp";

import defaultVerticalJpg from "../../../assets/img/defaultPrintableVertical_xxl.jpg";
import defaultVerticalXlJpg from "../../../assets/img/defaultPrintableVertical_xl.jpg";
import defaultVerticalXsJpg from "../../../assets/img/defaultPrintableVertical_xs.jpg";
import WebP from "../global/WebP";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import { setPrint, setSave } from "../../utilities/print";
import PrintableStandards from "./PrintableStandards";

export default function Printable() {
  const location = useLocation();
  const { shortname: snParam } = useParams();

  const { printables, games } = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);

  const [page, setPage] = useState(null);
  const [similarGames, setSimilarGames] = useState([]);

  const initPrintable = () => {
    const pageObject = printables.find((p) => p?.localeShortname === snParam || p?.shortname === snParam);

    if (pageObject) {
      const newSimilarGames = pageObject.games.reduce((sgArray, sg) => {
        const gameData = games.find((gd) => gd.id === sg);

        if (gameData) {
          sgArray.push({
            type: contentTypes.game,
            title: gameData.fullname,
            path: gameData.localeShortname || gameData.shortname,
            image: gameData.miniBanner ? gameData.miniBanner : null,
            grades: gameData.grades,
            mobile: gameData.mobileActive,
            searchable: gameData.searchable,
          });
        }

        return sgArray;
      }, []);

      setSimilarGames(newSimilarGames);
    }

    // will be undefined from printables find if not found
    // used for not found render
    setPage(pageObject);
  };

  useEffect(() => {
    if (!loading && snParam && printables && games) {
      initPrintable();
    }
  }, [loading, printables, games, snParam]);

  const leaderSizeArray = ["xxs", "xs", "sm", "md", "lg"];
  const mobileStyle = mobile ? " printable-mobile" : "";
  const directionStyle = page?.horizontal ? "" : "vertical";
  const defaultArt = page?.horizontal ? defaultHorizontalXlJpg : defaultVerticalXlJpg;
  const mb = page?.miniBanner ? `${assetUrl}${page.miniBanner}` : defaultMiniBanner;
  const art = page?.art ? `${assetUrl}${page.art}` : defaultArt;
  const hasBook = page?.book;

  let fileType = "jpg";
  let fileData = art;

  if (hasBook) {
    fileType = "pdf";
    fileData = `${assetUrl}${page.book}`;
  } else {
    const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;

    const artExtension = art.match(fileExtensionPattern);

    if (artExtension) {
      fileType = artExtension[0].split(".")[1];
    }
  }

  const printFunction = () => {
    setPrint({
      eventLabel: location.pathname,
      fileData,
      fileType,
      fileName: page ? `${page.localeShortname || page.shortname}.${fileType}` : "",
    });
  };

  const saveFunction = () => {
    let saveData = fileData;
    let saveType = fileType;

    if (fileType === "webp") {
      saveType = "jpg";
      saveData = fileData.replace(/\.[a-z]+$/, ".jpg");
    }

    setSave({
      eventLabel: location.pathname,
      fileData: saveData,
      fileType: saveType,
      fileName: page ? `${page.localeShortname || page.shortname}.${saveType}` : "",
    });
  };

  const renderSreenshot = () => {
    const ssWidth = page?.horizontal ? "1024" : "576";
    const ssHeight = page?.horizontal ? "780" : "756";

    let printableScreenshot = (
      <picture>
        <source
          srcSet={page?.horizontal ? defaultHorizontalXsWebp : defaultVerticalXsWebp}
          media={`(max-width: ${breakpoints.width.xs}px)`}
          type="image/webp"
        />
        <source
          srcSet={page?.horizontal ? defaultHorizontalXsJpg : defaultVerticalXsJpg}
          media={`(max-width: ${breakpoints.width.xs}px)`}
          type="image/jpeg"
        />

        <source
          srcSet={page?.horizontal ? defaultHorizontalSmWebp : defaultVerticalXlWebp}
          media={`(max-width: ${breakpoints.width.sm}px)`}
          type="image/webp"
        />
        <source
          srcSet={page?.horizontal ? defaultHorizontalSmJpg : defaultVerticalXlJpg}
          media={`(max-width: ${breakpoints.width.sm}px)`}
          type="image/jpeg"
        />

        <source
          srcSet={page?.horizontal ? defaultHorizontalXlWebp : defaultVerticalXlWebp}
          media={`(max-width: ${breakpoints.width.xl}px)`}
          type="image/webp"
        />
        <source
          srcSet={page?.horizontal ? defaultHorizontalXlJpg : defaultVerticalXlJpg}
          media={`(max-width: ${breakpoints.width.xl}px)`}
          type="image/jpeg"
        />

        <source srcSet={page?.horizontal ? defaultHorizontalWebp : defaultVerticalWebp} type="image/webp" />
        <source srcSet={page?.horizontal ? defaultHorizontalJpg : defaultVerticalJpg} type="image/jpeg" />

        <img width={ssWidth} height={ssHeight} className="printable-screenshot" src="" alt={page?.fullname} />
      </picture>
    );

    if (page?.screenshot) {
      let extension = page.screenshot.match(/\.[a-z]+$/) || [""];
      extension = extension[0];
      const secretSansExtension = extension ? page.screenshot.split(extension)[0] : page.screenshot;

      let size = secretSansExtension.match(/\_[0-9]+$/) || [""];
      size = size[0];
      const secretSansSize = size ? secretSansExtension.split(size)[0] : secretSansExtension;

      const horizontalXsWebp = `${assetUrl}${secretSansSize}_326.webp`;
      const horizontalXsJpg = `${assetUrl}${secretSansSize}_326${extension}`;
      const verticalXsWebp = `${assetUrl}${secretSansSize}_326.webp`;
      const verticalXsJpg = `${assetUrl}${secretSansSize}_326${extension}`;
      const horizontalSmWebp = `${assetUrl}${secretSansSize}_591.webp`;
      const horizontalSmJpg = `${assetUrl}${secretSansSize}_591${extension}`;
      const verticalXlWebp = `${assetUrl}${secretSansSize}_430.webp`;
      const verticalXlJpg = `${assetUrl}${secretSansSize}_430${extension}`;
      const horizontalXlWebp = `${assetUrl}${secretSansSize}_727.webp`;
      const horizontalXlJpg = `${assetUrl}${secretSansSize}_727${extension}`;
      const horizontalWebp = `${assetUrl}${secretSansSize}_767.webp`;
      const horizontalJpg = `${assetUrl}${secretSansSize}_767${extension}`;
      const verticalWebp = `${assetUrl}${secretSansSize}_454.webp`;
      const verticalJpg = `${assetUrl}${secretSansSize}_454${extension}`;

      if (size) {
        printableScreenshot = (
          <picture>
            <source
              srcSet={page?.horizontal ? horizontalXsWebp : verticalXsWebp}
              media={`(max-width: ${breakpoints.width.xs}px)`}
              type="image/webp"
            />
            <source
              srcSet={page?.horizontal ? horizontalXsJpg : verticalXsJpg}
              media={`(max-width: ${breakpoints.width.xs}px)`}
              type="image/jpeg"
            />

            <source
              srcSet={page?.horizontal ? horizontalSmWebp : verticalXlWebp}
              media={`(max-width: ${breakpoints.width.sm}px)`}
              type="image/webp"
            />
            <source
              srcSet={page?.horizontal ? horizontalSmJpg : verticalXlJpg}
              media={`(max-width: ${breakpoints.width.sm}px)`}
              type="image/jpeg"
            />

            <source
              srcSet={page?.horizontal ? horizontalXlWebp : verticalXlWebp}
              media={`(max-width: ${breakpoints.width.xl}px)`}
              type="image/webp"
            />
            <source
              srcSet={page?.horizontal ? horizontalXlJpg : verticalXlJpg}
              media={`(max-width: ${breakpoints.width.xl}px)`}
              type="image/jpeg"
            />

            <source srcSet={page?.horizontal ? horizontalWebp : verticalWebp} type="image/webp" />
            <source srcSet={page?.horizontal ? horizontalJpg : verticalJpg} type="image/jpeg" />

            <img width={ssWidth} height={ssHeight} className="printable-screenshot" src="" alt={page?.fullname} />
          </picture>
        );
      } else {
        printableScreenshot = (
          <WebP
            secret={`${assetUrl}${page.screenshot}`}
            imgProps={{
              width: ssWidth,
              height: ssHeight,
              className: "printable-screenshot",
              alt: page?.fullname,
            }}
          />
        );
      }
    }

    return (
      <a className="printable-container" onClick={printFunction}>
        {/* provides aspect ratio */}
        {printableScreenshot}

        <div className="printable-print-container">
          <div className="printable-icon-container">
            <picture>
              <source srcSet={PrintIcon} type="image/webp" />
              <img width="249" height="249" src={PrintIconFallback} alt={getString("navigation.print.0")} />
            </picture>
            <div className="printable-icon-text">{getString("navigation.print.1")}</div>
          </div>
        </div>
      </a>
    );
  };

  const renderPrintableStandards = () => {
    if (getLangCode() !== language.default) {
      return null;
    }
    return <PrintableStandards page={page} />;
  };

  const renderContent = () => {
    if (page === undefined) {
      return <NotFound item={getString("printables.title.2")} route="PRINTABLES" cta={getString("printables.go")} />;
    }

    const printPageWrapperStyle = hasBook ? " pdf" : "";

    return (
      <>
        {metaType(
          "ImageObject",
          {
            metaTitle: page?.fullname,
            metaKeywords: page?.keywords,
            metaDescription: page?.description,
            image: mb,
            uploadDate: page?.updatedAt,
            noCrawl: true,
          },
          location.pathname,
        )}

        <div className={`wrapper printable${mobileStyle}`}>
          <OptionallyDisplayed doDisplay={loading}>
            <LoadingAnim position="absolute" className="background-transparent-pt-dark-space orange" />
          </OptionallyDisplayed>

          <Leader sizeArray={leaderSizeArray} className={!mobile ? "a-leader-asteroid" : ""} />

          <div className="printable-title">{page?.fullname || getString("loading")}</div>

          <div className="gv-title-buttons">
            <div className="gv-title-buttons-container">
              <button type="button" className="button-dimensional-icon print" onClick={printFunction}>
                <span>{getString("navigation.print.0")}</span>
              </button>
            </div>

            {renderPrintableStandards()}

            <div className="gv-title-buttons-container">
              <button type="button" className="button-dimensional-icon download" onClick={saveFunction}>
                <span>{getString("navigation.download.0")}</span>
              </button>
            </div>
          </div>

          <div className="printable-a-wrapper">
            {/* <Sky160600 isLeft={true} /> */}
            <Sky160600 />

            <div className="printable-description-wrapper">
              {/* supplies the overall width */}

              <div className={`printable-page-wrapper${printPageWrapperStyle}`}>
                {/* supplies the overall height */}

                <div className={`printable-page ${directionStyle}`}>
                  {/* supplies the variant widths */}
                  {renderSreenshot()}
                </div>
              </div>

              <div className="printable-description">
                <p className="description-text">{page?.description}</p>
              </div>
            </div>

            {/* <Sky160600 /> */}
            <Sky160600Static />
          </div>

          <OptionallyDisplayed doDisplay={!loading}>
            <List
              className="similar-list"
              title={getString("printables.similar.games")}
              items={similarGames}
              sort="title"
            />
          </OptionallyDisplayed>
        </div>
      </>
    );
  };

  return (
    <>
      <PrintablesNav />

      {renderContent()}
    </>
  );
}
