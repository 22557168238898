import React from "react";
import PropTypes from "prop-types";
import ArrowRight from "../../../assets/svg/arrowRight.svg";

export default function ListArrow({ className, clickHandler }) {
  return (
    <div className={className} onClick={clickHandler} data-testid="list-arrow">
      <ArrowRight />
    </div>
  );
}

ListArrow.propTypes = {
  className: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
};
