import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getString } from "../../utilities";
import UpArrow from "../../../assets/svg/doubleUpArrow.svg";
import StandardsButton from "./standardsButton";
import { educatorInfoDataFetcher } from "../../utilities/data";
import ErrorMessage from "../global/errorMessage";
import { loadingSelector } from "../../redux/slices/dataSlice";
import { getLangCode, language } from "../../config";

export default function GradeInfo({ grade, infoId }) {
  const loading = useSelector(loadingSelector);
  const gradeNumberRef = useRef(null);

  const [doDisplay, setDoDisplay] = useState(false);
  const [gradeInfoData, setGradeInfoData] = useState(null);
  const [fetchError, setFetchError] = useState(null);

  const gradeInfoInit = async () => {
    try {
      const nextGradeInfoData = await educatorInfoDataFetcher(grade.number);
      setGradeInfoData(nextGradeInfoData);
      setFetchError(null);
    } catch (error) {
      setFetchError(error);
      setGradeInfoData(null);
    }
  };

  useEffect(() => {
    if (grade?.number !== gradeNumberRef.current) {
      gradeNumberRef.current = grade.number;
      gradeInfoInit();
    }
  }, [grade]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setDoDisplay(true);
      }, 500);
    }
  }, [loading]);

  const renderErrorMessage = () => {
    if (fetchError) {
      return (
        <div className="educator-info-fetch-error">
          <ErrorMessage error={fetchError} />
        </div>
      );
    }

    return null;
  };

  const renderInfoContent = () => {
    if (gradeInfoData) {
      return (
        <>
          <div className="educator-info-intro">{gradeInfoData.intro}</div>

          {gradeInfoData.categories.map(({ title, info }, index) => {
            const categoryClass = `educator-info-category-${index + 1}`;

            return (
              <div key={categoryClass} className={`educator-info-category ${categoryClass}`}>
                <div className="educator-info-category-title">{title}</div>
                <div className="educator-info-category-info">
                  <ul>
                    {info.map((str, index) => {
                      const liKey = `liKey${index}`;
                      return <li key={liKey}>{str}</li>;
                    })}
                  </ul>
                </div>
              </div>
            );
          })}

          {grade.number > -1 && getLangCode() === language.default && (
            <div className="educator-info-standards">
              <p>{getString("grades.standardsCta")}</p>

              <StandardsButton url={grade.localeUrl || grade.url} number={grade.number} />
            </div>
          )}
        </>
      );
    }

    return null;
  };

  if (doDisplay) {
    return (
      <div className="wrapper grade">
        <div id={infoId} className="container-medium educator-info">
          <div className="educator-info-title">{getString("educatorInfo")}</div>

          <button
            type="button"
            className="button-flat-color round dimensional cyan auto"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <UpArrow className="inline-icon" />
            {getString("navigation.top.0")}
          </button>

          {renderErrorMessage()}

          {renderInfoContent()}
        </div>
      </div>
    );
  }

  return null;
}

GradeInfo.propTypes = {
  grade: PropTypes.object.isRequired,
  infoId: PropTypes.string.isRequired,
};
