import React from "react";
import { useNavigate } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { printableCategoryNavNames, printableCategories } from "../../config/categories";
import { getLangCode } from "../../config";

export default function CategoryNav() {
  const navigate = useNavigate();

  return printableCategoryNavNames.map((navName) => {
    const printableObject = printableCategories.find(
      ({ shortname, localeShortname }) => localeShortname === navName || shortname === navName,
    );

    if (printableObject) {
      return (
        <button
          type="button"
          key={navName}
          className={`button-flat-color round dimensional ${printableObject.shortname} ${getLangCode()}`}
          onClick={() => {
            navigate(`${routeCodes.CATEGORYPRINTABLES}${printableObject.localeShortname || printableObject.shortname}`);
          }}
        >
          {printableObject.name}
        </button>
      );
    }

    return null;
  });
}
