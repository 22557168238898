import React from "react";
import PropTypes from "prop-types";
import { breakpoints, getLangCode, language } from "../../config";

// Theme categories
import planetBgCatPngXs from "../../../assets/img/planetBgCat_xs.png";
import planetBgCatPngSm from "../../../assets/img/planetBgCat_sm.png";
import planetBgCatPngMd from "../../../assets/img/planetBgCat_md.png";
import planetBgCatPngLg from "../../../assets/img/planetBgCat_lg.png";
import planetBgCatPngXl from "../../../assets/img/planetBgCat_xl.png";
import planetBgCatWebpXs from "../../../assets/img/planetBgCat_xs.webp";
import planetBgCatWebpSm from "../../../assets/img/planetBgCat_sm.webp";
import planetBgCatWebpMd from "../../../assets/img/planetBgCat_md.webp";
import planetBgCatWebpLg from "../../../assets/img/planetBgCat_lg.webp";
import planetBgCatWebpXl from "../../../assets/img/planetBgCat_xl.webp";

import planetBgStoryPngXs from "../../../assets/img/planetBgStory_xs.png";
import planetBgStoryPngSm from "../../../assets/img/planetBgStory_sm.png";
import planetBgStoryPngMd from "../../../assets/img/planetBgStory_md.png";
import planetBgStoryPngLg from "../../../assets/img/planetBgStory_lg.png";
import planetBgStoryPngXl from "../../../assets/img/planetBgStory_xl.png";
import planetBgStoryWebpXs from "../../../assets/img/planetBgStory_xs.webp";
import planetBgStoryWebpSm from "../../../assets/img/planetBgStory_sm.webp";
import planetBgStoryWebpMd from "../../../assets/img/planetBgStory_md.webp";
import planetBgStoryWebpLg from "../../../assets/img/planetBgStory_lg.webp";
import planetBgStoryWebpXl from "../../../assets/img/planetBgStory_xl.webp";

import planetBgCarPngXs from "../../../assets/img/planetBgCar_xs.png";
import planetBgCarPngSm from "../../../assets/img/planetBgCar_sm.png";
import planetBgCarPngMd from "../../../assets/img/planetBgCar_md.png";
import planetBgCarPngLg from "../../../assets/img/planetBgCar_lg.png";
import planetBgCarPngXl from "../../../assets/img/planetBgCar_xl.png";
import planetBgCarWebpXs from "../../../assets/img/planetBgCar_xs.webp";
import planetBgCarWebpSm from "../../../assets/img/planetBgCar_sm.webp";
import planetBgCarWebpMd from "../../../assets/img/planetBgCar_md.webp";
import planetBgCarWebpLg from "../../../assets/img/planetBgCar_lg.webp";
import planetBgCarWebpXl from "../../../assets/img/planetBgCar_xl.webp";

import planetBgAnimalPngXs from "../../../assets/img/planetBgAnimal_xs.png";
import planetBgAnimalPngSm from "../../../assets/img/planetBgAnimal_sm.png";
import planetBgAnimalPngMd from "../../../assets/img/planetBgAnimal_md.png";
import planetBgAnimalPngLg from "../../../assets/img/planetBgAnimal_lg.png";
import planetBgAnimalPngXl from "../../../assets/img/planetBgAnimal_xl.png";
import planetBgAnimalWebpXs from "../../../assets/img/planetBgAnimal_xs.webp";
import planetBgAnimalWebpSm from "../../../assets/img/planetBgAnimal_sm.webp";
import planetBgAnimalWebpMd from "../../../assets/img/planetBgAnimal_md.webp";
import planetBgAnimalWebpLg from "../../../assets/img/planetBgAnimal_lg.webp";
import planetBgAnimalWebpXl from "../../../assets/img/planetBgAnimal_xl.webp";

import planetBgFoodPngXs from "../../../assets/img/planetBgFood_xs.png";
import planetBgFoodPngSm from "../../../assets/img/planetBgFood_sm.png";
import planetBgFoodPngMd from "../../../assets/img/planetBgFood_md.png";
import planetBgFoodPngLg from "../../../assets/img/planetBgFood_lg.png";
import planetBgFoodPngXl from "../../../assets/img/planetBgFood_xl.png";
import planetBgFoodWebpXs from "../../../assets/img/planetBgFood_xs.webp";
import planetBgFoodWebpSm from "../../../assets/img/planetBgFood_sm.webp";
import planetBgFoodWebpMd from "../../../assets/img/planetBgFood_md.webp";
import planetBgFoodWebpLg from "../../../assets/img/planetBgFood_lg.webp";
import planetBgFoodWebpXl from "../../../assets/img/planetBgFood_xl.webp";

import planetBgSpacePngXs from "../../../assets/img/planetBgSpace_xs.png";
import planetBgSpaceWebpXs from "../../../assets/img/planetBgSpace_xs.webp";
import planetBgSpacePngXl from "../../../assets/img/planetBgSpace_xl.png";
import planetBgSpaceWebpXl from "../../../assets/img/planetBgSpace_xl.webp";

import planetBgRetro from "../../../assets/img/planetBgRetro.png";

import planetBgRobotPngXs from "../../../assets/img/planetBgRobot_xs.png";
import planetBgRobotPngSm from "../../../assets/img/planetBgRobot_sm.png";
import planetBgRobotPngMd from "../../../assets/img/planetBgRobot_md.png";
import planetBgRobotPngLg from "../../../assets/img/planetBgRobot_lg.png";
import planetBgRobotPngXl from "../../../assets/img/planetBgRobot_xl.png";
import planetBgRobotWebpXs from "../../../assets/img/planetBgRobot_xs.webp";
import planetBgRobotWebpSm from "../../../assets/img/planetBgRobot_sm.webp";
import planetBgRobotWebpMd from "../../../assets/img/planetBgRobot_md.webp";
import planetBgRobotWebpLg from "../../../assets/img/planetBgRobot_lg.webp";
import planetBgRobotWebpXl from "../../../assets/img/planetBgRobot_xl.webp";

import planetBgMoneyPngXs from "../../../assets/img/planetBgMoney_xs.png";
import planetBgMoneyPngSm from "../../../assets/img/planetBgMoney_sm.png";
import planetBgMoneyPngMd from "../../../assets/img/planetBgMoney_md.png";
import planetBgMoneyPngLg from "../../../assets/img/planetBgMoney_lg.png";
import planetBgMoneyPngXl from "../../../assets/img/planetBgMoney_xl.png";
import planetBgMoneyWebpXs from "../../../assets/img/planetBgMoney_xs.webp";
import planetBgMoneyWebpSm from "../../../assets/img/planetBgMoney_sm.webp";
import planetBgMoneyWebpMd from "../../../assets/img/planetBgMoney_md.webp";
import planetBgMoneyWebpLg from "../../../assets/img/planetBgMoney_lg.webp";
import planetBgMoneyWebpXl from "../../../assets/img/planetBgMoney_xl.webp";

import planetBgSportsPngXs from "../../../assets/img/planetBgSports_xs.png";
import planetBgSportsPngSm from "../../../assets/img/planetBgSports_sm.png";
import planetBgSportsPngMd from "../../../assets/img/planetBgSports_md.png";
import planetBgSportsPngLg from "../../../assets/img/planetBgSports_lg.png";
import planetBgSportsPngXl from "../../../assets/img/planetBgSports_xl.png";
import planetBgSportsWebpXs from "../../../assets/img/planetBgSports_xs.webp";
import planetBgSportsWebpSm from "../../../assets/img/planetBgSports_sm.webp";
import planetBgSportsWebpMd from "../../../assets/img/planetBgSports_md.webp";
import planetBgSportsWebpLg from "../../../assets/img/planetBgSports_lg.webp";
import planetBgSportsWebpXl from "../../../assets/img/planetBgSports_xl.webp";

// Subject categories
import ufboArtPng from "../../../assets/img/ufboArt.png";
import ufboArtWebp from "../../../assets/img/ufboArt.webp";
import ufboLangArtPng from "../../../assets/img/ufboLangArt.png";
import ufboLangArtWebp from "../../../assets/img/ufboLangArt.webp";
import ufboLangArtPngLa from "../../../assets/img/ufboLangArt_la.png";
import ufboLangArtWebpLa from "../../../assets/img/ufboLangArt_la.webp";
import ufboMathPng from "../../../assets/img/ufboMath.png";
import ufboMathWebp from "../../../assets/img/ufboMath.webp";
import ufboSciencePng from "../../../assets/img/ufboScience.png";
import ufboScienceWebp from "../../../assets/img/ufboScience.webp";
import ufboSocStudPng from "../../../assets/img/ufboSocStud.png";
import ufboSocStudWebp from "../../../assets/img/ufboSocStud.webp";
import ufboTypingPng from "../../../assets/img/ufboTyping.png";
import ufboTypingWebp from "../../../assets/img/ufboTyping.webp";

// Genre categories
import ufboBingoPng from "../../../assets/img/ufboBingo.png";
import ufboBingoWebp from "../../../assets/img/ufboBingo.webp";
import ufboCrosswordPng from "../../../assets/img/ufboCrossword.png";
import ufboCrosswordWebp from "../../../assets/img/ufboCrossword.webp";
import ufboHuntPng from "../../../assets/img/ufboHunt.png";
import ufboHuntWebp from "../../../assets/img/ufboHunt.webp";
import ufboMultiplayerPng from "../../../assets/img/ufboMultiplayer.png";
import ufboMultiplayerWebp from "../../../assets/img/ufboMultiplayer.webp";
import ufboPlatformerPng from "../../../assets/img/ufboPlatformer.png";
import ufboPlatformerWebp from "../../../assets/img/ufboPlatformer.webp";
import ufboPuzzlePng from "../../../assets/img/ufboPuzzle.png";
import ufboPuzzleWebp from "../../../assets/img/ufboPuzzle.webp";
import ufboRacingPng from "../../../assets/img/ufboRacing.png";
import ufboRacingWebp from "../../../assets/img/ufboRacing.webp";
import ufboWordSearchPng from "../../../assets/img/ufboWordSearch.png";
import ufboWordSearchWebp from "../../../assets/img/ufboWordSearch.webp";

export default function CategoryGamesArt({ name, title, type }) {
  // must match theme and subject categories (startup data)
  // names cleaned up with lowAndNoSpecialString

  // must go smallest to largest for media query max-width usage
  // must have webp first for usage as png is the fallback
  const categoryArt = {
    theme: {
      cat: {
        xs: {
          webp: planetBgCatWebpXs,
          png: planetBgCatPngXs,
        },
        sm: {
          webp: planetBgCatWebpSm,
          png: planetBgCatPngSm,
        },
        md: {
          webp: planetBgCatWebpMd,
          png: planetBgCatPngMd,
        },
        lg: {
          webp: planetBgCatWebpLg,
          png: planetBgCatPngLg,
        },
        xl: {
          webp: planetBgCatWebpXl,
          png: planetBgCatPngXl,
        },
      },
      story: {
        xs: {
          webp: planetBgStoryWebpXs,
          png: planetBgStoryPngXs,
        },
        sm: {
          webp: planetBgStoryWebpSm,
          png: planetBgStoryPngSm,
        },
        md: {
          webp: planetBgStoryWebpMd,
          png: planetBgStoryPngMd,
        },
        lg: {
          webp: planetBgStoryWebpLg,
          png: planetBgStoryPngLg,
        },
        xl: {
          webp: planetBgStoryWebpXl,
          png: planetBgStoryPngXl,
        },
      },
      car: {
        xs: {
          webp: planetBgCarWebpXs,
          png: planetBgCarPngXs,
        },
        sm: {
          webp: planetBgCarWebpSm,
          png: planetBgCarPngSm,
        },
        md: {
          webp: planetBgCarWebpMd,
          png: planetBgCarPngMd,
        },
        lg: {
          webp: planetBgCarWebpLg,
          png: planetBgCarPngLg,
        },
        xl: {
          webp: planetBgCarWebpXl,
          png: planetBgCarPngXl,
        },
      },
      animal: {
        xs: {
          webp: planetBgAnimalWebpXs,
          png: planetBgAnimalPngXs,
        },
        sm: {
          webp: planetBgAnimalWebpSm,
          png: planetBgAnimalPngSm,
        },
        md: {
          webp: planetBgAnimalWebpMd,
          png: planetBgAnimalPngMd,
        },
        lg: {
          webp: planetBgAnimalWebpLg,
          png: planetBgAnimalPngLg,
        },
        xl: {
          webp: planetBgAnimalWebpXl,
          png: planetBgAnimalPngXl,
        },
      },
      food: {
        xs: {
          webp: planetBgFoodWebpXs,
          png: planetBgFoodPngXs,
        },
        sm: {
          webp: planetBgFoodWebpSm,
          png: planetBgFoodPngSm,
        },
        md: {
          webp: planetBgFoodWebpMd,
          png: planetBgFoodPngMd,
        },
        lg: {
          webp: planetBgFoodWebpLg,
          png: planetBgFoodPngLg,
        },
        xl: {
          webp: planetBgFoodWebpXl,
          png: planetBgFoodPngXl,
        },
      },
      space: {
        xs: {
          webp: planetBgSpaceWebpXs,
          png: planetBgSpacePngXs,
        },
        xl: {
          webp: planetBgSpaceWebpXl,
          png: planetBgSpacePngXl,
        },
      },
      retro: {
        xl: {
          png: planetBgRetro,
        },
      },
      robot: {
        xs: {
          webp: planetBgRobotWebpXs,
          png: planetBgRobotPngXs,
        },
        sm: {
          webp: planetBgRobotWebpSm,
          png: planetBgRobotPngSm,
        },
        md: {
          webp: planetBgRobotWebpMd,
          png: planetBgRobotPngMd,
        },
        lg: {
          webp: planetBgRobotWebpLg,
          png: planetBgRobotPngLg,
        },
        xl: {
          webp: planetBgRobotWebpXl,
          png: planetBgRobotPngXl,
        },
      },
      money: {
        xs: {
          webp: planetBgMoneyWebpXs,
          png: planetBgMoneyPngXs,
        },
        sm: {
          webp: planetBgMoneyWebpSm,
          png: planetBgMoneyPngSm,
        },
        md: {
          webp: planetBgMoneyWebpMd,
          png: planetBgMoneyPngMd,
        },
        lg: {
          webp: planetBgMoneyWebpLg,
          png: planetBgMoneyPngLg,
        },
        xl: {
          webp: planetBgMoneyWebpXl,
          png: planetBgMoneyPngXl,
        },
      },
      sports: {
        xs: {
          webp: planetBgSportsWebpXs,
          png: planetBgSportsPngXs,
        },
        sm: {
          webp: planetBgSportsWebpSm,
          png: planetBgSportsPngSm,
        },
        md: {
          webp: planetBgSportsWebpMd,
          png: planetBgSportsPngMd,
        },
        lg: {
          webp: planetBgSportsWebpLg,
          png: planetBgSportsPngLg,
        },
        xl: {
          webp: planetBgSportsWebpXl,
          png: planetBgSportsPngXl,
        },
      },
    },
    subject: {
      "art-music": {
        sm: {
          webp: ufboArtWebp,
          png: ufboArtPng,
        },
      },
      "language-arts": {
        sm: {
          webp: getLangCode() === language.la ? ufboLangArtWebpLa : ufboLangArtWebp,
          png: getLangCode() === language.la ? ufboLangArtPngLa : ufboLangArtPng,
        },
      },
      writing: {
        sm: {
          webp: ufboLangArtWebp,
          png: ufboLangArtPng,
        },
      },
      math: {
        sm: {
          webp: ufboMathWebp,
          png: ufboMathPng,
        },
      },
      science: {
        sm: {
          webp: ufboScienceWebp,
          png: ufboSciencePng,
        },
      },
      "social-studies": {
        sm: {
          webp: ufboSocStudWebp,
          png: ufboSocStudPng,
        },
      },
      typing: {
        sm: {
          webp: ufboTypingWebp,
          png: ufboTypingPng,
        },
      },
      multiplication: {
        sm: {
          webp: ufboMathWebp,
          png: ufboMathPng,
        },
      },
    },
    genre: {
      bingo: {
        sm: {
          webp: ufboBingoWebp,
          png: ufboBingoPng,
        },
      },
      crossword: {
        sm: {
          webp: ufboCrosswordWebp,
          png: ufboCrosswordPng,
        },
      },
      hunt: {
        sm: {
          webp: ufboHuntWebp,
          png: ufboHuntPng,
        },
      },
      multiplayer: {
        sm: {
          webp: ufboMultiplayerWebp,
          png: ufboMultiplayerPng,
        },
      },
      platformer: {
        sm: {
          webp: ufboPlatformerWebp,
          png: ufboPlatformerPng,
        },
      },
      puzzle: {
        sm: {
          webp: ufboPuzzleWebp,
          png: ufboPuzzlePng,
        },
      },
      racing: {
        sm: {
          webp: ufboRacingWebp,
          png: ufboRacingPng,
        },
      },
      "word-search": {
        sm: {
          webp: ufboWordSearchWebp,
          png: ufboWordSearchPng,
        },
      },
    },
  };

  const categoryArtWidth = {
    theme: "1920",
    subject: "381",
    genre: "508",
  };

  const categoryArtHeight = {
    theme: "299", // based on avg
    subject: "258", // based on avg
    genre: "284",
  };

  const renderSources = () => {
    const artObject = categoryArt[type][name];
    const artArray = Object.keys(artObject);
    let keyCount = 0;

    return artArray.map((artKey, index) => {
      const sourceObject = artObject[artKey];
      const sourceArray = Object.keys(sourceObject);

      const media = index === artArray.length - 1 ? "" : `(max-width: ${breakpoints.width[artKey]}px)`;

      return sourceArray.map((sourceKey) => {
        keyCount++;
        return (
          <source
            key={`artKey${keyCount}`}
            srcSet={sourceObject[sourceKey]}
            media={media}
            type={`image/${sourceKey}`}
          />
        );
      });
    });
  };

  return (
    <div className={`categorygames-art-container cgs-${name}`}>
      <picture>
        {renderSources()}

        <img width={categoryArtWidth[type]} height={categoryArtHeight[type]} src="" alt={title || ""} />
      </picture>
    </div>
  );
}

CategoryGamesArt.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
