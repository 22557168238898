import React, { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { metaDefault } from "../../config/meta";
import { getString, purifiedParsedString } from "../../utilities";
import aboutStrings from "../../strings/about";
import Arrow from "../../../assets/svg/arrowRight.svg";
import ArrowTip from "../../../assets/svg/arrowTip.svg";

export default function Privacy() {
  const location = useLocation();
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    console.log("activeId: ", activeId);
  }, [activeId]);

  const renderTopics = () => {
    const { data } = aboutStrings.privacy.policy;
    if (data && data.length > 0) {
      return data.map((obj, i) => {
        const topicId = `topicId${i}`;
        const isActive = activeId === topicId;
        const toggleStyle = isActive ? "active" : "";
        const arrow = isActive ? (
          <Arrow aria-hidden="true" className="toggle-title-arrow" />
        ) : (
          <ArrowTip aria-hidden="true" className="toggle-title-arrow" />
        );

        return (
          <Fragment key={topicId}>
            <div
              id={topicId}
              role="button"
              aria-pressed={isActive}
              className={`wrapper toggle-title ${toggleStyle}`}
              tabIndex={i + 1}
              onClick={() => {
                if (activeId !== topicId) {
                  setActiveId(topicId);
                } else {
                  setActiveId(null);
                }
              }}
            >
              <div className="container toggle-title-container">
                <div className="toggle-title-text">{obj.title}</div>
                {arrow}
              </div>
            </div>
            <div aria-hidden="false" className={`wrapper padded even toggle-body ${toggleStyle}`}>
              <div className="container-medium">{purifiedParsedString(obj.body)}</div>
            </div>
          </Fragment>
        );
      }, []);
    }

    return null;
  };

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("privacy.title.0") })}

      <div className="privacy">
        <div className="wrapper padded">
          <div className="container">
            <h1>{getString("privacy.title.0")}</h1>
          </div>
        </div>

        <div className="wrapper padded">
          <div className="container-medium">{getString("privacy.body", { html: true, strings: aboutStrings })}</div>
        </div>

        <div className="wrapper odd">
          <div className="container toggle-section-header-text">
            {getString("privacy.policy.title", { strings: aboutStrings })}
          </div>
        </div>
        {renderTopics()}
      </div>

      <div className="wrapper header-shadow" />
    </>
  );
}
