import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { loadPrerollVideoDependencies } from "../../utilities/head";
import { adData, getLangCode } from "../../config";
import { setPreContentVideo } from "../../redux/slices/preContentVideoSlice";

export default function PreContentVideo() {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const timer = useRef(null);

  const loadedFunction = () => {
    const usPrivacyCookie = document.cookie.split(";").find((cookie) => cookie.includes("usprivacy"));
    const cookieEntry = usPrivacyCookie?.split("=") || [];
    const isCcpa = cookieEntry.length > 1 && cookieEntry[1] !== "1---";

    const adPlacementUrl = `${adData.adPlacementUrl[getLangCode()]}${isCcpa ? `&us_privacy=${cookieEntry[1]}` : "&tfcd=1"}`;
    console.log("ad placement URL: ", adPlacementUrl);

    if (adPlacementUrl) {
      window.ovpjs.addVideoPlayerAdOnly({
        divContainerId: "preroll-video-container",
        placeholderVideo: {
          type: "video/mp4",
          src: "/static/assets/blankVideo5s.mp4",
        },
        adPlacementUrl,
      });

      console.log("window.ovpjs: ", window.ovpjs);
      window.ovpjs.player.instance.ready(function () {
        this.on("adsready", function () {
          // IMA SDK Events For d Playing status:
          // https://github.com/googleads/videojs-ima/blob/596c76f95b175ba7483a9490d58a0c2335aa8942/dist/videojs.ima.js#L1450

          this.ima.addEventListener(window.google.ima.AdEvent.Type.COMPLETE, function () {
            window.ovpjs?.player?.instance?.destroy();
            dispatch(setPreContentVideo({ preContentVideo: false }));
            console.log("preContentVideo - COMPLETE");
          });

          this.ima.addEventListener(window.google.ima.AdEvent.Type.SKIPPED, () => {
            window.ovpjs?.player?.instance?.destroy();
            dispatch(setPreContentVideo({ preContentVideo: false }));
            console.log("preContentVideo - SKIPPED");
          });
        });
      });

      // Player events
      window.ovpjs.player.instance.on("adserror", () => {
        window.ovpjs?.player?.instance?.destroy();
        dispatch(setPreContentVideo({ preContentVideo: false }));
        console.log("preContentVideo - adserror");
      });

      window.ovpjs.player.instance.on("contentended", () => {
        window.ovpjs?.player?.instance?.destroy();
        dispatch(setPreContentVideo({ preContentVideo: false }));
        console.log("preContentVideo - contentended");
      });

      const playPromise = window.ovpjs.player.instance.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Playback started!
          })
          .catch((error) => {
            // console.log("autoplay prevented");
            // Auto-play was prevented
          });
      }
    } else {
      window.ovpjs?.player?.instance?.destroy();
      dispatch(setPreContentVideo({ preContentVideo: false }));
      console.log("failed to retrieve video placement url");
    }
  };

  useEffect(() => {
    if (loaded) {
      loadedFunction();
    }
  }, [loaded]);

  useEffect(() => {
    loadPrerollVideoDependencies();
    window.ovpReady = () => {};
    setLoaded(false);

    const checkForOvp = () => {
      if (window.ovpjs) {
        setLoaded(true);
        clearInterval(timer.current);
        timer.current = null;
      }
    };

    timer.current = setInterval(checkForOvp, 200);

    return () => {
      clearInterval(timer.current);
      window.ovpjs?.player?.instance?.destroy();
      timer.current = null;
    };
  }, [location]);

  return <div className="a-prerollvideo-container" id="preroll-video-container" />;
}
