import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { sortedArrayByItem, getString, capitalize } from "../../utilities";
import categoryMbArt from "../../utilities/categoryMbArt";
import { userRoles, publicFavLimit, contentTypes, HOME_LIST_COUNT, adData } from "../../config";
import { routeCodes } from "../../config/routes";
import List from "../global/list";
import { gameCategories } from "../../config/categories";
import LoadingAnim from "../global/loadingAnim";
import { gamesSelector, isLoggedInSelector, roleSelector } from "../../redux/slices/loginSlice";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import Leader from "../ads/leader";
import { mobile } from "../../utilities/detection";

export default function HomeLists() {
  const role = useSelector(roleSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const favGames = useSelector(gamesSelector);
  const { lists, games, printables } = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);

  const leaderStyleArray = ["a-leader-asteroid", "a-leader-satellite", "a-leader-spaceTravel"];

  const listArray = useMemo(() => {
    const nextListArray = [];

    const isFree = role === userRoles.free;
    if (loading) {
      let i;
      for (i = 0; i < HOME_LIST_COUNT; ++i) {
        if (i % 2 === 0) {
          const leaderId = Math.ceil(i / 3);
          const adId = adData.divIds.leader[mobile ? "mobile" : "desktop"][leaderId];
          const leaderKey = adId ? adId : `leaderKey${leaderId}`;
          nextListArray.push(<Leader key={leaderKey} className={leaderStyleArray[leaderId]} adId={adId} />);
        }
        nextListArray.push(
          <div key={`placeholder${i}`} className="home-list-container">
            <LoadingAnim />
          </div>,
        );
      }
    } else {
      const homeLists = sortedArrayByItem(
        lists.filter((l) => l.home && l.group !== "free"),
        "order",
      ).slice(0, HOME_LIST_COUNT);

      let index;
      const hasFavGamesFirst = isLoggedIn && favGames && favGames.length > 0 && !isFree;
      for (index = 0; index < homeLists.length; ++index) {
        let finalIndex = index;
        finalIndex += hasFavGamesFirst ? 1 : 0;
        if (index % 2 === 0) {
          const leaderId = Math.ceil(index / 3);
          const adId = adData.divIds.leader[mobile ? "mobile" : "desktop"][leaderId];
          const leaderKey = adId ? adId : `leaderKey${leaderId}`;
          nextListArray.push(<Leader key={leaderKey} className={leaderStyleArray[leaderId]} adId={adId} />);
        }
        const homelist = homeLists[index];
        const homeLisKey = `hlk_${index}`;
        nextListArray.push(
          <div key={homeLisKey} className="home-list-container">
            <List
              title={homelist.title}
              items={homelist.items.reduce((homelistitems, item) => {
                let itemObject = null;

                if (item.game) {
                  const itemGame = games ? games.find((g) => g.id === item.game) : null;

                  if (itemGame) {
                    itemObject = {
                      type: contentTypes.game,
                      title: itemGame.fullname,
                      path: itemGame.localeShortname || itemGame.shortname,
                      image: itemGame.miniBanner ? itemGame.miniBanner : null,
                      grades: itemGame.grades,
                      order: item.order,
                      mobile: itemGame.mobileActive,
                      searchable: itemGame.searchable,
                    };
                  }
                } else if (item.printable) {
                  const itemPrintable = printables
                    ? printables.find((printable) => printable.id === item.printable)
                    : null;

                  if (itemPrintable) {
                    itemObject = {
                      type: contentTypes.printable,
                      title: itemPrintable.fullname,
                      image: itemPrintable.miniBanner ? itemPrintable.miniBanner : null,
                      grades: itemPrintable.grades,
                      order: item.order,
                      style: "mb-home-printable",
                      path: itemPrintable.localeShortname || itemPrintable.shortname,
                    };
                  }
                }

                if (itemObject) homelistitems.push(itemObject);

                return homelistitems;
              }, [])}
              platform={homelist.platform}
              sort="order"
              className={`home-list ${!mobile && finalIndex % 2 === 0 ? "cyan" : ""}`}
            />
          </div>,
        );
      }
    }

    if (isLoggedIn && favGames && favGames.length > 0) {
      // User is signed in and has games in their favorites
      let favsList;
      if (!loading && games) {
        favsList = (
          <div key="premiumFavsList" className="home-list-container">
            <List
              title={getString("tools.favorite.title.1")}
              items={favGames.reduce((favsListItems, item, index) => {
                if (!isFree || (isFree && index < publicFavLimit)) {
                  const itemGame = games.find((g) =>
                    g.localeShortname ? g.localeShortname === item.shortname : g.shortname === item.shortname,
                  );

                  if (itemGame) {
                    const itemObject = {
                      type: contentTypes.game,
                      title: itemGame.fullname,
                      path: itemGame.localeShortname || itemGame.shortname,
                      image: itemGame.miniBanner ? itemGame.miniBanner : null,
                      grades: itemGame.grades,
                      mobile: itemGame.mobileActive,
                      searchable: itemGame.searchable,
                    };

                    favsListItems.push(itemObject);
                  }
                }

                return favsListItems;
              }, [])}
              className={`home-list ${!mobile && isFree ? "" : "cyan"}`}
            />
          </div>
        );
      } else {
        favsList = (
          <div key="premiumFavsList" className="home-list-container">
            <LoadingAnim />
          </div>
        );
      }

      if (isFree) {
        // FREE user put favorites list at the end/bottom
        nextListArray.push(favsList);
      } else {
        // Premium user put favorites list at the top
        nextListArray.unshift(favsList);
      }
    }

    const groupClass = {
      subject: "green",
      theme: "purple",
      genre: "blue-genre",
    };
    const keys = Object.keys(groupClass);

    let i;
    for (i = 0; i < keys.length; ++i) {
      const group = keys[i];
      const adId = adData.divIds.leader[mobile ? "mobile" : "desktop"][3];
      const leaderKey = adId ? adId : `categoryLeaderKey`;
      // purple satelites design only used inside category lists
      const leaderStyle = "a-leader-satellites";

      if (isLoggedIn && favGames && favGames.length > 0) {
        if (i === 0) {
          nextListArray.push(<Leader key={leaderKey} className={leaderStyle} adId={adId} />);
        }
      } else if (i === 1) {
        nextListArray.push(<Leader key={leaderKey} className={leaderStyle} adId={adId} />);
      }
      nextListArray.push(
        <List
          key={group}
          title={getString("games.by", { replace: [getString(`gameCategoryGroups.${group}`)] })}
          items={sortedArrayByItem(
            gameCategories.filter((cat) => cat.type === group),
            "order",
          ).map((cat) => {
            const { image, fallback } = categoryMbArt(contentTypes.game, cat.shortname); // currently only GAME CategoryGroups

            return {
              type: contentTypes.link,
              style: "mb-label-center",
              title: cat.name,
              clickHandler: {
                pathname: `${routeCodes.CATEGORYGAMES}${cat.localeShortname ? cat.localeShortname : cat.shortname}`,
                state: { type: group.shortname },
              },
              image,
              fallbackImage: fallback,
            };
          })}
          className={`home-list ${groupClass[group]}`}
          noscroll={true}
        />,
      );
    }

    return nextListArray;
  }, [role, isLoggedIn, favGames, loading, lists, games, printables]);

  return <div className="home-lists">{listArray}</div>;
}
