import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useCombobox } from "downshift";

import { getString, getSuggestions, sortedArrayByItem } from "../../utilities";
import { getAllGames } from "../../utilities/games";
import { getPrintableCategory, renderDesktopSignupModal } from "../../utilities/printables";
import MagnifySearchField from "../../../assets/svg/magnifySearchField.svg";
import { routeCodes } from "../../config/routes";
import { contentTypes, getContentType, getLangCode, getSearchParamString } from "../../config";
import faqStrings from "../../strings/faq";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";

export default function SearchField({ hasSearch }) {
  const [searchData, setSearchData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const loading = useSelector(loadingSelector);
  const data = useSelector(dataSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const navigate = useNavigate();

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, searchData, "label"));
  };

  const {
    highlightedIndex,
    isOpen,
    inputValue,
    closeMenu,
    getInputProps,
    getItemProps,
    getMenuProps,
    selectItem,
    setInputValue,
  } = useCombobox({
    items: suggestions,
    itemToString: (item) => (item ? item.label : ""), // From docs: This callback must include a null check: it is invoked with null whenever the user abandons input via <Esc>.
    // Go to search page
    onStateChange: (changes) => {
      switch (changes.type) {
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (changes.selectedItem) {
            const { label, path, type } = changes.selectedItem;
            if ([contentTypes.faq, contentTypes.standard].includes(type)) {
              handleSearchSubmit(type, label);
            } else {
              let prefix = "";
              if (type === contentTypes.game) {
                prefix = routeCodes.GAMES;
              } else if (type === contentTypes.printable) {
                prefix = routeCodes.PRINTABLES;
              } else if (type === contentTypes.blog) {
                prefix = routeCodes.BLOG;
              }
              const location = {
                pathname: `${prefix}${path}`,
              };
              if (type !== contentTypes.printable || isLoggedIn) {
                navigate(location);
              } else {
                renderDesktopSignupModal();
              }
              selectItem(null);
              setInputValue(label);
            }
          } else {
            handleSearchSubmit(undefined, inputValue);
          }
          break;
        default:
          break;
      }
    },
    // Get suggestions
    onInputValueChange: ({ inputValue }) => {
      onSuggestionsFetchRequested({ value: inputValue });
    },
  });

  // when the suggestion is selected

  const handleSearchSubmit = (type, value) => {
    closeMenu();
    const cleanValue = value.trim();
    let filterType = type || contentTypes.all;
    filterType = getContentType(filterType, { langCode: getLangCode() });
    const termParamString = getSearchParamString("term");
    const typeParamString = getSearchParamString("type");

    const location = {
      pathname: routeCodes.SEARCH,
      search: `?${termParamString}=${encodeURIComponent(cleanValue)}&${typeParamString}=${filterType}`,
    };
    navigate(location);
  };

  useEffect(() => {
    if (!loading && data) {
      const nextSearchData = [];

      const setData = (arry, labelKey, pathKeys, type) => {
        arry.forEach((obj) => {
          const label = obj[labelKey];
          let path = null;
          for (let i = 0; i < pathKeys.length; ++i) {
            path = obj[pathKeys[i]];
            if (path !== null && path !== undefined) {
              break;
            }
          }
          const dataObj = { label, path, type };

          // handle categories of type
          // suggestions might have specific icon artwork or actions
          if (type === contentTypes.printable) {
            dataObj.category = getPrintableCategory(obj.categories);
          }

          nextSearchData.push(dataObj);
        });
      };

      if (data.games) {
        setData(getAllGames(data.games), "fullname", ["localeShortname", "shortname"], contentTypes.game);
      }

      if (data.standards) {
        setData(data.standards, "tag", ["tag"], contentTypes.standard);
      }

      if (data.posts) {
        setData(data.posts, "title", ["id"], contentTypes.blog);
      }

      if (data.printables) {
        setData(data.printables, "fullname", ["localeShortname", "shortname"], contentTypes.printable);
      }

      // faq not in startup data, in strings data
      faqStrings.sections.forEach((section) => {
        setData(section.data, "question", ["question"], contentTypes.faq);
      });

      setSearchData(sortedArrayByItem(nextSearchData, "label"));
    }
  }, [data, loading]);

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => {
    const typeCatStyle = suggestion.category || suggestion.type;
    return (
      <div className={`header-search-suggestion${typeCatStyle ? ` header-search-${typeCatStyle}-suggestion` : ""}`}>
        {suggestion.label}
      </div>
    );
  };

  return (
    <div className={`header-search${hasSearch ? " open" : ""}`}>
      <div className="header-search-container">
        <input placeholder={getString("search.cta", { replace: [getString("abcya.0")] })} {...getInputProps()} />
      </div>
      <ul {...getMenuProps()} className="search-suggestions-container suggestions-list">
        {isOpen &&
          suggestions.map((item, index) => {
            return (
              <li
                className={`suggestion${highlightedIndex === index ? " highlighted" : ""}`}
                key={JSON.stringify(item)}
                {...getItemProps({ item, index })}
              >
                {renderSuggestion(item)}
              </li>
            );
          })}
      </ul>
      <button
        data-testid="header-search-button"
        type="button"
        className="search-submit"
        onClick={() => handleSearchSubmit(undefined, inputValue)}
      >
        <MagnifySearchField />
      </button>
    </div>
  );
}

SearchField.propTypes = {
  hasSearch: PropTypes.bool.isRequired,
};
