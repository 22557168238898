import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CloseOnEscape from "./closeOnEscape";
import OptionallyDisplayed from "./optionallyDisplayed";
import BodyNoScroll from "./bodyNoScroll";

export default function Modal({ doShow, closeOnEscape, children, className = "" }) {
  useEffect(() => {
    if (doShow) {
      // going from hidden to shown
      // take focus off of any form fields that might be in focus
      // prevent changing of that selected field if user hits enter
      // and shows modal over form
      const inputs = document.querySelectorAll("input");

      setTimeout(() => {
        inputs.forEach((input) => input.blur());
      }, 0);
    }
  }, [doShow]);

  return (
    <OptionallyDisplayed doDisplay={doShow}>
      <BodyNoScroll isActive={doShow}>
        <CloseOnEscape onEscape={closeOnEscape}>
          <div className="wrapper modal-overlay">
            <div className={`modal-content ${className}`}>{children}</div>
          </div>
        </CloseOnEscape>
      </BodyNoScroll>
    </OptionallyDisplayed>
  );
}

Modal.propTypes = {
  doShow: PropTypes.bool.isRequired,
  closeOnEscape: PropTypes.func,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};
