import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MiniBanner from "../global/miniBanner";
import { contentTypes } from "../../config";
import { metaDefault } from "../../config/meta";
import { routeCodes } from "../../config/routes";
import { getString, getSuggestions, sortedArrayByItem } from "../../utilities";
import { getAllGames } from "../../utilities/games";
import { searchArray } from "../../utilities/search";
import LoadingAnim from "../global/loadingAnim";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import StandardsModal from "../global/standardsModal";
import SearchBox from "../global/SearchBox";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";

export default function StandardsGame() {
  const location = useLocation();
  const navigate = useNavigate();
  const gamesContainer = useRef();
  const [games, setGames] = useState(null);
  const [hasListeners, setHasListeners] = useState(false);
  const [searchedGames, setSearchedGames] = useState(null);
  const [searching, setSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadCounter, setLoadCounter] = useState(1);
  const [selectedGame, setSelectedGame] = useState(null);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (load) setLoadCounter(loadCounter + 1);
  }, [load]);

  const { games: dataGames, standards: dataStandards } = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);

  const settingGames = async () => {
    // filter out all games that don't have standards assigned to them
    const gamesWithStandards = dataGames.filter((game) => game.standards.length > 0);

    let ag = await getAllGames(gamesWithStandards);
    ag = await sortedArrayByItem(ag, "fullname");
    if (ag !== games) setGames(ag);
  };

  useEffect(() => {
    if (dataGames && dataStandards) settingGames();
  }, [dataGames, dataStandards]);

  const resetMiniBanners = () => {
    // infinite scroll load for game minibanners
    if (gamesContainer.current) {
      const buff = 30; // show more button for over scrolling
      const gc = gamesContainer.current.getBoundingClientRect();
      const { clientHeight } = document.documentElement;
      const shouldLoad = gc.height + gc.top <= clientHeight + buff;

      setLoad(shouldLoad);
    }
  };

  useEffect(() => {
    if (!hasListeners) {
      setHasListeners(true);

      setTimeout(() => {
        window.addEventListener("scroll", resetMiniBanners);
        window.addEventListener("resize", resetMiniBanners);
      }, 100);
    }

    return () => {
      // componentWillUnmount (cleanup)
      window.removeEventListener("scroll", resetMiniBanners);
      window.removeEventListener("resize", resetMiniBanners);
    };
  }, []);

  const gameClickHandler = async (game) => {
    if (modal) {
      await setModal(false);
      await setSelectedGame(null);
    }
    setModal(true);
    setSelectedGame(game);
  };

  const renderGames = () => {
    if (loading) {
      return <LoadingAnim />;
    }

    if (!loading && games) {
      const gameObjects = searchedGames || games;

      if (searching) {
        return <LoadingAnim />;
      }

      if (gameObjects.length > 0) {
        // 18 is 3 rows of minibanners at 1366 x 768
        // most popular size (chrome books)
        const mbCount = 18;
        const pi = loadCounter * mbCount;
        const more = pi < gameObjects.length;
        const mbToRender = more ? gameObjects.slice(0, pi) : gameObjects;

        return (
          <>
            {mbToRender.map((game, index) => (
              <MiniBanner
                key={game.id}
                type={contentTypes.game}
                style="mb-no-icon" //eslint-disable-line
                title={game.fullname}
                path={game.shortname}
                image={game.miniBanner ? game.miniBanner : null}
                grades={game.grades}
                isMobile={game.mobileActive}
                isSearchable={game.searchable}
                hasNoScroll={true}
                clickHandler={() => {
                  gameClickHandler(game);
                }}
                tabIndex={String(index + 1)}
              />
            ))}
            <OptionallyDisplayed doDisplay={more}>
              <div className="loadmore-wrapper">
                <button
                  type="button"
                  onClick={() => {
                    setLoadCounter(loadCounter + 1);
                  }}
                  className="button-flat-color orange round dimensional auto"
                >
                  {getString("navigation.more.1")}
                </button>
              </div>
            </OptionallyDisplayed>
          </>
        );
      }
    }

    return <div className="search-noResults">{getString("search.noresults")}</div>;
  };

  const renderModal = () => {
    if (selectedGame) {
      const standards = selectedGame.standards.map((standard) => {
        return dataStandards.find((ds) => ds.id === standard);
      });

      return (
        <StandardsModal
          standards={standards}
          click={() => {
            navigate(`${routeCodes.GAMES}${selectedGame.localeShortname || selectedGame.shortname}`);
          }}
          close={() => {
            setModal(false);
          }}
          doShow={modal}
          header={selectedGame.fullname}
        >
          {getString("standards.page.1")}
        </StandardsModal>
      );
    }

    return null;
  };

  const showSearch = !loading && games?.length > 0;

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("standards.games") })}

      <div className="wrapper visible padded even standards-search-wrapper">
        <SearchBox
          searched={searchedGames}
          setSearched={setSearchedGames}
          setSearching={setSearching}
          search={async (searchQuery) => await searchArray(games, searchQuery, contentTypes.game)}
          onSuggestionsFetchRequested={({ value }) => {
            setSuggestions(
              getSuggestions(value, games, "fullname")
                .map((game) => game.fullname)
                .sort(),
            );
          }}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          showSearch={showSearch}
          placeholder={getString("search.cta", { replace: [getString("games.title.0")] })}
        />
      </div>

      <div className="standards-content">
        <div className="container standards-content-container" ref={gamesContainer}>
          {renderGames()}
        </div>
      </div>

      {renderModal()}
    </>
  );
}
