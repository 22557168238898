import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { contentTypes, getParamValue } from "../../config";
import { metaDefault } from "../../config/meta";
import { getString, getSuggestions, sortedArrayByItem } from "../../utilities";
import { getAllGames } from "../../utilities/games";
import { searchArray } from "../../utilities/search";
import Arrow from "../../../assets/svg/arrowRight.svg";
import ArrowTip from "../../../assets/svg/arrowTip.svg";
import MiniBanner from "../global/miniBanner";
import LoadingAnim from "../global/loadingAnim";
import { routeCodes } from "../../config/routes";
import grades from "../../config/grades";
import SearchBox from "../global/SearchBox";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { dataSelector } from "../../redux/slices/dataSlice";
import { renderDesktopSignupModal } from "../../utilities/printables";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";

export default function StandardsStandard() {
  const location = useLocation();

  let initialSectionId = getParamValue("grade", { location });
  initialSectionId = initialSectionId ? parseInt(initialSectionId, 10) : null;

  const [standards, setStandards] = useState(null);
  const [sectionId, setSectionId] = useState(initialSectionId);
  const [subjectId, setSubjectId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [standardId, setStandardId] = useState(null);
  const [searchedStandards, setSearchedStandards] = useState(null);
  const [initialSearch, setInitialSearch] = useState("");
  const [searching, setSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const { printables: dataPrintables, games: dataGames, standards: dataStandards } = useSelector(dataSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);

  const settingStandards = async () => {
    const standardsWithContent = dataStandards.reduce((standardsArray, standardObject) => {
      let standardGames = [];
      let standardPrintables = [];

      if (standardObject.games?.length > 0) {
        // make sure each game id is in game data "active"
        standardGames = standardObject.games.filter((gameId) => dataGames.find(({ id }) => id === gameId));
      }

      if (standardObject.printables?.length > 0) {
        // make sure each printable id is in printable data "active"
        standardPrintables = standardObject.printables.filter((printableId) =>
          dataPrintables.find(({ id }) => id === printableId),
        );
      }

      if (standardGames.length > 0 || standardPrintables.length > 0) {
        standardsArray.push({ ...standardObject, games: standardGames, printables: standardPrintables });
      }

      return standardsArray;
    }, []);

    setStandards(standardsWithContent);

    const idParamValue = getParamValue("id", { location });

    if (idParamValue) {
      const standardMatch = standardsWithContent.find((standard) => standard.id === idParamValue);
      if (standardMatch) {
        const nextSearched = await searchArray(standardsWithContent, standardMatch.tag, contentTypes.standard);

        setInitialSearch(standardMatch.tag);
        setSearchedStandards(nextSearched);
      }
    }
  };

  useEffect(() => {
    if (dataStandards && dataGames && dataPrintables) {
      settingStandards();
    }
  }, [dataStandards, dataGames, dataPrintables]);

  const sectionClickHandler = (id) => {
    const newId = id === sectionId ? null : id;
    setSectionId(newId);
    setSubjectId(null);
    setCategoryId(null);
    setStandardId(null);
  };

  const subjectClickHandler = (id) => {
    const newId = id === subjectId ? null : id;
    setSubjectId(newId);
    setCategoryId(null);
    setStandardId(null);
  };

  const categoryClickHandler = (id) => {
    const newId = id === categoryId ? null : id;
    setCategoryId(newId);
    setStandardId(null);
  };

  const standardClickHandler = (id) => {
    const newId = id === standardId ? null : id;
    setStandardId(newId);
  };

  const renderActiveGames = (isActive, games) => {
    if (isActive && games?.length > 0) {
      const sortedGames = sortedArrayByItem(games, "fullname");

      return (
        <div className="standards-standard-content">
          <div className="standards-content-header">{getString("games.title.1")}</div>
          <div className="container standards-content-container">
            {sortedGames.map((game) => (
              <MiniBanner
                key={game.id}
                type={contentTypes.game}
                style="mb-no-icon" //eslint-disable-line
                title={game.fullname}
                path={game.shortname}
                image={game.miniBanner ? game.miniBanner : null}
                grades={game.grades}
                isMobile={game.mobileActive}
                isSearchable={game.searchable}
                hasNoScroll={true}
                clickHandler={`${routeCodes.GAMES}${game.localeShortname || game.shortname}`}
              />
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  const renderActivePrintables = (isActive, printables) => {
    if (isActive && printables?.length > 0) {
      const sortedPrintables = sortedArrayByItem(printables, "fullname");

      return (
        <div className="standards-standard-content ssc-printables">
          <div className="standards-content-header">{getString("printables.title.0")}</div>
          <div className="container standards-content-container">
            {sortedPrintables.map((print) => {
              const clickHandler = isLoggedIn
                ? `${routeCodes.PRINTABLES}${print.localeShortname || print.shortname}`
                : renderDesktopSignupModal;
              return (
                <MiniBanner
                  key={print.id}
                  type={contentTypes.printable}
                  title={print.fullname}
                  path={print.shortname}
                  image={print.miniBanner ? print.miniBanner : null}
                  style="mb-portrait mb-no-icon"
                  grades={print.grades}
                  hasNoScroll={true}
                  clickHandler={clickHandler}
                />
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  };

  const renderActiveStandards = (isActive, standards) => {
    if (isActive && standards && standards.length > 0) {
      const sortedStandards = sortedArrayByItem(standards, "tag");

      return sortedStandards.map((standard, index) => {
        const stanKey = `stanKey${index}`;
        const isActive = standardId === standard.tag;
        const titleStyle = isActive ? "active" : "";
        const arrow = isActive ? <Arrow className="toggle-title-arrow" /> : <ArrowTip className="toggle-title-arrow" />;

        const standardGames =
          standard.games && standard.games.length > 0
            ? standard.games.map((sg) => dataGames.find((g) => g.id === sg))
            : [];

        const standardPrintables =
          standard.printables && standard.printables.length > 0
            ? standard.printables.map((sp) => dataPrintables.find((p) => p.id === sp))
            : [];

        return (
          <Fragment key={stanKey}>
            <div
              className={`wrapper toggle-title great-grandchild ${titleStyle}`}
              onClick={() => {
                standardClickHandler(standard.tag);
              }}
            >
              <div className="container toggle-title-container great-grandchild">
                <div className="toggle-title-text">
                  <>
                    <strong>{standard.tag}:</strong>
                    &nbsp;
                    {standard.description}
                  </>
                </div>
                {arrow}
              </div>
            </div>
            {renderActiveGames(isActive, getAllGames(standardGames))}
            {renderActivePrintables(isActive, standardPrintables)}
          </Fragment>
        );
      });
    }

    return null;
  };

  const renderActiveCategories = (isActive, standards) => {
    if (isActive && standards && standards.length > 0) {
      const categoriesToRender = standards.reduce((ca, obj) => {
        if (!ca.includes(obj.category)) ca.push(obj.category);
        return ca.sort();
      }, []);

      return categoriesToRender.map((category, index) => {
        const id = category?.replace(/[^0-9a-z]/gi, "").toLowerCase();

        const catkey = `catkey${index}`;
        const isActive = categoryId === id;
        const titleStyle = isActive ? "active" : "";
        const arrow = isActive ? <Arrow className="toggle-title-arrow" /> : <ArrowTip className="toggle-title-arrow" />;

        return (
          <Fragment key={catkey}>
            <div
              className={`wrapper toggle-title grandchild ${titleStyle}`}
              onClick={() => {
                categoryClickHandler(id);
              }}
            >
              <div className="container toggle-title-container grandchild">
                <div className="toggle-title-text">{category}</div>
                {arrow}
              </div>
            </div>
            {renderActiveStandards(
              isActive,
              standards.filter((s) => s.category === category),
            )}
          </Fragment>
        );
      });
    }

    return null;
  };

  const renderActiveSubjects = (isActive, standards) => {
    if (isActive && standards && standards.length > 0) {
      const subjectsToRender = standards.reduce((sa, obj) => {
        if (!sa.includes(obj.subject)) sa.push(obj.subject);
        return sa.sort();
      }, []);

      return subjectsToRender.map((subject, index) => {
        const id = subject.toLowerCase();
        const subKey = `subKey${index}`;
        const isActive = subjectId === id;
        const titleStyle = isActive ? "active" : "";
        const arrow = isActive ? <Arrow className="toggle-title-arrow" /> : <ArrowTip className="toggle-title-arrow" />;

        return (
          <Fragment key={subKey}>
            <div
              className={`wrapper toggle-title child ${titleStyle}`}
              onClick={() => {
                subjectClickHandler(id);
              }}
            >
              <div className="container toggle-title-container child">
                <div className="toggle-title-text">{getString(`standards.subjects.${subject}`)}</div>
                {arrow}
              </div>
            </div>
            {renderActiveCategories(
              isActive,
              standards.filter((s) => s.subject === subject),
            )}
          </Fragment>
        );
      });
    }

    return null;
  };

  const renderSections = () => {
    if (standards) {
      if (searching) {
        return <LoadingAnim />;
      }

      const standardsToRender = searchedStandards || standards;

      if (standardsToRender.length > 0) {
        const gradesToRender = Array.from(
          standardsToRender.reduce((ga, obj) => {
            return new Set([...ga, ...obj.grades]);
          }, new Set()),
        );

        return gradesToRender
          .sort((a, b) => a - b)
          .map((section, index) => {
            let title = grades.find((g) => g.number === section);
            title = title.localeUrl || title.url;
            const secKey = `secKey${index}`;
            const isActive = sectionId === section;
            const titleStyle = isActive ? "active" : "";
            const arrow = isActive ? (
              <Arrow className="toggle-title-arrow" />
            ) : (
              <ArrowTip className="toggle-title-arrow" />
            );

            return (
              <Fragment key={secKey}>
                <div
                  className={`wrapper toggle-title ${titleStyle}`}
                  tabIndex={index + 1}
                  onClick={() => {
                    sectionClickHandler(section);
                  }}
                  data-testid={`section-title-${title.toLowerCase()}`}
                >
                  <div className="container toggle-title-container">
                    <div className="toggle-title-text">
                      {getString("grades.title", { replace: [title.toUpperCase()] })}
                    </div>
                    {arrow}
                  </div>
                </div>
                {renderActiveSubjects(
                  isActive,
                  standardsToRender.filter((s) => {
                    const sgNums = s.grades;
                    return sgNums.includes(section);
                  }),
                )}
              </Fragment>
            );
          });
      }
    }

    return (
      <div className="search-noResults wrapper padded">
        <div className="container">{getString("search.noresults")}</div>
      </div>
    );
  };

  const showSearch = standards && standards.length > 0;

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("standards.title.2") })}

      <OptionallyDisplayed doDisplay={showSearch}>
        <div className="wrapper visible padded even standards-search-wrapper">
          <SearchBox
            searched={searchedStandards}
            setSearched={setSearchedStandards}
            setSearching={setSearching}
            search={async (searchQuery) => await searchArray(standards, searchQuery, contentTypes.standard)}
            onSuggestionsFetchRequested={({ value }) => {
              setSuggestions(
                getSuggestions(value, standards, "tag")
                  .map((standard) => standard.tag)
                  .sort(),
              );
            }}
            suggestions={suggestions}
            setSuggestions={setSuggestions}
            showSearch={showSearch}
            placeholder={getString("search.cta", { replace: [getString("standards.title.4")] })}
            initialInputValue={initialSearch}
          />
        </div>
      </OptionallyDisplayed>

      <div className="standards-standards">
        <div className="standards-standards-container">{renderSections()}</div>
      </div>
    </>
  );
}
