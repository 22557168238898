import React, { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { metaDefault } from "../../config/meta";
import faqStrings from "../../strings/faq";
import { getString, purifiedParsedString, getSuggestions, compareNormalizedStrings } from "../../utilities";
import FAQNav from "./faqNav";
import ContactUs from "./contactUs";
import { searchArray } from "../../utilities/search";
import Arrow from "../../../assets/svg/arrowRight.svg";
import ArrowTip from "../../../assets/svg/arrowTip.svg";
import LoadingAnim from "../global/loadingAnim";
import { handleAnswerListeners } from "../../utilities/faq";
import SearchBox from "../global/SearchBox";
import { contentTypes } from "../../config";

export default function FAQ() {
  const location = useLocation();

  const initialId = location.state && location.state.activeId ? location.state.activeId : "";
  const [activeId, setActiveId] = useState(initialId);
  const [searchedFaqs, setSearchedFaqs] = useState(null);
  const [searching, setSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [gameData, setGameData] = useState(location.state?.gameData);

  const faqs = [];
  const faqSections = [];
  faqStrings.sections.forEach((sectionObj) => {
    const { title } = sectionObj;
    const section = title.toLowerCase();
    faqSections.push(section);

    sectionObj.data.forEach((obj) => {
      faqs.push({
        question: obj.question,
        answer: obj.answer,
        section,
        title,
      });
    });
  });

  useEffect(() => {
    if (location.hash && location.hash.length > 0) {
      setTimeout(() => {
        window.location.href = location.hash;
      }, 200);
    }
  }, [location.hash]);

  useEffect(() => {
    handleAnswerListeners(location);
  });

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(
      getSuggestions(value, faqs, "question")
        .map((item) => item.question)
        .sort(compareNormalizedStrings),
    );
  };

  const questionClickHandler = (qnaId) => {
    const newId = qnaId === activeId ? "" : qnaId;
    setActiveId(newId);
  };

  const renderActiveAnswer = (isActive, answer) => {
    if (isActive && answer && answer.length > 0) {
      return (
        <div className="wrapper padded even">
          <div className="container-medium">{purifiedParsedString(answer)}</div>
        </div>
      );
    }

    return null;
  };

  const renderFaqs = () => {
    if (faqs) {
      const faqsToRender = searchedFaqs || faqs;
      const sectionsToRender = faqsToRender.reduce((sa, obj) => {
        if (!sa.map(({ section }) => section).includes(obj.section)) {
          sa.push({ section: obj.section, title: obj.title });
        }

        return sa;
      }, []);

      if (searching) {
        return <LoadingAnim />;
      }

      if (faqsToRender.length > 0) {
        let ti = 0;

        return sectionsToRender.map((sectionData, index) => {
          const { section, title } = sectionData;
          const secKey = `secKey${index}`;
          const sectionArray = faqsToRender.filter((obj) => obj.section === section);

          return (
            <Fragment key={secKey}>
              <div id={section} className="wrapper odd">
                <div className="container toggle-section-header-text">{title}</div>
              </div>

              {sectionArray.reduce((qnaArray, obj, i) => {
                const qnaId = `${section}${i}`;
                const isActive = activeId === qnaId;
                const titleStyle = isActive ? "active" : "";
                const arrow = isActive ? (
                  <Arrow className="toggle-title-arrow" />
                ) : (
                  <ArrowTip className="toggle-title-arrow" />
                );

                ti++;

                qnaArray.push(
                  <Fragment key={qnaId}>
                    <div
                      className={`wrapper toggle-title faq-section-${index} ${titleStyle}`}
                      tabIndex={ti}
                      onClick={() => {
                        questionClickHandler(qnaId);
                      }}
                    >
                      <div className="container toggle-title-container">
                        <div className="toggle-title-text">{obj.question}</div>
                        {arrow}
                      </div>
                    </div>
                    {renderActiveAnswer(isActive, obj.answer)}
                  </Fragment>,
                );

                if (i === sectionArray.length - 1) {
                  ti++;

                  const cuId = `${section}_contactus`;
                  const cuIsActive = activeId === cuId;
                  const cuTitleStyle = cuIsActive ? "active" : "";
                  const arrow = cuIsActive ? (
                    <Arrow className="toggle-title-arrow" />
                  ) : (
                    <ArrowTip className="toggle-title-arrow" />
                  );

                  qnaArray.push(
                    <Fragment key={cuId}>
                      <div
                        className={`wrapper toggle-title faq-section-${index} ${cuTitleStyle}`}
                        tabIndex={ti}
                        onClick={() => {
                          questionClickHandler(cuId);
                        }}
                      >
                        <div className="container toggle-title-container">
                          <div className="toggle-title-text">{getString("faq.feedback.question")}</div>
                          {arrow}
                        </div>
                      </div>
                      {cuIsActive ? <ContactUs gameData={gameData} setGameData={setGameData} /> : null}
                    </Fragment>,
                  );
                }

                return qnaArray;
              }, [])}
            </Fragment>
          );
        });
      }
    }

    return (
      <div className="search-noResults wrapper padded">
        <div className="container">{getString("faq.noresults")}</div>
      </div>
    );
  };

  const showSearch = faqs && faqs.length > 0;

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("faq.title.0") })}

      <div className="faq">
        <div className="wrapper padded">
          <div className="container">
            <h1>{getString("faq.title.0")}</h1>
          </div>
        </div>

        <div className="wrapper padded visible">
          <SearchBox
            searched={searchedFaqs}
            setSearched={setSearchedFaqs}
            setSearching={setSearching}
            search={async (searchQuery) => await searchArray(faqs, searchQuery, contentTypes.faq)}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            suggestions={suggestions}
            setSuggestions={setSuggestions}
            showSearch={showSearch}
            placeholder={getString("search.cta", { replace: [getString("faq.title.1")] })}
          />
          <div className="container">
            <FAQNav sections={faqSections} />
          </div>
        </div>

        {renderFaqs()}
      </div>
      <div className="wrapper header-shadow" />
    </>
  );
}
