import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "urql";

import { metaDefault } from "../../config/meta";
import {
  billingPlatform as billingPlatformWeb,
  recaptchaSiteKey,
  EMAIL_CHAR_LIMIT,
  getAbcyaUrl,
  getLangCode,
  language,
} from "../../config";
import { REDEEM_GIFTCARD_MUTATION } from "../../graphql/mutations";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import { getString, handlePostRequest } from "../../utilities";
import { routeCodes } from "../../config/routes";
import PreviewCard from "../giftcard/previewCard";
import NotFound from "./notFound";
import Redeemed from "./redeemed";
import Free from "./free";
import Native from "./native";
import Invoiced from "./invoiced";
import TextViewHook from "../global/textViewHook";
import { getMaxLengthValidator, validateEmail } from "../../utilities/forms/rules";
import { useLazyQuery } from "../../hooks";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";

export default function Card() {
  const action = `Redeem_GiftCard_Form_Submission`;
  const [status, setStatus] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const [redeemEmail, setRedeemEmail] = useState("");
  const [billingPlatform, setBillingPlatform] = useState(null);
  const [checkGiftCard, setCheckGiftCard] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
  } = useForm();

  useEffect(() => {
    if (location.state && location.state.checkGiftCard) {
      setCheckGiftCard(location.state.checkGiftCard);
    } else {
      navigate(routeCodes.REDEEM, { replace: true });
    }
  }, []);

  const getVariables = () => {
    const myVariables = {
      giftCardId: checkGiftCard.id,
      email: getValues().redeemEmail,
    };

    return myVariables;
  };

  const redeemSuccess = (data) => {
    const { billingPlatform: nextBillingPlatform, redeemed, planId } = data.redeemGiftCard;
    let nextStatus = "";

    if (redeemed && nextBillingPlatform === billingPlatformWeb) {
      nextStatus = "redeemed";
    } else if (!redeemed && !nextBillingPlatform) {
      // user with email address provided doesn't exist/not found
      // redeemed id false and billingPlatform comes back null
      nextStatus = "notFound";
    } else if (!redeemed && nextBillingPlatform !== billingPlatformWeb) {
      // not redeemed and user is either free or non web subscriber
      switch (nextBillingPlatform) {
        case "NONE":
          nextStatus = planId === "friends" ? "friends" : "free";
          break;

        case "MANUAL":
          // manually invoiced users (mostly schools)
          nextStatus = "invoiced";
          break;

        default:
          nextStatus = "native";
      }
    }

    setStatus(nextStatus);
    setBillingPlatform(nextBillingPlatform);
    setSubmitting(false);
  };

  const onError = (error) => {
    setSubmitErrors({ message: error.toString() });
    setSubmitting(false);
  };

  const [result, redeemGiftCard] = useMutation(REDEEM_GIFTCARD_MUTATION);
  const { loading } = result;

  const onRecaptchaCompleted = (data) => {
    const { reCaptchaVerify } = data;

    if (reCaptchaVerify.success) {
      // verified
      redeemGiftCard(getVariables()).then((result) => handlePostRequest(result, redeemSuccess, onError));
    } else {
      // not verified
      setSubmitting(false);
      setSubmitErrors({ message: reCaptchaVerify.message });
    }
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onRecaptchaCompleted,
    onError,
  );

  const submitForm = (data) => {
    setRedeemEmail(data.redeemEmail);
    setSubmitting(true);

    // perform reCaptcha to get token
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(recaptchaSiteKey, { action }).then((token) => {
        // query with token
        recaptchaVerify({ token, action, verify: false });
      });
    });
  };

  const phoneHome = () => {
    navigate(routeCodes.HOME, { replace: true });
  };

  const redeemAnother = () => {
    navigate(routeCodes.REDEEM, { replace: true });
  };

  const resetCardHandler = () => {
    setStatus(null);
    setSubmitting(false);
    setBillingPlatform(null);
    setSubmitErrors(null);
    reset();
  };

  const renderForm = () => {
    const langCode = getLangCode();
    const labelString = langCode === language.la ? getString("forms.email.2") : getString("forms.email.0");

    return (
      <>
        <form onSubmit={handleSubmit(submitForm)}>
          <FormProvider register={register}>
            <h2>{getString("redeem.card.subtitle")}</h2>

            <OptionallyDisplayed doDisplay={!!submitErrors}>
              <ErrorMessage error={submitErrors} />
            </OptionallyDisplayed>

            <TextViewHook
              rules={{
                required: getString(
                  langCode === language.la ? "forms.errorMessages.isRequired.1" : "forms.errorMessages.isRequired.0",
                  { replace: [getString(langCode === language.la ? "giftcard.from.email.2" : "forms.email.2")] },
                ),
                validate: (value) =>
                  validateEmail(value, getString(langCode === language.la ? "forms.email.6" : "forms.email.2")),
                maxLength: getMaxLengthValidator(getString("forms.email.2"), EMAIL_CHAR_LIMIT),
              }}
              name="redeemEmail"
              placeholder={labelString}
              label={labelString}
              errors={errors}
            >
              {getString("redeem.card.cta")}
            </TextViewHook>

            <div className="redeem-card-form-buttons">
              <button type="submit" disabled={submitting} className="button-flat-color green">
                {getString("continue.0")}
              </button>
            </div>

            <div className="form-terms">{getString("recaptcha", { html: true })}</div>
          </FormProvider>
        </form>

        {(loading || recaptchaLoading || submitting) && (
          <LoadingAnim position="fixed" className="background-transparent-white">
            <h4>{getString("send.1")}</h4>
          </LoadingAnim>
        )}
      </>
    );
  };

  const renderContent = () => {
    // redeemed, notFound, free, invoiced, native
    // reference redeemSuccess for assigning status
    if (status === "redeemed") {
      // successful redemption on account with supplied email
      return (
        <Redeemed
          amount={checkGiftCard.amount}
          email={redeemEmail}
          redeemAnother={redeemAnother}
          phoneHome={phoneHome}
        />
      );
    }

    if (status === "notFound") {
      return <NotFound email={redeemEmail} resetCardHandler={resetCardHandler} />;
    }

    if (status === "free") {
      return <Free email={redeemEmail} resetCardHandler={resetCardHandler} />;
    }

    if (status === "native") {
      return <Native billingPlatform={billingPlatform} resetCardHandler={resetCardHandler} />;
    }

    if (status === "invoiced" || status === "friends") {
      return <Invoiced status={status} resetCardHandler={resetCardHandler} />;
    }

    // default (status null) render email input form
    return renderForm();
  };

  if (checkGiftCard) {
    const message = checkGiftCard && checkGiftCard.message ? checkGiftCard.message.trim() : "";

    return (
      <>
        {metaDefault({
          path: location.pathname,
          title: getString("redeem.title.0"),
          links: [
            {
              rel: "canonical",
              href: `${getAbcyaUrl()}${routeCodes.REDEEM}`,
            },
          ],
        })}

        <div className="redeem">
          <div className="wrapper padded">
            <div className="container">
              <h1>{getString("redeem.card.title")}</h1>
            </div>
          </div>

          <div className="wrapper padded">
            <div className="container redeem-card-container">
              <div className="redeem-card">
                <PreviewCard
                  designSecret={checkGiftCard.designFileSecret}
                  message={message}
                  amount={checkGiftCard.amount}
                  gcId={checkGiftCard.id}
                />
              </div>

              <div className="redeem-card-form">{renderContent()}</div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
}
