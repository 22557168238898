import React from "react";
import { useNavigate } from "react-router-dom";
import { getString } from "../../utilities";
import { routeCodes } from "../../config/routes";
import aboutStrings from "../../strings/about";
import { getLangCode, language } from "../../config";

export default function AboutNav() {
  const navigate = useNavigate();

  const renderMobileAppPrivacyButton = () => {
    if (getLangCode() !== language.default) {
      return null;
    }
    return (
      <button
        type="button"
        className="button-flat-color pt-nav-privacymobile"
        data-testid="mobile-app-privacy-link"
        onClick={() => {
          navigate(routeCodes.PRIVACYMOBILE);
        }}
      >
        {getString("privacymobile.title.0")}
      </button>
    );
  };

  return (
    <div className="pt-nav">
      <button
        type="button"
        className="button-flat-color pt-nav-story"
        onClick={() => {
          navigate(routeCodes.STORY);
        }}
      >
        {getString("story.title.1", { strings: aboutStrings })}
      </button>

      <button
        type="button"
        className="button-flat-color pt-nav-team"
        onClick={() => {
          navigate(routeCodes.TEAM);
        }}
      >
        {getString("team.title.0", { strings: aboutStrings })}
      </button>

      <button
        type="button"
        className="button-flat-color pt-nav-testimonials"
        onClick={() => {
          navigate(routeCodes.TESTIMONIALS);
        }}
      >
        {getString("testimonials.title.0", { strings: aboutStrings })}
      </button>

      <button
        type="button"
        className="button-flat-color pt-nav-prod-offer"
        onClick={() => {
          navigate(routeCodes.PRODUCTOFFERINGS);
        }}
      >
        {getString("productOfferings.title.2", { strings: aboutStrings })}
      </button>

      <button
        type="button"
        className="button-flat-color pt-nav-privacy"
        onClick={() => {
          navigate(routeCodes.PRIVACY);
        }}
      >
        {getString("privacy.title.0")}
      </button>

      {renderMobileAppPrivacyButton()}

      <button
        type="button"
        className="button-flat-color pt-nav-payterms"
        onClick={() => {
          navigate(routeCodes.PAYTERMS);
        }}
      >
        {getString("payterms.title.0")}
      </button>

      <button
        type="button"
        className="button-flat-color pt-nav-terms"
        onClick={() => {
          navigate(routeCodes.TERMS);
        }}
      >
        {getString("terms.title.2")}
      </button>
    </div>
  );
}
