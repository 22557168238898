import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { userRoles, assetUrl, contentTypes, getLangCode, language } from "../../config";
import { getString, isLoggedInWithRole, isAFunction, offset } from "../../utilities";
import { mobile as mobileClient } from "../../utilities/detection";
import { mobileFreeGame } from "../../utilities/games";
import FavStar from "../../../assets/img/favStar.svg";
import GameIcon from "../../../assets/img/mbTypeIcon_game.svg";
import PrintableIcon from "../../../assets/img/mbTypeIcon_printable.svg";
import DefaultMBImage from "../../../assets/img/defaultMiniBanner.jpg";
import dataGrades from "../../config/grades";
import WebP from "./WebP";
import { gamesSelector, isLoggedInSelector } from "../../redux/slices/loginSlice";
import { dataSelector } from "../../redux/slices/dataSlice";

export default function MiniBanner({
  type,
  title,
  path,
  image,
  fallbackImage,
  style,
  grades,
  hasNoScroll = false,
  clickHandler,
  tabIndex,
  isMobile = false,
  isSearchable = false,
}) {
  const [display, setDisplay] = useState(false);
  const [offscreen, setOffscreen] = useState(false);
  const [node, setNode] = useState(null);

  const data = useSelector(dataSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const games = useSelector(gamesSelector);

  useEffect(() => {
    let offScreenHandler = null;
    const nodeParent = node?.offsetParent;
    if (node) {
      if (!hasNoScroll) {
        offScreenHandler = () => {
          const mbParent = node.offsetParent;
          const mbParentOffset = offset(mbParent);
          const mbParentPos = mbParentOffset.left;
          const mbParentWidth = mbParent.offsetWidth;

          const mbOffset = offset(node);
          const mbPos = mbOffset.left;
          const mbWidth = node.offsetWidth;

          const isOff = mbPos < mbParentPos || mbPos + mbWidth > mbParentWidth + mbParentPos;

          setOffscreen(isOff);
        };

        offScreenHandler();

        window.addEventListener("resize", offScreenHandler);
        nodeParent.addEventListener("scroll", offScreenHandler);
      }
    }

    return () => {
      if (offScreenHandler) {
        window.removeEventListener("resize", offScreenHandler);
        nodeParent.removeEventListener("scroll", offScreenHandler);
      }
    };
  }, [node]);

  useEffect(() => {
    let nextDisplay = false;

    if ([contentTypes.link, contentTypes.printable].includes(type)) {
      nextDisplay = true;
    } else if (type === contentTypes.game && isSearchable) {
      // if it's a game it has to be searchable
      nextDisplay = !mobileClient || (isMobile && mobileClient);
    }

    setDisplay(nextDisplay);
  }, [isMobile, isSearchable, type]);

  const renderFree = () => {
    if (type === contentTypes.game) {
      const game = data.games.find((g) => (g.localeShortname ? g.localeShortname === path : g.shortname === path));

      if (
        game &&
        mobileClient &&
        mobileFreeGame(data.lists, game.id) &&
        (!isLoggedIn || isLoggedInWithRole(userRoles.free))
      ) {
        return (
          <div className={`freebadge ${getLangCode()}`}>
            <span>{getString("free.1")}</span>
          </div>
        );
      }
    }

    return null;
  };

  const renderNew = () => {
    if (type === contentTypes.game) {
      const game = data.games.find((g) => (g.localeShortname ? g.localeShortname === path : g.shortname === path));

      if (game && game.isNew) {
        return (
          <div className={`newbadge ${getLangCode()}`}>
            <span>{getString("new.0")}</span>
          </div>
        );
      }
    }

    return null;
  };

  const renderFav = () => {
    if (type === contentTypes.game) {
      const favGames = games.map((g) => g.shortname);
      const gameFav = isLoggedIn && favGames.includes(path);

      if (gameFav) {
        return <img src={FavStar} className="favstar" alt={getString("game.favorites.mine")} />;
      }
    }

    return null;
  };

  const renderGrades = () => {
    if (grades && grades.length > 0) {
      let gradesContent = <>&nbsp;</>;

      const getGradeShortname = (num) => {
        const gradeObj = dataGrades.find((grade) => grade.number === num);

        if (num === -1 && getLangCode() !== language.default) {
          return `${gradeObj.shortname.substring(0, 6)}.`;
        }
        return gradeObj.shortname;
      };

      const sortedGrades = [...grades].sort((a, b) => a - b);
      const lowGradeShortname = getGradeShortname(sortedGrades[0]).toUpperCase();

      if (grades.length > 1) {
        const highGradeShortname = getGradeShortname(sortedGrades[sortedGrades.length - 1]).toUpperCase();

        gradesContent = getString("grades.minibanner", {
          replace: [lowGradeShortname, highGradeShortname],
          html: true,
        });
      } else {
        gradesContent = `${getString("grade.2")} ${lowGradeShortname}`;
      }

      return <div className={`minibanner-label-grades ${getLangCode()}`}>{gradesContent}</div>;
    }

    return null;
  };

  const renderTypeContent = () => {
    const typeIcon = {
      printable: PrintableIcon,
      game: GameIcon,
    };

    const Icon = typeIcon[type];

    return Icon ? <img src={Icon} width="24" height="24" alt={type} className="minibanner-label-type" /> : null;
  };

  const renderContent = () => {
    let backgroundImage = !!image && Object.prototype.hasOwnProperty.call(image, "secret") ? image.secret : image;
    if ([contentTypes.game, contentTypes.printable].includes(type)) {
      backgroundImage = image ? `${assetUrl}${backgroundImage}` : DefaultMBImage;
    }

    return (
      <>
        <div data-testid={`minibanner-link-${path}`} className="minibanner-art">
          <WebP secret={backgroundImage} fallbackSecret={fallbackImage} imgProps={{ alt: title, draggable: false }} />
        </div>

        <div className="minibanner-label">
          <div className="minibanner-label-title">
            <div className="mblt-text">{title}</div>
          </div>
          <div className="minibanner-label-grades-type">
            {renderGrades()}
            {renderTypeContent()}
          </div>
        </div>

        <div className="minibanner-badge-container">
          {renderNew()}
          {renderFree()}
          {renderFav()}
        </div>
      </>
    );
  };

  if (display) {
    const mbClickHandler = clickHandler;

    const offStyle = offscreen ? { opacity: ".5" } : {};

    let mbClass = "minibanner";
    if (style) mbClass += ` ${style}`;

    if (isAFunction(mbClickHandler)) {
      return (
        <div className={mbClass} onClick={mbClickHandler} tabIndex={tabIndex} style={offStyle} ref={setNode}>
          {renderContent()}
        </div>
      );
    }

    return (
      <Link className={mbClass} to={mbClickHandler} tabIndex={tabIndex} style={offStyle} ref={setNode}>
        {renderContent()}
      </Link>
    );
  }

  return null;
}

MiniBanner.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  // fallbackImage is for client assets that get hashed by webpack
  fallbackImage: PropTypes.string,
  style: PropTypes.string,
  grades: PropTypes.array,
  hasNoScroll: PropTypes.bool,
  clickHandler: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
  tabIndex: PropTypes.string,
  isMobile: PropTypes.bool,
  isSearchable: PropTypes.bool,
};
