import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { getString } from "../../utilities";
import { scrollDocVerticallyToTarget } from "../../utilities/scroll";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import routeCodes from "../../config/routeCodes";
import { idSelector } from "../../redux/slices/loginSlice";
import { getLangCode } from "../../config";

export default function ManageNav({ isPremiumWeb, isFree, doCancel }) {
  const navigate = useNavigate();
  const subText = isFree ? getString("upgrade.title.2") : getString("manage.subscription.title.0");
  const cancelText = isFree ? getString("manage.delete.title.0") : getString("manage.cancel.title.0");
  const id = useSelector(idSelector);
  const buttonClass = `button-flat-color ${getLangCode()}`;

  return (
    <div className="pt-nav">
      <button
        type="button"
        className={buttonClass}
        onClick={() => {
          scrollDocVerticallyToTarget(document.getElementById("account"));
        }}
      >
        {getString("manage.accountInfo.title.0")}
      </button>

      <OptionallyDisplayed doDisplay={isPremiumWeb}>
        <button
          type="button"
          className={buttonClass}
          onClick={() => {
            scrollDocVerticallyToTarget(document.getElementById("payment"));
          }}
        >
          {getString("manage.payment.title.0")}
        </button>
      </OptionallyDisplayed>

      <button
        type="button"
        className={buttonClass}
        onClick={() => {
          scrollDocVerticallyToTarget(document.getElementById("subscription"));
        }}
      >
        {subText}
      </button>

      <OptionallyDisplayed doDisplay={doCancel}>
        <button
          type="button"
          className={buttonClass}
          onClick={() => {
            scrollDocVerticallyToTarget(document.getElementById("cancel"));
          }}
        >
          {cancelText}
        </button>
      </OptionallyDisplayed>

      <button
        onClick={() =>
          navigate(
            `${routeCodes.EMAIL_PREFERENCES}ABCya_${
              process.env.NODE_ENV !== "production" ? `${process.env.NODE_ENV}_` : ""
            }${id}`,
          )
        }
        type="button"
        className={buttonClass}
      >
        {getString("emailPreferences.manage.button")}
      </button>
    </div>
  );
}

ManageNav.propTypes = {
  isPremiumWeb: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
  doCancel: PropTypes.bool.isRequired,
};
