import React from "react";
import { Link } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import grades from "../../config/grades";
import { getLangCode, language } from "../../config";

export default function OurContent() {
  const gamesTitle = getString("games.all.0").toUpperCase();

  const renderLinks = () => {
    if (grades) {
      return grades.map((grade) => {
        const myString = grade.number === -1 ? "footer.games.0" : "footer.games.1";

        const shortname =
          getLangCode() !== language.default && grade.number === -1 ? grade.shortname.toLowerCase() : grade.shortname;

        return (
          <Link to={`${routeCodes.GRADES}${grade.localeUrl || grade.url}`} key={grade.url}>
            {getString(myString, { replace: [shortname] })}
          </Link>
        );
      });
    }
    return null;
  };

  return (
    <div className="footer-nav-col">
      <div className="title">
        <span>{getString("footer.content")}</span>
      </div>
      <Link to={routeCodes.GAMES}>{gamesTitle}</Link>
      {renderLinks()}
      <Link to={routeCodes.PRINTABLES}>{getString("printables.meta.title")}</Link>
    </div>
  );
}
