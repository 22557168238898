import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "urql";

import { MANAGE_ACCOUNT_MUTATION, DELETE_USER_MUTATION } from "../../graphql/mutations";
import { getString, convertEPOCHDate, handlePostRequest } from "../../utilities";
import { tokenName, userRoles } from "../../config";
import ErrorMessage from "../global/errorMessage";
import LoadingAnim from "../global/loadingAnim";
import PasswordConfirmModal from "../global/passwordConfirmModal";
import { gtmDeleteCancelSub } from "../../utilities/gtm";
import { useSelector } from "react-redux";
import { roleSelector } from "../../redux/slices/loginSlice";

export default function CancelSubscription({
  isUpdating,
  setIsUpdating,
  success,
  isPremiumWeb,
  accountInfo,
  doCancel,
}) {
  const planTitles = {
    PUBLIC: getString(`subscribe.plans.free.title.3`),
    FAMILY: getString(`subscribe.plans.family.title.4`),
    CLASSROOM: getString(`subscribe.plans.classroom.title.4`),
    SCHOOL: getString(`subscribe.plans.school.title.3`),
  };

  const [passwordModal, setPasswordModal] = useState(false);
  const role = useSelector(roleSelector);

  const onError = () => {
    setIsUpdating(false);
  };

  const updateSuccess = (data) => {
    const periodEnd = convertEPOCHDate(accountInfo.periodEnd);
    const isFree = role === userRoles.free;
    const token = data?.updateUser?.token;

    const successTitle = isFree ? getString("delete.1") : getString("cancel.3");

    const successMessage = isFree
      ? getString("manage.delete.success")
      : getString("manage.cancel.success", { replace: [planTitles[role], periodEnd] });

    gtmDeleteCancelSub(accountInfo.subscription.name, isFree);
    if (isFree) {
      try {
        window.localStorage.removeItem(tokenName);
      } catch (e) {}
    }
    success({ message: <p>{successMessage}</p>, title: successTitle, reload: true, token });
  };

  const freeUser = role === userRoles.free;

  const [result, mutationFunc] = useMutation(freeUser ? DELETE_USER_MUTATION : MANAGE_ACCOUNT_MUTATION);
  const { fetching: loading, error } = result;

  const renderButtons = () => {
    const periodEnd = convertEPOCHDate(accountInfo.periodEnd);

    const modalHeaderText = freeUser ? getString("manage.delete.title.1") : getString("manage.cancel.title.1");
    const buttonText = freeUser ? getString("manage.delete.title.0") : getString("manage.cancel.title.0");
    const loadingText = freeUser ? getString("delete.0") : getString("cancel.2");
    const warningText = freeUser
      ? getString("manage.delete.warning")
      : getString("manage.cancel.warning", { replace: [periodEnd] });
    const passwordText = freeUser ? getString("manage.delete.password") : getString("manage.cancel.password");

    return (
      <>
        <div className="container-small">
          {error && <ErrorMessage error={error} />}

          <button
            type="button"
            className="button-flat-color red manage-update-button"
            data-testid="cancel-delete-button"
            disabled={isUpdating}
            onClick={() => {
              setPasswordModal((prev) => !prev);
            }}
          >
            {buttonText}
          </button>
        </div>

        <PasswordConfirmModal
          doShow={!!passwordModal}
          header={modalHeaderText}
          close={() => {
            setPasswordModal((prev) => !prev);
            setIsUpdating(false);
          }}
          submit={() => {
            const variables = freeUser ? {} : { billingPlan: "FREE" };
            setPasswordModal((prev) => !prev);
            mutationFunc(variables).then((result) => handlePostRequest(result, updateSuccess, onError));
            setIsUpdating(true);
          }}
        >
          <p>
            <strong>{warningText}</strong>
          </p>
          <p>{passwordText}</p>
        </PasswordConfirmModal>

        {loading && (
          <LoadingAnim position="absolute" className="background-transparent-pt-grey">
            <h4>{loadingText}</h4>
          </LoadingAnim>
        )}
      </>
    );
  };

  const renderContent = () => {
    if (isPremiumWeb && accountInfo.canceled) {
      // a premium Stripe user has already canceled their subscrition
      const periodEnd = convertEPOCHDate(accountInfo.periodEnd);

      return (
        <div className="container-small">
          <p>{getString("manage.cancel.canceled", { replace: [periodEnd] })}</p>
        </div>
      );
    }

    return renderButtons();
  };

  if (!doCancel) {
    // don't offer cancel or delete to native, invoiced, friends, IXL and stripe without payment source (paid with just giftcards) users
    return null;
  }

  return (
    <div id="cancel" className="wrapper padded">
      <h2>{role === userRoles.free ? getString("manage.delete.title.1") : getString("manage.cancel.title.1")}</h2>
      {accountInfo ? renderContent() : null}
    </div>
  );
}

CancelSubscription.propTypes = {
  isUpdating: PropTypes.bool.isRequired,
  setIsUpdating: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
  isPremiumWeb: PropTypes.bool.isRequired,
  accountInfo: PropTypes.object,
  doCancel: PropTypes.bool.isRequired,
};
