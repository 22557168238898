import store from "../redux/store";
import { categoryTypes } from "../config";
import { setShowDesktopSignupModal } from "../redux/slices/desktopSignupSlice";

export const getPrintCats = (items, categories) => {
  return items.reduce((catArray, item) => {
    const { shortname } = item.category;
    const cat = categories.find((c) => c.shortname === shortname);
    if (cat) catArray.push(cat);
    return catArray;
  }, []);
};

export const getPrintableCategory = (categories) => {
  let printableCategory = null;
  if (categories && categories.length > 0) {
    let categorySet = new Set();

    categories.forEach((cat) => {
      categoryTypes.forEach((catType) => {
        if (cat.startsWith(catType)) categorySet.add(catType);
      });
    });

    if (categorySet.size > 0) {
      categorySet = Array.from(categorySet);

      // use coloring-pages be default if in array
      if (categorySet.includes("coloring-pages")) {
        printableCategory = "coloring-pages";
      } else {
        // can belong to multiple categories but very rare
        // maybe coloring-page & activity page together
        // if not the default use index 0 "first"
        printableCategory = categorySet[0];
      }
    }
  }

  return printableCategory;
};

export const renderDesktopSignupModal = () => {
  store().dispatch(
    setShowDesktopSignupModal({
      showDesktopSignupModal: true,
    }),
  );
};
