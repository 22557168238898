import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import ABCyaLogo from "../../../assets/svg/abcyalogo.svg";
import FullNav from "./fullNav";
import SmallNav from "./smallNav";
import SearchField from "./searchField";

export default function Header() {
  const location = useLocation();
  const [hasMenu, setHasMenu] = useState(false);
  const [hasSearch, setHasSearch] = useState(false);

  useEffect(() => {
    setHasMenu(false);
    setHasSearch(false);
  }, [location]);

  return (
    <div id="header" className="header">
      <div className="container header-container">
        <div className="header-logo">
          <Link to={routeCodes.HOME}>
            <ABCyaLogo className="header-logo-img" />
          </Link>
        </div>

        <SearchField hasSearch={hasSearch} />
        <SmallNav hasMenu={hasMenu} hasSearch={hasSearch} setHasMenu={setHasMenu} setHasSearch={setHasSearch} />
        <FullNav hasMenu={hasMenu} />
      </div>
    </div>
  );
}
