import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { getString } from "../../utilities";
import TextViewHook from "../global/textViewHook";
import { getMaxLengthValidator, getMinLengthValidator, validateEmail } from "../../utilities/forms/rules";
import { EMAIL_CHAR_LIMIT, getLangCode, language, PASSWORD_CHAR_LIMIT, PASSWORD_CHAR_MINIMUM } from "../../config";

export default function AccountFields({ errors, submitErrors = null, setSubmitErrors, isDisabled = false }) {
  const { getValues, trigger } = useFormContext();
  const langCode = getLangCode();

  const onChange = () => {
    if (submitErrors) {
      setSubmitErrors(null);
    }
  };

  return (
    <>
      <TextViewHook
        rules={{
          required: getString(
            langCode === language.la ? "forms.errorMessages.isRequired.1" : "forms.errorMessages.isRequired.0",
            { replace: [getString(langCode === language.la ? "forms.email.5" : "forms.email.4")] },
          ),
          validate: (value) =>
            validateEmail(value, getString(langCode === language.la ? "forms.email.7" : "forms.email.4")),
          maxLength: getMaxLengthValidator(getString("forms.email.4"), EMAIL_CHAR_LIMIT),
        }}
        errors={errors}
        className={langCode === language.la ? "col1" : "col2"}
        name="email"
        placeholder={getString("forms.email.4")}
        label={getString("forms.email.4")}
        onChange={() => {
          trigger("emailVerify");
          onChange();
        }}
        isDisabled={isDisabled}
      />

      <TextViewHook
        rules={{
          validate: (value) =>
            value === getValues().email ||
            getString(
              langCode === language.la ? "forms.errorMessages.mustMatch.1" : "forms.errorMessages.mustMatch.0",
              { replace: [getString(langCode === language.la ? "forms.email.5" : "forms.email.4")] },
            ),
        }}
        errors={errors}
        className={langCode === language.la ? "col1" : "col2 right"}
        name="emailVerify"
        placeholder={getString("forms.adultEmailVerify")}
        label={getString("forms.adultEmailVerify")}
        onChange={() => {
          trigger("email");
          onChange();
        }}
        isDisabled={isDisabled}
      />

      <TextViewHook
        rules={{
          required: getString(
            langCode === language.la ? "forms.errorMessages.isRequired.1" : "forms.errorMessages.isRequired.0",
            { replace: [getString(langCode === language.la ? "forms.password.3" : "forms.password.0")] },
          ),
          maxLength: getMaxLengthValidator(
            getString(langCode === language.la ? "forms.password.4" : "forms.password.0"),
            PASSWORD_CHAR_LIMIT,
          ),
          minLength: getMinLengthValidator(
            getString(langCode === language.la ? "forms.password.4" : "forms.password.0"),
            PASSWORD_CHAR_MINIMUM,
          ),
        }}
        errors={errors}
        className={langCode === language.la ? "col1" : "col2"}
        name="password"
        type="password"
        placeholder={getString("forms.password.0")}
        label={getString("forms.password.0")}
        onChange={() => {
          trigger("passwordVerify");
          onChange();
        }}
        isDisabled={isDisabled}
      />

      <TextViewHook
        rules={{
          validate: (value) =>
            value === getValues().password ||
            getString(
              langCode === language.la ? "forms.errorMessages.mustMatch.1" : "forms.errorMessages.mustMatch.0",
              { replace: [getString(langCode === language.la ? "forms.password.3" : "forms.password.0")] },
            ),
        }}
        errors={errors}
        className={langCode === language.la ? "col1" : "col2 right"}
        name="passwordVerify"
        type="password"
        placeholder={getString("forms.passwordVerify")}
        label={getString("forms.passwordVerify")}
        onChange={() => {
          trigger("password");
          onChange();
        }}
        isDisabled={isDisabled}
      />
    </>
  );
}

AccountFields.propTypes = {
  errors: PropTypes.object.isRequired,
  submitErrors: PropTypes.object,
  setSubmitErrors: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};
