import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { metaDefault } from "../../config/meta";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import aboutStrings from "../../strings/about";
import Founder from "./founder";
import TeamMembers from "./teamMembers";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { useSelector } from "react-redux";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";

export default function Team() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("team.title.1", { strings: aboutStrings }) })}

      <div className="team">
        <div className="wrapper padded">
          <div className="container">
            <h1>{getString("team.title.0", { strings: aboutStrings })}</h1>
            <div className="subtitle">{getString("team.subtitle", { strings: aboutStrings })}</div>
          </div>
        </div>

        <div className="wrapper padded">
          <Founder />

          <div className="container team-container">
            <TeamMembers />
          </div>
        </div>

        <OptionallyDisplayed doDisplay={!isLoggedIn}>
          <div className="wrapper padded">
            <div className="pt-footer-container">
              <h2>{getString("team.join", { strings: aboutStrings })}</h2>

              <button
                type="button"
                className="button-flat-color pt-cyan round"
                onClick={() => {
                  navigate(routeCodes.SUBSCRIBE);
                }}
              >
                {getString("join.6", { html: true })}
              </button>
            </div>
          </div>
        </OptionallyDisplayed>
      </div>

      <div className="wrapper header-shadow" />
    </>
  );
}
