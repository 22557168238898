import { hyphenToCamel } from "./";
import { categoryTypes, contentTypes } from "../config";

import bingo from "../../assets/img/minibannerCgBingo.webp";
import bingoJpg from "../../assets/img/minibannerCgBingo.jpg";
import car from "../../assets/img/minibannerCgCar.webp";
import carJpg from "../../assets/img/minibannerCgCar.jpg";
import cat from "../../assets/img/minibannerCgCat.webp";
import catJpg from "../../assets/img/minibannerCgCat.jpg";
import crosswordGames from "../../assets/img/minibannerCgCrossword.webp";
import crosswordGamesJpg from "../../assets/img/minibannerCgCrossword.jpg";
import hunt from "../../assets/img/minibannerCgHunt.webp";
import huntJpg from "../../assets/img/minibannerCgHunt.jpg";
import math from "../../assets/img/minibannerCgMath.webp";
import mathJpg from "../../assets/img/minibannerCgMath.jpg";
import money from "../../assets/img/minibannerCgMoney.webp";
import moneyJpg from "../../assets/img/minibannerCgMoney.jpg";
import multiplayer from "../../assets/img/minibannerCgMultiplayer.webp";
import multiplayerJpg from "../../assets/img/minibannerCgMultiplayer.jpg";
import multiplication from "../../assets/img/minibannerCgMulti.webp";
import multiplicationJpg from "../../assets/img/minibannerCgMulti.jpg";
import platformer from "../../assets/img/minibannerCgPlatformer.webp";
import platformerJpg from "../../assets/img/minibannerCgPlatformer.jpg";
import puzzle from "../../assets/img/minibannerCgPuzzle.webp";
import puzzleJpg from "../../assets/img/minibannerCgPuzzle.jpg";
import racing from "../../assets/img/minibannerCgRacing.webp";
import racingJpg from "../../assets/img/minibannerCgRacing.jpg";
import retro from "../../assets/img/minibannerCgRetro.webp";
import retroJpg from "../../assets/img/minibannerCgRetro.jpg";
import robot from "../../assets/img/minibannerCgRobot.webp";
import robotJpg from "../../assets/img/minibannerCgRobot.jpg";
import space from "../../assets/img/minibannerCgSpace.webp";
import spaceJpg from "../../assets/img/minibannerCgSpace.jpg";
import story from "../../assets/img/minibannerCgStory.webp";
import storyJpg from "../../assets/img/minibannerCgStory.jpg";
import typing from "../../assets/img/minibannerCgTyping.webp";
import typingJpg from "../../assets/img/minibannerCgTyping.jpg";
import wordSearchGames from "../../assets/img/minibannerCgWordSearch.webp";
import wordSearchGamesJpg from "../../assets/img/minibannerCgWordSearch.jpg";
import writing from "../../assets/img/minibannerCgWriting.webp";
import writingJpg from "../../assets/img/minibannerCgWriting.jpg";
// games

import all from "../../assets/img/minibannerCgAll.webp";
import allJpg from "../../assets/img/minibannerCgAll.jpg";
import autumn from "../../assets/img/minibannerCgAutumn.webp";
import autumnJpg from "../../assets/img/minibannerCgAutumn.jpg";
import characters from "../../assets/img/minibannerCgCharacters.webp";
import charactersJpg from "../../assets/img/minibannerCgCharacters.jpg";
import connectDots from "../../assets/img/minibannerCgConnectDots.webp";
import connectDotsJpg from "../../assets/img/minibannerCgConnectDots.jpg";
import crosswordPrintables from "../../assets/img/minibannerCgCrosswordPrintables.webp";
import crosswordPrintablesJpg from "../../assets/img/minibannerCgCrosswordPrintables.jpg";
import easter from "../../assets/img/minibannerCgEaster.webp";
import easterJpg from "../../assets/img/minibannerCgEaster.jpg";
import finishDrawing from "../../assets/img/minibannerCgFinishDrawing.webp";
import finishDrawingJpg from "../../assets/img/minibannerCgFinishDrawing.jpg";
import generalResources from "../../assets/img/minibannerCgGeneralResources.webp";
import generalResourcesJpg from "../../assets/img/minibannerCgGeneralResources.jpg";
import halloween from "../../assets/img/minibannerCgHalloween.webp";
import halloweenJpg from "../../assets/img/minibannerCgHalloween.jpg";
import howMany from "../../assets/img/minibannerCgHowMany.webp";
import howManyJpg from "../../assets/img/minibannerCgHowMany.jpg";
import makeANote from "../../assets/img/minibannerCgMakeNote.webp";
import makeANoteJpg from "../../assets/img/minibannerCgMakeNote.jpg";
import maze from "../../assets/img/minibannerCgMaze.webp";
import mazeJpg from "../../assets/img/minibannerCgMaze.jpg";
import seasonal from "../../assets/img/minibannerCgSeasonal.webp";
import seasonalJpg from "../../assets/img/minibannerCgSeasonal.jpg";
import skills from "../../assets/img/minibannerCgSkills.webp";
import skillsJpg from "../../assets/img/minibannerCgSkills.jpg";
import spotDifference from "../../assets/img/minibannerCgSpotDifference.webp";
import spotDifferenceJpg from "../../assets/img/minibannerCgSpotDifference.jpg";
import spring from "../../assets/img/minibannerCgSpring.webp";
import springJpg from "../../assets/img/minibannerCgSpring.jpg";
import stPat from "../../assets/img/minibannerCgStPat.webp";
import stPatJpg from "../../assets/img/minibannerCgStPat.jpg";
import summer from "../../assets/img/minibannerCgSummer.webp";
import summerJpg from "../../assets/img/minibannerCgSummer.jpg";
import thanksgiving from "../../assets/img/minibannerCgThanksgiving.webp";
import thanksgivingJpg from "../../assets/img/minibannerCgThanksgiving.jpg";
import usHolidays from "../../assets/img/minibannerCgUSHolidays.webp";
import usHolidaysJpg from "../../assets/img/minibannerCgUSHolidays.jpg";
import valentine from "../../assets/img/minibannerCgValentine.webp";
import valentineJpg from "../../assets/img/minibannerCgValentine.jpg";
import winter from "../../assets/img/minibannerCgWinter.webp";
import winterJpg from "../../assets/img/minibannerCgWinter.jpg";
import winterHolidays from "../../assets/img/minibannerCgWinterHolidays.webp";
import winterHolidaysJpg from "../../assets/img/minibannerCgWinterHolidays.jpg";
import wordScramble from "../../assets/img/minibannerCgWordScramble.webp";
import wordScrambleJpg from "../../assets/img/minibannerCgWordScramble.jpg";
import wordSearchPrintables from "../../assets/img/minibannerCgWordSearchPrintables.webp";
import wordSearchPrintablesJpg from "../../assets/img/minibannerCgWordSearchPrintables.jpg";
// printables

import animal from "../../assets/img/minibannerCgAnimal.webp";
import animalJpg from "../../assets/img/minibannerCgAnimal.jpg";
// "animal" for games "animals" for printable, but same art
import artMusic from "../../assets/img/minibannerCgArt.webp";
import artMusicJpg from "../../assets/img/minibannerCgArt.jpg";
import food from "../../assets/img/minibannerCgFood.webp";
import foodJpg from "../../assets/img/minibannerCgFood.jpg";
import languageArts from "../../assets/img/minibannerCgLangArt.webp";
import languageArtsJpg from "../../assets/img/minibannerCgLangArt.jpg";
import science from "../../assets/img/minibannerCgScience.webp";
import scienceJpg from "../../assets/img/minibannerCgScience.jpg";
import socialStudies from "../../assets/img/minibannerCgSocStud.webp";
import socialStudiesJpg from "../../assets/img/minibannerCgSocStud.jpg";
import sports from "../../assets/img/minibannerCgSports.webp";
import sportsJpg from "../../assets/img/minibannerCgSports.jpg";
// shared

export const categoryUrlName = (name, { catTypes } = { catTypes: categoryTypes }) => {
  let cName = name;

  catTypes.forEach((cat) => {
    if (name.startsWith(cat)) {
      cName = name.split(`${cat}-`)[1];
    }
  });

  return cName;
};

export default function categoryMbArt(type, name = "all") {
  const art = {
    all: { image: all, fallback: allJpg },
    animal: { image: animal, fallback: animalJpg },
    animals: { image: animal, fallback: animalJpg },
    artMusic: { image: artMusic, fallback: artMusicJpg },
    autumn: { image: autumn, fallback: autumnJpg },
    bingo: { image: bingo, fallback: bingoJpg },
    car: { image: car, fallback: carJpg },
    cat: { image: cat, fallback: catJpg },
    characters: { image: characters, fallback: charactersJpg },
    connectDots: { image: connectDots, fallback: connectDotsJpg },
    crossword: {
      image: type === contentTypes.printable ? crosswordPrintables : crosswordGames,
      fallback: type === contentTypes.printable ? crosswordPrintablesJpg : crosswordGamesJpg,
    },
    // shared category but different art
    easter: { image: easter, fallback: easterJpg },
    finishDrawing: { image: finishDrawing, fallback: finishDrawingJpg },
    food: { image: food, fallback: foodJpg },
    generalResources: { image: generalResources, fallback: generalResourcesJpg },
    halloween: { image: halloween, fallback: halloweenJpg },
    howMany: { image: howMany, fallback: howManyJpg },
    hunt: { image: hunt, fallback: huntJpg },
    languageArts: { image: languageArts, fallback: languageArtsJpg },
    makeANote: { image: makeANote, fallback: makeANoteJpg },
    math: { image: math, fallback: mathJpg },
    maze: { image: maze, fallback: mazeJpg },
    money: { image: money, fallback: moneyJpg },
    multiplayer: { image: multiplayer, fallback: multiplayerJpg },
    multiplication: { image: multiplication, fallback: multiplicationJpg },
    platformer: { image: platformer, fallback: platformerJpg },
    puzzle: { image: puzzle, fallback: puzzleJpg },
    racing: { image: racing, fallback: racingJpg },
    retro: { image: retro, fallback: retroJpg },
    robot: { image: robot, fallback: robotJpg },
    seasonal: { image: seasonal, fallback: seasonalJpg },
    science: { image: science, fallback: scienceJpg },
    skills: { image: skills, fallback: skillsJpg },
    socialStudies: { image: socialStudies, fallback: socialStudiesJpg },
    space: { image: space, fallback: spaceJpg },
    sports: { image: sports, fallback: sportsJpg },
    spotDifference: { image: spotDifference, fallback: spotDifferenceJpg },
    spring: { image: spring, fallback: springJpg },
    story: { image: story, fallback: storyJpg },
    stPat: { image: stPat, fallback: stPatJpg },
    summer: { image: summer, fallback: summerJpg },
    thanksgiving: { image: thanksgiving, fallback: thanksgivingJpg },
    typing: { image: typing, fallback: typingJpg },
    usHolidays: { image: usHolidays, fallback: usHolidaysJpg },
    valentine: { image: valentine, fallback: valentineJpg },
    winter: { image: winter, fallback: winterJpg },
    winterHolidays: { image: winterHolidays, fallback: winterHolidaysJpg },
    wordScramble: { image: wordScramble, fallback: wordScrambleJpg },
    wordSearch: {
      image: type === contentTypes.printable ? wordSearchPrintables : wordSearchGames,
      fallback: type === contentTypes.printable ? wordSearchPrintablesJpg : wordSearchGamesJpg,
    },
    // shared category but different art
    writing: { image: writing, fallback: writingJpg },
  };

  const { image, fallback } = art[hyphenToCamel(categoryUrlName(name))];

  return {
    image,
    fallback,
  };
}
