import React from "react";
import ArrowRight from "../../../assets/svg/arrowRight.svg";

export default function MobilePlayButton() {
  return (
    <button type="button" className="playbutton button-flat-color orange dimensional">
      <ArrowRight />
    </button>
  );
}
