import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "urql";
import { routeCodes } from "../../config/routes";
import LoadingAnim from "../global/loadingAnim";
import Modal from "../global/modal";
import ModalClose from "../global/modalClose";
import ErrorMessage from "../global/errorMessage";
import { getString, handlePostRequest, isLoggedInWithoutRole } from "../../utilities";
import { ADD_FAVORITE_GAME, REMOVE_FAVORITE_GAME } from "../../graphql/mutations";
import { publicFavLimit, userRoles } from "../../config";
import Arrow from "../../../assets/svg/arrowRight.svg";
import { gtmJoinClick } from "../../utilities/gtm";
import { gamesSelector, isLoggedInSelector, setGames } from "../../redux/slices/loginSlice";

export default function AddFav({ game }) {
  const games = useSelector(gamesSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);

  const addSuccess = (data) => {
    const { addFavoriteGame } = data;
    dispatch(setGames({ games: addFavoriteGame }));
    try {
      window.localStorage.setItem("games", JSON.stringify(addFavoriteGame));
    } catch (e) {}
  };

  const removeSuccess = (data) => {
    const { removeFavoriteGame } = data;
    dispatch(setGames({ games: removeFavoriteGame }));
    try {
      window.localStorage.setItem("games", JSON.stringify(removeFavoriteGame));
    } catch (e) {}
  };

  const error = (data) => {
    setModalMessage(
      <>
        <div className="modal-content-header">{getString("error.0")}</div>
        <ErrorMessage error={data} />
      </>,
    );
    setModal(true);
  };

  const [addResult, addGame] = useMutation(ADD_FAVORITE_GAME);
  const { fetching: addLoading } = addResult;

  const [removeResult, removeGame] = useMutation(REMOVE_FAVORITE_GAME);
  const { fetching: removalLoading } = removeResult;

  const renderContent = () => {
    if (isLoggedInWithoutRole(userRoles.student)) {
      const favGames = games.map((g) => g.localeShortname || g.shortname);
      const myFav = isLoggedIn && favGames.includes(game.localeShortname ? game.localeShortname : game.shortname);

      const activeStyle = myFav ? "active" : "";
      const myBtnLabel = myFav ? "game.favorites.remove" : "game.favorites.add";
      const loadingString = myFav ? "game.favorites.removing" : "game.favorites.adding";
      const variables = { shortname: game.shortname };

      return (
        <>
          <button
            type="button"
            className={`button-dimensional-icon favorite ${activeStyle}`}
            onClick={() => {
              if (isLoggedInWithoutRole(userRoles.free) || myFav || favGames.length < publicFavLimit) {
                if (myFav) {
                  removeGame(variables).then((result) => handlePostRequest(result, removeSuccess, error));
                } else {
                  addGame(variables).then((result) => handlePostRequest(result, addSuccess, error));
                }
              } else {
                // toggle modal prompting to signup for premium to add more than 3
                setModalMessage(
                  <>
                    <div className="modal-content-header">{getString("join.0")}</div>
                    <p>{getString("game.favorites.publicLimit.0")}</p>
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          navigate(routeCodes.MANAGE);
                        }}
                        className="button-flat-color pt-green round auto"
                      >
                        {getString("manage.title.0")}
                        <Arrow className="inline-arrow" />
                      </button>
                    </div>
                  </>,
                );
                setModal(true);
              }
            }}
          >
            <span>{getString(myBtnLabel)}</span>
          </button>

          {(addLoading || removalLoading) && (
            <LoadingAnim position="fixed" className="background-transparent-white">
              <h4>{getString(loadingString)}</h4>
            </LoadingAnim>
          )}
        </>
      );
    }

    return (
      <button
        type="button"
        className="button-dimensional-icon favorite"
        onClick={() => {
          // toggle modal prompting to signup to add favorites
          setModalMessage(
            <>
              <div className="modal-content-header">{getString("join.0")}</div>
              <p>{getString("game.favorites.signup")}</p>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    navigate(routeCodes.SUBSCRIBE);
                    gtmJoinClick(location.pathname, "game-add-favorite-button");
                  }}
                  className="button-flat-color pt-green round auto"
                >
                  {getString("join.3")}
                  <Arrow className="inline-arrow" />
                </button>
              </div>
            </>,
          );
          setModal(true);
        }}
      >
        <span>{getString("game.favorites.add")}</span>
      </button>
    );
  };

  if (game.searchable && (!isLoggedIn || isLoggedInWithoutRole(userRoles.student))) {
    return (
      <div className="gv-title-buttons-container">
        {renderContent()}

        <Modal doShow={modal} closeOnEscape={() => setModal((prev) => !prev)}>
          <ModalClose
            close={() => {
              setModal((prev) => !prev);
            }}
          />

          {modalMessage}
        </Modal>
      </div>
    );
  }
  return null;
}

AddFav.propTypes = {
  game: PropTypes.object.isRequired,
};
