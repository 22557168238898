import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { getString } from "../../utilities";
import SubscriptionCard from "../subscribe/subscriptionCard";
import { routeCodes } from "../../config/routes";
import { Link } from "react-router-dom";
import Arrow from "../../../assets/svg/arrowRight.svg";
import { roleSelector } from "../../redux/slices/loginSlice";
import { userRoles } from "../../config";

export default function SchoolPlans({ plan, setPlan, setPlanAmount, planData }) {
  const { pathname } = useLocation();
  const loginRole = useSelector(roleSelector);

  const renderRequestQuote = () => {
    if (loginRole === userRoles.school) {
      return null;
    }
    return (
      <p>
        <Link
          type="button"
          to={routeCodes.SUBSCRIBE_SCHOOL}
          state={{ prevPath: pathname }}
          className="planbutton button-flat-color school-sub-light round auto"
        >
          {getString("subscribe.plans.school.requestQuote").toUpperCase()}
          <Arrow className="inline-arrow" />
        </Link>
      </p>
    );
  };

  return (
    <div className="wrapper plans-wrapper school">
      <div className="plans-title">{getString("subscribe.plans.school.title.0")}</div>

      {renderRequestQuote()}

      <div className="plans-subs">
        <SubscriptionCard
          plan={plan}
          setPlan={setPlan}
          setPlanAmount={setPlanAmount}
          planData={planData}
          subscription="m12"
        />
      </div>
    </div>
  );
}

SchoolPlans.propTypes = {
  plan: PropTypes.string,
  setPlan: PropTypes.func.isRequired,
  setPlanAmount: PropTypes.func.isRequired,
  planData: PropTypes.object.isRequired,
};
