import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import Modal from "../global/modal";
import Confirm from "../global/confirm";

export default function MobilePublicModal({ doShow, close }) {
  const navigate = useNavigate();

  return (
    <Modal doShow={doShow} closeOnEscape={close}>
      <Confirm
        confirm={async () => {
          await close();
          navigate(routeCodes.MANAGE);
        }}
        cancel={close}
        button={getString("manage.title.1")}
      >
        <div className="modal-content-header">{getString("join.4")}</div>
        <p>{getString("game.mobile.signup")}</p>
      </Confirm>
    </Modal>
  );
}

MobilePublicModal.propTypes = {
  doShow: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
