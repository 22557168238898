import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getLangCode, language } from "../../config";

export default function HeavenlyBody({ children, path, styles }) {
  const langCode = getLangCode();
  const heavenlyStyles = [...styles];

  if (langCode !== language.default) {
    heavenlyStyles.push(`home-planet-${langCode}`);
  }

  return (
    <Link className={`home-planet ${heavenlyStyles.join(" ")}`} to={path}>
      {children}
    </Link>
  );
}

HeavenlyBody.propTypes = {
  path: PropTypes.string.isRequired,
  styles: PropTypes.array.isRequired,
  children: PropTypes.any.isRequired,
};
