import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import Leader from "../ads/leader";
import MiniBanner from "../global/miniBanner";
import PrintablesNav from "./printablesNav";
import { contentTypes } from "../../config";
import { metaType } from "../../config/meta";
import { routeCodes } from "../../config/routes";
import { getString, sortedArrayByItem, getSuggestions, compareNormalizedStrings } from "../../utilities";
import { searchArray } from "../../utilities/search";
import LoadingAnim from "../global/loadingAnim";
import NotFound from "../global/notFound";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import SubCategoryNav from "./subCategoryNav";
import PrintableCategoryArt from "./PrintableCategoryArt";
import { printableCategories } from "../../config/categories";
import SearchBox from "../global/SearchBox";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";
import { renderDesktopSignupModal } from "../../utilities/printables";

export default function CategoryPrintables() {
  const location = useLocation();
  const { shortname: snParam, cat: catParam } = useParams();

  const [category, setCategory] = useState(
    printableCategories.find((cat) => cat.localeShortname === snParam || cat.shortname === snParam),
  );
  const [printables, setPrintables] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState(null);

  const printablesContainer = useRef();
  const [hasListeners, setHasListeners] = useState(false);
  const [searchedPrintables, setSearchedPrintables] = useState(null);
  const [searching, setSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadCounter, setLoadCounter] = useState(1);

  useEffect(() => {
    if (load) setLoadCounter(loadCounter + 1);
  }, [load]);

  const loading = useSelector(loadingSelector);
  const { printables: printablesData } = useSelector(dataSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);

  useEffect(() => {
    setCategory(printableCategories.find((cat) => cat.localeShortname === snParam || cat.shortname === snParam));
    setSubCategories(
      sortedArrayByItem(
        printableCategories.filter((cat) => {
          const shortname = cat.localeShortname || cat.shortname;
          return shortname !== snParam && shortname.startsWith(snParam);
        }),
        "name",
      ),
    );
    if (catParam) {
      setSubCategory(
        printableCategories.find(
          (cat) => cat.localeShortname === `${snParam}-${catParam}` || cat.shortname === `${snParam}-${catParam}`,
        ),
      );
    } else {
      setSubCategory(null);
    }
  }, [snParam, catParam]);

  useEffect(() => {
    if (!loading && (category || subCategory) && printablesData) {
      const filterCategory = subCategory || category;
      let catPrintables = printablesData.filter((printable) =>
        printable.categories.some((printableCategory) => printableCategory.startsWith(filterCategory.shortname)),
      );

      catPrintables = sortedArrayByItem(catPrintables, "fullname");
      setPrintables(catPrintables);

      const nextActiveSubCategories = new Set();
      printablesData.forEach((printable) => {
        printable.categories.forEach((category) => {
          nextActiveSubCategories.add(category);
        });
      });

      setLoadCounter(1);
    }
  }, [loading, printablesData, category, subCategory]);

  const resetMiniBanners = () => {
    // infinite scroll load for printable minibanners
    if (printablesContainer.current) {
      const buff = 30; // show more button for over scrolling
      const gc = printablesContainer.current.getBoundingClientRect();
      const { clientHeight } = document.documentElement;
      const shouldLoad = gc.height + gc.top <= clientHeight + buff;

      setLoad(shouldLoad);
    }
  };

  useEffect(() => {
    if (!hasListeners) {
      setHasListeners(true);

      setTimeout(() => {
        window.addEventListener("scroll", resetMiniBanners);
        window.addEventListener("resize", resetMiniBanners);
      }, 100);
    }

    return () => {
      // componentWillUnmount (cleanup)
      window.removeEventListener("scroll", resetMiniBanners);
      window.removeEventListener("resize", resetMiniBanners);
    };
  }, []);

  const renderPrintables = () => {
    const printableObjects = searchedPrintables || printables;

    if (searching) {
      return <LoadingAnim className="orange" />;
    }

    if (printableObjects && printableObjects.length > 0) {
      // 18 is 3 rows of minibanners at 1366 x 768
      // most popular size (chrome books)
      const mbCount = 18;
      const pi = loadCounter * mbCount;
      const more = pi < printableObjects.length;
      const mbToRender = more ? printableObjects.slice(0, pi) : printableObjects;

      return (
        <>
          {mbToRender.map((printable, index) => {
            const clickHandler = isLoggedIn
              ? `${routeCodes.PRINTABLES}${printable.localeShortname || printable.shortname}`
              : renderDesktopSignupModal;
            return (
              <MiniBanner
                key={printable.id}
                type={contentTypes.printable}
                title={printable.fullname}
                path={printable.localeShortname || printable.shortname}
                grades={category.shortname !== "coloring-pages" ? printable.grades : null}
                image={printable.miniBanner ? printable.miniBanner : null}
                style="mb-portrait"
                hasNoScroll={true}
                tabIndex={String(index + 1)}
                clickHandler={clickHandler}
              />
            );
          })}

          <OptionallyDisplayed doDisplay={more}>
            <div className="catpr-loadmore">
              <button
                type="button"
                onClick={() => {
                  setLoadCounter(loadCounter + 1);
                }}
                className="button-flat-color orange round dimensional auto"
              >
                {getString("navigation.more.1")}
              </button>
            </div>
          </OptionallyDisplayed>
        </>
      );
    }

    return <div className="search-noResults">{getString("search.noresults")}</div>;
  };

  if (category) {
    const metaObject = {
      metaTitle: category.metaTitle,
      metaDescription: category.metaDescription,
      metaKeywords: category.metaKeywords,
    };

    // users not logged in or logged in and free

    const catStyle = `catpr-${category.shortname}`;

    return (
      <>
        {metaType("WebSite", metaObject, location.pathname)}

        <PrintablesNav />

        <div className="categoryprintables">
          <div className={`categoryprintables-title ${catStyle}`}>{category.name}</div>

          <SearchBox
            searched={searchedPrintables}
            setSearched={setSearchedPrintables}
            setSearching={setSearching}
            search={async (searchQuery) => await searchArray(printables, searchQuery, contentTypes.printable)}
            onSuggestionsFetchRequested={({ value }) => {
              setSuggestions(
                getSuggestions(value, printables, "fullname")
                  .map((printable) => printable.fullname)
                  .sort(compareNormalizedStrings),
              );
            }}
            suggestions={suggestions}
            setSuggestions={setSuggestions}
            showSearch
            placeholder={getString("search.cta", { replace: [category.name] })}
            catStyle={catStyle}
          />

          <div className="nav-art-wrapper">
            <div className={`categoryprintables-nav-art-container ${catStyle}`}>
              <PrintableCategoryArt shortname={category.shortname} description={category.metaDescription} />
            </div>
          </div>
        </div>

        <Leader className="green" />

        <div className="printable-subcategory-nav">
          <SubCategoryNav
            categories={subCategories}
            route={`${routeCodes.CATEGORYPRINTABLES}${
              category.localeShortname ? category.localeShortname : category.shortname
            }`}
          />
        </div>

        <div className="categoryprintables-printables">
          <div className="categoryprintables-printables-container" ref={printablesContainer}>
            {renderPrintables()}
          </div>
        </div>
      </>
    );
  }

  // category will end up "undefined"
  // data is retreived and set into state
  // url param shortname provided isn't a category in printables group
  // ie: abcya.com/printables/foo/bar
  return <NotFound item={getString("printables.title.1")} route="PRINTABLES" cta={getString("printables.go")} />;
}
