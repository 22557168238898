const getKeyForEnv = (key) => {
  return `${key}_${process.env.NODE_ENV}`;
};

export const getWithExpiry = (key) => {
  const keyForEnv = getKeyForEnv(key);

  let itemStr;

  try {
    itemStr = localStorage.getItem(keyForEnv);
  } catch (error) {
    console.log("getWithExpiry error: ", error.message);
  }

  if (!itemStr) return null;

  const item = JSON.parse(itemStr);

  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    try {
      localStorage.removeItem(keyForEnv);
    } catch (e) {}
    return null;
  }
  return item.value;
};

export const setWithExpiry = (key, value, ttl, overwrite) => {
  // ttl should be milliseconds
  // ie 5 second time to live = 5000
  // overwrite: boolean
  const keyForEnv = getKeyForEnv(key);

  const existing = getWithExpiry(keyForEnv);

  if (overwrite || !existing) {
    const now = new Date();
    const item = {
      value,
      expiry: now.getTime() + ttl,
    };

    try {
      localStorage.setItem(keyForEnv, JSON.stringify(item));
    } catch (error) {
      console.log("setWithExpiry error: ", error.message);
    }
  }
};
