import React from "react";
import { getString } from "../../utilities";
import { scrollDocVerticallyToTarget } from "../../utilities/scroll";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { userRoles } from "../../config";
import { useSelector } from "react-redux";
import { roleSelector } from "../../redux/slices/loginSlice";

export default function ToolsNav() {
  const role = useSelector(roleSelector);

  return (
    <div className="pt-nav">
      <button
        type="button"
        className="button-flat-color"
        onClick={() => {
          scrollDocVerticallyToTarget(document.getElementById("timer-anchor"));
        }}
      >
        {getString("tools.timer.title.0")}
      </button>

      <OptionallyDisplayed doDisplay={role !== userRoles.student}>
        <button
          type="button"
          className="button-flat-color"
          onClick={() => {
            scrollDocVerticallyToTarget(document.getElementById("category"));
          }}
        >
          {getString("tools.category.title.0")}
        </button>
      </OptionallyDisplayed>

      <button
        type="button"
        className="button-flat-color"
        onClick={() => {
          scrollDocVerticallyToTarget(document.getElementById("favorite"));
        }}
      >
        {getString("tools.favorite.title.0")}
      </button>
    </div>
  );
}
