import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useMutation } from "urql";
import { FEEDBACK_MUTATION } from "../../graphql/mutations";
import { EMAIL_CHAR_LIMIT, getLangCode, recaptchaSiteKey, TEXT_FIELD_CHAR_LIMIT } from "../../config";
import { metaDefault } from "../../config/meta";
import { getString, handlePostRequest } from "../../utilities";
import { emailReg, getMaxLengthValidator } from "../../utilities/forms/rules";
import feedbackStrings from "../../strings/feedback";
import RoleButton from "./roleButton";
import ErrorMessage from "../global/errorMessage";
import LoadingAnim from "../global/loadingAnim";
import TextViewHook from "../global/textViewHook";
import OKModal from "../global/okModal";
import Select from "../global/Select";
import { useLazyQuery } from "../../hooks";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";

export default function Feedback() {
  const action = `Feedback_Form_Submission`;
  const location = useLocation();
  const [role, setRole] = useState("parent");
  const [submitErrors, setSubmitErrors] = useState();
  const [successModal, setSuccessModal] = useState(false);
  const [countriesArray, setCountriesArray] = useState([]);
  const { register, handleSubmit, reset, control, formState, getValues } = useForm();

  const questionData = feedbackStrings.questions.data;

  const getQuestions = (role) => {
    return questionData[role].reduce((qArry, q, i) => {
      qArry.push({
        id: `q-${role}-${i}`,
        question: q,
      });
      return qArry;
    }, []);
  };

  const allQuestions = getQuestions("all");
  const roleQuestions = getQuestions(role);

  const roles = Object.keys(questionData).filter((key) => key !== "all");

  const getVariables = (verifyKey) => {
    const formData = getValues();
    const variables = {
      role,
      verifyKey,
      verifyValue: action,
    };

    const questionArray = [];

    [...roleQuestions, ...allQuestions].forEach((q) => {
      const answer = formData[q.id];
      let question = q.question;

      if (answer && answer.trim().length > 0) {
        if (typeof question === "object") {
          question = question.text;
        }

        questionArray.push({
          question,
          answer: answer.trim(),
        });
      }
    });

    if (questionArray.length > 0) {
      variables.questions = JSON.stringify(questionArray);
    } else {
      return null;
    }

    const trimStrings = ["email", "emailVerify", "name", "city", "state"];
    trimStrings.forEach((prop) => {
      const value = formData[prop];

      if (value && value.trim().length > 0) {
        variables[prop] = value.trim();
      }
    });

    if (formData.country) {
      variables.country = JSON.stringify(formData.country);
    }

    return variables;
  };

  const resolverError = (error) => {
    // const e = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors : error.message

    if (error !== submitErrors) {
      setSubmitErrors(error);
      window.scrollTo(0, 0);
    }
    reset();
  };

  const onFeedbackCompleted = async ({ feedback: { success, message } }) => {
    if (success) {
      setSubmitErrors(null);

      reset();
      // have to specifically reset controllers by name
      reset({
        country: "",
      });
      setSuccessModal(true);
    } else {
      setSubmitErrors({ message });
    }
    reset();
  };

  const [result, feedback] = useMutation(FEEDBACK_MUTATION);
  const { fetching: submitting } = result;

  const onCompleted = ({ reCaptchaVerify }) => {
    if (reCaptchaVerify) {
      if (reCaptchaVerify.success) {
        // verified
        // execute feedback mutation
        const variables = getVariables(reCaptchaVerify.key);

        if (variables) {
          feedback(variables).then((result) => handlePostRequest(result, onFeedbackCompleted, resolverError));
        }
      } else {
        // not verified
        setSubmitErrors({ message: reCaptchaVerify.message });
      }
    }
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onCompleted,
    resolverError,
  );

  useEffect(() => {
    const loadCountries = async () => {
      const { default: countryData } = await import(`../../utilities/countries/${getLangCode()}`);
      setCountriesArray(countryData);
    };

    loadCountries();
  }, []);

  const loading = recaptchaLoading || submitting;

  const renderQuestion = (q) => {
    const { id, question } = q;
    if (typeof question === "object") {
      // radio selection
      const { answers, text } = question;

      return (
        <div className="form-field" key={id}>
          <div className="form-field-children">{text}</div>
          <div className="form-field-container">
            {answers.map((a, i) => {
              const aKey = `aKey${i + 1}`;
              const { value, checked } = a;

              return (
                <label key={aKey} className="radio-label" htmlFor={aKey}>
                  <input id={aKey} type="radio" {...register(id)} name={id} value={value} defaultChecked={!!checked} />
                  {value}
                </label>
              );
            })}
            {formState.errors[id] && <div className="validation-error">{formState.errors[id].message}</div>}
          </div>
        </div>
      );
    }

    // string textarea
    return (
      <TextViewHook
        rules={{ maxLength: getMaxLengthValidator(getString("forms.answer"), TEXT_FIELD_CHAR_LIMIT) }}
        type="textarea"
        key={id}
        errors={formState.errors}
        name={id}
      >
        <div className="form-field-children">{question}</div>
      </TextViewHook>
    );
  };

  const checkData = () => {
    // keep share data question last in feedback.js
    // don't want to include the last share data question
    // to submit nothing with just that is pointless
    const shareQuestionID = `q-all-${allQuestions.length - 1}`;

    const formValuesObject = getValues();

    const touchedQuestionArray = Object.keys(formValuesObject).filter((key) => formValuesObject[key]);

    return touchedQuestionArray.some((key) => key.startsWith("q-") && key !== shareQuestionID);
  };

  const renderCountrySelect = () => {
    if (!submitting && countriesArray && countriesArray.length > 0) {
      return (
        <div className="form-field">
          <Controller
            control={control}
            name="country"
            defaultValue=""
            render={({ field: { onChange }, fieldState }) => (
              <Select
                onChange={onChange}
                {...fieldState}
                placeholder={getString("forms.select.0")}
                items={countriesArray}
                searchKey="name"
                label={getString("forms.country.0")}
              />
            )}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("title.0", { strings: feedbackStrings }) })}

      <div className="feedback">
        <div className="wrapper padded">
          <div className="container">
            <h1>{getString("title.0", { strings: feedbackStrings })}</h1>
          </div>

          <div className="container-medium pt-caption">
            {getString("message", { html: true, strings: feedbackStrings })}
          </div>
        </div>

        <div className="wrapper padded">
          <div className="pt-date-caption">{getString("choose", { strings: feedbackStrings })}</div>

          <div className="planselect">
            {roles.map((r) => (
              <RoleButton key={r} type={r} text={getString(`${r}.0`)} role={role} setRole={setRole} />
            ))}
          </div>

          <div className="container-medium">
            <form
              onSubmit={handleSubmit((data) => {
                if (!loading) {
                  // reset if errors are present
                  if (submitErrors) {
                    setSubmitErrors(null);
                  }

                  const dataPresent = checkData();
                  if (dataPresent) {
                    // perform reCaptcha to get token
                    window.grecaptcha.ready(() => {
                      window.grecaptcha.execute(recaptchaSiteKey, { action }).then((token) => {
                        // query with token
                        recaptchaVerify({ token, action, verify: true });
                      });
                    });
                  }
                }
              })}
              className="login-form"
            >
              <FormProvider register={register}>
                {submitErrors && <ErrorMessage error={submitErrors} />}

                <h2>{getString("contact", { strings: feedbackStrings })}</h2>

                <TextViewHook
                  rules={{ maxLength: getMaxLengthValidator(getString("forms.name.0"), TEXT_FIELD_CHAR_LIMIT) }}
                  errors={formState.errors}
                  name="name"
                  label={getString("forms.name.0")}
                  className="col2"
                />

                <TextViewHook
                  rules={{
                    pattern: {
                      value: emailReg,
                      message: getString("forms.errorMessages.email.0", {
                        replace: [getString("forms.email.3")],
                      }),
                    },
                    maxLength: getMaxLengthValidator(getString("forms.email.3"), EMAIL_CHAR_LIMIT),
                  }}
                  errors={formState.errors}
                  name="email"
                  label={getString("forms.email.3")}
                  className="col2 right"
                />

                <TextViewHook
                  rules={{ maxLength: getMaxLengthValidator(getString("forms.city.0"), TEXT_FIELD_CHAR_LIMIT) }}
                  errors={formState.errors}
                  name="city"
                  label={getString("forms.city.0")}
                  className="col2"
                />

                <TextViewHook
                  rules={{ maxLength: getMaxLengthValidator(getString("forms.state.0"), TEXT_FIELD_CHAR_LIMIT) }}
                  errors={formState.errors}
                  name="state"
                  label={getString("forms.state.0")}
                  className="col2 right"
                />

                {renderCountrySelect()}

                <h2>{getString("questions.title", { strings: feedbackStrings })}</h2>

                {roleQuestions.map((rq) => renderQuestion(rq))}

                {allQuestions.map((aq) => renderQuestion(aq))}

                <div className="feedback-form-footer">
                  <button className="button-flat-color pt-green" type="submit">
                    {getString("submit.1")}
                  </button>

                  <p>{getString("terms", { html: true, strings: feedbackStrings })}</p>
                  <p>{getString("recaptcha", { html: true })}</p>
                </div>
              </FormProvider>
            </form>

            <OKModal
              doShow={successModal}
              close={() => {
                setSuccessModal(false);
                window.scrollTo(0, 0);
              }}
              title={getString("thankyou.0")}
            >
              <p>{getString("submitted.message", { strings: feedbackStrings })}</p>
            </OKModal>

            {loading && (
              <LoadingAnim position="fixed" className="background-transparent-white">
                <h4>{getString("send.1")}</h4>
              </LoadingAnim>
            )}
          </div>
        </div>
      </div>

      <div className="wrapper header-shadow" />
    </>
  );
}
