import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { getString, isLoggedInWithRole } from "../../utilities";
import { userRoles } from "../../config";
import { routeCodes } from "../../config/routes";
import { mobileFreePremium } from "../../utilities/games";
import { useDispatch } from "react-redux";
import { modalToggle } from "../../redux/slices/loginSlice";

export default function Trouble({ isMobileFree = false, isMobileActive = false, gameData = null, publicModalToggle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobilePremium = mobileFreePremium(isMobileActive, isMobileFree);
  const publicUser = isLoggedInWithRole(userRoles.free);

  let stringPath = "game.subscriberLogin";

  if (mobilePremium) {
    stringPath = "game.trouble";
  } else if (publicUser) {
    stringPath = "game.publicUpgrade";
  }

  return (
    <button
      type="button"
      className="button-flat-color round dimensional orange auto trouble"
      onClick={() => {
        if (mobilePremium) {
          const options = gameData ? { state: { gameData } } : null;

          navigate(routeCodes.FAQ, options);
        } else if (publicUser) {
          publicModalToggle();
        } else {
          dispatch(modalToggle());
        }
      }}
    >
      {getString(stringPath)}
    </button>
  );
}

Trouble.propTypes = {
  isMobileFree: PropTypes.bool,
  isMobileActive: PropTypes.bool,
  publicModalToggle: PropTypes.func.isRequired,
  gameData: PropTypes.object,
};
