import React from "react";
import { isCrawler, isPrerender } from "../../utilities/detection";
import { getString } from "../../utilities";
import { useSelector } from "react-redux";
import { adBlockingSelector } from "../../redux/slices/userClientSlice";

export default function AdblockIframe() {
  const adBlocking = useSelector(adBlockingSelector);

  if (!isCrawler && !isPrerender && adBlocking) {
    return (
      <iframe
        src="/abcya.html"
        frameBorder="0"
        height="1"
        width="1"
        title={getString("abcya.0")}
        sandbox="allow-same-origin"
        style={{ display: "none" }}
      />
    );
  }

  return null;
}
