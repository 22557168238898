import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { variousURLs } from "../../config";

export default function ArcademicsHTML5({ gameName, settings }) {
  const ifrm = useRef();

  useEffect(() => {
    if (gameName && gameName.length > 0) {
      ifrm.current.contentWindow.document.open();
      ifrm.current.contentWindow.document.write("<body></body>");
      ifrm.current.contentWindow.document.close();
      ifrm.current.contentWindow.document.body.style.margin = "0";
      ifrm.current.contentWindow.document.body.style.overflow = "hidden";
      ifrm.current.contentWindow.document.body.style.padding = "0";
      ifrm.current.contentWindow.document.body.style.width = "100%";
      ifrm.current.contentWindow.baseURL = variousURLs.arcademics.game;

      ifrm.current.contentWindow.load = function (gameName, settings) {
        const iframeWindow = this;
        const script = iframeWindow.document.createElement("script");
        script.src = `${iframeWindow.baseURL}/${gameName}.js`;
        script.onload = function () {
          const gameClass = gameName
            .split("-")
            .map((x) => {
              return x.charAt(0).toUpperCase() + x.slice(1);
            })
            .join("");
          new iframeWindow.arcademics[gameClass](settings);
        };
        iframeWindow.document.head.appendChild(script);
      };
      ifrm.current.contentWindow.load(gameName, settings);
    }
  }, [gameName]);

  return (
    <>
      <iframe
        title={gameName}
        sandbox="allow-same-origin allow-scripts allow-top-navigation"
        ref={ifrm}
        frameBorder="0"
        scrolling="no"
        style={{ border: "none", height: "100%", width: "100%" }}
      />
    </>
  );
}

ArcademicsHTML5.propTypes = {
  gameName: PropTypes.string,
  settings: PropTypes.object.isRequired,
};
