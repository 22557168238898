import React from "react";
import { useLocation } from "react-router-dom";
import { variousURLs } from "../../config";
import { metaDefault } from "../../config/meta";
import { getString } from "../../utilities";
import aboutStrings from "../../strings/about";
import ProductOffer from "./ProductOffer";

import ABCyaBingoAppIcon from "../../../assets/img/abcyaBingoAppIcon.jpg";
import CreateACarAppIcon from "../../../assets/img/createACarAppIcon.jpg";
import DonutDropAppIcon from "../../../assets/img/donutDropAppIcon.jpg";
import DreidelAppIcon from "../../../assets/img/dreidelAppIcon.jpg";
import MathBingoAppIcon from "../../../assets/img/mathBingoAppIcon.jpg";
import WordBingoAppIcon from "../../../assets/img/wordBingoAppIcon.jpg";

export default function ProductOfferings() {
  const location = useLocation();
  const {
    nativeDownload,
    productOfferings: { abcyaBingo, createACar, donutDrop, dreidel, mathBingo, wordBingo },
  } = variousURLs;

  return (
    <div className="prod-offer">
      {metaDefault({
        path: location.pathname,
        title: getString("productOfferings.title.0", { strings: aboutStrings }),
      })}

      <div className="wrapper padded prod-offer-header">
        <h1>{getString("productOfferings.title.0", { strings: aboutStrings })}</h1>

        <div className="container-medium pt-caption">
          {getString("productOfferings.caption", { strings: aboutStrings })}
        </div>
      </div>

      <div className="prod-offer-offers">
        <ProductOffer
          title={getString("productOfferings.offerings.0.title", { strings: aboutStrings })}
          caption={getString("productOfferings.offerings.0.caption", { strings: aboutStrings })}
          stores={{ ios: nativeDownload.IOS, android: nativeDownload.ANDROID, amazon: nativeDownload.AMAZON }}
        />

        <ProductOffer
          title={getString("productOfferings.offerings.1.title", { strings: aboutStrings })}
          caption={getString("productOfferings.offerings.1.caption", { strings: aboutStrings })}
          image={ABCyaBingoAppIcon}
          stores={{ ios: abcyaBingo.IOS, android: abcyaBingo.ANDROID, amazon: abcyaBingo.AMAZON }}
        />

        <ProductOffer
          title={getString("productOfferings.offerings.2.title", { strings: aboutStrings })}
          caption={getString("productOfferings.offerings.2.caption", { strings: aboutStrings })}
          image={CreateACarAppIcon}
          stores={{ ios: createACar.IOS }}
        />

        <ProductOffer
          title={getString("productOfferings.offerings.3.title", { strings: aboutStrings })}
          caption={getString("productOfferings.offerings.3.caption", { strings: aboutStrings })}
          image={DonutDropAppIcon}
          stores={{ ios: donutDrop.IOS, android: donutDrop.ANDROID, amazon: donutDrop.AMAZON }}
        />

        <ProductOffer
          title={getString("productOfferings.offerings.4.title", { strings: aboutStrings })}
          caption={getString("productOfferings.offerings.4.caption", { strings: aboutStrings })}
          image={DreidelAppIcon}
          stores={{ ios: dreidel.IOS }}
        />

        <ProductOffer
          title={getString("productOfferings.offerings.5.title", { strings: aboutStrings })}
          caption={getString("productOfferings.offerings.5.caption", { strings: aboutStrings })}
          image={MathBingoAppIcon}
          stores={{ ios: mathBingo.IOS }}
        />

        <ProductOffer
          title={getString("productOfferings.offerings.6.title", { strings: aboutStrings })}
          caption={getString("productOfferings.offerings.6.caption", { strings: aboutStrings })}
          image={WordBingoAppIcon}
          stores={{ ios: wordBingo.IOS }}
        />
      </div>

      <div className="wrapper header-shadow" />
    </div>
  );
}
