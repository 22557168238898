import React from "react";
import { Link } from "react-router-dom";
import dataGrades from "../../config/grades";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import { getLangCode, language } from "../../config";

export default function GradeNav() {
  const renderPreschool = (grade) => {
    if (getLangCode() === language.default) {
      return (
        <>
          {getString("grades.pre")}
          {/* index 1 is kindgergarten ASSUMING "K" value */}
          <span className="gradenav-link-post">{dataGrades[1].shortname}</span>
        </>
      );
    }

    return <strong>{grade.shortname}</strong>;
  };

  const renderGrades = () => {
    return dataGrades.map((grade) => {
      if (grade.number === -1) {
        return (
          <Link
            key={grade.url}
            to={`${routeCodes.GRADES}${grade.localeUrl || grade.url}`}
            className="gradenav-link"
            data-testid="grade-link"
          >
            {renderPreschool(grade)}
          </Link>
        );
      }

      return (
        <Link
          key={grade.url}
          to={`${routeCodes.GRADES}${grade.localeUrl || grade.url}`}
          className="gradenav-link"
          data-testid="grade-link"
        >
          {getString("grade.1")}
          <span className="gradenav-link-post">{grade.shortname}</span>
        </Link>
      );
    });
  };

  return (
    <div className="wrapper gradenav">
      <div className="container gradenav-container">{renderGrades()}</div>
    </div>
  );
}
