import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Leader from "../ads/leader";
import MiniBanner from "../global/miniBanner";
import { contentTypes, breakpoints } from "../../config";
import { metaDefault } from "../../config/meta";
import { routeCodes } from "../../config/routes";
import { compareNormalizedStrings, getString, getSuggestions, sortedArrayByItem } from "../../utilities";
import { getAllGames } from "../../utilities/games";
import { searchArray } from "../../utilities/search";
import CategoryNav from "./categoryNav";
import GradeNav from "../global/gradeNav";
import LoadingAnim from "../global/loadingAnim";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import SearchBox from "../global/SearchBox";

// Sun Background - Responsive Picture Set
import SunBgPngXs from "../../../assets/img/planetBgAllGames_xs.png";
import SunBgWebpXs from "../../../assets/img/planetBgAllGames_xs.webp";
import SunBgPngSm from "../../../assets/img/planetBgAllGames_sm.png";
import SunBgWebpSm from "../../../assets/img/planetBgAllGames_sm.webp";
import SunBgPngMd from "../../../assets/img/planetBgAllGames_md.png";
import SunBgWebpMd from "../../../assets/img/planetBgAllGames_md.webp";
import SunBgPngLg from "../../../assets/img/planetBgAllGames_lg.png";
import SunBgWebpLg from "../../../assets/img/planetBgAllGames_lg.webp";
import SunBgPngXl from "../../../assets/img/planetBgAllGames_xl.png";
import SunBgWebpXl from "../../../assets/img/planetBgAllGames_xl.webp";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";

export default function Games() {
  const location = useLocation();
  const gamesContainer = useRef();
  const [games, setGames] = useState(null);
  const [hasListeners, setHasListeners] = useState(false);
  const [searchedGames, setSearchedGames] = useState(null);
  const [searching, setSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadCounter, setLoadCounter] = useState(1);

  useEffect(() => {
    if (load) setLoadCounter(loadCounter + 1);
  }, [load]);

  const { games: dataGames } = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);

  useEffect(() => {
    if (dataGames) {
      let ag = getAllGames(dataGames);
      ag = sortedArrayByItem(ag, "fullname");
      if (ag !== games) setGames(ag);
    }
  }, [dataGames]);

  const resetMiniBanners = () => {
    // infinite scroll load for game minibanners
    if (gamesContainer.current) {
      const buff = 30; // show more button for over scrolling
      const gc = gamesContainer.current.getBoundingClientRect();
      const { clientHeight } = document.documentElement;
      const shouldLoad = gc.height + gc.top <= clientHeight + buff;

      setLoad(shouldLoad);
    }
  };

  useEffect(() => {
    if (!hasListeners) {
      setHasListeners(true);

      setTimeout(() => {
        window.addEventListener("scroll", resetMiniBanners);
        window.addEventListener("resize", resetMiniBanners);
      }, 100);
    }

    return () => {
      // componentWillUnmount (cleanup)
      window.removeEventListener("scroll", resetMiniBanners);
      window.removeEventListener("resize", resetMiniBanners);
    };
  }, []);

  const renderGames = () => {
    if (loading) {
      return <LoadingAnim className="orange" />;
    }

    if (!loading && games) {
      const gameObjects = searchedGames || games;

      if (searching) {
        return <LoadingAnim className="orange" />;
      }

      if (gameObjects.length > 0) {
        // 18 is 3 rows of minibanners at 1366 x 768
        // most popular size (chrome books)
        const mbCount = 18;
        const pi = loadCounter * mbCount;
        const more = pi < gameObjects.length;
        const mbToRender = more ? gameObjects.slice(0, pi) : gameObjects;

        return (
          <>
            {mbToRender.map((game, index) => (
              <MiniBanner
                key={game.id}
                type={contentTypes.game}
                title={game.fullname}
                path={game.shortname}
                image={game.miniBanner ? game.miniBanner : null}
                grades={game.grades}
                isMobile={game.mobileActive}
                isSearchable={game.searchable}
                hasNoScroll={true}
                clickHandler={`${routeCodes.GAMES}${game.localeShortname || game.shortname}`}
                tabIndex={String(index + 1)}
              />
            ))}
            <OptionallyDisplayed doDisplay={more}>
              <div className="loadmore-wrapper">
                <button
                  type="button"
                  onClick={() => {
                    setLoadCounter(loadCounter + 1);
                  }}
                  className="button-flat-color orange round dimensional auto"
                >
                  {getString("navigation.more.1")}
                </button>
              </div>
            </OptionallyDisplayed>
          </>
        );
      }
    }

    return <div className="search-noResults">{getString("search.noresults")}</div>;
  };

  const renderArt = () => {
    return (
      <picture>
        <source srcSet={SunBgWebpXs} media={`(max-width: ${breakpoints.width.xs}px)`} type="image/webp" />
        <source srcSet={SunBgPngXs} media={`(max-width: ${breakpoints.width.xs}px)`} type="image/png" />

        <source srcSet={SunBgWebpSm} media={`(max-width: ${breakpoints.width.sm}px)`} type="image/webp" />
        <source srcSet={SunBgPngSm} media={`(max-width: ${breakpoints.width.sm}px)`} type="image/png" />

        <source srcSet={SunBgWebpMd} media={`(max-width: ${breakpoints.width.md}px)`} type="image/webp" />
        <source srcSet={SunBgPngMd} media={`(max-width: ${breakpoints.width.md}px)`} type="image/png" />

        <source srcSet={SunBgWebpLg} media={`(max-width: ${breakpoints.width.lg}px)`} type="image/webp" />
        <source srcSet={SunBgPngLg} media={`(max-width: ${breakpoints.width.lg}px)`} type="image/png" />

        <source srcSet={SunBgWebpXl} type="image/webp" />
        <source srcSet={SunBgPngXl} type="image/png" />

        <img width="1920" height="225" src="" alt={getString("games.all.0")} />
      </picture>
    );
  };

  const showSearch = !loading && games && games.length > 0;

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("games.all.0") })}

      <GradeNav />

      <div className="games">
        <div className="games-title">{getString("games.all.0")}</div>

        <SearchBox
          searched={searchedGames}
          setSearched={setSearchedGames}
          setSearching={setSearching}
          search={async (searchQuery) => await searchArray(games, searchQuery, contentTypes.game)}
          onSuggestionsFetchRequested={({ value }) => {
            setSuggestions(
              getSuggestions(value, games, "fullname")
                .map((game) => game.fullname)
                .sort(compareNormalizedStrings),
            );
          }}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          showSearch={showSearch}
          placeholder={getString("search.cta", { replace: [getString("games.title.0")] })}
        />

        <div className="nav-art-wrapper">
          <div className="games-nav-art-container">{renderArt()}</div>
        </div>
      </div>

      <Leader className="green" />

      <CategoryNav />

      <div className="games-games">
        <div className="container games-games-container" ref={gamesContainer}>
          {renderGames()}
        </div>
      </div>
    </>
  );
}
