import React, { useState, useRef, useMemo } from "react";
import { useOutsideClicker } from "../../hooks";
import { contentTypes } from "../../config";
import { getString, sortedArrayByItem } from "../../utilities";
import categoryMbArt from "../../utilities/categoryMbArt";
import List from "../global/list";
import arrowLeft from "../../../assets/img/selectArrowLeft.svg";
import arrowDown from "../../../assets/img/selectArrowDown.svg";
import { routeCodes } from "../../config/routes";
import { gameCategories } from "../../config/categories";

export default function CategoryNav() {
  const selectContainer = useRef();
  const [group, setGroup] = useState("subject");
  const [selectToggle, setSelectToggle] = useState(false);
  const groups = useMemo(() => {
    return gameCategories.reduce(
      (currentGroups, cat) => {
        const { image, fallback } = categoryMbArt(contentTypes.game, cat.shortname);

        currentGroups[cat.type].push({
          type: contentTypes.link,
          style: "mb-label-center",
          title: cat.name,
          clickHandler: {
            pathname: `${routeCodes.CATEGORYGAMES}${cat.localeShortname ? cat.localeShortname : cat.shortname}`,
            state: { type: cat.type },
          },
          image,
          fallbackImage: fallback,
          order: cat.order,
        });
        return currentGroups;
      },
      { subject: [], theme: [], genre: [] },
    );
  }, []);

  const toggleSelect = () => {
    setSelectToggle((prevState) => !prevState);
  };

  useOutsideClicker(selectContainer, () => {
    setSelectToggle(false);
  });

  const renderList = () => {
    return <List key={group} items={sortedArrayByItem(groups[group] || [], "order")} className="gnc-list" />;
  };

  const renderSelect = () => {
    const selectActiveClass = selectToggle ? " active" : "";
    const selectTitle = selectToggle
      ? `${getString("browse.0")}:`
      : getString("games.by", { replace: [getString(`gameCategoryGroups.${group}`)] });
    const selectArrow = selectToggle ? arrowDown : arrowLeft;

    return (
      <div className={`gnc-select${selectActiveClass}`} ref={selectContainer}>
        <div className="gnc-select-title" tabIndex="0" role="button" onClick={toggleSelect}>
          <span className="gnc-select-title-text">{selectTitle}</span>
          <img src={selectArrow} className="gnc-select-title-arrow" alt="" />
        </div>

        {Object.keys(groups).map((shortname, i) => (
          <div
            key={shortname}
            tabIndex={i + 1}
            className={`gnc-select-option-${shortname}`}
            role="button"
            aria-hidden={!selectToggle}
            onClick={() => {
              if (selectToggle) {
                setGroup(shortname);
                toggleSelect();
              }
            }}
          >
            {getString("games.by", { replace: [getString(`gameCategoryGroups.${shortname}`)] })}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="games-nav-categories">
      {renderList()}
      {renderSelect()}
    </div>
  );
}
