import React from "react";
import PropTypes from "prop-types";
import Modal from "../global/modal";
import ModalClose from "../global/modalClose";
import { getString } from "../../utilities";
import AgeGateForm from "./AgeGateForm";

export default function AgeGateModal({ showAgeGateModal, setAgeGateModal, submit }) {
  const closeModal = () => {
    setAgeGateModal((prev) => !prev);
  };

  return (
    <Modal doShow={showAgeGateModal} closeOnEscape={closeModal}>
      <ModalClose close={closeModal} />
      <div className="modal-content-header">{getString("ageGate.title")}</div>

      <AgeGateForm closeFunction={closeModal} submitFunction={submit} />
    </Modal>
  );
}

AgeGateModal.propTypes = {
  showAgeGateModal: PropTypes.bool.isRequired,
  setAgeGateModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};
