import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mobile } from "../../utilities/detection";
import { gtmJoinClick, gtmGAEvent } from "../../utilities/gtm";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import FlareShimmer from "../global/FlareShimmer";
import Shimmer from "../../../assets/img/shimmer.png";
import { getLangCode, variousURLs } from "../../config";
import IXLLogo from "../../../assets/img/ixlLogo.svg";
import { adsSelector, isLoggedInSelector } from "../../redux/slices/loginSlice";
import MultiBrand from "./MultiBrand";

export default function Copyright() {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const ads = useSelector(adsSelector);

  const currentYear = new Date().getFullYear();
  const loggedStyle = isLoggedIn ? "" : "loggedin";
  const maStyle = ads && mobile ? " mobileFixedPadding" : "";

  return (
    <div className={`footer-copyright ${loggedStyle}${maStyle}`}>
      <OptionallyDisplayed doDisplay={!isLoggedIn}>
        <button
          type="button"
          className="footer-copyright-join premium-join"
          onClick={() => {
            gtmJoinClick(location.pathname, "copyright-button");
            navigate(routeCodes.SUBSCRIBE);
          }}
        >
          <FlareShimmer filePath={Shimmer} />
          {getString("join.7", { html: true })}
        </button>
      </OptionallyDisplayed>

      <div className="footer-copyright-ixl">
        <a
          onClick={() => {
            gtmGAEvent({
              eventCategory: "IXL footer",
              eventAction: "button-click",
              eventLabel: location.pathname,
            });
          }}
          href={`${
            variousURLs.ixl.base[getLangCode()]
          }/?partner=abcya&adGroup=footer&utm_source=abcya.com&utm_medium=partner&utm_campaign=footer`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={IXLLogo} loading="lazy" alt={getString("ixl.title")} width="130" height="48" />
        </a>
        <div>{getString("taglineIxl")}</div>
      </div>

      <MultiBrand />

      <a href={variousURLs.kidsafe} target="_blank" rel="noopener noreferrer">
        <img
          border="0"
          src={variousURLs.kidsafeImg}
          loading="lazy"
          width="154"
          height="56"
          alt={getString("footer.kidsafe.alt")}
          className="footer-copyright-kidsafe"
        />
      </a>

      <div className="footer-copyright-text">{getString("copyright", { replace: [currentYear] })}</div>
    </div>
  );
}
