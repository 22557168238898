import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Provider as GqlProvider } from "urql";
import { onLCP, onFCP, onCLS, onTTFB } from "web-vitals/attribution";

import AdProvider from "./context/adContext";
import App from "./components/app";
import { client } from "./config/client";

import "../stylesheets/main.scss";
import store from "./redux/store";
import { gtmCls, gtmFcp, gtmLcp, gtmTtfb } from "./utilities/gtm";

// Google Tag Manger Items
window.dataLayer = window.dataLayer || [];

// eslint-disable-next-line
window.local_data = {};
const container = document.getElementById("root");
const root = createRoot(container);

onLCP(gtmLcp);
onFCP(gtmFcp);
onCLS(gtmCls);
onTTFB(gtmTtfb);

root.render(
  <GqlProvider value={client}>
    <BrowserRouter>
      <Provider store={store()}>
        <AdProvider>
          <App />
        </AdProvider>
      </Provider>
    </BrowserRouter>
  </GqlProvider>,
);
