import React from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
import routeCodes from "../../config/routeCodes";

export default function SortButton({ text, to }) {
  const location = useLocation();
  const isDefault =
    [routeCodes.STANDARDS_GAMES, routeCodes.STANDARDS_PRINTABLES].every(
      (sort) => !location.pathname.startsWith(sort),
    ) && to === routeCodes.STANDARDS;

  return (
    <NavLink
      type="button"
      to={to}
      end
      className={({ isActive }) => `button-flat-color uppercase pt-standards ${isActive || isDefault ? "active" : ""}`}
    >
      {text}
    </NavLink>
  );
}

SortButton.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
