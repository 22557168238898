import { isMobileSafari } from "react-device-detect";
import { offset } from "./";

const animate = (callbackObj, duration) => {
  const requestAnimationFrame =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame;
  let startTime = 0;
  let percentage = 0;
  let animationTime = 0;

  const newDuration = duration * 1000 || 1000;

  const animation = function (timestamp) {
    if (startTime === 0) {
      startTime = timestamp;
    } else {
      animationTime = timestamp - startTime;
    }

    if (typeof callbackObj.start === "function" && startTime === timestamp) {
      callbackObj.start();

      requestAnimationFrame(animation);
    } else if (animationTime < newDuration) {
      if (typeof callbackObj.progress === "function") {
        percentage = animationTime / newDuration;
        callbackObj.progress(percentage);
      }

      requestAnimationFrame(animation);
    } else if (typeof callbackObj.done === "function") {
      callbackObj.done();
    }
  };

  return requestAnimationFrame(animation);
};

const easeInOutQuad = (t) => {
  // console.log('t: ', t)
  const p = t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  // console.log('p: ', p)
  return p;
};

const easeOutQuad = (t) => {
  // console.log('t: ', t)
  const p = 1 - (1 - t) * (1 - t);
  // console.log('p: ', p)
  return p;
};

export const horizontalScroll = (elem, rangeInPixels) => {
  // elem.scrollLeft += rangeInPixels
  if (elem) {
    const sequenceObj = {};
    const seconds = 0.5;
    const startingScrollPosition = elem.scrollLeft;

    sequenceObj.progress = (percentage) => {
      // eslint-disable-next-line
      elem.scrollLeft = startingScrollPosition + easeInOutQuad(percentage) * rangeInPixels;
    };

    animate(sequenceObj, seconds);
  }
};

export const verticalScroll = (elem, rangeInPixels) => {
  // elem.scrollTop += rangeInPixels
  if (elem) {
    const sequenceObj = {};
    const seconds = 0.5;
    const startingScrollPosition = elem.scrollTop;

    sequenceObj.progress = (percentage) => {
      // eslint-disable-next-line
      elem.scrollTop = startingScrollPosition + easeOutQuad(percentage) * rangeInPixels;
      // console.log('elem.scrollTop: ', elem.scrollTop)
    };

    animate(sequenceObj, seconds);
  }
};

export const scrollDocVerticallyToTarget = (target) => {
  if (target) {
    const header = document.getElementById("header");
    const cookieBanner = document.getElementById("cookiebanner");

    const targetOffest = offset(target);

    const headerHeight = header.getBoundingClientRect().height;

    const cbHeight = cookieBanner ? cookieBanner.getBoundingClientRect().height : 0;

    const offsetTop = targetOffest.top - (headerHeight + cbHeight);
    const elem = isMobileSafari ? document.scrollingElement : document.documentElement;

    verticalScroll(elem, offsetTop);
  } else {
    console.log("scrollDocVerticallyToTarget no target");
  }
};
