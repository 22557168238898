import React from "react";
import PropTypes from "prop-types";

export default function PlansPromoDisclaimer({ promo, pRole }) {
  if (promo && promo.description && promo.planGroups.includes(pRole.toUpperCase())) {
    return <div className="plans-promo-disclaimer">{`*${promo.disclaimer}`}</div>;
  }

  return null;
}

PlansPromoDisclaimer.propTypes = {
  pRole: PropTypes.string,
  promo: PropTypes.object,
};
