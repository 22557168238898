import React from "react";
import PropTypes from "prop-types";

export default function WebP({ secret = "", fallbackSecret, imgProps = {} }) {
  // Images without the webp prefix have not been reuploaded with webp image upload change, which creates webp files alongside jpg. This prefix demarcates the change.
  const extensionPattern = /\.[a-z]+$/;
  const hasWebP = secret?.search(/\.?webp_?/) > 0;
  let extension = secret.match(extensionPattern) || [""];
  extension = extension[0];
  const secretSansExtension = extension ? secret.split(extension)[0] : secret;

  const renderWebP = () => {
    if (hasWebP) {
      return <source srcSet={`${secretSansExtension}.webp`} type="image/webp" />;
    }
    return null;
  };

  return (
    <picture>
      {renderWebP()}
      <img
        className="webp-image"
        src={fallbackSecret ? fallbackSecret : secret.replace(extensionPattern, ".jpg")}
        {...imgProps}
      />
    </picture>
  );
}

WebP.propTypes = {
  secret: PropTypes.string,
  fallbackSecret: PropTypes.string,
  // Props other than src for the img fallback.
  imgProps: PropTypes.object,
};
