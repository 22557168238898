/* meta tags and schema scripts for SEO/Search */

import React from "react";
import Helmet from "react-helmet";
import { getSchema, getMeta } from "../utilities/head";
import { getString } from "../utilities";
import { getAbcyaUrl, getLangCode, language, variousURLs } from ".";

/* Main App Default Templates */
export const metaMain = () => {
  return (
    <Helmet
      encodeSpecialCharacters
      defaultTitle={getString("head.defaultTitle")}
      titleTemplate={getString("head.titleTemplate")}
    />
  );
};

/* ABCya Default - supply a title or let it use default */
export const metaDefault = ({ path, title, links, noCrawl, notFound }) => {
  const metaTitle = title || "";

  const schemaName = title && title.length > 0 ? title : getString("head.defaultTitle");

  const linksArray = links || [];

  const metaArray = [
    { name: "keywords", content: getString("head.keywords") },
    { name: "description", content: getString("head.description") },
    { property: "og:title", content: getString("head.schemaTitle", { replace: [schemaName] }) },

    { property: "og:description", content: getString("head.description") },
    { property: "og:url", content: `${getAbcyaUrl()}${path}` },
    { property: "og:type", content: "website" },
    { property: "og:site_name", content: getString(getLangCode() === language.default ? "abcya.1" : "abcya.0") },
    { property: "og:image", content: variousURLs.logo[getLangCode()] },
  ];

  if (noCrawl) {
    metaArray.push({ name: "robots", content: "noindex,nofollow" });
  } else {
    metaArray.push({ name: "robots", content: "max-image-preview:large" });
  }

  if (notFound) {
    // prerender.io special meta tag
    // https://docs.prerender.io/docs/11-best-practices
    metaArray.push({
      name: "prerender-status-code",
      content: "404",
    });
  }

  const scriptArray = noCrawl
    ? []
    : getSchema({
        type: "WebSite",
        name: getString("head.schemaTitle", { replace: [schemaName] }),
        description: getString("head.description"),
        keywords: getString("head.keywords"),
        url: path,
      });

  return <Helmet title={metaTitle} link={linksArray} script={scriptArray} meta={getMeta(metaArray)} />;
};

/* Specific Type and Data Supplied (games, grades, etc.) */
export const metaType = (type, data, path, links) => {
  const linksArray = links || [];

  const image = Object.prototype.hasOwnProperty.call(data, "image") ? data.image : variousURLs.logo[getLangCode()];

  const metaArray = [
    { name: "keywords", content: data.metaKeywords },
    { name: "description", content: data.metaDescription },

    { property: "og:title", content: getString("head.schemaTitle", { replace: [data.metaTitle] }) },
    { property: "og:description", content: data.metaDescription },
    { property: "og:url", content: `${getAbcyaUrl()}${path}` },
    { property: "og:type", content: type },
    { property: "og:site_name", content: getString(getLangCode() === language.default ? "abcya.1" : "abcya.0") },
    { property: "og:image", content: image },
  ];

  if (data.noCrawl) {
    metaArray.push({ name: "robots", content: "noindex,nofollow" });
  } else {
    metaArray.push({ name: "robots", content: "max-image-preview:large" });
  }

  const schema = {
    type,
    name: getString("head.schemaTitle", { replace: [data.metaTitle] }),
    description: data.metaDescription,
    keywords: data.metaKeywords,
    url: path,
  };

  const scriptArray = data.noCrawl ? [] : getSchema(schema);

  return <Helmet title={data.metaTitle} link={linksArray} script={scriptArray} meta={getMeta(metaArray)} />;
};
