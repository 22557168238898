import React from "react";
import { useLocation } from "react-router-dom";
import { metaDefault } from "../../config/meta";
import { getString } from "../../utilities";
import aboutStrings from "../../strings/about";

export default function Payterms() {
  const location = useLocation();

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("payterms.title.0") })}

      <div className="payterms">
        <div className="wrapper padded">
          <div className="container">
            <h1>{getString("payterms.title.0")}</h1>
          </div>
        </div>

        <div className="wrapper padded">
          <div className="container-medium">{getString("payterms.body", { html: true, strings: aboutStrings })}</div>
        </div>
      </div>

      <div className="wrapper header-shadow" />
    </>
  );
}
