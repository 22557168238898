import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "./modal";
import ModalClose from "./modalClose";
import { getString } from "../../utilities";
import { isCrawler, isPrerender } from "../../utilities/detection";
import { routeCodes } from "../../config/routes";
import Arrow from "../../../assets/svg/arrowRight.svg";
import { upgradeModalSelector, upgradeModalToggle } from "../../redux/slices/loginSlice";

export default function UpgradePremiumModal() {
  const upgradeModal = useSelector(upgradeModalSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!isCrawler && !isPrerender) {
    return (
      <Modal doShow={upgradeModal}>
        <ModalClose close={() => dispatch(upgradeModalToggle())} />

        <div className="modal-content-header">{getString("upgrade.title.1")}</div>
        <p>{getString("upgrade.message.0")}</p>

        <button
          type="button"
          onClick={() => {
            dispatch(upgradeModalToggle());
            navigate(`${routeCodes.MANAGE}#subscription`);
          }}
          className="button-flat-color pt-green round auto"
        >
          {getString("manage.title.0")}
          <Arrow className="inline-arrow" />
        </button>
      </Modal>
    );
  }
  return null;
}
