import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { metaType } from "../../config/meta";
import { blogPosts, contentTypes } from "../../config";
import { getString, getSuggestions, sortedArrayByItem } from "../../utilities";
import { searchArray } from "../../utilities/search";
import LoadingAnim from "../global/loadingAnim";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import BlogMinibanner from "./blogMinibanner";
import SearchBox from "../global/SearchBox";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";

export default function Blog() {
  const { posts } = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);

  const [searchedPosts, setSearchedPosts] = useState(null);
  const [searching, setSearching] = useState(false);
  const [postId, setPostId] = useState(0);
  const [suggestions, setSuggestions] = useState([]);

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(
      getSuggestions(value, posts, "title")
        .map((item) => item.title)
        .sort(),
    );
  };

  const location = useLocation();

  const renderFeaturedPost = () => {
    if (loading) {
      return <LoadingAnim />;
    }

    if (searchedPosts) {
      return null;
    }

    const featuredPost = sortedArrayByItem(posts, "postDate", true).filter((p) => p.postDate)[0];

    return (
      <>
        <div className="pt-date-caption">{getString("blog.featured")}</div>
        <BlogMinibanner post={featuredPost} isFeatured={true} />
      </>
    );
  };

  const renderPosts = () => {
    if (!loading && posts) {
      const renderId = postId + 1 + blogPosts.initial + postId * (blogPosts.additional - 1);
      const recentPosts = sortedArrayByItem(posts, "postDate", true).slice(1, renderId);
      // const postsToRender = searchedPosts || recentPosts

      // if searchedPosts is null and posts length > 1
      // render recent entries title and mini banners
      if (!searchedPosts && recentPosts.length > 1) {
        // minus 1 because we splice the first one off for "featured aka most recent post date"
        const showMore = !searchedPosts && recentPosts.length < posts.length - 1;

        return (
          <div className="wrapper padded blog-posts-wrapper">
            <h2>{getString("blog.recentTitle")}</h2>

            <div className="container-medium blog-posts-container">
              {recentPosts.map((post, index) => {
                const pmbKey = `pmbKey${index}`;
                return <BlogMinibanner key={pmbKey} post={post} />;
              })}

              <OptionallyDisplayed doDisplay={showMore}>
                <div className="blog-more-container">
                  <button
                    type="button"
                    className="button-flat-color pt-cyan round auto"
                    onClick={() => {
                      setPostId((prev) => prev + 1);
                    }}
                  >
                    {getString("navigation.more.1")}
                  </button>
                </div>
              </OptionallyDisplayed>
            </div>
          </div>
        );
      }

      if (searchedPosts) {
        // if searchedPosts is an empty array (length 0)
        // render no results message
        if (searchedPosts.length > 0) {
          // else searchedPosts is greater than 0
          // render results title and mini banners
          const searchedTitle = <h2>{getString("searchResults.0")}</h2>;

          const searchedContent = searchedPosts.map((post, index) => {
            const pmbKey = `pmbKey${index}`;
            return <BlogMinibanner key={pmbKey} post={post} />;
          });

          return (
            <div className="wrapper padded blog-posts-wrapper">
              {searchedTitle}

              <div className="container-medium blog-posts-container">{searchedContent}</div>
            </div>
          );
        } else {
          return <div className="search-noResults wrapper padded">{getString("search.noresults")}</div>;
        }
      }
    }

    return null;
  };

  const renderContent = () => {
    if (posts && posts.length > 0) {
      const showSearch = !loading && posts && posts.length > 0;

      if (loading) {
        return (
          <div className="wrapper padded">
            <LoadingAnim className="background-white" />
          </div>
        );
      }

      return (
        <>
          <div className="wrapper padded visible">
            <SearchBox
              searched={searchedPosts}
              setSearched={setSearchedPosts}
              setSearching={setSearching}
              search={async (searchQuery) => await searchArray(posts, searchQuery, contentTypes.blog)}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              suggestions={suggestions}
              setSuggestions={setSuggestions}
              showSearch={showSearch}
              placeholder={getString("blog.search")}
            />

            <div className="container-medium blog-medium-container">{renderFeaturedPost()}</div>
          </div>

          <OptionallyDisplayed doDisplay={!loading && !(posts && searching)}>{renderPosts()}</OptionallyDisplayed>
          <OptionallyDisplayed doDisplay={loading || (posts && searching)}>
            <LoadingAnim />
          </OptionallyDisplayed>
        </>
      );
    }

    return null;
  };

  return (
    <>
      {metaType(
        "Blog",
        {
          metaTitle: getString("blog.title.0"),
          metaDescription: getString("head.description"),
          metaKeywords: getString("head.keywords"),
        },
        location.pathname,
      )}

      <div className="blog">
        <div className="wrapper padded">
          <div className="container">
            <h1>{getString("blog.title.0")}</h1>
          </div>
        </div>

        {renderContent()}
      </div>
      <div className="wrapper header-shadow" />
    </>
  );
}
