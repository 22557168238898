import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "urql";

import { useLazyQuery } from "../../hooks";
import { getString, handlePostRequest } from "../../utilities";
import { GET_EMAIL_SUBSCRIBER_STATUS } from "../../graphql/queries";
import LoadingAnim from "../global/loadingAnim";
import { SET_EMAIL_SUBSCRIBER_STATUS } from "../../graphql/mutations";
import OKModal from "../global/okModal";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import ErrorMessage from "../global/errorMessage";
import { metaDefault } from "../../config/meta";

const unsubStatus = "Unsubscribed";

export default function EmailPreferences() {
  const [searchParams] = useSearchParams();
  const linkType = searchParams.get("utm");
  const { subscriberKey } = useParams();
  const userId = subscriberKey ? subscriberKey.match(/(?:(?!_))[a-zA-Z0-9]+$/)[0] : "";
  const [showSubscriberNotFound, setShowSubscriberNotFound] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const location = useLocation();

  const methods = useForm({ defaultValues: { status: unsubStatus } });
  const { handleSubmit, register, setValue } = methods;

  const [result, setSubscriberStatus] = useMutation(SET_EMAIL_SUBSCRIBER_STATUS);
  const { fetching: submitting } = result;

  const onSetStatusCompleted = (data) => {
    if (data?.setEmailSubscriberStatus?.status === null) {
      setShowSubscriberNotFound(true);
    } else {
      setValue("status", data?.setEmailSubscriberStatus?.status);
      setShowSuccessModal(true);
    }
  };

  const onError = (error) => {
    setSubmitErrors({ message: error.message });
  };

  const onRetrieveError = () => {
    setShowSubscriberNotFound(true);
  };

  const { executeQuery: retrieveSubscriber, loading: retrieveLoading } = useLazyQuery(
    GET_EMAIL_SUBSCRIBER_STATUS,
    (data) => {
      if (data?.getEmailSubscriberStatus?.status === null) {
        setShowSubscriberNotFound(true);
        return;
      }
      if (linkType === "footer_unsub_link") {
        setSubscriberStatus({ id: userId, status: unsubStatus }).then((result) =>
          handlePostRequest(result, onSetStatusCompleted, onError),
        );
      } else {
        setValue("status", data?.getEmailSubscriberStatus?.status);
      }
    },
    onRetrieveError,
  );

  useEffect(() => {
    if (!subscriberKey) {
      setShowSubscriberNotFound(true);
    } else {
      retrieveSubscriber({ id: userId });
    }
  }, []);

  const renderForm = () => {
    return (
      <div className="container email-preferences-container">
        <OptionallyDisplayed doDisplay={!!submitErrors}>
          <ErrorMessage error={submitErrors} />
        </OptionallyDisplayed>
        <p>{getString("emailPreferences.instructions")}</p>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit((data) => {
              setSubmitErrors(null);
              setSubscriberStatus({ id: userId, status: data.status }).then((result) =>
                handlePostRequest(result, onSetStatusCompleted, onError),
              );
            })}
          >
            <div className="email-preferences-options">
              <label htmlFor="active" className="radio-button">
                <input {...register("status")} type="radio" value="Active" id="active" />
                {getString("emailPreferences.form.subscribe")}
              </label>
              <label htmlFor="unsubscribed" className="radio-button">
                <input {...register("status")} type="radio" value={unsubStatus} id="unsubscribed" />
                {getString("emailPreferences.form.unsubscribe")}
              </label>
            </div>
            <button className="button-flat-color pt-green" type="submit">
              {getString("submit.1")}
            </button>
          </form>
        </FormProvider>
      </div>
    );
  };

  const loading = retrieveLoading || submitting;

  const renderContent = () => {
    if (showSubscriberNotFound) {
      return (
        <div className="email-preferences-container" data-testid="subscriber-not-found">
          <p>{getString("emailPreferences.notFound", { html: true })}</p>
        </div>
      );
    } else if (loading) {
      return <LoadingAnim />;
    }
    return renderForm();
  };

  return (
    <>
      {metaDefault({ title: getString("emailPreferences.title.1"), path: location.pathname, noCrawl: true })}
      <div className="wrapper padded">
        <div className="container">
          <h1>{getString("emailPreferences.title.0")}</h1>
          {renderContent()}
        </div>
        <OKModal
          doShow={showSuccessModal}
          close={() => setShowSuccessModal(false)}
          title={getString(
            `emailPreferences.success.title.${
              result?.data?.setEmailSubscriberStatus?.status === "Active" ? "subscribed" : "unsubscribed"
            }`,
          )}
        >
          <p>
            {getString(
              `emailPreferences.success.message.${
                result?.data?.setEmailSubscriberStatus?.status === "Active" ? "subscribed" : "unsubscribed"
              }`,
            )}
          </p>
        </OKModal>
      </div>
    </>
  );
}
