import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import aboutStrings from "../../strings/about";

import teamAlanLisa from "../../../assets/img/teamAlanLisa.jpg";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";

export default function Founder() {
  const dataLoading = useSelector(loadingSelector);
  const data = useSelector(dataSelector);

  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState(null);

  useEffect(() => {
    if (!dataLoading && data.games && data.games !== games) {
      setLoading(dataLoading);
      setGames(data.games);
    }
  }, [loading, data]);

  const renderFavorite = (favGame) => {
    const favGameObj = games.find((g) => g.shortname === favGame);
    const { fullname: favName, localeShortname } = favGameObj || {};

    if (favName && favName.length > 0) {
      return (
        <div className="team-member-text-favorite">
          <div>{getString("team.favorite", { strings: aboutStrings })}</div>
          <Link className="team-member-text-game" to={`${routeCodes.GAMES}${localeShortname || favGame}`}>
            {favName}
          </Link>
        </div>
      );
    }

    return null;
  };

  if (loading) {
    return null;
  }

  return (
    <div className="team-member-double">
      <img className="team-member-artwork" src={teamAlanLisa} alt="Alan and Lisa" width="100%" height="auto" />
      <div className="team-member-column-container">
        <div className="team-member-column-container-column">
          <div className="team-member-text-name">ALAN</div>
          <div className="team-member-text-job">
            <div>{getString("team.jobs.management.0", { strings: aboutStrings })}</div>
          </div>
          {renderFavorite("pizza")}
        </div>

        <div className="team-member-column-container-column">
          <div className="team-member-text-name">LISA</div>
          <div className="team-member-text-job">
            <div>{getString("team.jobs.management.1", { strings: aboutStrings })}</div>
          </div>
          {renderFavorite("hold_the_phone")}
        </div>
      </div>
    </div>
  );
}
