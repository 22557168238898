import React from "react";
import PropTypes from "prop-types";
import X from "../../../assets/svg/x.svg";

export default function ModalClose({ close }) {
  return (
    <a data-testid="modal-close-anchor" className="modal-close" onClick={close}>
      <X />
    </a>
  );
}

ModalClose.propTypes = {
  close: PropTypes.func.isRequired,
};
