import React from "react";
import PropTypes from "prop-types";
import { getString, currencyConvert, isAFunction } from "../../utilities";
import { getLangCode } from "../../config";

export default function SubscriptionCard({ plan, setPlan = () => {}, setPlanAmount, subscription, planData, isBest }) {
  const getSubStyle = (p) => {
    const disabledStyle = !planData ? "disabled" : "";
    const activeStyle = plan === p ? "active" : "";
    return `sub-card ${disabledStyle} ${activeStyle}`;
  };

  const setSubscription = () => {
    if (!planData) return;

    if (isAFunction(setPlanAmount)) {
      setPlanAmount(planData?.[subscription].amount);
    }
    setPlan(subscription);
  };

  const renderBest = () => {
    if (isBest) {
      return (
        <div className={`sub-card-best ${getLangCode()}`}>
          <span className="currency">{getString("currencySymbol.usd")}</span>
          <span>{getString(`subscribe.plans.best`)}</span>
        </div>
      );
    }

    return null;
  };

  const renderCost = () => {
    const costData = planData?.[subscription];

    let costDollars = costData?.total.dollars;
    let costCents = costData?.total.cents;
    const perMonth = getString(`subscribe.plans.${subscription}.term`);
    let costTerm = `${perMonth}*`;

    if (costData?.promo) {
      costDollars = costData.promo.total.dollars;
      costCents = costData.promo.total.cents;
      costTerm = <>&nbsp;</>;
    }

    return (
      <div className="cost">
        <div className="dollars">
          <sup>{getString("currencySymbol.usd")}</sup>
          {costDollars}
        </div>

        <div className="cents-term">
          <div className="cents">{costCents}</div>
          <div className="term">{costTerm}</div>
        </div>
      </div>
    );
  };

  const renderDisclaimer = () => {
    const costData = planData?.[subscription];
    let disclaimerText = getString(`subscribe.plans.${subscription}.disclaimer.0`, {
      replace: [currencyConvert(costData?.amount, "us")],
    });

    if (costData?.promo) {
      // if subscription is m6 or m12.. not m1
      if (!subscription.endsWith("1"))
        disclaimerText = getString(`subscribe.plans.promo.disclaimer.0`, {
          replace: [currencyConvert(costData.promo.monthly.total)],
        });

      return (
        <div className="sub-card-disclaimer scd-promo">
          <div className="slashed-text scd-og-price">{currencyConvert(costData?.amount)}</div>
          <div>{`*${disclaimerText}`}</div>
        </div>
      );
    }

    return <div className="sub-card-disclaimer">{`*${disclaimerText}`}</div>;
  };

  return (
    <div
      data-testid={`subscription-card-${subscription}`}
      className={getSubStyle(subscription)}
      onClick={setSubscription}
    >
      {renderBest()}

      <div className="sub-card-title">{getString(`subscribe.plans.${subscription}.title.0`)}</div>

      <div className="sub-card-container">
        {renderCost()}

        <button type="button" className="button-flat-color pt-green">
          {getString("choose.0")}
        </button>
      </div>

      {renderDisclaimer()}
    </div>
  );
}

SubscriptionCard.propTypes = {
  plan: PropTypes.string,
  setPlan: PropTypes.func,
  setPlanAmount: PropTypes.func,
  subscription: PropTypes.string.isRequired,
  planData: PropTypes.object,
  isBest: PropTypes.bool,
};
