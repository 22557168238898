import React from "react";
import ABCyaLogo from "../../../assets/svg/abcyalogo.svg";
import TNYT from "../../../assets/img/theNewYorkTimesLogo.png";
import Apple from "../../../assets/img/appleLogo.png";
import USA from "../../../assets/img/usaTodayLogo.png";
import Parents from "../../../assets/img/parentsLogo.png";
import Scholastic from "../../../assets/img/scholasticLogo.png";
import FoxNews from "../../../assets/img/foxNewsLogo.png";

import { getString } from "../../utilities";

export default function Featured() {
  return (
    <div className="footer-featured">
      <ABCyaLogo className="footer-featured-logo" />

      <div className="footer-featured-tagline">{getString("tagline")}</div>
      {getString("footer.featured.title")}
      <ul className="footer-featured-logos">
        <li>
          <img
            src={TNYT}
            loading="lazy"
            alt={getString("footer.featured.outlets.0")}
            width="88"
            height="69"
            className="tnyt"
          />
        </li>
        <li>
          <img
            src={Apple}
            loading="lazy"
            alt={getString("footer.featured.outlets.1")}
            width="45"
            height="57"
            className="apple"
          />
        </li>
        <li>
          <img
            src={USA}
            loading="lazy"
            alt={getString("footer.featured.outlets.2")}
            width="128"
            height="47"
            className="usa"
          />
        </li>
        <li>
          <img
            src={Parents}
            loading="lazy"
            alt={getString("footer.featured.outlets.3")}
            width="92"
            height="25"
            className="parents"
          />
        </li>
        <li>
          <img
            src={Scholastic}
            loading="lazy"
            alt={getString("footer.featured.outlets.4")}
            width="125"
            height="56"
            className="scholastic"
          />
        </li>
        <li>
          <img
            src={FoxNews}
            loading="lazy"
            alt={getString("footer.featured.outlets.5")}
            width="67"
            height="54"
            className="fox"
          />
        </li>
      </ul>
    </div>
  );
}
