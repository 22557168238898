import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useMutation } from "urql";
import { metaDefault } from "../../config/meta";
import { VERIFY_USER_EMAIL } from "../../graphql/mutations";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import { getString, handlePostRequest } from "../../utilities";

export default function Verify() {
  const location = useLocation();
  const { id } = useParams();
  const [submitErrors, setSubmitErrors] = useState(null);

  const resolverError = (error) => {
    console.log("verify email resolver error: ", error);

    if (error !== submitErrors) {
      setSubmitErrors(error);
      window.scrollTo(0, 0);
    }
  };

  const onCompleted = (data) => {
    if (!data || !data.verifyUserEmail || !data.verifyUserEmail.verified) {
      setSubmitErrors({ message: getString("verify.notVerified", { html: true }) });
    }
  };
  const [result, verifyUserEmail] = useMutation(VERIFY_USER_EMAIL);
  const { fetching: loading } = result;

  useEffect(() => {
    // did mount
    verifyUserEmail({ id }).then((result) => handlePostRequest(result, onCompleted, resolverError));
  }, []);

  const renderContent = () => {
    if (loading) {
      return (
        <LoadingAnim>
          <h4>{getString("verify.verifying")}</h4>
        </LoadingAnim>
      );
    }

    if (submitErrors) {
      return <ErrorMessage error={submitErrors} />;
    }

    return (
      <>
        <p>{getString("verify.thankyou")}</p>
        <p>{getString("verify.login", { html: true })}</p>
      </>
    );
  };

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("verify.metaTitle"), noCrawl: true })}

      <div className="verify wrapper padded">
        <h1>{getString("verify.title")}</h1>

        <div className="container">{renderContent()}</div>
      </div>

      <div className="wrapper header-shadow" />
    </>
  );
}
