import React, { useState } from "react";
import PropTypes from "prop-types";

import { currencyConvert, getString } from "../../utilities";
import { giftcardMax } from "../../config";
import GiftCardField from "./giftCardField";
import OptionallyDisplayed from "./optionallyDisplayed";

export default function GiftCards({
  total,
  setTotal,
  setGiftCardNotApplied,
  giftCards,
  setGiftCards,
  isDisabled = false,
}) {
  const [redeemed, setRedeemed] = useState(0);
  const [numChildren, setNumChildren] = useState(1);

  const addChild = () => {
    setNumChildren((prev) => prev + 1);
  };

  const renderChildren = () => {
    const children = [];

    for (let i = 0; i < numChildren; i++) {
      children.push(
        <GiftCardField
          setGiftCardNotApplied={setGiftCardNotApplied}
          key={i}
          index={i + 1}
          total={total}
          setTotal={setTotal}
          giftCards={giftCards}
          setGiftCards={setGiftCards}
          setNumRedeemed={setRedeemed}
          isDisabled={isDisabled}
        />,
      );
    }

    return children;
  };

  // if redeemed is greater than 1 and total is < giftcardMax
  // show add another button/verbiage
  const canRedeem = total < giftcardMax;
  const showTotal = total > 0;
  const showMore = canRedeem && redeemed === numChildren;

  return (
    <div className="giftcards">
      {renderChildren()}

      <OptionallyDisplayed doDisplay={showTotal}>
        <div className="giftcards-total">{getString("giftcard.total.0", { replace: [currencyConvert(total)] })}</div>
      </OptionallyDisplayed>

      <OptionallyDisplayed doDisplay={showMore}>
        <button type="button" onClick={addChild} className="button-flat-color pt-steel round auto plus">
          {getString("giftcard.another")}
        </button>
      </OptionallyDisplayed>
    </div>
  );
}

GiftCards.propTypes = {
  total: PropTypes.number.isRequired,
  setTotal: PropTypes.func.isRequired,
  setGiftCardNotApplied: PropTypes.func.isRequired,
  giftCards: PropTypes.array.isRequired,
  setGiftCards: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};
