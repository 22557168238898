import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "urql";
import { metaDefault } from "../../config/meta";
import { FORGOT_PASSWORD_MUTATION } from "../../graphql/mutations";
import { emailReg, getMaxLengthValidator } from "../../utilities/forms/rules";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import { EMAIL_CHAR_LIMIT, getLangCode, language, recaptchaSiteKey } from "../../config";
import { getString, handlePostRequest } from "../../utilities";
import TextViewHook from "../global/textViewHook";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";
import { useLazyQuery } from "../../hooks";

export default function Forgot() {
  const action = "Forgot_Password_Form_Submission";
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getVariables = () => {
    return getValues();
  };

  const onPwCompleted = (data) => {
    const { forgotPassword } = data;
    if (forgotPassword && forgotPassword.success) {
      setSuccess(forgotPassword.success);
      setSubmitting(false);
    }
  };

  const onPwError = (error) => {
    setSubmitting(false);
    setSubmitErrors({ message: error.message });
  };

  const [result, forgotPassword] = useMutation(FORGOT_PASSWORD_MUTATION);
  const { fetching: loading } = result;

  const onCompleted = async ({ reCaptchaVerify }) => {
    if (reCaptchaVerify) {
      if (reCaptchaVerify.success) {
        // verified
        // execute forgot mutation
        const variables = getVariables();

        if (variables) {
          forgotPassword(variables).then((result) => handlePostRequest(result, onPwCompleted, onPwError));
        }
      } else {
        // not verified
        setSubmitting(false);
        setSubmitErrors({ message: reCaptchaVerify.message });
      }
    }
  };

  const onError = (error) => {
    setSubmitting(false);
    setSubmitErrors({ message: error.message });
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onCompleted,
    onError,
  );

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("forgot.metaTitle"), noCrawl: true })}

      <div className="forgot">
        <div className="wrapper padded">
          <h1>{getString("forgot.title")}</h1>
          <div className="container-small">
            {success ? getString("forgot.caption.1", { html: true }) : getString("forgot.caption.0", { html: true })}
          </div>
        </div>
        <OptionallyDisplayed doDisplay={!success}>
          <div className="wrapper padded">
            <div className="container-small">
              <form
                onSubmit={handleSubmit(async (data) => {
                  if (!submitting) {
                    setSubmitting(true);

                    window.grecaptcha.ready(() => {
                      window.grecaptcha.execute(recaptchaSiteKey, { action }).then((token) => {
                        // query with token
                        recaptchaVerify({ token, action, verify: false });
                      });
                    });
                  }
                })}
              >
                <FormProvider register={register}>
                  <h2>{getString("forgot.subtitle")}</h2>

                  <OptionallyDisplayed doDisplay={!!submitErrors}>
                    <ErrorMessage error={submitErrors} />
                  </OptionallyDisplayed>

                  <TextViewHook
                    name="email"
                    placeholder={
                      getLangCode() === language.la ? getString("forms.email.2") : getString("forms.email.0")
                    }
                    errors={errors}
                    rules={{
                      required: true,
                      pattern: {
                        value: emailReg,
                        message: getString("forms.errorMessages.email.0", {
                          replace: [getString("forms.email.3")],
                        }),
                      },
                      maxLength: getMaxLengthValidator(getString("forms.email.3"), EMAIL_CHAR_LIMIT),
                    }}
                  />

                  <button
                    type="submit"
                    disabled={recaptchaLoading || loading || submitting}
                    className="button-flat-color pt-green"
                  >
                    {getString("submit.1")}
                  </button>

                  <div className="form-terms">{getString("recaptcha", { html: true })}</div>
                </FormProvider>
              </form>

              {(recaptchaLoading || loading || submitting) && (
                <LoadingAnim position="absolute">
                  <h4>{getString("forgot.loading")}</h4>
                </LoadingAnim>
              )}
            </div>
          </div>
        </OptionallyDisplayed>
      </div>
      <div className="wrapper header-shadow" />
    </>
  );
}
