import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getString } from "../../utilities";
import Modal from "./modal";
import ModalClose from "./modalClose";
import { showPrintSaveErrorModalSelector, toggleShowPrintSaveErrorModal } from "../../redux/slices/printSaveSlice";
import { cleanupPrintSave } from "../../utilities/print";

export default function PrintSaveErrorModal() {
  const showErrorModal = useSelector(showPrintSaveErrorModalSelector);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(toggleShowPrintSaveErrorModal());
    cleanupPrintSave("failed");
  };

  return (
    <Modal doShow={showErrorModal} closeOnEscape={close}>
      <ModalClose close={close} />
      <div className="modal-content-header">{getString("print.error.sorry")}</div>
      <p>{getString("print.error.message")}</p>

      <button type="button" className="button-flat-color pt-green" onClick={close}>
        {getString("ok")}
      </button>
    </Modal>
  );
}
