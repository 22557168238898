import React from "react";
import { Link } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { printableCategoryNavNames, printableCategories } from "../../config/categories";
import { getLangCode } from "../../config";

export default function PrintablesNav() {
  const renderNavLinks = () => {
    return printableCategoryNavNames.map((navName) => {
      const printableObject = printableCategories.find(
        ({ shortname, localeShortname }) => localeShortname === navName || shortname === navName,
      );

      if (printableObject) {
        return (
          <Link
            key={navName}
            to={`${routeCodes.CATEGORYPRINTABLES}${navName}`}
            className={`printablesnav-link ${printableObject.shortname} ${getLangCode()}`}
            data-testid="printable-nav-link"
          >
            {printableObject.name}
          </Link>
        );
      }

      return null;
    });
  };

  return (
    <div className="printablesnav">
      <div className="printablesnav-container">{renderNavLinks()}</div>
    </div>
  );
}
