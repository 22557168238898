import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { mobile } from "../../utilities/detection";
import { getString, isLoggedInWithoutRole, isLoggedInWithRole, fullscreenSupported } from "../../utilities";
import { userRoles } from "../../config";
import { fullScreen } from "../../utilities/games";
import Modal from "../global/modal";
import ModalClose from "../global/modalClose";
import ErrorMessage from "../global/errorMessage";
import Arrow from "../../../assets/svg/arrowRight.svg";
import { gtmJoinClick } from "../../utilities/gtm";
import { setFullScreenActive, toggleFullScreenActive } from "../../redux/slices/userClientSlice";

export default function FullScreen() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);

  const fullScreenHandler = () => {
    dispatch(toggleFullScreenActive());
  };

  const addEventListeners = () => {
    if (document.fullscreenEnabled) {
      document.addEventListener("fullscreenchange", fullScreenHandler, false);
    } else if (document.webkitFullscreenEnabled) {
      document.addEventListener("webkitfullscreenchange", fullScreenHandler, false);
    } else if (document.mozFullScreenEnabled) {
      document.addEventListener("mozfullscreenchange", fullScreenHandler, false);
    } else if (document.msFullscreenEnabled) {
      document.addEventListener("MSFullscreenChange", fullScreenHandler, false);
    }
  };

  const removeEventListeners = () => {
    if (document.fullscreenEnabled) {
      document.removeEventListener("fullscreenchange", fullScreenHandler, false);
    } else if (document.webkitFullscreenEnabled) {
      document.removeEventListener("webkitfullscreenchange", fullScreenHandler, false);
    } else if (document.mozFullScreenEnabled) {
      document.removeEventListener("mozfullscreenchange", fullScreenHandler, false);
    } else if (document.msFullscreenEnabled) {
      document.removeEventListener("MSFullscreenChange", fullScreenHandler, false);
    }

    dispatch(setFullScreenActive({ fullScreenActive: false }));
  };

  useEffect(() => {
    addEventListeners();
    return removeEventListeners;
  }, []);

  if (!mobile) {
    return (
      <div className="gv-title-buttons-container">
        <button
          type="button"
          className="button-dimensional-icon fullscreen"
          onClick={async () => {
            if (isLoggedInWithoutRole(userRoles.free)) {
              if (fullscreenSupported()) {
                const ifrm = document.getElementById("desktopGame");
                if (ifrm) {
                  fullScreen.go(ifrm);
                } else {
                  setModalMessage(
                    <>
                      <div className="modal-content-header">{getString("error.0")}</div>
                      <ErrorMessage error={{ message: "No game id available." }} />
                    </>,
                  );
                  setModal(true);
                }
              } else {
                // show modal with message to use browser that supports full screen
                setModalMessage(
                  <>
                    <div className="modal-content-header">{getString("game.fullscreen.incompatible.title")}</div>
                    <p>{getString("game.fullscreen.incompatible.message")}</p>
                  </>,
                );
                setModal(true);
              }
            } else {
              // show modal to join now if not logged in
              setModalMessage(
                <>
                  <div className="modal-content-header">{getString("join.0")}</div>
                  <p>{getString("game.fullscreen.signup")}</p>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        navigate(routeCodes.SUBSCRIBE);
                        gtmJoinClick(location.pathname, "game-fullscreen-modal");
                      }}
                      className="button-flat-color pt-green round auto"
                    >
                      {getString("join.3")}
                      <Arrow className="inline-arrow" />
                    </button>
                  </div>
                </>,
              );

              // show modal message to upgrade if logged in and free/public
              if (isLoggedInWithRole(userRoles.free)) {
                setModalMessage(
                  <>
                    <div className="modal-content-header">{getString("join.0")}</div>
                    <p>{getString("game.fullscreen.upgrade")}</p>
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          navigate(routeCodes.MANAGE);
                        }}
                        className="button-flat-color pt-green round auto"
                      >
                        {getString("manage.title.0")}
                        <Arrow className="inline-arrow" />
                      </button>
                    </div>
                  </>,
                );
              }
              setModal(true);
            }
          }}
        >
          <span>{getString("game.fullscreen.title.0")}</span>
        </button>

        <Modal doShow={modal} closeOnEscape={() => setModal((prev) => !prev)}>
          <ModalClose close={() => setModal((prev) => !prev)} />

          {modalMessage}
        </Modal>
      </div>
    );
  }

  return null;
}
