import parser from "html-react-parser";
import DOMPurify from "dompurify";
import { getAbcyaUrl, getLangCode, language, tokenName, userRoles } from "../config";
import strings from "../strings";
import store from "../redux/store";

export const manageInputCursor = (e) => {
  // manually positions the cursor in an input field
  // because value is set from state and state is changed "onChange"
  // which makes the cursor jump to the end of the input field
  const caret = e.target.selectionStart;
  const element = e.target;
  window.requestAnimationFrame(() => {
    element.selectionStart = caret;
    element.selectionEnd = caret;
  });
};

export const getDescendantProp = (obj, path) => {
  const myObj = path.split(".").reduce((acc, part) => acc && acc[part], obj);
  if (myObj) {
    return myObj;
  }
  console.warn(`Could not find obj for path ${path}`);
  return "";
};

export const purify = (dirtyString, configObj) => {
  return configObj ? DOMPurify.sanitize(dirtyString, configObj) : DOMPurify.sanitize(dirtyString);
};

export const parsePurifiedString = (cleanString) => {
  const options = {
    htmlparser2: {
      lowerCaseTags: false,
    },
  };
  return parser(cleanString, options);
};

export const purifiedParsedString = (dirtyString, configObj) => {
  const cleanString = purify(dirtyString, configObj);
  return parsePurifiedString(cleanString);
};

export const getString = (path, options = {}) => {
  const { replace = [], html, strings: myStrings = strings } = options;

  let stringObj = getDescendantProp(myStrings, path);
  const replaceChar = "^";

  replace.forEach((txt) => {
    stringObj = stringObj.replace(replaceChar, txt);
  });

  if (html) {
    return purifiedParsedString(stringObj);
  }
  return stringObj;
};

export const convertISODate = (timeStr, time) => {
  const date = new Date(timeStr);
  const day = date.getDate();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const timeString = time ? ` @ ${hours}:${minutes}` : "";

  return `${month}/${day}/${year}${timeString}`;
};

export const convertEPOCHDate = (epochDate) => {
  const date = new Date(epochDate * 1000); // multiply by 1000 for milliseconds
  const day = date.getDate();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  // default en_US - "en"
  let dateString = `${month}/${day}/${year}`;

  if (getLangCode() === language.la) {
    // es_US - "la"
    dateString = `${day}/${month}/${year}`;
  }

  return dateString;
};

export const currencyConvert = (amount, locale) => {
  const currAmount = Number(amount);

  // default USD, add conditionals for other locals
  // if local && local === 'es' etc.
  let currency = "";
  let currSymbol = "";

  // else default us
  currSymbol = getString("currencySymbol.usd");

  currency = currAmount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  return currency.startsWith(currSymbol) ? currency : `${currSymbol}${currency}`;
};

export const getAge = (year, month, day) => {
  const today = new Date();
  // months start with 0
  const birthDate = new Date(year, month, day);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const getPlanData = (data, group, promo) => {
  // result will be an object with the months as the key "m1, m6, m12" etc.
  let planIDs = {};
  if (data && data.subscriptions) {
    const groupData = data.subscriptions.filter((obj) => {
      return obj.planGroup === group && obj.active;
    });

    planIDs = groupData.reduce((result, item) => {
      const myResult = result;
      const key = `m${item.months}`;
      const hasPromo = promo && promo.planGroups.includes(group.toUpperCase());

      // for ab testing total price shown instead of monthly price
      const tt = item.amount.toFixed(2);
      const td = tt.split(".")[0]; // us dollars
      const tc = tt.split(".")[1]; // us cents

      const val = {
        planId: item.planId,
        amount: item.amount,
        name: item.name,
        total: { dollars: td, cents: tc },
      };

      if (hasPromo) {
        // for promo prices
        let ptt = item.amount;

        if (promo.amountOff > 0) {
          ptt -= promo.amountOff;
        } else if (promo.percentOff > 0) {
          ptt -= ptt * promo.percentOff;
        }

        val.promoAmount = ptt;

        let pmt = ptt / item.months;
        const promoMonthly = { total: pmt };

        pmt = pmt.toFixed(2);

        promoMonthly.dollars = pmt.split(".")[0]; // us dollars
        promoMonthly.cents = pmt.split(".")[1]; // us cents

        ptt = ptt.toFixed(2);
        const ptd = ptt.split(".")[0]; // us dollars
        const ptc = ptt.split(".")[1]; // us cents

        val.promo = {
          total: { dollars: ptd, cents: ptc },
          monthly: promoMonthly,
        };
      }

      myResult[key] = val;

      return myResult;
    }, {});
  }
  return planIDs;
};

export const compareNormalizedStrings = (a, b) => {
  const normalA = a.normalize("NFD").replace(/[\u0300-\u036F]/g, "");
  const normalB = b.normalize("NFD").replace(/[\u0300-\u036F]/g, "");
  return normalA.localeCompare(normalB);
};

export const sortedArrayByItem = (items, key, desc) => {
  const myItems = [...items];
  const compare = (a, b) => {
    if (typeof a[key] === "string" && typeof b[key] === "string") {
      return compareNormalizedStrings(a[key], b[key]);
    }
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  };

  const sortedItems = myItems.sort(compare);
  // defaults to ascending
  return desc ? sortedItems.reverse() : sortedItems;
};

export const booleanToggle = (elem, prop) => {
  // pass in a state property to toggle between true or false
  // great for toggling on and off modals (see handleSubmit in forms)
  elem.setState((prevState) => {
    return { [prop]: !prevState[prop] };
  });
};

export const isLoggedInWithRole = (role) => {
  const {
    login: { isLoggedIn, role: currentRole },
  } = store().getState();
  return isLoggedIn && currentRole === role;
};

export const isLoggedInWithoutRole = (role) => {
  const {
    login: { isLoggedIn, role: currentRole },
  } = store().getState();
  return isLoggedIn && currentRole !== role;
};
export const freePublicUser = () => {
  const {
    login: { isLoggedIn },
  } = store().getState();
  return !isLoggedIn || isLoggedInWithRole(userRoles.free);
};

export const isAFunction = (myFunc) => {
  return typeof myFunc === "function" || myFunc instanceof Function;
};

export const logout = (path) => {
  // remove token from local storage and reload page to reset apollo client
  try {
    window.localStorage.removeItem(tokenName);
    window.localStorage.removeItem("planId");
    window.localStorage.removeItem("billingPlatform");
    window.localStorage.removeItem("games");
    window.localStorage.removeItem("hiddenCategories");
  } catch (e) {}
  if (path) {
    window.location.assign(path);
  } else {
    window.location.reload();
  }
};

export const login = (data) => {
  const { token, path } = data;
  // create token in local storage and reload page to reset scripts in head etc.
  try {
    window.localStorage.setItem(tokenName, token);
  } catch (e) {}

  if (path && path.length > 0) {
    window.location.assign(path);
  } else {
    window.location.reload();
  }
};

export const fullscreenSupported = () => {
  return (
    document.fullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.msFullscreenEnabled
  );
};

export const offset = (el) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

export const lowAndNoSpecialString = (str, char) => {
  // retuns a lower case string
  // with no special characters or spaces
  const lowerName = str.toLowerCase().trim();
  return lowerName.replace(/[^A-Z0-9]+/gi, char);
};

export const hyphenToCamel = (str) => {
  return str.replace(/-([a-z0-9])/g, (g) => g[1].toUpperCase());
};

export const asyncTimeout = (ms) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(true);
    }, ms),
  );
};

export const addNoScroll = () => {
  document.body.classList.add("body-html-noscroll");
  document.documentElement.classList.add("body-html-noscroll");
};

export const removeNoScroll = () => {
  document.body.classList.remove("body-html-noscroll");
  document.documentElement.classList.remove("body-html-noscroll");
};

export const capitalize = (word) => {
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
};

export const getSuggestions = (query, items, searchKey) => {
  const queryLowerCase = query
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036F]/g, "");
  const queryLength = queryLowerCase.length;

  const regex = new RegExp(queryLowerCase.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
  return queryLength > 0
    ? items.filter((item) => {
        const itemValue = item[searchKey];
        if (!itemValue) {
          return false;
        }
        return (
          itemValue
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036F]/g, "")
            .search(regex) !== -1
        );
      })
    : [];
};

export const sendResponse = (message, origin) => {
  const gameIframe = document.getElementById("abcya-game-iframe");

  if (gameIframe) {
    let responseMessage = message;
    responseMessage.source = "wrapper";
    responseMessage = JSON.parse(JSON.stringify(responseMessage));

    gameIframe.contentWindow.postMessage(responseMessage, origin);
  }
};

export const handlePostRequest = (result, onCompleted, onError) => {
  if (result.error) {
    if (onError) {
      onError(result.error);
    }
  } else if (result.data) {
    onCompleted(result.data);
  }
};

export const removeTrailingSlash = (str) => {
  return str.replace(/\/+$/g, "");
};

export const setLangPreference = (path = "") => {
  let langCode = getLangCode();

  if (langCode === language.default) {
    // default/english going to spanish/other language
    // set redirect token
    langCode = language.la;
    try {
      window.localStorage.setItem(language.preferenceToken, language.la);
    } catch (e) {}
  } else {
    // not spanish/other language
    // set to default/english
    // NO redirect token setting
    langCode = language.default;
  }

  const url = new URL(`${getAbcyaUrl(langCode)}${path}${language.resetHash}`);

  window.location.replace(url);
};
