import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { mobile } from "../../utilities/detection";
import DesktopContent from "./desktopContent";
import MobileContent from "./mobileContent";
import PreContentVideo from "../ads/preContentVideo";
import AdblockContentMessage from "../global/adblockContentMessage";
import { getString } from "../../utilities";
import Trouble from "./trouble";
import { fullScreen } from "../../utilities/games";
import Close from "../../../assets/svg/close.svg";
import { adsSelector } from "../../redux/slices/loginSlice";
import { fullScreenActiveSelector } from "../../redux/slices/userClientSlice";
import {
  preContentVideoSelector,
  clickToPlayVideoSelector,
  setPreContentVideo,
  setShouldReload,
} from "../../redux/slices/preContentVideoSlice";
import { contentAdTypes } from "../../config";

export default function GameContent({ game, isMobileFree, isMobileActive, isArcademics, publicModalToggle }) {
  const prevGameShortname = useRef(null);
  const currGameShortname = useRef(null);
  const prevGameAdType = useRef(null);
  const currGameAdType = useRef(null);

  const fullScreenActive = useSelector(fullScreenActiveSelector);
  const preContentVideo = useSelector(preContentVideoSelector);
  const clickToPlayVideo = useSelector(clickToPlayVideoSelector);
  const dispatch = useDispatch();

  const ads = useSelector(adsSelector);

  useEffect(() => {
    if (game.shortname) {
      if (currGameShortname.current !== game.shortname) {
        prevGameShortname.current = currGameShortname.current;
        prevGameAdType.current = currGameAdType.current;

        currGameShortname.current = game.shortname;
        currGameAdType.current = game.adType;

        dispatch(
          setShouldReload({
            shouldReload: !prevGameShortname.current || currGameAdType.current !== prevGameAdType.current,
          }),
        );
      }

      dispatch(setPreContentVideo({ preContentVideo: !mobile && game.adType === contentAdTypes.preroll && ads }));
    }
  }, [ads, game]);

  const renderCloseFullButton = () => {
    if (fullScreenActive) {
      return (
        <a
          onClick={() => {
            fullScreen.exit();
          }}
          className="game-fullscreen-close"
          alt={getString("navigation.fullscreen.exit")}
        >
          <Close />
        </a>
      );
    }

    return null;
  };

  const renderContent = () => {
    if (mobile) {
      return (
        <MobileContent
          game={game}
          isMobileFree={isMobileFree}
          isMobileActive={isMobileActive}
          isArcademics={isArcademics}
          publicModalToggle={publicModalToggle}
        />
      );
    }

    if (preContentVideo) {
      return <PreContentVideo />;
    }
    return <DesktopContent game={game} isArcademics={isArcademics} />;
  };

  let directionStyle =
    (mobile && game?.mobileOrientation === "portrait") || game?.orientation === "portrait" ? "vertical" : "";
  if (isArcademics) directionStyle = "arcademics";
  const containerStyle = fullScreenActive ? "game-container-fullscreen" : "container";

  const prerollStyle = preContentVideo || clickToPlayVideo ? "prerollvideo" : "";

  return (
    <div className="game-description-wrapper">
      {/* supplies the overall width */}
      <div className="game-game-wrapper">
        {/* supplies the overall height */}

        <div id="game-container" className={`game-game ${directionStyle} ${prerollStyle}`}>
          {/* supplies the variant widths */}

          <div id="desktopGame" className={containerStyle}>
            {/* provides aspect ratio */}

            {renderContent()}

            <AdblockContentMessage message={getString("adblock.content", { replace: [getString("game.title.0")] })} />

            {renderCloseFullButton(game)}
          </div>
        </div>
      </div>

      <div className="description">
        <p className="description-text">{game?.description}</p>

        <Trouble
          isMobileFree={isMobileFree}
          isMobileActive={isMobileActive}
          publicModalToggle={publicModalToggle}
          gameData={game ? { label: game.fullname, value: game.id } : null}
        />
      </div>
    </div>
  );
}

GameContent.propTypes = {
  game: PropTypes.object.isRequired,
  isMobileFree: PropTypes.bool.isRequired,
  isMobileActive: PropTypes.bool.isRequired,
  isArcademics: PropTypes.bool.isRequired,
  publicModalToggle: PropTypes.func.isRequired,
};
