import { mobile } from "./detection";
import { isLoggedInWithoutRole } from "./";
import { contentAdTypes, getLangCode, language, userRoles } from "../config";
import store from "../redux/store";

export const mobileFreePremium = (active, free) => {
  // Will return true if a game is availble to play on mobile
  // Mobile active is true, and game is Free or user is premium
  return active && (isLoggedInWithoutRole(userRoles.free) || free);
};

export const mobileFreeGame = (ls, id) => {
  const mobileFreeList = ls.find((list) => {
    return list.platform === "MOBILE" && list.group === "free";
  });

  const mobileFreeGames = mobileFreeList ? mobileFreeList.items.map((item) => (item.game ? item.game : "")) : [];

  return !!mobileFreeGames.find((g) => g === id);
};

export const getMobileHomeStyle = (corner, offX, offY, buttAlign) => {
  const styleObj = {};
  let classString = "";

  if (corner.startsWith("TOP")) {
    if (offY !== 0) {
      styleObj.top = `${offY}px`;
    }
  }
  if (corner.startsWith("BOTTOM")) {
    classString += " bottom";
    if (offY !== 0) {
      styleObj.bottom = `${offY}px`;
    }
  }
  if (corner.endsWith("LEFT")) {
    if (offX !== 0) {
      styleObj.left = `${offX}px`;
    }
  }
  if (corner.endsWith("RIGHT")) {
    classString += " right";
    if (offX !== 0) {
      styleObj.right = `${offX}px`;
    }
  }
  if (buttAlign === "vertical" || buttAlign === "portrait") {
    classString += " vertical";
  }

  return {
    class: classString,
    style: styleObj,
  };
};

export const fullScreen = {
  go: (elem) => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  },
  exit: () => {
    if (document.exitFullscreen && document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen && document.webkitFullscreenElement) {
      document.webkitExitFullscreen();
    } else if (document.mozExitFullScreen && document.mozFullscreenElement) {
      document.mozExitFullScreen();
    } else if (document.msExitFullscreen && document.msFullscreenElement) {
      document.msExitFullscreen();
    }
  },
};

export const getAllGames = (data) => {
  return data.filter((game) => game.searchable && (!mobile || (mobile && game.mobileActive)));
};

export const getGamesByGradeNumber = (games, gradeNum) => {
  const allGames = getAllGames(games);
  return allGames.reduce((gradeGamesArray, game) => {
    if (game.grades.includes(gradeNum)) {
      gradeGamesArray.push(game);
    }
    return gradeGamesArray;
  }, []);
};

const getGameObjectFromLocation = (location) => {
  const {
    data: { games },
  } = store().getState().data;

  const delim = "/";
  const pathnameArray = location.pathname.split(delim);
  const shortNameIndex = location.pathname.endsWith(delim) ? pathnameArray.length - 2 : pathnameArray.length - 1;
  const shortname = pathnameArray[shortNameIndex];
  return games.find((game) => game.localeShortname === shortname || game.shortname === shortname);
};

export const getPauseAfterPlayStatus = (location) => {
  const isGameProxy = location.pathname.startsWith("/gameproxy");
  const gameObject = getGameObjectFromLocation(location);

  let pauseAfterPlay = false;

  if (gameObject?.adType) {
    const { ads } = store().getState().login;
    console.log("ads: ", ads);
    console.log("gameObject.adType: ", gameObject.adType);

    pauseAfterPlay = ads && !mobile && gameObject.adType === contentAdTypes.clickToPlay && !isGameProxy;
  }

  return pauseAfterPlay;
};

export const getGameParentData = (location) => {
  const languageCode = getLangCode();

  // determine click to play flag based on game shortname and test game list.  send back in response as config object
  const pauseAfterPlay = getPauseAfterPlayStatus(location);

  console.log("pauseAfterPlay: ", pauseAfterPlay);
  console.log("location: ", location);

  const parent = {
    name: "abcya-web",
    env: process.env.NODE_ENV,
    capabilities: {
      canPrintImages: true,
      canSaveImages: true,
    },
    config: {
      pauseAfterPlay,
    },
  };

  if (location.pathname.startsWith("/gameproxy")) {
    const searchParams = new URLSearchParams(location.search);
    const contentLanguageValue = searchParams.get("content-language")?.trim();
    const variantName = searchParams.get("variant")?.trim();

    if (contentLanguageValue) {
      parent.contentLanguage = contentLanguageValue;
    }

    if (variantName) {
      parent.variant = { id: variantName };
    }
  } else {
    const contentLanguage =
      languageCode in language.codes ? language.codes[languageCode] : language.codes[language.default];
    parent.contentLanguage = contentLanguage.replace("_", "-");
  }

  if (process.env.NODE_ENV !== "production" && location.hash) {
    // check for test hash
    const testKey = "gameparams";
    const testFlag = `#${testKey}`;

    if (location.hash.startsWith(testFlag)) {
      const testPropsArray = location.hash.split(",");
      if (testPropsArray && testPropsArray.length > 1) {
        // index 0 should always be `#test or #test,`

        // establish test object
        const params = {};

        testPropsArray.forEach((p) => {
          // iterate through all props in array
          if (!p.startsWith(testFlag)) {
            // don't include the test flag
            // parse key and value pairs
            const pArray = p.split("=");
            if (pArray.length === 2) {
              // only add if properly formatted
              // key index 0, value index 1, length 2
              // add them to parent data
              params[pArray[0]] = pArray[1];
            }
          }
        });

        if (Object.keys(params).length > 0) {
          parent[testKey] = params;
        }
      }
    }
  }
  return parent;
};
