import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getString } from "../../utilities";
import Hamburger from "../../../assets/svg/hamburger.svg";
import Magnify from "../../../assets/svg/magnifySearchField.svg";
import { isLoggedInSelector, modalToggle } from "../../redux/slices/loginSlice";
import { getLangCode, language } from "../../config";

export default function SmallNav({ setHasSearch, hasSearch, setHasMenu, hasMenu }) {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();

  let loginPath = "navigation.login.2";
  let logoutPath = "navigation.logout.3";
  if (getLangCode() !== language.default) {
    loginPath = "navigation.login.0";
    logoutPath = "navigation.logout.0";
  }

  return (
    <div className="header-nav-small">
      <div className="menubtn-container">
        <a
          className={hasMenu ? "active" : ""}
          onClick={() => {
            setHasMenu((prevHasMenu) => !prevHasMenu);
            setHasSearch(false);
          }}
        >
          {getString("navigation.menu")}
          <Hamburger />
        </a>
      </div>

      <div className="searchbtn-container">
        <a
          className={hasSearch ? "active" : ""}
          onClick={() => {
            setHasSearch((prevHasSearch) => !prevHasSearch);
            setHasMenu(false);
          }}
        >
          {getString("navigation.search")}
          <Magnify />
        </a>
      </div>

      <a
        onClick={() => {
          setHasMenu(false);
          setHasSearch(false);
          dispatch(modalToggle());
        }}
      >
        {isLoggedIn ? getString(logoutPath, { html: true }) : getString(loginPath, { html: true })}
      </a>
    </div>
  );
}

SmallNav.propTypes = {
  setHasMenu: PropTypes.func.isRequired,
  hasMenu: PropTypes.bool.isRequired,
  setHasSearch: PropTypes.func.isRequired,
  hasSearch: PropTypes.bool.isRequired,
};
