import { createSlice } from "@reduxjs/toolkit";

const preContentVideoSlice = createSlice({
  name: "preContentVideo",
  initialState: {
    preContentVideo: false,
    clickToPlayVideo: false,
    messageOrigin: null,
    shouldReload: true,
  },
  reducers: {
    setPreContentVideo: (prevState, action) => {
      const state = prevState;
      state.preContentVideo = action.payload.preContentVideo;
    },
    setClickToPlayVideo: (prevState, action) => {
      const state = prevState;
      state.clickToPlayVideo = action.payload.clickToPlayVideo;
    },
    setMessageOrigin: (prevState, action) => {
      const state = prevState;
      state.messageOrigin = action.payload.messageOrigin;
    },
    setShouldReload: (prevState, action) => {
      const state = prevState;
      state.shouldReload = action.payload.shouldReload;
    },
  },
  selectors: {
    preContentVideoSelector: (state) => state.preContentVideo,
    clickToPlayVideoSelector: (state) => state.clickToPlayVideo,
    messageOriginSelector: (state) => state.messageOrigin,
    shouldReloadSelector: (state) => state.shouldReload,
  },
});

export const { preContentVideoSelector, clickToPlayVideoSelector, messageOriginSelector, shouldReloadSelector } =
  preContentVideoSlice.selectors;
export const { setPreContentVideo, setClickToPlayVideo, setMessageOrigin, setShouldReload } =
  preContentVideoSlice.actions;

export default preContentVideoSlice.reducer;
