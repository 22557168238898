import React, { useEffect, useRef } from "react";
import OKModal from "./okModal";
import { useDispatch, useSelector } from "react-redux";
import {
  aDownloadSelector,
  loadingSelector,
  printCleanModalSelector,
  printDataSelector,
  printPDFSelector,
  saveDataSelector,
  setADownload,
  setPrintCleanModal,
  setPrintData,
  setPrintPDFModal,
} from "../../redux/slices/printSaveSlice";
import { getString } from "../../utilities";
import ModalClose from "./modalClose";
import { gtmGAEvent } from "../../utilities/gtm";
import { cleanupPrintSave } from "../../utilities/print";
import { isFirefox } from "react-device-detect";
import { isIOS12OrLess, mobile } from "../../utilities/detection";
import Modal from "./modal";
import Confirm from "./confirm";
import BodyNoScroll from "./bodyNoScroll";
import WebP from "./WebP";
import LoadingAnim from "./loadingAnim";

export default function PrintSaveUi() {
  const url = window.URL || window.webkitURL;
  const dispatch = useDispatch();
  const saveData = useSelector(saveDataSelector);
  const printData = useSelector(printDataSelector);
  const printCleanModal = useSelector(printCleanModalSelector);
  const printPDFModal = useSelector(printPDFSelector);
  const aDownload = useSelector(aDownloadSelector);
  const loading = useSelector(loadingSelector);
  const saveAnchor = useRef(null);
  const printFrame = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setADownload({ aDownload: window.Modernizr.adownload }));
    }, 100);
  }, []);

  useEffect(() => {
    if (saveData && saveAnchor.current) {
      setTimeout(() => {
        // console.log('saveAnchor.current: ', saveAnchor.current)
        saveAnchor.current.click();
        setTimeout(() => {
          url.revokeObjectURL(saveData.fileData);
        }, 200);
      }, 100);
    }
  }, [saveData]);

  const renderPrintCleanModal = () => {
    return (
      <OKModal
        doShow={true}
        close={() => {
          dispatch(setPrintCleanModal({ printCleanModal: false }));
        }}
        title={getString("attention.0")}
      >
        <ModalClose
          close={() => {
            cleanupPrintSave("canceled");
          }}
        />

        <p>{getString("print.cleanMessage.message")}</p>
      </OKModal>
    );
  };

  const renderPDFModal = () => {
    const isDownload = isFirefox || (mobile && !isIOS12OrLess);
    const cta = isDownload ? getString("navigation.download.0") : getString("navigation.open.0");
    const message = isDownload
      ? getString("print.pdfMessage.message.download")
      : getString("print.pdfMessage.message.open");

    const closeModal = () => {
      dispatch(setPrintPDFModal({ printPDFModal: false }));
      // GA event push
      gtmGAEvent({
        eventCategory: "print",
        eventAction: "button-click",
        eventLabel: print.eventLabel,
      });

      dispatch(setPrintData({ printData: null }));
    };

    return (
      <Modal doShow={printPDFModal} closeOnEscape={closeModal}>
        <Confirm
          confirm={() => {
            closeModal();
            const pdfWindow = window.open(print.fileData, "_blank");
            pdfWindow.opener = null;
            pdfWindow.focus();
          }}
          cancel={closeModal}
          button={cta}
        >
          <div className="modal-content-header">{getString("attention.0")}</div>
          <p>{message}</p>
        </Confirm>
      </Modal>
    );
  };

  const renderPrintContainer = () => {
    const { fileData, fileName = getString("print.defaultFile"), fileType, origin } = printData;

    let mqlFunction = null;
    const mediaQueryList = window.matchMedia ? window.matchMedia("print") : null;

    const printCleanup = () => {
      if (isIOS12OrLess) {
        mediaQueryList.removeListener(mqlFunction);
      }
      if (fileType === "pdf") {
        // console.log('PDF CLEANUP!!!!')
        window.removeEventListener("click", printCleanup);
      } else {
        // window.onfocus = null
        window.onafterprint = null;

        const rudy = document.getElementById("root");

        if (rudy) {
          if (rudy.style.removeProperty) {
            rudy.style.removeProperty("height");
          } else {
            rudy.style.removeAttribute("height");
          }
        }
      }

      cleanupPrintSave();
    };

    mqlFunction = (e) => {
      if (!e.matches) {
        printCleanup();
      }
    };

    const eventHandlers = () => {
      if (fileType === "pdf") {
        // send message to printFrame with fileData/blob
        // console.log('printFrame: ', printFrame.current)
        printFrame.current.contentWindow.postMessage({ fileData }, origin);
        window.addEventListener("click", printCleanup);
      } else {
        const rudy = document.getElementById("root");
        if (rudy) {
          rudy.style.height = "100%";
        }

        // after print
        if (isIOS12OrLess) {
          mediaQueryList.addListener(mqlFunction);
        } else if (!mobile) {
          window.onafterprint = () => {
            printCleanup();
          };
        }

        setTimeout(() => {
          window.print();
        }, 100);
      }
    };

    if (fileType === "pdf") {
      if (print.pdfPrint) {
        return (
          <iframe
            ref={printFrame}
            title={fileName}
            src="/pdfProxy.html"
            style={{ visibility: "hidden", height: 0, width: 0, position: "absolute" }}
            onLoad={eventHandlers}
          />
        );
      }
      return null;
    }

    return (
      <BodyNoScroll isActive={true}>
        <div className="print-container" onClick={printCleanup}>
          <WebP
            secret={fileData}
            imgProps={{ className: "print-file", alt: getString("print.alt"), onLoad: eventHandlers }}
          />
        </div>
      </BodyNoScroll>
    );
  };

  const renderSaveModal = () => {
    const { fileData, fileName = getString("save.defaultFile") } = saveData;

    if (aDownload) {
      return (
        <a
          href={fileData}
          style={{ display: "none" }}
          ref={saveAnchor}
          onClick={() => {
            cleanupPrintSave();
          }}
          download={fileName}
        >
          {fileName}
        </a>
      );
    }

    return (
      <Modal
        doShow={true}
        closeOnEscape={() => {
          cleanupPrintSave("canceled");
        }}
      >
        <Confirm
          confirm={() => {
            const saveWindow = window.open(saveData.fileData, "_blank");
            saveWindow.opener = null;
            saveWindow.focus();
            cleanupPrintSave();
          }}
          cancel={() => {
            cleanupPrintSave("canceled");
          }}
          button={getString("navigation.open.0")}
        >
          <div className="modal-content-header">{getString("attention.0")}</div>
          <p>{getString("print.saveMessage.message")}</p>
        </Confirm>
      </Modal>
    );
  };

  const renderContent = () => {
    if (printCleanModal) {
      return renderPrintCleanModal();
    } else if (printPDFModal) {
      return renderPDFModal();
    } else if (printData) {
      return renderPrintContainer();
    } else if (saveData) {
      return renderSaveModal();
    }
    return null;
  };

  return (
    <>
      {renderContent()}
      {loading && <LoadingAnim position="fixed" className="background-transparent-white" />}
    </>
  );
}
