import React, { Children, useEffect } from "react";
import PropTypes from "prop-types";

export default function CloseOnEscape({ onEscape, children }) {
  const handleEscape = ({ code }) => {
    if (onEscape && code === "Escape") {
      onEscape();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, []);

  return <>{Children.only(children)}</>;
}

CloseOnEscape.propTypes = {
  onEscape: PropTypes.func,
  children: PropTypes.any.isRequired,
};
