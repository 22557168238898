import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { routeCodes } from "../../config/routes";

export default function CategoryNav({ categories, url }) {
  const navigate = useNavigate();

  if (categories && categories.length > 0) {
    return categories.map((cat) => {
      return (
        <button
          type="button"
          key={cat.id}
          className={`button-flat-color round dimensional ${cat.shortname.toLowerCase()}`}
          onClick={() =>
            navigate(`${routeCodes.GRADES}${url}/${cat.localeShortname ? cat.localeShortname : cat.shortname}`)
          }
        >
          {cat.name}
        </button>
      );
    });
  }

  return null;
}

CategoryNav.propTypes = {
  categories: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
};
