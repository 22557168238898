import React from "react";
import TeamMember from "./teamMember";

import teamAmy from "../../../assets/img/teamAmy.jpg";
import teamErin from "../../../assets/img/teamErin.jpg";
import teamJake from "../../../assets/img/teamJake.jpg";
import teamMichael from "../../../assets/img/teamMichael.jpg";
import teamRusty from "../../../assets/img/teamRusty.jpg";
import teamShane from "../../../assets/img/teamShane.jpg";
import teamStephen from "../../../assets/img/teamStephen.jpg";
import teamTom from "../../../assets/img/teamTom.jpg";
import teamAlex from "../../../assets/img/teamAlex.jpg";
import teamDan from "../../../assets/img/teamDan.png";
import teamRachel from "../../../assets/img/teamRachel.png";
import teamSandra from "../../../assets/img/teamSandra.png";
import teamJianyu from "../../../assets/img/teamJianyu.png";

export default function TeamMembers() {
  // see aboutStrings ('../../strings/about') for the job description path

  return (
    <>
      <TeamMember name="MICHAEL" favorite="true-colors-color-theory" artwork={teamMichael} job="team.jobs.design.1" />

      <TeamMember name="AMY" favorite="dolch_sight_word_spelling" artwork={teamAmy} job="team.jobs.design.0" />

      <TeamMember
        name="ERIN"
        favorite="parts-of-speech-quest-prepositions"
        artwork={teamErin}
        job="team.jobs.design.2"
      />

      <TeamMember name="JAKE" favorite="zero_sum" artwork={teamJake} job="team.jobs.development.2" />

      <TeamMember name="RUSTY" favorite="fuzzbug_fuzzathlon" artwork={teamRusty} job="team.jobs.development.1" />

      <TeamMember name="TOM" favorite="multiplication_mine" artwork={teamTom} job="team.jobs.development.0" />

      <TeamMember name="STEPHEN" favorite="zero_sum" artwork={teamStephen} job="team.jobs.development.1" />

      <TeamMember name="SHANE" favorite="order_of_operations" artwork={teamShane} job="team.jobs.development.1" />

      <TeamMember name="ALEX" favorite="detective-x-algebra" artwork={teamAlex} job="team.jobs.development.1" />

      <TeamMember name="JIANYU" favorite="dress_for_the_weather" artwork={teamJianyu} job="team.jobs.development.0" />

      <TeamMember name="DAN" favorite="2048" artwork={teamDan} job="team.jobs.product.0" />

      <TeamMember name="RACHEL" favorite="alphabats_alliteration" artwork={teamRachel} job="team.jobs.product.1" />

      <TeamMember name="SANDRA" favorite="alphabats_rhyming" artwork={teamSandra} job="team.jobs.product.2" />
    </>
  );
}
