import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { gtmJoinClick } from "../../utilities/gtm";
import { isLoggedInSelector, modalToggle } from "../../redux/slices/loginSlice";
import { getLangCode, language } from "../../config";

export default function Help() {
  const location = useLocation();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();

  let loginPath = "navigation.login.3";
  let logoutPath = "navigation.logout.2";
  if (getLangCode() !== language.default) {
    loginPath = "navigation.login.0";
    logoutPath = "navigation.logout.0";
  }

  return (
    <div className="footer-nav-col">
      <div className="title">
        <span>{getString("help.title.0")}</span>
      </div>

      <a onClick={() => dispatch(modalToggle())}>{isLoggedIn ? getString(logoutPath) : getString(loginPath)}</a>

      <OptionallyDisplayed doDisplay={!isLoggedIn}>
        <Link
          to={routeCodes.SUBSCRIBE}
          onClick={() => {
            gtmJoinClick(location.pathname, "footer-button");
          }}
        >
          {getString("join.8")}
        </Link>
      </OptionallyDisplayed>

      <Link to={routeCodes.FAQ}>{getString("faq.title.1")}</Link>

      <Link to={routeCodes.CONTACT}>{getString("contact.0")}</Link>
    </div>
  );
}
