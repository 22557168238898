import React from "react";
import PropTypes from "prop-types";
import { getString, currencyConvert } from "../../utilities";
import { getLangCode } from "../../config";

export default function PromoBurst({ promo = null }) {
  if (promo) {
    let offText = getString("promo.title.0");

    if (promo.amountOff > 0) {
      offText = getString("promo.off", { replace: [currencyConvert(promo.amountOff)] });
    } else if (promo.percentOff > 0) {
      offText = getString("promo.off", { replace: [`${promo.percentOff * 100}%`] });
    }

    return <div className={`promo-burst ${getLangCode()}`}>{offText}</div>;
  }

  return null;
}

PromoBurst.propTypes = {
  promo: PropTypes.object,
};
