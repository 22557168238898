import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { getString } from "../../utilities";
import { routeCodes } from "../../config/routes";

export default function Native({ billingPlatform, resetCardHandler }) {
  const navigate = useNavigate();
  const storeString = getString(`appStores.${billingPlatform}`);

  return (
    <>
      <h3>{getString("redeem.card.native.title", { replace: [storeString] })}</h3>

      <div>{getString("redeem.card.native.message", { replace: [storeString], html: true })}</div>

      <div className="redeem-card-form-buttons">
        <button
          type="button"
          className="button-flat-color green"
          onClick={() => {
            navigate(`${routeCodes.FAQ}#apps`, { replace: true });
          }}
        >
          {getString("faq.title.0")}
        </button>

        <button type="button" className="button-flat-color orange" onClick={resetCardHandler}>
          {getString("redeem.card.anotherEmail")}
        </button>
      </div>
    </>
  );
}

Native.propTypes = {
  billingPlatform: PropTypes.string,
  resetCardHandler: PropTypes.func.isRequired,
};
