import React from "react";
import PropTypes from "prop-types";
import Modal from "../global/modal";
import Confirm from "../global/confirm";
import { getString } from "../../utilities";
import { getLangCode, language } from "../../config";

export default function PurchaseConfirm({ doShow, submit, cancel, purchaseData }) {
  const { total, amount, quantity, recipients, designName } = purchaseData;

  const renderRecipients = () => {
    if (recipients && recipients.length > 0) {
      return (
        <div className="pc-lineitem">
          <span className="pc-lineitem-title">{getString("giftcard.confirm.recipients")}: </span>
          <br />
          <strong>{recipients.join(", ")}</strong>
        </div>
      );
    }

    return null;
  };

  const renderDesign = () => {
    if (getLangCode() === language.default && designName && designName.length > 0) {
      return (
        <div className="pc-lineitem">
          <span className="pc-lineitem-title">{getString("giftcard.confirm.design")}: </span>
          <strong>{designName}</strong>
        </div>
      );
    }

    return null;
  };

  return (
    <Modal doShow={doShow}>
      <Confirm
        confirm={async () => {
          submit();
        }}
        cancel={async () => {
          cancel();
        }}
        button={getString("giftcard.complete")}
      >
        <div className="modal-content-header">{getString("subscribe.confirm.title")}</div>
        <div className="purchase-confirm">
          <p>{getString("giftcard.confirm.message")}: </p>
          <div className="pc-lineitems">
            <div className="pc-lineitem">
              <span className="pc-lineitem-title">{getString("giftcard.quantity")}: </span>
              <strong>{quantity}</strong>
            </div>
            <div className="pc-lineitem">
              <span className="pc-lineitem-title">{getString("cost.0")}: </span>
              <strong>{amount}</strong>
            </div>

            {renderRecipients()}
            {renderDesign()}

            <div className="pc-total">
              <span className="pc-total-title">{getString("total.0")}: </span>
              {total}
            </div>
          </div>
        </div>
      </Confirm>
    </Modal>
  );
}

PurchaseConfirm.propTypes = {
  doShow: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  purchaseData: PropTypes.object.isRequired,
};
