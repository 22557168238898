import React from "react";
import { useLocation } from "react-router-dom";
import { metaDefault } from "../../config/meta";
import aboutStrings from "../../strings/about";
import { getString } from "../../utilities";
import Leader from "../ads/leader";
import AboutNav from "./aboutNav";

export default function About() {
  const location = useLocation();

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("about.title.1") })}

      <div className="about">
        <div className="wrapper padded">
          <div className="container">
            <h1>{getString("about.title.2")}</h1>

            <div className="container-medium pt-caption">
              {getString("about", { html: true, strings: aboutStrings })}
            </div>

            <AboutNav />
          </div>
        </div>

        <Leader className="pt-steel" />
      </div>

      <div className="wrapper header-shadow" />
    </>
  );
}
