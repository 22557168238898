import React from "react";
import PropTypes from "prop-types";
import { getString } from "../../utilities";
import PromoBurst from "../subscribe/promoBurst";
import { userRoles } from "../../config";

export default function PlanButton({ plan, pRole, color, func, promo }) {
  const planButtonSyle = (plan, pRole, color) => {
    const active = plan === pRole ? " active" : "";
    return `button-flat-color round ${color}${active}`;
  };

  const renderPromo = () => {
    if (pRole === userRoles.family) {
      return <PromoBurst promo={promo} />;
    }
    return null;
  };

  return (
    <button
      type="button"
      className={planButtonSyle(plan.toLowerCase(), pRole.toLowerCase(), color)}
      onClick={() => {
        if (plan.toLowerCase() !== pRole.toLowerCase()) {
          func(pRole);
        }
      }}
    >
      {getString(`subscribe.plans.${pRole.toLowerCase()}.title.0`)}
      {renderPromo()}
    </button>
  );
}

PlanButton.propTypes = {
  plan: PropTypes.string,
  pRole: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  func: PropTypes.func.isRequired,
  promo: PropTypes.object,
};
