import React from "react";
import PropTypes from "prop-types";
import BodyNoScroll from "../global/bodyNoScroll";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { getLangCode } from "../../config";

export default function Rotate({ doDisplay, orientation }) {
  return (
    <BodyNoScroll isActive={doDisplay}>
      <OptionallyDisplayed doDisplay={doDisplay}>
        <div className={`game-mobile-rotate ${orientation} ${getLangCode()}`} />
      </OptionallyDisplayed>
    </BodyNoScroll>
  );
}

Rotate.propTypes = {
  doDisplay: PropTypes.bool.isRequired,
  orientation: PropTypes.string.isRequired,
};
