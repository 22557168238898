import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import NotFound from "../global/notFound";
import Leader from "../ads/leader";
import MiniBanner from "../global/miniBanner";
import GradeNav from "../global/gradeNav";
import { contentTypes } from "../../config";
import { metaType } from "../../config/meta";
import { routeCodes } from "../../config/routes";
import { getString, sortedArrayByItem } from "../../utilities";
import CategoryGamesArt from "./CategoryGamesArt";
import { gameCategories } from "../../config/categories";
import { adsSelector } from "../../redux/slices/loginSlice";
import { dataSelector } from "../../redux/slices/dataSlice";

const renderCategoryGames = (categoryGamesArray, ads) => {
  if (categoryGamesArray.length > 0) {
    const gameList = sortedArrayByItem(categoryGamesArray, "fullname").map((game, index) => (
      <MiniBanner
        key={game.id}
        type={contentTypes.game}
        title={game.fullname}
        path={game.localeShortname || game.shortname}
        image={game.miniBanner ? game.miniBanner : null}
        grades={game.grades}
        isMobile={game.mobileActive}
        isSearchable={game.searchable}
        hasNoScroll={true}
        clickHandler={`${routeCodes.GAMES}${game.localeShortname || game.shortname}`}
        tabIndex={String(index)}
      />
    ));

    const cgsStyle = `categorygames-games ${ads ? "" : "cgs-premium"}`;

    return (
      <div className={cgsStyle}>
        <div className="container categorygames-games-container">{gameList}</div>
      </div>
    );
  }

  return null;
};

export default function CategoryGames() {
  const location = useLocation();
  const match = useParams();
  const { games } = useSelector(dataSelector);
  const ads = useSelector(adsSelector);

  const [categoryGamesArray, setCategoryGamesArray] = useState([]);
  const [categoryObject] = useState(
    gameCategories.find((cat) => cat.localeShortname === match.cat || cat.shortname === match.cat),
  );
  const categoryType = location?.state?.type || categoryObject?.type;

  useEffect(() => {
    if (games && categoryObject) {
      setCategoryGamesArray(games.filter((g) => g.categories.includes(categoryObject.shortname)));
    }
  }, [games, categoryObject]);

  if (categoryObject === undefined) {
    // will be undefined if cat param is not found
    return <NotFound item={getString("categoryGames.title")} cta={getString("games.all.1")} route="GAMES" />;
  }

  let categoryStyle = `cgs-${categoryObject.shortname}`;

  if (categoryType === "genre") categoryStyle = `cgs-${categoryType}`;

  return (
    <>
      {metaType("WebSite", categoryObject, location.pathname)}

      <GradeNav />

      <div className="categorygames-wrapper">
        <div className={`categorygames cgs-${categoryType}`}>
          <div className="categorygames-text">
            <div className={`categorygames-title ${categoryStyle}`}>{categoryObject.name}</div>

            <div className="categorygames-description">{categoryObject.description}</div>
          </div>

          <CategoryGamesArt name={categoryObject.shortname} title={categoryObject.name} type={categoryType} />
        </div>
      </div>

      <Leader className="cyan" />

      {renderCategoryGames(categoryGamesArray, ads)}
    </>
  );
}
