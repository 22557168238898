import React from "react";
import PropTypes from "prop-types";
import { getLangCode, language } from "../../config";
import { setLangPreference } from "../../utilities";

export default function LanguagePlanet({ children, styles }) {
  const langCode = getLangCode();
  const heavenlyStyles = [...styles];

  if (langCode !== language.default) {
    heavenlyStyles.push(`home-planet-${langCode}`);
  }

  const langPref = getLangCode() === language.default ? language.la : language.default;

  return (
    <a
      data-testid={`languagePlanet_${langPref}`}
      role="link"
      className={`home-planet ${heavenlyStyles.join(" ")}`}
      onClick={() => {
        setLangPreference();
      }}
    >
      {children}
    </a>
  );
}

LanguagePlanet.propTypes = {
  styles: PropTypes.array.isRequired,
  children: PropTypes.any.isRequired,
};
