import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { getString } from "../../utilities";
import TextViewHook from "../global/textViewHook";
import {
  EMAIL_CHAR_LIMIT,
  getLangCode,
  giftCardAmount,
  giftcardAmounts,
  giftcardQuantity,
  language,
} from "../../config";
import { getMaxLengthValidator, validateEmail } from "../../utilities/forms/rules";

export default function Details({ amount, setAmount, quantity, setQuantity, submitErrors, setSubmitErrors }) {
  const langCode = getLangCode();

  const {
    register,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  const renderQuantity = () => {
    const quantityOptions = [];
    for (let q = 1; q <= giftcardQuantity; q++) {
      const opKey = `opKey${q}`;

      quantityOptions.push(
        <option value={q} key={opKey}>
          {q}
        </option>,
      );
    }
    return quantityOptions;
  };

  const selectChange = (e) => {
    setQuantity(parseInt(e.target.value, 10));
  };

  const renderAmountButtons = () => {
    return giftcardAmounts.map((num) => {
      const amountClass = parseInt(amount, 10) === num ? "active" : "";

      return (
        <button
          type="button"
          key={num}
          className={`giftcard-details-amount-button ${amountClass}`}
          onClick={() => {
            setAmount(num);
            setValue("amount", num);
            trigger(["amount"]);
          }}
        >
          {`$${num}`}
        </button>
      );
    });
  };

  const onFieldChanged = () => {
    if (submitErrors) {
      setSubmitErrors(null);
    }
  };

  return (
    <div className="wrapper padded">
      <div className="container giftcard-details">
        <h2>{getString("giftcard.details")}</h2>

        <div className="static-label">{getString("giftcard.choose.amount.title")}</div>
        <div className="giftcard-details-instructions">{getString("giftcard.choose.amount.rules")}</div>
        <div className="giftcard-details-amount">
          {renderAmountButtons()}

          <div className="form-field giftcard-details-amount-input">
            <div className="form-field-container">
              <input
                name="amount"
                placeholder={getString("giftcard.choose.amount.enter")}
                type="number"
                {...register("amount", {
                  validate: (num) => {
                    const value = parseInt(num, 10);
                    return (
                      (value >= giftCardAmount.minimum &&
                        value <= giftCardAmount.maximum &&
                        value % giftCardAmount.increment === 0) ||
                      getString("giftcard.choose.amount.rules")
                    );
                  },
                  onChange: (e) => {
                    setAmount(parseInt(e.target.value, 10));
                    onFieldChanged();
                  },
                })}
              />
              {errors.amount && <div className="validation-error">{errors.amount.message}</div>}
            </div>
          </div>
        </div>

        <div className="static-label">{getString("giftcard.quantity")}:</div>
        <div className="form-field">
          <select className="giftcard-details-quantity-select" value={quantity} onChange={selectChange}>
            {renderQuantity()}
          </select>
        </div>

        <div className="static-label">{getString("giftcard.from.title")}</div>
        <TextViewHook
          rules={{
            required: getString(
              langCode === language.la ? "forms.errorMessages.isRequired.1" : "forms.errorMessages.isRequired.0",
              {
                replace: [getString(langCode === language.la ? "giftcard.from.name.1" : "giftcard.from.name.0")],
              },
            ),
          }}
          errors={errors}
          name="purchaserName"
          placeholder={getString("giftcard.from.name.0")}
          defaultValue=""
          onChange={onFieldChanged}
        />

        <TextViewHook
          rules={{
            validate: (value) =>
              validateEmail(
                value,
                getString(langCode === language.la ? "giftcard.from.email.3" : "giftcard.from.email.0"),
              ),
            required: getString(
              langCode === language.la ? "forms.errorMessages.isRequired.1" : "forms.errorMessages.isRequired.0",
              {
                replace: [getString(langCode === language.la ? "giftcard.from.email.1" : "giftcard.from.email.0")],
              },
            ),
            maxLength: getMaxLengthValidator(getString("giftcard.from.email.0"), EMAIL_CHAR_LIMIT),
          }}
          errors={errors}
          name="purchaserEmail"
          placeholder={getString("giftcard.from.email.0")}
          defaultValue=""
          onChange={() => {
            trigger("emailVerify");
            onFieldChanged();
          }}
        />

        <TextViewHook
          rules={{
            validate: (value) =>
              value === getValues().purchaserEmail ||
              getString(
                langCode === language.la ? "forms.errorMessages.mustMatch.1" : "forms.errorMessages.mustMatch.0",
                {
                  replace: [getString(langCode === language.la ? "giftcard.from.email.2" : "giftcard.from.email.0")],
                },
              ),
          }}
          errors={errors}
          name="emailVerify"
          placeholder={getString("forms.emailVerify")}
          defaultValue=""
          onChange={onFieldChanged}
        />
      </div>
    </div>
  );
}

Details.propTypes = {
  amount: PropTypes.number.isRequired,
  setAmount: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
  submitErrors: PropTypes.object,
  setSubmitErrors: PropTypes.func.isRequired,
};
