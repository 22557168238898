import { createSlice } from "@reduxjs/toolkit";

const printSaveSlice = createSlice({
  name: "printSave",
  initialState: {
    printData: null,
    saveData: null,
    loading: false,
    aDownload: false,
    printCleanModal: false,
    printPDFModal: false,
    origin: "",
    silent: false,
    message: "",
    showPrintSaveErrorModal: false,
    eventLabel: "",
    isPrint: false,
  },
  reducers: {
    setPrintData: (prevState, action) => {
      const state = prevState;
      state.printData = action.payload.printData;
    },
    setSaveData: (prevState, action) => {
      const state = prevState;
      state.saveData = action.payload.saveData;
    },
    setLoading: (prevState, action) => {
      const state = prevState;
      state.loading = action.payload.loading;
    },
    setADownload: (prevState, action) => {
      const state = prevState;
      state.aDownload = action.payload.aDownload;
    },
    setPrintCleanModal: (prevState, action) => {
      const state = prevState;
      state.printCleanModal = action.payload.printCleanModal;
    },
    setPrintPDFModal: (prevState, action) => {
      const state = prevState;
      state.printPDFModal = action.payload.printPDFModal;
    },
    setPrintSaveCallback: (prevState, action) => {
      const state = prevState;
      state.origin = action.payload.origin;
      state.message = action.payload.message;
    },
    printSaveCleanup: (prevState) => {
      const state = prevState;
      state.origin = "";
      state.message = "";
      state.printCleanModal = false;
      state.saveData = null;
      state.printData = null;
    },
    toggleShowPrintSaveErrorModal: (prevState) => {
      const state = prevState;
      state.showPrintSaveErrorModal = !prevState.showPrintSaveErrorModal;
    },
    setEventLabel: (prevState, action) => {
      const state = prevState;
      state.eventLabel = action.payload.eventLabel;
    },
    setIsPrint: (prevState, action) => {
      const state = prevState;
      state.isPrint = action.payload.isPrint;
    },
  },
  selectors: {
    printDataSelector: (state) => state.printData,
    saveDataSelector: (state) => state.saveData,
    loadingSelector: (state) => state.loading,
    aDownloadSelector: (state) => state.aDownload,
    printCleanModalSelector: (state) => state.printCleanModal,
    printPDFSelector: (state) => state.printPDFModal,
    printSaveOriginSelector: (state) => state.origin,
    printSaveMessageSelector: (state) => state.message,
    showPrintSaveErrorModalSelector: (state) => state.showPrintSaveErrorModal,
    isPrintSelector: (state) => state.isPrint,
  },
});

export const {
  setPrintData,
  setSaveData,
  setLoading,
  setADownload,
  setPrintCleanModal,
  setPrintPDFModal,
  setPrintSaveCallback,
  printSaveCleanup,
  toggleShowPrintSaveErrorModal,
  setEventLabel,
  setIsPrint,
} = printSaveSlice.actions;

export const {
  printDataSelector,
  saveDataSelector,
  loadingSelector,
  aDownloadSelector,
  printCleanModalSelector,
  printPDFSelector,
  printSaveOriginSelector,
  printSaveMessageSelector,
  showPrintSaveErrorModalSelector,
  isPrintSelector,
} = printSaveSlice.selectors;

export default printSaveSlice.reducer;
