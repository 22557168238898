import React from "react";
import PropTypes from "prop-types";
import { getString } from "../../utilities";
import NoSymbol from "../../../assets/svg/noSymbol.svg";
import Lock from "../../../assets/svg/unlock.svg";
import Devices from "../../../assets/svg/devices.svg";
import SubscriptionCard from "./subscriptionCard";
import SchoolSubCTA from "./schoolSubCTA";
import PlansPromoDescription from "../global/plansPromoDescription";
import PlansPromoDisclaimer from "../global/plansPromoDisclaimer";
import { getLangCode, language } from "../../config";

export default function ClassroomPlans({ plan, setPlan, planAmount, setPlanAmount, planData, promo = null }) {
  return (
    <div className="wrapper plans-wrapper classroom">
      <div className="plans-title">{getString("subscribe.plans.classroom.title.1")}</div>

      <PlansPromoDescription promo={promo} pRole="classroom" />

      <ul className="plans-perks">
        <li>
          <NoSymbol className="icon" />
          {getString("subscribe.plans.classroom.perks.0")}
        </li>
        <li>
          <Lock className="icon" />
          {getString("subscribe.plans.classroom.perks.1")}
        </li>
        <li>
          <Devices className="icon" />
          {getString("subscribe.plans.classroom.perks.2")}
        </li>
      </ul>

      <div className="plans-subs">
        <SubscriptionCard
          setPlan={setPlan}
          planAmount={planAmount}
          setPlanAmount={setPlanAmount}
          planData={planData}
          plan={plan}
          subscription="m12"
        />
      </div>

      {getLangCode() === language.default && <SchoolSubCTA />}

      <PlansPromoDisclaimer promo={promo} pRole="family" />
    </div>
  );
}

ClassroomPlans.propTypes = {
  plan: PropTypes.string.isRequired,
  setPlan: PropTypes.func.isRequired,
  planAmount: PropTypes.number,
  setPlanAmount: PropTypes.func,
  planData: PropTypes.object.isRequired,
  promo: PropTypes.object,
};
