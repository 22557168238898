import { createSlice } from "@reduxjs/toolkit";

const leavingSiteSlice = createSlice({
  name: "leavingSite",
  initialState: {
    url: "",
    showLeaveModal: false,
  },
  reducers: {
    leaveSiteHandler: (prevState, action) => {
      const state = prevState;
      if (action.payload.url) {
        state.url = action.payload.url;
      }
      state.showLeaveModal = true;
    },
    setShowLeaveModal: (prevState, action) => {
      const state = prevState;
      state.showLeaveModal = action.payload.showLeaveModal;
    },
  },
  selectors: {
    showLeaveModalSelector: (state) => state.showLeaveModal,
    urlSelector: (state) => state.url,
  },
});

export const { leaveSiteHandler, setShowLeaveModal } = leavingSiteSlice.actions;
export const { showLeaveModalSelector, urlSelector } = leavingSiteSlice.selectors;

export default leavingSiteSlice.reducer;
