import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import DoubleArrow from "../../../assets/svg/doubleArrowRight.svg";
import Arrow from "../../../assets/svg/arrowRight.svg";
import { getContentType, getLangCode, getSearchParamString } from "../../config";

export default function PageNav({ searchState }) {
  const navigate = useNavigate();

  const getActiveStyle = (id) => {
    return searchState.pageId === id ? "active" : "";
  };

  const navClickHandler = (id) => {
    const termParamString = getSearchParamString("term");
    const typeParamString = getSearchParamString("type");
    const filterType = getContentType(searchState.searchType, { langCode: getLangCode() });

    const location = {
      pathname: routeCodes.SEARCH,
      search: `?${termParamString}=${searchState.searchTerm}&${typeParamString}=${filterType}&id=${id}`,
    };
    navigate(location);
  };

  const renderTabs = (lastId) => {
    const tabArray = [];
    for (let i = 0; i <= lastId; i++) {
      const tabKey = `tabKey${i}`;
      tabArray.push(
        <button
          type="button"
          key={tabKey}
          onClick={() => {
            navClickHandler(i);
          }}
          className={`tab button-flat-color pt-cyan round auto ${getActiveStyle(i)}`}
        >
          {i + 1}
        </button>,
      );
    }
    return tabArray;
  };

  const lastId = searchState.pageCount - 1;

  return (
    <div className="search-page-nav">
      <button
        type="button"
        onClick={() => {
          navClickHandler(0);
        }}
        disabled={searchState.pageId <= 0}
        className="directional-button button-flat-color pt-yellow round auto"
      >
        <DoubleArrow className="inline-arrow double left" />
        {getString("first.0")}
      </button>

      <button
        type="button"
        onClick={() => {
          navClickHandler(searchState.pageId - 1);
        }}
        disabled={searchState.pageId <= 0}
        className="directional-button button-flat-color pt-yellow round auto"
      >
        <Arrow className="inline-arrow left" />
        {getString("back.0")}
      </button>

      <div className="search-page-nav-tabs">{renderTabs(lastId)}</div>

      <button
        type="button"
        onClick={() => {
          navClickHandler(searchState.pageId + 1);
        }}
        disabled={searchState.pageId >= lastId}
        className="directional-button forward button-flat-color pt-green round auto"
      >
        {getString("next.0")}
        <Arrow className="inline-arrow" />
      </button>

      <button
        type="button"
        onClick={() => {
          navClickHandler(lastId);
        }}
        disabled={searchState.pageId >= lastId}
        className="directional-button forward button-flat-color pt-green round auto"
      >
        {getString("last.0")}
        <DoubleArrow className="inline-arrow double" />
      </button>
    </div>
  );
}

PageNav.propTypes = {
  searchState: PropTypes.object.isRequired,
};
