import React from "react";
import HeavenlyBody from "./heavenlyBody";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import Billboard from "./billboard";
import MilkyWay from "../../../assets/img/milkyWay.svg";
import grades from "../../config/grades";
import { getLangCode, language } from "../../config";
import LanguagePlanet from "./languagePlanet";

export default function HomeNav() {
  return (
    <div className="home-nav-wrapper">
      <div className="home-nav">
        <img src={MilkyWay} className="milkyway" width="1405" height="480" alt="" />

        <Billboard />

        <div className="col left">
          <HeavenlyBody path={routeCodes.GAMES} styles={["sun"]}>
            <span>{getString("games.all.0")}</span>
          </HeavenlyBody>

          <HeavenlyBody
            path={`${routeCodes.GRADES}${grades[0]?.localeUrl || grades[0]?.url}`}
            styles={["grade-planet", "pink"]}
          >
            <span>{grades[0]?.shortname.toUpperCase()}</span>
          </HeavenlyBody>

          <HeavenlyBody
            path={`${routeCodes.GRADES}${grades[1]?.localeUrl || grades[1]?.url}`}
            styles={["grade-planet", "yellow"]}
          >
            <span>{getString("grades.title", { replace: [grades[1]?.shortname] })}</span>
          </HeavenlyBody>

          <HeavenlyBody
            path={`${routeCodes.GRADES}${grades[2]?.localeUrl || grades[2]?.url}`}
            styles={["grade-planet", "red"]}
          >
            <span>{getString("grades.title", { replace: [grades[2]?.shortname] })}</span>
          </HeavenlyBody>

          <HeavenlyBody
            path={`${routeCodes.GRADES}${grades[3]?.localeUrl || grades[3]?.url}`}
            styles={["grade-planet", "blue"]}
          >
            <span>{getString("grades.title", { replace: [grades[3]?.shortname] })}</span>
          </HeavenlyBody>
        </div>

        <div className="col middle">
          <HeavenlyBody
            path={`${routeCodes.GRADES}${grades[4]?.localeUrl || grades[4]?.url}`}
            styles={["grade-planet", "green"]}
          >
            <span>{getString("grades.title", { replace: [grades[4]?.shortname] })}</span>
          </HeavenlyBody>

          <HeavenlyBody
            path={`${routeCodes.GRADES}${grades[5]?.localeUrl || grades[5]?.url}`}
            styles={["grade-planet", "magenta"]}
          >
            <span>{getString("grades.title", { replace: [grades[5]?.shortname] })}</span>
          </HeavenlyBody>

          <HeavenlyBody
            path={`${routeCodes.GRADES}${grades[6]?.localeUrl || grades[6]?.url}`}
            styles={["grade-planet", "orange"]}
          >
            <span>{getString("grades.title", { replace: [grades[6]?.shortname] })}</span>
          </HeavenlyBody>

          <HeavenlyBody
            path={`${routeCodes.GRADES}${grades[7]?.localeUrl || grades[7]?.url}`}
            styles={["grade-planet", "cyan"]}
          >
            <span>{getString("grades.title", { replace: [grades[7]?.shortname] })}</span>
          </HeavenlyBody>
        </div>

        <div className="col right">
          <LanguagePlanet styles={["language-planet"]}>
            {getLangCode() === language.default && (
              <div className="newbadge">
                <span>{getString("new.0")}</span>
              </div>
            )}
            <span className="language-text">{getString("playInLocale.0")}</span>
          </LanguagePlanet>

          <HeavenlyBody path={routeCodes.PRINTABLES} styles={["printables-planet"]}>
            <span>{getString("printables.title.0")}</span>
          </HeavenlyBody>
        </div>
      </div>
    </div>
  );
}
