import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { getString } from "../../utilities";
import { routeCodes } from "../../config/routes";
import { emailSelector, isLoggedInSelector, modalToggle } from "../../redux/slices/loginSlice";

export default function Free({ email, resetCardHandler }) {
  const navigate = useNavigate();
  const loginEmail = useSelector(emailSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();

  // default is logged out
  let myMessage = getString("redeem.card.free.message.loggedOut", { replace: [email], html: true });
  let ctaButtonText = getString("navigation.login.1");

  if (isLoggedIn) {
    if (email !== loginEmail) {
      myMessage = getString("redeem.card.free.message.loggedInDiff", {
        replace: [loginEmail, email],
        html: true,
      });

      ctaButtonText = getString("navigation.logout.1");
    } else if (email === loginEmail) {
      myMessage = getString("redeem.card.free.message.isLoggedIn", { html: true });

      ctaButtonText = getString("manage.title.0");
    }
  }

  return (
    <>
      <h2>{getString("redeem.card.free.title")}</h2>

      <div>
        {myMessage}
        {getString("redeem.card.free.disclaim", { html: true })}
      </div>

      <div className="redeem-card-form-buttons">
        <button
          type="button"
          className="button-flat-color green"
          onClick={() => {
            if (isLoggedIn) {
              if (email !== loginEmail) {
                dispatch(modalToggle({ path: routeCodes.MANAGE }));
              } else if (email === loginEmail) {
                navigate(routeCodes.MANAGE, { replace: true });
              }
            } else {
              // not logged in
              dispatch(modalToggle({ path: routeCodes.MANAGE }));
            }
          }}
        >
          {ctaButtonText}
        </button>

        <button type="button" className="button-flat-color orange" onClick={resetCardHandler}>
          {getString("redeem.card.anotherEmail")}
        </button>
      </div>
    </>
  );
}

Free.propTypes = {
  email: PropTypes.string.isRequired,
  resetCardHandler: PropTypes.func.isRequired,
};
