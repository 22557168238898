import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { horizontalScroll } from "../../utilities/scroll";
import ListArrow from "../global/listArrow";
import DesignBanner from "./designBanner";

export default function Designs({ giftCardDesigns, designId, designClickHandler }) {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [hasListeners, setHasListeners] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(false);
  const [scrollRight, setScrollRight] = useState(true);

  const ldc = useRef(null);
  const location = useLocation();

  const resetArrows = () => {
    if (ldc.current) {
      const lmcScrollPos = ldc.current.scrollLeft;
      const lmcScrollWidth = ldc.current.scrollWidth;
      const lmcWidth = ldc.current.offsetWidth;

      setScrollLeft(lmcScrollPos > 0);
      setScrollRight(!(lmcScrollWidth - lmcScrollPos === lmcWidth));
    }
  };

  useEffect(() => {
    if (!hasListeners) {
      setHasListeners(true);

      window.addEventListener("resize", resetArrows);
      ldc.current.addEventListener("scroll", resetArrows);
      resetArrows();
    }
    resetArrows();

    if (currentLocation !== location) {
      setCurrentLocation(location);
      // reset scrollLeft location to 0 if coming from nav already scrolled
      ldc.current.scrollLeft = 0;
    }

    return () => {
      // componentWillUnmount (cleanup)
      window.removeEventListener("resize", resetArrows);
      if (ldc.current) {
        ldc.current.removeEventListener("scroll", resetArrows);
      }
    };
  }, []);

  const renderDesigns = () => {
    if (giftCardDesigns && giftCardDesigns.length > 0) {
      return giftCardDesigns.map((design, index) => {
        const designKey = `gcd${index}`;

        return (
          <DesignBanner
            key={designKey}
            isActive={designId === design.id}
            name={design.name}
            image={design.file.secret}
            clickHandler={() => {
              designClickHandler(design.id, design.file.secret, design.name);
            }}
          />
        );
      });
    }

    return null;
  };

  const rightArrowStyle = scrollRight ? " active" : "";
  const leftArrowStyle = scrollLeft ? " active" : "";

  return (
    <div className="container list-container">
      <div className="giftcard-list-designbanner-container" ref={ldc}>
        {renderDesigns()}
      </div>

      <ListArrow
        className={`list-arrow left${leftArrowStyle}`}
        clickHandler={() => {
          horizontalScroll(ldc.current, -(ldc.current.offsetWidth / 2));
        }}
      />

      <ListArrow
        className={`list-arrow right${rightArrowStyle}`}
        clickHandler={() => {
          horizontalScroll(ldc.current, ldc.current.offsetWidth / 2);
        }}
      />
    </div>
  );
}

Designs.propTypes = {
  giftCardDesigns: PropTypes.array.isRequired,
  designId: PropTypes.string.isRequired,
  designClickHandler: PropTypes.func.isRequired,
};
