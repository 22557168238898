import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GIFTCARD_DESIGNS_QUERY } from "../../graphql/queries";
import { getString } from "../../utilities";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import Designs from "./designs";
import { useLazyQuery } from "../../hooks";

export default function DesignList({ designClickHandler, designId }) {
  const [giftCardDesigns, setGiftCardDesigns] = useState(null);
  const [submitErrors, setSubmitErrors] = useState();

  const resolverError = (error) => {
    console.log("gift card designs resolver error: ", error);

    if (error !== submitErrors) {
      setSubmitErrors(error);
      window.scrollTo(0, 0);
    }
  };

  const onCompleted = (data) => {
    if (data && data.giftCardDesigns?.length > 0 && data.giftCardDesigns !== giftCardDesigns) {
      designClickHandler(data.giftCardDesigns[0].id, data.giftCardDesigns[0].file.secret, data.giftCardDesigns[0].name);
      setGiftCardDesigns(data.giftCardDesigns);
    }
  };
  const { executeQuery: designQuery, loading } = useLazyQuery(GIFTCARD_DESIGNS_QUERY, onCompleted, resolverError);
  useEffect(() => {
    designQuery();
  }, []);

  const renderContent = () => {
    if (loading) return <LoadingAnim />;

    if (giftCardDesigns && giftCardDesigns.length > 0) {
      return <Designs giftCardDesigns={giftCardDesigns} designId={designId} designClickHandler={designClickHandler} />;
    }

    return null;
  };

  return (
    <div className="giftcard-list">
      <h2>{getString("giftcard.choose.design")}</h2>
      {submitErrors && <ErrorMessage error={submitErrors} />}
      {renderContent()}
    </div>
  );
}

DesignList.propTypes = {
  designClickHandler: PropTypes.func.isRequired,
  designId: PropTypes.string.isRequired,
};
