import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { contentTypes, getContentType, getLangCode, getSearchParamString, language } from "../../config";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";

export default function FilterNav({ searchState }) {
  const navigate = useNavigate();

  const getActiveStyle = (type) => {
    return searchState.searchType === type ? "active" : "";
  };

  const navClickHandler = (type) => {
    const termParamString = getSearchParamString("term");
    const typeParamString = getSearchParamString("type");
    const filterType = getContentType(type, { langCode: getLangCode() });
    const location = {
      pathname: routeCodes.SEARCH,
      search: `?${termParamString}=${encodeURIComponent(searchState.searchTerm)}&${typeParamString}=${filterType}&id=0`,
    };
    navigate(location);
  };

  const resultCount = searchState.searchResults.length;

  const renderStandardsFilter = () => {
    if (getLangCode() !== language.default) {
      return null;
    }
    return (
      <button
        type="button"
        data-testid="standards-button"
        className={`button-flat-color ${getActiveStyle(contentTypes.standard)} pt-nav-standards`}
        onClick={() => {
          navClickHandler(contentTypes.standard);
        }}
      >
        {getString("standards.title.0")}
      </button>
    );
  };

  const renderBlogsFilter = () => {
    if (getLangCode() !== language.default) {
      return null;
    }
    return (
      <button
        type="button"
        className={`button-flat-color ${getActiveStyle(contentTypes.blog)} pt-nav-blog`}
        onClick={() => {
          navClickHandler(contentTypes.blog);
        }}
      >
        {getString("blog.title.2")}
      </button>
    );
  };

  return (
    <div className="search-filter-nav">
      {getString("search.filter")}
      <div className="pt-nav">
        <button
          type="button"
          className={`button-flat-color ${getActiveStyle(contentTypes.all)} filter-all`}
          onClick={() => {
            navClickHandler(contentTypes.all);
          }}
        >
          {getString("all.0")}
        </button>

        <button
          type="button"
          className={`button-flat-color ${getActiveStyle(contentTypes.game)} filter-games`}
          onClick={() => {
            navClickHandler(contentTypes.game);
          }}
        >
          {getString("games.title.1")}
        </button>

        <button
          type="button"
          className={`button-flat-color ${getActiveStyle(contentTypes.printable)} filter-printables`}
          onClick={() => {
            navClickHandler(contentTypes.printable);
          }}
        >
          {getString("printables.title.0")}
        </button>

        {renderStandardsFilter()}

        {renderBlogsFilter()}

        <button
          type="button"
          className={`button-flat-color ${getActiveStyle(contentTypes.faq)} filter-faq`}
          onClick={() => {
            navClickHandler(contentTypes.faq);
          }}
        >
          {getString("faq.title.1")}
        </button>
      </div>
      {getString("search.resultCounts", {
        replace: [resultCount, searchState.pageId + 1, searchState.pageCount],
      })}
    </div>
  );
}

FilterNav.propTypes = {
  searchState: PropTypes.object.isRequired,
};
