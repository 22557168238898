import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getMediaUrl, variousURLs, arcademicsIntroDuration } from "../../config";
import ArcademicsHTML5 from "./arcademicsHTML5";
import { clickToPlayVideoSelector } from "../../redux/slices/preContentVideoSlice";
import ClickToPlayVideo from "../ads/ClickToPlayVideo";

export default function DesktopContent({ game, isArcademics }) {
  const ifrm = useRef();
  const clickToPlayVideo = useSelector(clickToPlayVideoSelector);

  useEffect(() => {
    if (!isArcademics && game?.shortname) {
      ifrm.current.contentWindow.location.replace(`${getMediaUrl()}/games/${game.shortname}/html/index.html`);
    }
  }, [game]);

  if (isArcademics) {
    return (
      <ArcademicsHTML5
        gameName={game.arcademicsName}
        settings={{
          introURL: variousURLs.arcademics.intro,
          introDuration: arcademicsIntroDuration,
          endGameEnabled: false,
          printEnabled: false,
        }}
      />
    );
  }

  const gameVisibilityStyle = clickToPlayVideo
    ? {
        opacity: 0,
        visibility: "hidden",
      }
    : null;

  return (
    <>
      {clickToPlayVideo && <ClickToPlayVideo />}

      <iframe
        id="abcya-game-iframe"
        title={game.shortname}
        style={gameVisibilityStyle}
        frameBorder="0"
        ref={ifrm}
        allow="accelerometer *;magnetometer *;gyroscope *;"
      />
    </>
  );
}

DesktopContent.propTypes = {
  game: PropTypes.object.isRequired,
  isArcademics: PropTypes.bool.isRequired,
};
