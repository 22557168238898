import {
  isMobile,
  isIOS,
  isIOS13,
  isIPhone13,
  isIPad13,
  isIPod13,
  getUA,
  isTablet,
  browserVersion,
  isIE,
  isEdge,
  isSafari,
} from "react-device-detect";
// import { breakpoints } from '../config'

export const isKindle = () => {
  return !!(
    getUA.match(/Kindle/i) ||
    getUA.match(/Silk/i) ||
    getUA.match(/KFTT/i) ||
    getUA.match(/KFOT/i) ||
    getUA.match(/KFJWA/i) ||
    getUA.match(/KFJWI/i) ||
    getUA.match(/KFSOWI/i) ||
    getUA.match(/KFDOWI/i) ||
    getUA.match(/KFTHWA/i) ||
    getUA.match(/KFTHWI/i) ||
    getUA.match(/KFAPWA/i) ||
    getUA.match(/KFAPWI/i)
  );
};

export const mobile = isMobile || isTablet || isIOS13 || isIPhone13 || isIPad13 || isIPod13 || isKindle();

export const mobileStrict = isMobile && !isTablet && !isKindle();

export const isMobileApple = isIOS || isIOS13 || isIPhone13 || isIPad13 || isIPod13;
export const isIOS10OrLess = isIOS && parseFloat(browserVersion) < 11;
export const isIOS12OrLess = isIOS && parseFloat(browserVersion) < 13;

export const webp = !((isSafari || isIOS) && parseFloat(browserVersion) < 14);

// export const adsMobile = mobile || (!mobile && window.innerWidth < breakpoints.width.md)

const botPattern =
  "(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebisCrawler|FAST Enterprise isCrawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerisCrawler|webmon |httrack|webisCrawler|grub.org|UsineNouvelleisCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix isCrawler|ahrefsbot|Aboundex|domainisCrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketisCrawler|drupact|webcompanyisCrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkisCrawler|coccoc|integromedb|content isCrawler spider|toplistbot|seokicks-robot|it2media-domain-isCrawler|ip-web-isCrawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotisCrawler|urlappendbot|brainobot|fr-isCrawler|binlar|SimpleisCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|isCrawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";

const re = new RegExp(botPattern, "i");

export const isCrawler = re.test(getUA);

export const isPrerender = /HeadlessChrome/.test(getUA) || /prerender/.test(getUA);

export const canPrint = (!isIE && !isIOS10OrLess) || isEdge;

export const hasTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

export const getScrollbarWidth = () => {
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll";
  document.body.appendChild(outer);

  const inner = document.createElement("div");
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

export const isTesting = () => {
  const { hostname } = window.location;
  // possible future use || hostname === "la-testing.abcya.com"
  return hostname === "testing.abcya.com";
};
