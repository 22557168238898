import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Leader from "../ads/leader";
import { breakpoints } from "../../config";
import { metaType } from "../../config/meta";
import CategoryNav from "./categoryNav";
import PrintableCategories from "./printableCategories";
import { getString } from "../../utilities";

import planetBgPrintablesPngXs from "../../../assets/img/planetBgPrintables_xs.png";
import planetBgPrintablesWebpXs from "../../../assets/img/planetBgPrintables_xs.webp";
import planetBgPrintablesPngSm from "../../../assets/img/planetBgPrintables_sm.png";
import planetBgPrintablesWebpSm from "../../../assets/img/planetBgPrintables_sm.webp";
import planetBgPrintablesPngMd from "../../../assets/img/planetBgPrintables_md.png";
import planetBgPrintablesWebpMd from "../../../assets/img/planetBgPrintables_md.webp";
import planetBgPrintablesPngLg from "../../../assets/img/planetBgPrintables_lg.png";
import planetBgPrintablesWebpLg from "../../../assets/img/planetBgPrintables_lg.webp";
import planetBgPrintablesPngXl from "../../../assets/img/planetBgPrintables_xl.png";
import planetBgPrintablesWebpXl from "../../../assets/img/planetBgPrintables_xl.webp";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";

export default function Printables() {
  const location = useLocation();

  const [categoryLists, setCategoryLists] = useState(null);

  const { lists, printables: prints } = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);

  const initPrintables = () => {
    const nextCategoryLists = lists.filter((list) => list.group === "printable-category");
    setCategoryLists(nextCategoryLists);
  };

  useEffect(() => {
    if (!loading) {
      initPrintables();
    }
  }, [loading]);

  const metaObject = {
    metaTitle: getString("printables.meta.title"),
    metaDescription: getString("printables.meta.description"),
    metaKeywords: getString("printables.meta.keywords"),
  };

  return (
    <>
      {metaType("WebSite", metaObject, location.pathname)}

      <div className="printables">
        <div className="printables-text">
          <div className="printables-title">{getString("printables.title.0")}</div>
        </div>

        <div className="printables-nav-categories">
          <CategoryNav />
        </div>

        <div className="printables-art-container">
          <picture>
            <source
              srcSet={planetBgPrintablesWebpXs}
              media={`(max-width: ${breakpoints.width.xs}px)`}
              type="image/webp"
            />
            <source
              srcSet={planetBgPrintablesPngXs}
              media={`(max-width: ${breakpoints.width.xs}px)`}
              type="image/png"
            />

            <source
              srcSet={planetBgPrintablesWebpSm}
              media={`(max-width: ${breakpoints.width.sm}px)`}
              type="image/webp"
            />
            <source
              srcSet={planetBgPrintablesPngSm}
              media={`(max-width: ${breakpoints.width.sm}px)`}
              type="image/png"
            />

            <source
              srcSet={planetBgPrintablesWebpMd}
              media={`(max-width: ${breakpoints.width.md}px)`}
              type="image/webp"
            />
            <source
              srcSet={planetBgPrintablesPngMd}
              media={`(max-width: ${breakpoints.width.md}px)`}
              type="image/png"
            />

            <source
              srcSet={planetBgPrintablesWebpLg}
              media={`(max-width: ${breakpoints.width.lg}px)`}
              type="image/webp"
            />
            <source
              srcSet={planetBgPrintablesPngLg}
              media={`(max-width: ${breakpoints.width.lg}px)`}
              type="image/png"
            />

            <source srcSet={planetBgPrintablesWebpXl} type="image/webp" />
            <source srcSet={planetBgPrintablesPngXl} type="image/png" />

            <img width="1920" height="234" src="" alt={getString("printables.meta.description")} />
          </picture>
        </div>
      </div>

      <Leader className="green" />
      <PrintableCategories categoryLists={categoryLists} printables={prints} />
    </>
  );

  // printables will end up "undefined"
  // data was fetched but printables categoryGroup was empty/missing
}
