import React, { useState } from "react";
import { isSiteDown } from "../../config";
import { getString } from "../../utilities";
import OKModal from "./okModal";

export default function ServiceDownWarningModal() {
  const [doShow, setDoShow] = useState(isSiteDown);

  return (
    <OKModal title={getString("attention.0")} button={getString("ok")} close={() => setDoShow(false)} doShow={doShow}>
      <p>{getString("externalServiceDown")}</p>
    </OKModal>
  );
}
