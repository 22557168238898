import React from "react";
import PropTypes from "prop-types";
import { breakpoints } from "../../config";

import planetBgColoringWebpXs from "../../../assets/img/planetBgColoring_xs.webp";
import planetBgColoringPngXs from "../../../assets/img/planetBgColoring_xs.png";
import planetBgColoringWebpSm from "../../../assets/img/planetBgColoring_sm.webp";
import planetBgColoringPngSm from "../../../assets/img/planetBgColoring_sm.png";
import planetBgColoringWebpMd from "../../../assets/img/planetBgColoring_md.webp";
import planetBgColoringPngMd from "../../../assets/img/planetBgColoring_md.png";
import planetBgColoringWebpLg from "../../../assets/img/planetBgColoring_lg.webp";
import planetBgColoringPngLg from "../../../assets/img/planetBgColoring_lg.png";
import planetBgColoringWebpXl from "../../../assets/img/planetBgColoring_xl.webp";
import planetBgColoringPngXl from "../../../assets/img/planetBgColoring_xl.png";

import planetBgActivityWebpXs from "../../../assets/img/planetBgActivity_xs.webp";
import planetBgActivityPngXs from "../../../assets/img/planetBgActivity_xs.png";
import planetBgActivityWebpSm from "../../../assets/img/planetBgActivity_sm.webp";
import planetBgActivityPngSm from "../../../assets/img/planetBgActivity_sm.png";
import planetBgActivityWebpMd from "../../../assets/img/planetBgActivity_md.webp";
import planetBgActivityPngMd from "../../../assets/img/planetBgActivity_md.png";
import planetBgActivityWebpLg from "../../../assets/img/planetBgActivity_lg.webp";
import planetBgActivityPngLg from "../../../assets/img/planetBgActivity_lg.png";
import planetBgActivityWebpXl from "../../../assets/img/planetBgActivity_xl.webp";
import planetBgActivityPngXl from "../../../assets/img/planetBgActivity_xl.png";

import planetBgWorksheetWebpXs from "../../../assets/img/planetBgWorksheet_xs.webp";
import planetBgWorksheetPngXs from "../../../assets/img/planetBgWorksheet_xs.png";
import planetBgWorksheetWebpSm from "../../../assets/img/planetBgWorksheet_sm.webp";
import planetBgWorksheetPngSm from "../../../assets/img/planetBgWorksheet_sm.png";
import planetBgWorksheetWebpMd from "../../../assets/img/planetBgWorksheet_md.webp";
import planetBgWorksheetPngMd from "../../../assets/img/planetBgWorksheet_md.png";
import planetBgWorksheetWebpLg from "../../../assets/img/planetBgWorksheet_lg.webp";
import planetBgWorksheetPngLg from "../../../assets/img/planetBgWorksheet_lg.png";
import planetBgWorksheetWebpXl from "../../../assets/img/planetBgWorksheet_xl.webp";
import planetBgWorksheetPngXl from "../../../assets/img/planetBgWorksheet_xl.png";

import planetBgResourceWebpXs from "../../../assets/img/planetBgResource_xs.webp";
import planetBgResourcePngXs from "../../../assets/img/planetBgResource_xs.png";
import planetBgResourceWebpSm from "../../../assets/img/planetBgResource_sm.webp";
import planetBgResourcePngSm from "../../../assets/img/planetBgResource_sm.png";
import planetBgResourceWebpMd from "../../../assets/img/planetBgResource_md.webp";
import planetBgResourcePngMd from "../../../assets/img/planetBgResource_md.png";
import planetBgResourceWebpLg from "../../../assets/img/planetBgResource_lg.webp";
import planetBgResourcePngLg from "../../../assets/img/planetBgResource_lg.png";
import planetBgResourceWebpXl from "../../../assets/img/planetBgResource_xl.webp";
import planetBgResourcePngXl from "../../../assets/img/planetBgResource_xl.png";

export default function PrintableCategoryArt({ shortname, description }) {
  // must go smallest to largest for media query max-width usage
  // must have webp first for usage as png is the fallback
  const categoryArt = {
    "coloring-pages": {
      xs: {
        webp: planetBgColoringWebpXs,
        png: planetBgColoringPngXs,
      },
      sm: {
        webp: planetBgColoringWebpSm,
        png: planetBgColoringPngSm,
      },
      md: {
        webp: planetBgColoringWebpMd,
        png: planetBgColoringPngMd,
      },
      lg: {
        webp: planetBgColoringWebpLg,
        png: planetBgColoringPngLg,
      },
      xl: {
        webp: planetBgColoringWebpXl,
        png: planetBgColoringPngXl,
      },
    },
    "activity-pages": {
      xs: {
        webp: planetBgActivityWebpXs,
        png: planetBgActivityPngXs,
      },
      sm: {
        webp: planetBgActivityWebpSm,
        png: planetBgActivityPngSm,
      },
      md: {
        webp: planetBgActivityWebpMd,
        png: planetBgActivityPngMd,
      },
      lg: {
        webp: planetBgActivityWebpLg,
        png: planetBgActivityPngLg,
      },
      xl: {
        webp: planetBgActivityWebpXl,
        png: planetBgActivityPngXl,
      },
    },
    worksheets: {
      xs: {
        webp: planetBgWorksheetWebpXs,
        png: planetBgWorksheetPngXs,
      },
      sm: {
        webp: planetBgWorksheetWebpSm,
        png: planetBgWorksheetPngSm,
      },
      md: {
        webp: planetBgWorksheetWebpMd,
        png: planetBgWorksheetPngMd,
      },
      lg: {
        webp: planetBgWorksheetWebpLg,
        png: planetBgWorksheetPngLg,
      },
      xl: {
        webp: planetBgWorksheetWebpXl,
        png: planetBgWorksheetPngXl,
      },
    },
    "teacher-resources": {
      xs: {
        webp: planetBgResourceWebpXs,
        png: planetBgResourcePngXs,
      },
      sm: {
        webp: planetBgResourceWebpSm,
        png: planetBgResourcePngSm,
      },
      md: {
        webp: planetBgResourceWebpMd,
        png: planetBgResourcePngMd,
      },
      lg: {
        webp: planetBgResourceWebpLg,
        png: planetBgResourcePngLg,
      },
      xl: {
        webp: planetBgResourceWebpXl,
        png: planetBgResourcePngXl,
      },
    },
  };

  const renderSources = () => {
    const artObject = categoryArt[shortname];
    const artArray = Object.keys(artObject);
    let keyCount = 0;

    return artArray.map((artKey, index) => {
      const sourceObject = artObject[artKey];
      const sourceArray = Object.keys(sourceObject);

      const media = index === artArray.length - 1 ? "" : `(max-width: ${breakpoints.width[artKey]}px)`;

      return sourceArray.map((sourceKey) => {
        keyCount++;
        return (
          <source
            key={`artKey${keyCount}`}
            srcSet={sourceObject[sourceKey]}
            media={media}
            type={`image/${sourceKey}`}
          />
        );
      });
    });
  };

  return (
    <picture>
      {renderSources()}
      {/* height of image is based on average */}
      {/* not all planet bg images are the same height */}
      <img width="1920" height="269" src="" alt={description || ""} />
    </picture>
  );
}

PrintableCategoryArt.propTypes = {
  shortname: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
