import React from "react";
import { useSelector } from "react-redux";
import { mobile } from "../../utilities/detection";
import BillboardArrow from "../global/billboardArrow";
import { userRoles } from "../../config";
import { isLoggedInWithRole } from "../../utilities";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";
import BillboardAd from "../ads/Billboard";

export default function Billboard() {
  const isLoggedIn = useSelector(isLoggedInSelector);

  if (!mobile) {
    if (!isLoggedIn || isLoggedInWithRole(userRoles.free)) {
      return (
        <div className="billboard-ad">
          <BillboardArrow />
          <div className="billboard-content-container">
            <BillboardAd />
          </div>
          <BillboardArrow className="right" />
        </div>
      );
    }
  }

  return null;
}
