import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { isCrawler, isPrerender } from "../../utilities/detection";
import { getString } from "../../utilities";
import X from "../../../assets/svg/x.svg";
import { adsSelector } from "../../redux/slices/loginSlice";
import { adBlockingSelector } from "../../redux/slices/userClientSlice";

export default function AdblockContentMessage({ message }) {
  const location = useLocation();
  const adBlocking = useSelector(adBlockingSelector);
  const ads = useSelector(adsSelector);
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    setShowMessage(true);
  }, [location]);

  return !isCrawler && !isPrerender && ads && adBlocking && showMessage ? (
    <div
      className="ablock-contentmessage"
      onClick={() => {
        setShowMessage(false);
      }}
    >
      {message}
      <div className="adblock-contentmessage-close">
        <X alt={getString("navigation.close.0")} title={getString("navigation.close.0")} />
      </div>
    </div>
  ) : null;
}

AdblockContentMessage.propTypes = {
  message: PropTypes.string.isRequired,
};
