import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MiniBanner from "../global/miniBanner";
import { contentTypes } from "../../config";
import { metaDefault } from "../../config/meta";
import { routeCodes } from "../../config/routes";
import { getString, getSuggestions, sortedArrayByItem } from "../../utilities";
import { searchArray } from "../../utilities/search";
import LoadingAnim from "../global/loadingAnim";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import StandardsModal from "../global/standardsModal";
import SearchBox from "../global/SearchBox";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";
import { renderDesktopSignupModal } from "../../utilities/printables";

export default function StandardsPrintable() {
  const location = useLocation();
  const navigate = useNavigate();
  const printableContainer = useRef();
  const [printables, setPrintables] = useState(null);
  const [hasListeners, setHasListeners] = useState(false);
  const [searchedPrintables, setSearchedPrintables] = useState(null);
  const [searching, setSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadCounter, setLoadCounter] = useState(1);
  const [selectedPrintable, setSelectedPrintable] = useState(null);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (load) setLoadCounter(loadCounter + 1);
  }, [load]);

  const isLoggedIn = useSelector(isLoggedInSelector);
  const { printables: dataPrintables, standards: dataStandards } = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);

  const settingPrintables = async () => {
    // filter out all printables that don't have standards assigned to them
    const printablesWithStandards = dataPrintables.filter((print) => print.standards.length > 0);

    const ap = await sortedArrayByItem(printablesWithStandards, "fullname");
    if (ap !== printables) setPrintables(ap);
  };

  useEffect(() => {
    if (dataPrintables && dataStandards) settingPrintables();
  }, [dataPrintables, dataStandards]);

  const resetMiniBanners = () => {
    // infinite scroll load for game minibanners
    if (printableContainer.current) {
      const buff = 30; // show more button for over scrolling
      const gc = printableContainer.current.getBoundingClientRect();
      const { clientHeight } = document.documentElement;
      const shouldLoad = gc.height + gc.top <= clientHeight + buff;

      setLoad(shouldLoad);
    }
  };

  useEffect(() => {
    if (!hasListeners) {
      setHasListeners(true);

      setTimeout(() => {
        window.addEventListener("scroll", resetMiniBanners);
        window.addEventListener("resize", resetMiniBanners);
      }, 100);
    }

    return () => {
      // componentWillUnmount (cleanup)
      window.removeEventListener("scroll", resetMiniBanners);
      window.removeEventListener("resize", resetMiniBanners);
    };
  }, []);

  const standardsPrintableClickHandler = (print) => {
    if (modal) {
      setModal(false);
      setSelectedPrintable(null);
    }
    setModal(true);
    setSelectedPrintable(print);
  };

  const renderPrintables = () => {
    if (loading) {
      return <LoadingAnim />;
    }

    if (!loading && printables) {
      const printableObjects = searchedPrintables || printables;

      if (searching) {
        return <LoadingAnim />;
      }

      if (printableObjects.length > 0) {
        // 18 is 3 rows of minibanners at 1366 x 768
        // most popular size (chrome books)
        const mbCount = 18;
        const pi = loadCounter * mbCount;
        const more = pi < printableObjects.length;
        const mbToRender = more ? printableObjects.slice(0, pi) : printableObjects;

        return (
          <>
            {mbToRender.map((print, index) => (
              <MiniBanner
                key={print.id}
                type={contentTypes.printable}
                title={print.fullname}
                path={print.shortname}
                image={print.miniBanner ? print.miniBanner : null}
                style="mb-portrait mb-no-icon"
                grades={print.grades}
                hasNoScroll={true}
                clickHandler={() => {
                  standardsPrintableClickHandler(print);
                }}
                tabIndex={String(index + 1)}
              />
            ))}
            <OptionallyDisplayed doDisplay={more}>
              <div className="loadmore-wrapper">
                <button
                  type="button"
                  onClick={() => {
                    setLoadCounter(loadCounter + 1);
                  }}
                  className="button-flat-color orange round dimensional auto"
                >
                  {getString("navigation.more.1")}
                </button>
              </div>
            </OptionallyDisplayed>
          </>
        );
      }
    }

    return <div className="search-noResults">{getString("search.noresults")}</div>;
  };

  const renderModal = () => {
    if (selectedPrintable) {
      const standards = selectedPrintable.standards.map((s) => {
        return dataStandards.find((ds) => ds.id === s);
      });

      return (
        <StandardsModal
          standards={standards}
          click={() => {
            if (isLoggedIn) {
              navigate(`${routeCodes.PRINTABLES}${selectedPrintable.localeShortname || selectedPrintable.shortname}`);
            } else {
              setModal(false);
              renderDesktopSignupModal();
            }
          }}
          close={() => {
            setModal(false);
          }}
          doShow={modal}
          header={selectedPrintable.fullname}
        >
          {getString("standards.page.2")}
        </StandardsModal>
      );
    }

    return null;
  };

  const showSearch = !loading && printables && printables.length > 0;

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("standards.printables") })}

      <div className="wrapper visible padded even standards-search-wrapper">
        <SearchBox
          searched={searchedPrintables}
          setSearched={setSearchedPrintables}
          setSearching={setSearching}
          search={async (searchQuery) => await searchArray(printables, searchQuery, contentTypes.printable)}
          onSuggestionsFetchRequested={({ value }) => {
            setSuggestions(
              getSuggestions(value, printables, "fullname")
                .map((printable) => printable.fullname)
                .sort(),
            );
          }}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          showSearch={showSearch}
          placeholder={getString("search.cta", { replace: [getString("printables.title.0")] })}
        />
      </div>

      <div className="standards-content">
        <div className="container standards-content-container" ref={printableContainer}>
          {renderPrintables()}
        </div>
      </div>

      {renderModal()}
    </>
  );
}
