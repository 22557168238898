import React from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../../redux/store";
import Modal from "../global/modal";
import DesktopSignupForm from "./DesktopSignupForm";
import { setShowDesktopSignupModal, showDesktopSignupModalSelector } from "../../redux/slices/desktopSignupSlice";

export default function DesktopSignupModal() {
  const dispatch = useDispatch();

  const showDesktopSignupModal = useSelector(showDesktopSignupModalSelector);

  return (
    <Modal
      doShow={showDesktopSignupModal}
      closeOnEscape={() => {
        const canEscape = store().getState().desktopSignup.canEscape;

        if (canEscape) {
          dispatch(
            setShowDesktopSignupModal({
              showDesktopSignupModal: false,
            }),
          );
        }
      }}
    >
      <DesktopSignupForm />
    </Modal>
  );
}
