import React from "react";
import PropTypes from "prop-types";
import { CardElement } from "@stripe/react-stripe-js";
import { useFormContext } from "react-hook-form";
import { getString } from "../../utilities";

export default function PaymentInfo({
  setCreditComplete,
  setGiftCards,
  setPreviewModal,
  setPurchaseModal,
  quantity,
  isLoading,
  total,
  submitErrors = null,
  setSubmitErrors,
}) {
  const { getValues, handleSubmit } = useFormContext();

  const updateGiftCards = () => {
    const values = getValues();
    const nextGiftCards = [];
    for (let i = 0; i < quantity; ++i) {
      const recipientEmail = values[`recipientEmail${i}`];
      const message = values[`message${i}`];
      nextGiftCards.push({ recipientEmail, message });
    }
    setGiftCards(nextGiftCards);
  };

  const submitHandler = () => {
    updateGiftCards();
    if (!isLoading) {
      setPurchaseModal((prev) => !prev);
    }
  };

  return (
    <div className="wrapper padded">
      <div className="container-small">
        <h2>{getString("subscribe.payment")}</h2>

        <CardElement
          onChange={(data) => {
            setCreditComplete(data.complete);
            if (submitErrors) {
              setSubmitErrors(null);
            }
          }}
        />

        <div className="giftcard-total">
          <strong>{`${getString("total.0")}: `}</strong>
          {total}
        </div>

        <div className="giftcard-details-instructions">{getString("giftcard.disclaimer")}</div>

        <button
          type="button"
          className="button-flat-color green"
          onClick={(e) => {
            e.preventDefault();

            updateGiftCards();
            setPreviewModal((prev) => !prev);
          }}
        >
          {getString("giftcard.preview")}
        </button>

        <button
          type="button"
          className="button-flat-color green"
          disabled={isLoading}
          onClick={handleSubmit(submitHandler)}
        >
          {getString("giftcard.purchase.1")}
        </button>

        <div className="giftcard-details-captcha">{getString("recaptcha", { html: true })}</div>
      </div>
    </div>
  );
}

PaymentInfo.propTypes = {
  setCreditComplete: PropTypes.func.isRequired,
  setGiftCards: PropTypes.func.isRequired,
  setPreviewModal: PropTypes.func.isRequired,
  setPurchaseModal: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  total: PropTypes.string.isRequired,
  submitErrors: PropTypes.object,
  setSubmitErrors: PropTypes.func.isRequired,
};
