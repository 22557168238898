import React from "react";
import PropTypes from "prop-types";
import Flare from "../../../assets/img/flare.png";

export default function FlareShimmer({ filePath }) {
  return (
    <>
      <div className="shimmer-img-container">
        <div className="shimmer-img">
          <img src={filePath} alt="Shimmer animation" />
        </div>
      </div>
      <div className="flare-img bottom-right">
        <img src={Flare} alt="Flare animation" />
      </div>
      <div className="flare-img upper-left">
        <img src={Flare} alt="Flare animation" />
      </div>
    </>
  );
}

FlareShimmer.propTypes = {
  filePath: PropTypes.string.isRequired,
};
