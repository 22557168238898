import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Featured from "./featured";
import Navigation from "./navigation";
import Copyright from "./copyright";
import { loadingSelector } from "../../redux/slices/dataSlice";

export default function Footer() {
  const [display, setDisplay] = useState(false);
  const loading = useSelector(loadingSelector);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setDisplay(true);
      }, 500);
    }
  }, [loading]);

  if (display) {
    return (
      <div className="footer">
        <Featured />
        <Navigation />
        <Copyright />
      </div>
    );
  }
  return null;
}
