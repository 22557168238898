import React from "react";
import PropTypes from "prop-types";
import { getString } from "../../utilities";

export default function Control({
  isActive,
  className,
  label,
  icon,
  clickFunc,
  isDisabled,
  onText = getString("on.0"),
  offText = getString("off.0"),
}) {
  const activeStyle = isActive ? "active" : "";
  const controlStyle = className || "";
  const id = label.toLowerCase();

  return (
    <div className="control">
      <label htmlFor={id}>{label}</label>
      <button
        type="button"
        id={id}
        disabled={isDisabled}
        className={`control-button ${activeStyle} ${controlStyle}`}
        onClick={clickFunc}
      >
        {isActive ? onText : offText}
        {isActive ? icon.active : icon.inactive || icon.active}
      </button>
    </div>
  );
}

Control.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  className: PropTypes.string,
  icon: PropTypes.object.isRequired,
  clickFunc: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onText: PropTypes.string,
  offText: PropTypes.string,
};
