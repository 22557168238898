import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getString, sortedArrayByItem } from "../../utilities";
import LoadingAnim from "../global/loadingAnim";
import BlogMinibanner from "../blog/blogMinibanner";
import { routeCodes } from "../../config/routes";
import Arrow from "../../../assets/svg/arrowRight.svg";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";

export default function RecentBlogs() {
  const navigate = useNavigate();

  const [posts, setPosts] = useState([]);
  const data = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);

  useEffect(() => {
    if (data?.posts && data.posts.length > 0) {
      const recentPosts = sortedArrayByItem(data.posts, "postDate", true).slice(0, 3);

      setPosts(recentPosts);
    }
  }, [data]);

  if (loading) {
    return (
      <div className="wrapper padded">
        <LoadingAnim />
      </div>
    );
  }

  if (!loading && posts.length > 0) {
    return (
      <div className="wrapper padded blog-posts-wrapper even">
        <h2>{getString("parentsTeachers.recentBlogs")}</h2>

        <div className="blog-more-container">
          <button
            type="button"
            onClick={() => {
              navigate(routeCodes.BLOG);
            }}
            className="button-flat-color pt-cyan round auto"
          >
            {getString("parentsTeachers.visit")}
            <Arrow className="inline-arrow" />
          </button>
        </div>

        <div className="container-medium blog-posts-container">
          {posts.map((post, index) => {
            const pmbKey = `pmbKey${index}`;
            return <BlogMinibanner key={pmbKey} post={post} />;
          })}
        </div>
      </div>
    );
  }

  return null;
}
