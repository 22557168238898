import { contentTypes } from "../config";

export const searchArray = async (objectArray, search, type) => {
  const cleanSearch = search.trim().toLowerCase();

  if (cleanSearch.length > 0) {
    const exactmatch = objectArray.find((obj) => {
      if ([contentTypes.game, contentTypes.printable].includes(type)) {
        return obj?.fullname?.trim().toLowerCase() === cleanSearch;
      }

      if (type === contentTypes.standard) {
        return obj?.tag?.trim().toLowerCase() === cleanSearch;
      }

      if (type === contentTypes.faq) {
        return obj?.question?.trim().toLowerCase() === cleanSearch;
      }

      if (type === contentTypes.blog) {
        return obj?.title?.trim().toLowerCase() === cleanSearch;
      }

      return null;
    });

    if (exactmatch) {
      return [exactmatch];
    }

    const getOptions = () => {
      switch (type) {
        case contentTypes.printable:
        case contentTypes.game:
          return {
            shouldSort: true,
            threshold: 0.34,
            minMatchCharLength: 2,
            maxPatternLength: 32,
            keys: [
              {
                name: "fullname",
                weight: 0.6,
              },
              { name: "shortname", weight: 0.1 },
              { name: "metaTitle", weight: 0.1 },
              { name: "metaKeywords", weight: 0.1 },
              { name: "searchKeywords", weight: 0.1 },
            ],
          };

        case contentTypes.standard:
          return {
            shouldSort: true,
            findAllMatches: true,
            threshold: 0.3,
            location: 0,
            distance: 1000,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
              {
                name: "tag",
                weight: 0.75,
              },
              { name: "description", weight: 0.25 },
            ],
          };

        case contentTypes.faq:
          return {
            shouldSort: true,
            findAllMatches: true,
            threshold: 0.3,
            location: 0,
            distance: 1000,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
              {
                name: "question",
                weight: 0.75,
              },
              { name: "answer", weight: 0.25 },
            ],
          };

        case contentTypes.blog:
          return {
            shouldSort: true,
            findAllMatches: true,
            threshold: 0.3,
            location: 0,
            distance: 1000,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
              {
                name: "title",
                weight: 0.75,
              },
              { name: "briefDescription", weight: 0.25 },
            ],
          };

        default:
          return undefined;
      }
    };

    const Fuse = (await import("fuse.js")).default;

    const fuse = new Fuse(objectArray, getOptions());

    return fuse.search(cleanSearch).map((si) => si.item);
  }

  return null;
};
