import { getString } from ".";
import { getStartupDataUrls, getGradeInfoUrl, getGameInfoUrl } from "../config";

export const startupDataFetcher = async () => {
  return Promise.all(
    getStartupDataUrls().map((url) => {
      return fetch(url).then((response) => {
        return response.json();
      });
    }),
  );
};

export const educatorInfoDataFetcher = async (id) => {
  const isGrade = Number.isInteger(id);
  const filePath = isGrade ? getGradeInfoUrl(id) : getGameInfoUrl(id);

  const response = await fetch(filePath);

  if (!response.ok) {
    throw new Error(getString("educatorInfoErrorMessage", { replace: [response.status] }));
  }

  const infoData = await response.json();
  return infoData;
};
