import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";

import { ACCOUNT_INFO_QUERY } from "../../graphql/queries";
import { metaDefault } from "../../config/meta";
import { billingPlatform, userRoles, stripePublishableKeys } from "../../config";
import { getString, login, logout } from "../../utilities";
import ManageNav from "./manageNav";
import AccountInfo from "./accountInfo";
import PaymentInfo from "./paymentInfo";
import ManageSubscription from "./manageSubscription";
import CancelSubscription from "./cancelSubscription";
import OKModal from "../global/okModal";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import { billingPlatformSelector, planIdSelector, roleSelector } from "../../redux/slices/loginSlice";
import { useLazyQuery } from "../../hooks";
import { getStripeLocale } from "../../utilities/stripe";

export default function Manage() {
  const stripePromise = useMemo(() => loadStripe(stripePublishableKeys[process.env.NODE_ENV]), []);

  const [isUpdating, setIsUpdating] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [successReload, setSuccessReload] = useState(false);
  const [successToken, setSuccessToken] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);
  const [queryError, setQueryError] = useState(null);

  const location = useLocation();
  const loginBillingPlatform = useSelector(billingPlatformSelector);
  const planId = useSelector(planIdSelector);
  const role = useSelector(roleSelector);

  const { loading, executeQuery } = useLazyQuery(
    ACCOUNT_INFO_QUERY,
    (data) => setAccountInfo(data.accountInfo),
    (error) => setQueryError(error),
  );
  useEffect(() => {
    executeQuery();
  }, []);

  const successModalOpen = ({ message, title, reload, token }) => {
    setSuccessModal(true);
    setSuccessMessage(message);
    setSuccessTitle(title || "");
    setSuccessReload(reload || false);
    setSuccessToken(token || null);
  };

  const isPremiumWeb = loginBillingPlatform === billingPlatform;

  const cancel =
    (isPremiumWeb && accountInfo && accountInfo.sourceExists) ||
    (loginBillingPlatform === "NONE" && planId !== "friends");

  const renderContent = () => {
    if (loading) {
      return (
        <div className="wrapper padded">
          <LoadingAnim />
        </div>
      );
    }

    if (queryError) {
      return (
        <div className="wrapper padded">
          <div className="container-small">
            <ErrorMessage error={queryError} />
          </div>
        </div>
      );
    }

    const studentAccess =
      accountInfo && (accountInfo.accessCode || accountInfo.accessName)
        ? { accessCode: accountInfo.accessCode, accessName: accountInfo.accessName }
        : null;

    const isCanceled =
      accountInfo && Object.prototype.hasOwnProperty.call(accountInfo, "canceled") ? accountInfo.canceled : true;

    return (
      <>
        <AccountInfo
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
          success={successModalOpen}
          studentAccess={studentAccess}
        />

        <Elements stripe={stripePromise} options={{ locale: getStripeLocale() }}>
          <PaymentInfo
            isPremiumWeb={isPremiumWeb}
            isCanceled={isCanceled}
            isUpdating={isUpdating}
            setIsUpdating={setIsUpdating}
            success={successModalOpen}
          />

          <ManageSubscription
            isPremiumWeb={isPremiumWeb}
            isUpdating={isUpdating}
            setIsUpdating={setIsUpdating}
            success={successModalOpen}
            accountInfo={accountInfo}
          />
        </Elements>

        <CancelSubscription
          isPremiumWeb={isPremiumWeb}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
          success={successModalOpen}
          accountInfo={accountInfo}
          doCancel={cancel}
        />

        <div className="wrapper padded">
          <div className="container-small">
            <div className="form-terms">{getString("recaptcha", { html: true })}</div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("manage.title.1"), noCrawl: true })}

      <div className="manage">
        <div className="wrapper padded">
          <div className="container">
            <h1>{getString("manage.title.0")}</h1>
            <ManageNav isPremiumWeb={isPremiumWeb} isFree={role === userRoles.free} doCancel={cancel} />
          </div>
        </div>

        {renderContent()}
      </div>
      <div className="wrapper header-shadow" />

      <OKModal
        doShow={successModal}
        close={() => {
          if (successToken) {
            login({ token: successToken });
          } else {
            setSuccessModal(false);
            setIsUpdating(false);
            if (successReload) {
              logout();
            }
          }
        }}
        title={successTitle?.length > 0 ? successTitle : getString("update.2")}
      >
        {successMessage}
      </OKModal>
    </>
  );
}
