import React from "react";
import PropTypes from "prop-types";
import BubbleTip from "../../../assets/svg/bubbleTip.svg";
import { purifiedParsedString } from "../../utilities";

export default function Testimony(props) {
  const { data } = props;

  if (data) {
    const testimonyClass = data.full ? "testimonials-testimony-full" : "testimonials-testimony";

    return (
      <div className={testimonyClass}>
        <div className="testimonials-testimony-quote">{purifiedParsedString(data.quote)}</div>
        <div className="testimonials-testimony-container">
          <div className="testimonials-testimony-name">{`${data.source.name},`}</div>
          <div className="testimonials-testimony-description">{data.source.description}</div>
          <BubbleTip className="testimonials-testimony-tip" />
        </div>
        <div className="testimonials-testimony-location">{data.source.location}</div>
      </div>
    );
  }

  return null;
}

Testimony.propTypes = {
  data: PropTypes.object.isRequired,
};
