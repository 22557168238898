import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { routeCodes } from "../../config/routes";
import { getString, isLoggedInWithoutRole } from "../../utilities";
import { getLangCode, language, userRoles, variousURLs } from "../../config";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import feedbackStrings from "../../strings/feedback";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";
import { leaveSiteHandler } from "../../redux/slices/leavingSiteSlice";

export default function ParentsTeachers() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();

  const renderStandards = () => {
    if (getLangCode() !== language.default) {
      return null;
    }
    return (
      <Link data-testid="standards-link" to={routeCodes.STANDARDS}>
        {getString("standards.title.1")}
      </Link>
    );
  };

  const renderBlog = () => {
    if (getLangCode() !== language.default) {
      return null;
    }
    return (
      <Link data-testid="blog-link" to={routeCodes.BLOG}>
        {getString("blog.title.4")}
      </Link>
    );
  };

  return (
    <div className="footer-nav-col">
      <div className="title">
        <span>{getString("parentsTeachers.title.0")}</span>
      </div>
      <OptionallyDisplayed doDisplay={isLoggedInWithoutRole(userRoles.student)}>
        <Link to={routeCodes.MANAGE}>{getString("manage.title.1")}</Link>
      </OptionallyDisplayed>

      <Link to={routeCodes.GIFTCARD}>{getString("giftcard.purchase.2")}</Link>

      <Link to={routeCodes.REDEEM}>{getString("giftcard.redeem.1")}</Link>

      {renderBlog()}

      {renderStandards()}

      <OptionallyDisplayed doDisplay={isLoggedIn}>
        <Link to={routeCodes.TOOLS}>{getString("tools.title.1")}</Link>
      </OptionallyDisplayed>

      <Link to={routeCodes.ABOUT}>{getString("about.title.1")}</Link>

      <Link to={routeCodes.FEEDBACK}>{getString("title.1", { strings: feedbackStrings })}</Link>

      <OptionallyDisplayed doDisplay={!isLoggedIn || isLoggedInWithoutRole(userRoles.student)}>
        <a
          onClick={() => {
            dispatch(leaveSiteHandler({ url: variousURLs.abcyaStore }));
          }}
        >
          {getString("store.0")}
        </a>
      </OptionallyDisplayed>
    </div>
  );
}
