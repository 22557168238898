import React from "react";
import PropTypes from "prop-types";
import { getString } from "../../utilities";
import { getLangCode, variousURLs } from "../../config";
import { useSelector } from "react-redux";
import { billingPlatformSelector } from "../../redux/slices/loginSlice";

export default function NoSourceContent({ planString }) {
  const billingPlatform = useSelector(billingPlatformSelector);

  const renderMessage = () => {
    if (planString === "ixl_family" || planString === "native") {
      const externalMessage =
        planString === "native" ? "manage.subscription.messages.native.0" : "manage.subscription.messages.ixl";
      const manageUrl =
        planString === "ixl_family"
          ? variousURLs.ixl.manage.family[getLangCode()]
          : variousURLs.nativeManage[billingPlatform];
      return (
        <>
          <p>{getString(externalMessage)}</p>
          <div className="manage-account-url">
            <p className="current-value">
              <a href={manageUrl} target="_blank" rel="noopener noreferrer">
                {manageUrl}
              </a>
            </p>
          </div>
        </>
      );
    } else if (planString === "premium") {
      return <p>{getString("manage.subscription.messages.web.0")}</p>;
    } else if (planString === "invoiced") {
      return <p>{getString("manage.subscription.messages.invoiced.0", { html: true })}</p>;
    }
    return <p>{getString("manage.subscription.messages.ixlSchool", { html: true })}</p>;
  };

  return <div className="container-small">{renderMessage()}</div>;
}

NoSourceContent.propTypes = {
  planString: PropTypes.string.isRequired,
};
