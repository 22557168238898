import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import MobileHome from "../../../assets/svg/mobileHome.svg";
import FullScreen from "../../../assets/svg/fullscreen.svg";
import { getString, fullscreenSupported } from "../../utilities";
import { getMobileHomeStyle, fullScreen } from "../../utilities/games";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import {
  fullScreenActiveSelector,
  setFullScreenActive,
  toggleFullScreenActive,
} from "../../redux/slices/userClientSlice";

export default function MobileGameButtons({ game, home }) {
  const dispatch = useDispatch();
  const fullScreenActive = useSelector(fullScreenActiveSelector);

  const fullScreenHandler = () => {
    dispatch(toggleFullScreenActive());
  };

  const addEventListeners = () => {
    document.addEventListener("webkitfullscreenchange", fullScreenHandler, false);
    document.addEventListener("mozfullscreenchange", fullScreenHandler, false);
    document.addEventListener("fullscreenchange", fullScreenHandler, false);
    document.addEventListener("MSFullscreenChange", fullScreenHandler, false);
  };

  const removeEventListeners = () => {
    document.removeEventListener("webkitfullscreenchange", fullScreenHandler, false);
    document.removeEventListener("mozfullscreenchange", fullScreenHandler, false);
    document.removeEventListener("fullscreenchange", fullScreenHandler, false);
    document.removeEventListener("MSFullscreenChange", fullScreenHandler, false);

    dispatch(setFullScreenActive({ fullScreenActive: false }));
  };

  useEffect(() => {
    addEventListeners();
    return removeEventListeners;
  }, []);

  const mobileHomeStyle = getMobileHomeStyle(
    game.buttonCorner,
    game.buttonOffsetX,
    game.buttonOffsetY,
    game.buttonAlign,
  );
  const fullScreenAlt = fullScreenActive
    ? getString("navigation.fullscreen.exit")
    : getString("navigation.fullscreen.go");

  return (
    <div className={`game-mobile-home${mobileHomeStyle.class}`} style={mobileHomeStyle.style}>
      <OptionallyDisplayed doDisplay={!fullScreenActive}>
        <a onClick={home} alt={game.fullname}>
          <MobileHome />
        </a>
      </OptionallyDisplayed>

      <OptionallyDisplayed doDisplay={fullscreenSupported()}>
        <a
          onClick={() => {
            if (fullScreenActive) {
              fullScreen.exit();
            } else {
              fullScreen.go(document.getElementById("root"));
            }
          }}
          alt={fullScreenAlt}
        >
          <FullScreen />
        </a>
      </OptionallyDisplayed>
    </div>
  );
}

MobileGameButtons.propTypes = {
  game: PropTypes.object.isRequired,
  home: PropTypes.func.isRequired,
};
