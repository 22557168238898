import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { getOriginList } from "../../config";
import { getGameParentData, getPauseAfterPlayStatus } from "../../utilities/games";
import { setPrint, setSave } from "../../utilities/print";
import { sendResponse } from "../../utilities";
import routeCodes from "../../config/routeCodes";
import { setClickToPlayVideo, setMessageOrigin } from "../../redux/slices/preContentVideoSlice";

export default function MessageHandler(props) {
  const routerLocation = useLocation();
  const dispatch = useDispatch();
  const locationRef = useRef(routerLocation);

  useEffect(() => {
    locationRef.current = routerLocation;
  }, [routerLocation]);

  const getLocationRef = () => {
    return locationRef.current;
  };

  const eventFunction = async (e) => {
    if (getOriginList().includes(e.origin)) {
      const location = getLocationRef();
      const message = e[e.message ? "message" : "data"];

      if (message?.source === "game") {
        const { type, payload } = message;
        switch (type) {
          case "handshake":
            message.payload = getGameParentData(location);
            message.status = "complete";
            sendResponse(message, e.origin);
            break;

          case "playButtonPressed":
            console.log("playButtonPressed message received"); // temp logging for testing
            if (getPauseAfterPlayStatus(location)) {
              dispatch(setMessageOrigin({ messageOrigin: e.origin }));
              dispatch(setClickToPlayVideo({ clickToPlayVideo: true }));
            }
            break;

          case "saveImage":
            setSave({
              eventLabel: location.pathname,
              fileData: payload.data,
              fileName: payload.fileName,
              origin: e.origin,
              silent: message.silent,
              message: JSON.stringify(message),
            });
            break;

          case "printImage":
            setPrint({
              eventLabel: location.pathname,
              fileData: payload.data,
              fileName: payload.fileName,
              origin: e.origin,
              silent: message.silent,
              message: JSON.stringify(message),
            });
            break;

          case "analytics":
            if (location.pathname.startsWith(routeCodes.GAMEPROXY)) {
              const gameEmbeded = window.parent.location !== window.location;

              if (gameEmbeded) {
                // only if gameproxy is embeded in an iframe
                // parent is not itself
                // send message to parent embeding gameproxy page
                // currently just IXL domains
                window.parent.postMessage(message, "*");
              }
            }
            break;

          default:
            message.status = "failed";
            message.statusMessage = "Unhandled message!";
            console.error("Unhandled message!", { type, payload });
            sendResponse(message, e.origin);
            break;
        }
      }
    }
  };

  useEffect(() => {
    // listen for incoming events from games/media etc.
    window.addEventListener("message", eventFunction, false);

    return () => {
      // remove listener
      window.removeEventListener("message", eventFunction, false);
    };
  }, []);

  return props.children;
}

MessageHandler.propTypes = {
  children: PropTypes.any,
};
