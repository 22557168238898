import React from "react";
import PropTypes from "prop-types";
import { scrollDocVerticallyToTarget } from "../../utilities/scroll";

export default function FAQNav({ sections }) {
  const renderNav = () => {
    if (sections && sections.length > 0) {
      return sections.map((section, index) => {
        const faqNavKey = `navKey${index}`;
        return (
          <button
            key={faqNavKey}
            type="button"
            className={`button-flat-color faq-section-${index}`}
            onClick={() => {
              const target = document.getElementById(section);
              scrollDocVerticallyToTarget(target);
            }}
          >
            {section.toUpperCase()}
          </button>
        );
      });
    }

    return null;
  };

  return <div className="pt-nav">{renderNav()}</div>;
}

FAQNav.propTypes = {
  sections: PropTypes.array.isRequired,
};
