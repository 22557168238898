import React from "react";
import { Outlet } from "react-router-dom";
import { getString } from "../../utilities";
import SortButton from "./sortButton";
import routeCodes from "../../config/routeCodes";

export default function Standards() {
  return (
    <>
      <div className="standards">
        <div className="wrapper padded odd">
          <h1>{getString("standards.title.0")}</h1>
          <h2>{getString("standards.subtitle.0")}</h2>
        </div>

        <div className="wrapper padded even">
          <div className="pt-date-caption">{getString("standards.sort")}</div>
          <div className="planselect">
            <SortButton to={routeCodes.STANDARDS} text={getString("standards.title.4")} />
            <SortButton to={routeCodes.STANDARDS_GAMES} text={getString("games.title.0")} />
            <SortButton to={routeCodes.STANDARDS_PRINTABLES} text={getString("printables.title.0")} />
          </div>
        </div>

        <Outlet />
      </div>
      <div className="header-shadow" />
    </>
  );
}
