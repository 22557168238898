import { gql } from "urql";

/* login, verify & auth forms */
export const LOGIN_MUTATION = gql`
  mutation ($emailAddress: String!, $password: String!) {
    login(email: $emailAddress, password: $password) {
      token
    }
  }
`;

export const VERIFY_USER_EMAIL = gql`
  mutation ($id: String!) {
    verifyUserEmail(id: $id) {
      verified
    }
  }
`;

/* forgot/update password mutations */
export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ($email: String!) {
    forgotPassword(email: $email) {
      success
      message
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation ($email: String!, $code: String!, $password: String!, $passwordVerify: String!) {
    updatePassword(
      where: { email: $email }
      data: { code: $code, password: $password, passwordVerify: $passwordVerify }
    ) {
      success
      message
    }
  }
`;
/* subscription sign up mutations */
export const SIGNUP_MUTATION = gql`
  mutation (
    $email: String!
    $emailVerify: String!
    $password: String!
    $passwordVerify: String!
    $billingPlan: String
    $giftCards: [String]
    $source: String
    $accessName: String
    $accessCode: String
    $promoCode: String
    $verifyKey: String
    $verifyValue: String
  ) {
    signup(
      source: $source
      verifyKey: $verifyKey
      verifyValue: $verifyValue
      data: {
        email: $email
        emailVerify: $emailVerify
        password: $password
        passwordVerify: $passwordVerify
        billingPlan: $billingPlan
        giftCards: $giftCards
        accessName: $accessName
        accessCode: $accessCode
        promoCode: $promoCode
      }
    ) {
      id
      token
      subscription {
        billingPlatform
      }
      invoiceId
    }
  }
`;

export const COMPLETE_SIGNUP_MUTATION = gql`
  mutation (
    $email: String!
    $code: String!
    $password: String!
    $passwordVerify: String!
    $accessName: String
    $accessCode: String
    $student: Boolean
  ) {
    completeSignup(
      data: { email: $email, password: $password, code: $code, accessName: $accessName, accessCode: $accessCode }
      passwordVerify: $passwordVerify
      student: $student
    ) {
      id
      token
    }
  }
`;

/* gitcard mutations */
export const CREATE_GIFTCARDS_MUTATION = gql`
  mutation (
    $quantity: Int!
    $giftCards: [giftCardsArrayData!]!
    $purchaserName: String!
    $purchaserEmail: String!
    $source: String!
    $amount: Int!
    $designId: String!
    $emailVerify: String!
    $verifyKey: String!
    $verifyValue: String!
  ) {
    createGiftCards(
      quantity: $quantity
      data: { amount: $amount, source: $source, purchaserName: $purchaserName, purchaserEmail: $purchaserEmail }
      giftCards: $giftCards
      designId: $designId
      emailVerify: $emailVerify
      verifyKey: $verifyKey
      verifyValue: $verifyValue
    ) {
      success
      message
    }
  }
`;

export const REDEEM_GIFTCARD_MUTATION = gql`
  mutation ($giftCardId: String!, $email: String!) {
    redeemGiftCard(data: { email: $email, giftCardId: $giftCardId }) {
      billingPlatform
      planId
      redeemed
    }
  }
`;

/* add a game to favorites */
export const ADD_FAVORITE_GAME = gql`
  mutation ($shortname: String!) {
    addFavoriteGame(shortname: $shortname) {
      shortname
    }
  }
`;

/* remove a game from favorites */
export const REMOVE_FAVORITE_GAME = gql`
  mutation ($shortname: String!) {
    removeFavoriteGame(shortname: $shortname) {
      shortname
    }
  }
`;

/* add a category to hidden categories */
export const ADD_HIDDEN_CATEGORY = gql`
  mutation ($id: String!) {
    hideCategory(catId: $id) {
      hiddenCategories {
        id
      }
    }
  }
`;

/* remove a category from hidden categories */
export const REMOVE_HIDDEN_CATEGORY = gql`
  mutation ($id: String!) {
    revealCategory(catId: $id) {
      hiddenCategories {
        id
      }
    }
  }
`;

/* manage account mutations */
export const MANAGE_ACCOUNT_MUTATION = gql`
  mutation (
    $email: String
    $emailVerify: String
    $password: String
    $passwordVerify: String
    $billingPlan: String
    $billingPlatform: BillingPlatform
    $giftCards: [String]
    $source: String
    $accessName: String
    $accessCode: String
    $promoCode: String
    $verifyKey: String
    $verifyValue: String
  ) {
    updateUser(
      source: $source
      verifyKey: $verifyKey
      verifyValue: $verifyValue
      data: {
        email: $email
        emailVerify: $emailVerify
        password: $password
        passwordVerify: $passwordVerify
        billingPlan: $billingPlan
        billingPlatform: $billingPlatform
        giftCards: $giftCards
        accessName: $accessName
        accessCode: $accessCode
        promoCode: $promoCode
      }
    ) {
      id
      token
      periodEnd
      subscription {
        id
        billingPlatform
        planGroup
        planId
      }
      games {
        id
        shortname
      }
      hiddenCategories {
        id
        name
      }
      invoiceId
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation {
    deleteUser {
      success
      message
    }
  }
`;

/* contact us form (FAQ) mutation */
export const CONTACT_US_MUTATION = gql`
  mutation (
    $email: String
    $body: String!
    $envOsName: String
    $envOsVer: String
    $adBlock: Boolean
    $gameId: String
    $envBrowserName: String
    $envBrowserVer: String
  ) {
    contactUs(
      data: {
        email: $email
        body: $body
        envOsName: $envOsName
        envOsVer: $envOsVer
        adBlock: $adBlock
        gameId: $gameId
        envBrowserName: $envBrowserName
        envBrowserVer: $envBrowserVer
      }
    ) {
      success
      message
    }
  }
`;

/* feedback form mutation */
export const FEEDBACK_MUTATION = gql`
  mutation (
    $role: String!
    $email: String
    $name: String
    $city: String
    $state: String
    $country: String
    $questions: String!
    $verifyKey: String!
    $verifyValue: String!
  ) {
    feedback(
      role: $role
      email: $email
      name: $name
      city: $city
      state: $state
      country: $country
      questions: $questions
      verifyKey: $verifyKey
      verifyValue: $verifyValue
    ) {
      success
      message
    }
  }
`;

export const SCHOOL_RFQ_MUTATION = gql`
  mutation (
    $name: String!
    $email: String!
    $phone: String!
    $position: String!
    $country: String!
    $schoolName: String!
    $address: String!
    $deviceCount: Int
    $studentCount: Int!
    $verifyKey: String!
    $verifyValue: String!
  ) {
    schoolRfqEmail(
      name: $name
      email: $email
      phone: $phone
      position: $position
      country: $country
      schoolName: $schoolName
      address: $address
      deviceCount: $deviceCount
      studentCount: $studentCount
      verifyKey: $verifyKey
      verifyValue: $verifyValue
    ) {
      success
      message
    }
  }
`;

export const SET_EMAIL_SUBSCRIBER_STATUS = gql`
  mutation ($id: String!, $status: String!) {
    setEmailSubscriberStatus(statusInput: { id: $id, status: $status }) {
      status
    }
  }
`;

export const SET_USER_TYPE_PREFERENCE = gql`
  mutation ($userType: UserType!) {
    setUserTypePreference(userType: $userType) {
      success
    }
  }
`;
