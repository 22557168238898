import { Client, cacheExchange, fetchExchange, mapExchange } from "urql";
import { AUTH_ERROR, getAPIEndpoint, getLangCode, language, tokenName } from ".";
import { jwtDecode } from "jwt-decode";
import store from "../redux/store";
import { setShowRevalidateModal } from "../redux/slices/loginSlice";

export const client = new Client({
  url: getAPIEndpoint(),
  exchanges: [
    cacheExchange,
    mapExchange({
      onError(error) {
        const hasGqlAuthError =
          error.graphQLErrors && error.graphQLErrors.some((gqlError) => gqlError.message.includes(AUTH_ERROR));
        const hasNetworkAuthError = error.networkError && error.networkError.message.includes(AUTH_ERROR);
        if (hasGqlAuthError || hasNetworkAuthError) {
          store().dispatch(setShowRevalidateModal({ showRevalidateModal: true }));
        }
      },
    }),
    fetchExchange,
  ],
  fetchOptions: () => {
    let token = "";
    try {
      token = window.localStorage.getItem(tokenName);
    } catch (e) {}

    let validToken = false;
    if (token) {
      try {
        jwtDecode(token);
        validToken = true;
      } catch (err) {
        validToken = false;
      }
    }

    return {
      headers: {
        authorization: validToken ? `Bearer ${token}` : "",
        "content-language": language.codes[getLangCode()],
      },
    };
  },
});
