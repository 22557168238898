import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { getString } from "../../utilities";
import { mobile } from "../../utilities/detection";
import { getMediaUrl, variousURLs, arcademicsIntroDuration, motionGames } from "../../config";
import LoadingAnim from "../global/loadingAnim";
import ArcademicsHTML5 from "./arcademicsHTML5";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import { setGameProxy } from "../../redux/slices/fullPageSlice";

export default function GameProxy() {
  const { games } = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);
  const dispatch = useDispatch();

  const { shortname: snParam } = useParams();

  // domain lock this page some how

  const [game, setGame] = useState(null);

  useEffect(() => {
    if (snParam && games) {
      const gameObject = games.find((g) => g.shortname === snParam);

      if (gameObject) {
        setTimeout(() => {
          // set global variable for GTM tracking
          window.local_data.grades = gameObject.grades;
          window.local_data.categories = gameObject.categories;
        }, 100);
      }

      setGame(gameObject);
    }
  }, [games, snParam]);

  const motionRequest = () => {
    if (mobile) {
      if (
        motionGames.includes(snParam) &&
        window.location.protocol === "https:" &&
        DeviceMotionEvent &&
        typeof DeviceMotionEvent.requestPermission === "function"
      ) {
        // only perform if the game is included in the list of games that require motion controls

        DeviceMotionEvent.requestPermission();
      }
    }
  };

  useEffect(() => {
    dispatch(setGameProxy({ gameProxy: true }));
    motionRequest();
    return () => {
      // component will unmount
      // reset/remove global variable for GTM tracking
      delete window.local_data.grades;
      delete window.local_data.categories;

      dispatch(setGameProxy({ gameProxy: false }));
    };
  }, []);

  const renderContent = () => {
    if (game.arcademicsName && game.arcademicsName.length > 0) {
      return (
        <ArcademicsHTML5
          gameName={game.arcademicsName}
          settings={{
            introURL: variousURLs.arcademics.intro,
            introDuration: arcademicsIntroDuration,
            endGameURL: `//${window.location.host}`,
          }}
        />
      );
    }

    const htmlGameSrc = `${getMediaUrl()}/games/${game.shortname}/html/index.html`;

    return (
      <iframe
        id="abcya-game-iframe"
        title={game.shortname}
        frameBorder="0"
        scrolling="no"
        allow="accelerometer *;magnetometer *;gyroscope *;"
        src={htmlGameSrc}
      />
    );
  };

  const renderPage = () => {
    if (loading) {
      return <LoadingAnim />;
    }

    if (game) {
      return renderContent();
    }

    return getString("notfound.sorry", { replace: [getString("game.title.0")] });
  };

  return (
    <>
      <Helmet meta={[{ name: "robots", content: "noindex,nofollow" }]} />
      <div className="game-proxy-game">{renderPage()}</div>
    </>
  );
}
