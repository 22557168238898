import React from "react";
import PropTypes from "prop-types";
import { breakpoints } from "../../config";

import planetBgPrekWebpXs from "../../../assets/img/planetBgPreK_xs.webp";
import planetBgPrekPngXs from "../../../assets/img/planetBgPreK_xs.png";
import planetBgPrekWebpSm from "../../../assets/img/planetBgPreK_sm.webp";
import planetBgPrekPngSm from "../../../assets/img/planetBgPreK_sm.png";
import planetBgPrekWebpMd from "../../../assets/img/planetBgPreK_md.webp";
import planetBgPrekPngMd from "../../../assets/img/planetBgPreK_md.png";
import planetBgPrekWebpLg from "../../../assets/img/planetBgPreK_lg.webp";
import planetBgPrekPngLg from "../../../assets/img/planetBgPreK_lg.png";
import planetBgPrekWebpXl from "../../../assets/img/planetBgPreK_xl.webp";
import planetBgPrekPngXl from "../../../assets/img/planetBgPreK_xl.png";

import planetBgGradeKWebpXs from "../../../assets/img/planetBgGradeK_xs.webp";
import planetBgGradeKPngXs from "../../../assets/img/planetBgGradeK_xs.png";
import planetBgGradeKWebpSm from "../../../assets/img/planetBgGradeK_sm.webp";
import planetBgGradeKPngSm from "../../../assets/img/planetBgGradeK_sm.png";
import planetBgGradeKWebpMd from "../../../assets/img/planetBgGradeK_md.webp";
import planetBgGradeKPngMd from "../../../assets/img/planetBgGradeK_md.png";
import planetBgGradeKWebpLg from "../../../assets/img/planetBgGradeK_lg.webp";
import planetBgGradeKPngLg from "../../../assets/img/planetBgGradeK_lg.png";
import planetBgGradeKWebpXl from "../../../assets/img/planetBgGradeK_xl.webp";
import planetBgGradeKPngXl from "../../../assets/img/planetBgGradeK_xl.png";

import planetBgGrade1WebpXs from "../../../assets/img/planetBgGrade1_xs.webp";
import planetBgGrade1PngXs from "../../../assets/img/planetBgGrade1_xs.png";
import planetBgGrade1WebpSm from "../../../assets/img/planetBgGrade1_sm.webp";
import planetBgGrade1PngSm from "../../../assets/img/planetBgGrade1_sm.png";
import planetBgGrade1WebpMd from "../../../assets/img/planetBgGrade1_md.webp";
import planetBgGrade1PngMd from "../../../assets/img/planetBgGrade1_md.png";
import planetBgGrade1WebpLg from "../../../assets/img/planetBgGrade1_lg.webp";
import planetBgGrade1PngLg from "../../../assets/img/planetBgGrade1_lg.png";
import planetBgGrade1WebpXl from "../../../assets/img/planetBgGrade1_xl.webp";
import planetBgGrade1PngXl from "../../../assets/img/planetBgGrade1_xl.png";

import planetBgGrade2WebpXs from "../../../assets/img/planetBgGrade2_xs.webp";
import planetBgGrade2PngXs from "../../../assets/img/planetBgGrade2_xs.png";
import planetBgGrade2WebpSm from "../../../assets/img/planetBgGrade2_sm.webp";
import planetBgGrade2PngSm from "../../../assets/img/planetBgGrade2_sm.png";
import planetBgGrade2WebpMd from "../../../assets/img/planetBgGrade2_md.webp";
import planetBgGrade2PngMd from "../../../assets/img/planetBgGrade2_md.png";
import planetBgGrade2WebpLg from "../../../assets/img/planetBgGrade2_lg.webp";
import planetBgGrade2PngLg from "../../../assets/img/planetBgGrade2_lg.png";
import planetBgGrade2WebpXl from "../../../assets/img/planetBgGrade2_xl.webp";
import planetBgGrade2PngXl from "../../../assets/img/planetBgGrade2_xl.png";

import planetBgGrade3WebpXs from "../../../assets/img/planetBgGrade3_xs.webp";
import planetBgGrade3PngXs from "../../../assets/img/planetBgGrade3_xs.png";
import planetBgGrade3WebpSm from "../../../assets/img/planetBgGrade3_sm.webp";
import planetBgGrade3PngSm from "../../../assets/img/planetBgGrade3_sm.png";
import planetBgGrade3WebpMd from "../../../assets/img/planetBgGrade3_md.webp";
import planetBgGrade3PngMd from "../../../assets/img/planetBgGrade3_md.png";
import planetBgGrade3WebpLg from "../../../assets/img/planetBgGrade3_lg.webp";
import planetBgGrade3PngLg from "../../../assets/img/planetBgGrade3_lg.png";
import planetBgGrade3WebpXl from "../../../assets/img/planetBgGrade3_xl.webp";
import planetBgGrade3PngXl from "../../../assets/img/planetBgGrade3_xl.png";

import planetBgGrade4WebpXs from "../../../assets/img/planetBgGrade4_xs.webp";
import planetBgGrade4PngXs from "../../../assets/img/planetBgGrade4_xs.png";
import planetBgGrade4WebpSm from "../../../assets/img/planetBgGrade4_sm.webp";
import planetBgGrade4PngSm from "../../../assets/img/planetBgGrade4_sm.png";
import planetBgGrade4WebpMd from "../../../assets/img/planetBgGrade4_md.webp";
import planetBgGrade4PngMd from "../../../assets/img/planetBgGrade4_md.png";
import planetBgGrade4WebpLg from "../../../assets/img/planetBgGrade4_lg.webp";
import planetBgGrade4PngLg from "../../../assets/img/planetBgGrade4_lg.png";
import planetBgGrade4WebpXl from "../../../assets/img/planetBgGrade4_xl.webp";
import planetBgGrade4PngXl from "../../../assets/img/planetBgGrade4_xl.png";

import planetBgGrade5WebpXs from "../../../assets/img/planetBgGrade5_xs.webp";
import planetBgGrade5PngXs from "../../../assets/img/planetBgGrade5_xs.png";
import planetBgGrade5WebpSm from "../../../assets/img/planetBgGrade5_sm.webp";
import planetBgGrade5PngSm from "../../../assets/img/planetBgGrade5_sm.png";
import planetBgGrade5WebpMd from "../../../assets/img/planetBgGrade5_md.webp";
import planetBgGrade5PngMd from "../../../assets/img/planetBgGrade5_md.png";
import planetBgGrade5WebpLg from "../../../assets/img/planetBgGrade5_lg.webp";
import planetBgGrade5PngLg from "../../../assets/img/planetBgGrade5_lg.png";
import planetBgGrade5WebpXl from "../../../assets/img/planetBgGrade5_xl.webp";
import planetBgGrade5PngXl from "../../../assets/img/planetBgGrade5_xl.png";

import planetBgGrade6WebpXs from "../../../assets/img/planetBgGrade6_xs.webp";
import planetBgGrade6PngXs from "../../../assets/img/planetBgGrade6_xs.png";
import planetBgGrade6WebpSm from "../../../assets/img/planetBgGrade6_sm.webp";
import planetBgGrade6PngSm from "../../../assets/img/planetBgGrade6_sm.png";
import planetBgGrade6WebpMd from "../../../assets/img/planetBgGrade6_md.webp";
import planetBgGrade6PngMd from "../../../assets/img/planetBgGrade6_md.png";
import planetBgGrade6WebpLg from "../../../assets/img/planetBgGrade6_lg.webp";
import planetBgGrade6PngLg from "../../../assets/img/planetBgGrade6_lg.png";
import planetBgGrade6WebpXl from "../../../assets/img/planetBgGrade6_xl.webp";
import planetBgGrade6PngXl from "../../../assets/img/planetBgGrade6_xl.png";

export default function GradesArt({ url, longname }) {
  // must go smallest to largest for media query max-width usage
  // must have webp first for usage as png is the fallback
  const gradeArt = {
    prek: {
      xs: {
        webp: planetBgPrekWebpXs,
        png: planetBgPrekPngXs,
      },
      sm: {
        webp: planetBgPrekWebpSm,
        png: planetBgPrekPngSm,
      },
      md: {
        webp: planetBgPrekWebpMd,
        png: planetBgPrekPngMd,
      },
      lg: {
        webp: planetBgPrekWebpLg,
        png: planetBgPrekPngLg,
      },
      xl: {
        webp: planetBgPrekWebpXl,
        png: planetBgPrekPngXl,
      },
    },
    k: {
      xs: {
        webp: planetBgGradeKWebpXs,
        png: planetBgGradeKPngXs,
      },
      sm: {
        webp: planetBgGradeKWebpSm,
        png: planetBgGradeKPngSm,
      },
      md: {
        webp: planetBgGradeKWebpMd,
        png: planetBgGradeKPngMd,
      },
      lg: {
        webp: planetBgGradeKWebpLg,
        png: planetBgGradeKPngLg,
      },
      xl: {
        webp: planetBgGradeKWebpXl,
        png: planetBgGradeKPngXl,
      },
    },
    1: {
      xs: {
        webp: planetBgGrade1WebpXs,
        png: planetBgGrade1PngXs,
      },
      sm: {
        webp: planetBgGrade1WebpSm,
        png: planetBgGrade1PngSm,
      },
      md: {
        webp: planetBgGrade1WebpMd,
        png: planetBgGrade1PngMd,
      },
      lg: {
        webp: planetBgGrade1WebpLg,
        png: planetBgGrade1PngLg,
      },
      xl: {
        webp: planetBgGrade1WebpXl,
        png: planetBgGrade1PngXl,
      },
    },
    2: {
      xs: {
        webp: planetBgGrade2WebpXs,
        png: planetBgGrade2PngXs,
      },
      sm: {
        webp: planetBgGrade2WebpSm,
        png: planetBgGrade2PngSm,
      },
      md: {
        webp: planetBgGrade2WebpMd,
        png: planetBgGrade2PngMd,
      },
      lg: {
        webp: planetBgGrade2WebpLg,
        png: planetBgGrade2PngLg,
      },
      xl: {
        webp: planetBgGrade2WebpXl,
        png: planetBgGrade2PngXl,
      },
    },
    3: {
      xs: {
        webp: planetBgGrade3WebpXs,
        png: planetBgGrade3PngXs,
      },
      sm: {
        webp: planetBgGrade3WebpSm,
        png: planetBgGrade3PngSm,
      },
      md: {
        webp: planetBgGrade3WebpMd,
        png: planetBgGrade3PngMd,
      },
      lg: {
        webp: planetBgGrade3WebpLg,
        png: planetBgGrade3PngLg,
      },
      xl: {
        webp: planetBgGrade3WebpXl,
        png: planetBgGrade3PngXl,
      },
    },
    4: {
      xs: {
        webp: planetBgGrade4WebpXs,
        png: planetBgGrade4PngXs,
      },
      sm: {
        webp: planetBgGrade4WebpSm,
        png: planetBgGrade4PngSm,
      },
      md: {
        webp: planetBgGrade4WebpMd,
        png: planetBgGrade4PngMd,
      },
      lg: {
        webp: planetBgGrade4WebpLg,
        png: planetBgGrade4PngLg,
      },
      xl: {
        webp: planetBgGrade4WebpXl,
        png: planetBgGrade4PngXl,
      },
    },
    5: {
      xs: {
        webp: planetBgGrade5WebpXs,
        png: planetBgGrade5PngXs,
      },
      sm: {
        webp: planetBgGrade5WebpSm,
        png: planetBgGrade5PngSm,
      },
      md: {
        webp: planetBgGrade5WebpMd,
        png: planetBgGrade5PngMd,
      },
      lg: {
        webp: planetBgGrade5WebpLg,
        png: planetBgGrade5PngLg,
      },
      xl: {
        webp: planetBgGrade5WebpXl,
        png: planetBgGrade5PngXl,
      },
    },
    6: {
      xs: {
        webp: planetBgGrade6WebpXs,
        png: planetBgGrade6PngXs,
      },
      sm: {
        webp: planetBgGrade6WebpSm,
        png: planetBgGrade6PngSm,
      },
      md: {
        webp: planetBgGrade6WebpMd,
        png: planetBgGrade6PngMd,
      },
      lg: {
        webp: planetBgGrade6WebpLg,
        png: planetBgGrade6PngLg,
      },
      xl: {
        webp: planetBgGrade6WebpXl,
        png: planetBgGrade6PngXl,
      },
    },
  };

  const renderSources = () => {
    const artObject = gradeArt[url];
    const artArray = Object.keys(artObject);
    let keyCount = 0;

    return artArray.map((artKey, index) => {
      const sourceObject = artObject[artKey];
      const sourceArray = Object.keys(sourceObject);

      const media = index === artArray.length - 1 ? "" : `(max-width: ${breakpoints.width[artKey]}px)`;

      return sourceArray.map((sourceKey) => {
        keyCount++;
        return (
          <source
            key={`artKey${keyCount}`}
            srcSet={sourceObject[sourceKey]}
            media={media}
            type={`image/${sourceKey}`}
          />
        );
      });
    });
  };

  return (
    <picture>
      {renderSources()}
      {/* height of image is based on average */}
      {/* not all planet bg images are the same height */}
      <img width="1920" height="247" src="" alt={longname || ""} />
    </picture>
  );
}

GradesArt.propTypes = {
  url: PropTypes.string.isRequired,
  longname: PropTypes.string.isRequired,
};
