import React from "react";
import { useLocation } from "react-router-dom";
import { metaDefault } from "../../config/meta";
import { getString } from "../../utilities";
import BackToClassroom from "./backToClassroom";
import IXLLogo from "../../../assets/img/ixlLogo.svg";
import ABCyaLogo from "../../../assets/svg/abcyalogo.svg";
import SchoolRfqForm from "./SchoolRfqForm";

export default function SchoolForm() {
  const location = useLocation();

  return (
    <>
      {metaDefault({
        path: location.pathname,
        title: `${getString("subscribe.plans.school.requestQuote")} • ${getString("subscribe.plans.school.title.2")}`,
      })}

      <div className="school-form">
        <div className="wrapper padded">
          <h1>{getString("subscribe.plans.school.cta")}</h1>
        </div>

        <div className="wrapper school-form-info">
          <div className="plans-title">{getString("subscribe.plans.school.title.0")}</div>

          <div className="school-form-info-logos">
            <img
              className="school-form-info-logo"
              src={IXLLogo}
              alt={getString("ixl.title")}
              width="632"
              height="236"
            />
            <span className="school-form-info-plus">+</span>
            <ABCyaLogo className="school-form-info-logo" />
          </div>

          <div className="school-form-info-perks">
            <ul>
              <li>{getString("subscribe.plans.school.perks.0", { html: true })}</li>
              <li>{getString("subscribe.plans.school.perks.1", { html: true })}</li>
              <li>{getString("subscribe.plans.school.perks.2", { html: true })}</li>
            </ul>
          </div>

          <div className="school-form-info-cta">{getString("subscribe.plans.school.learnMore")}</div>

          <BackToClassroom />
        </div>

        <div className="wrapper padded odd">
          <div className="container-small">
            <SchoolRfqForm />
          </div>
        </div>
      </div>
      <div className="wrapper header-shadow" />
    </>
  );
}
