import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../global/modal";
import LoginForm from "./loginForm";
import Confirm from "../global/confirm";
import { logout, getString } from "../../utilities";
import { isLoggedInSelector, loginModalSelector, modalToggle } from "../../redux/slices/loginSlice";

export default function LoginModal() {
  const loginModal = useSelector(loginModalSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();

  if (isLoggedIn) {
    return (
      <Modal doShow={loginModal} closeOnEscape={() => dispatch(modalToggle())}>
        <Confirm confirm={() => logout()} cancel={() => dispatch(modalToggle())}>
          <div className="modal-content-header">{getString("navigation.logout.2")}</div>
          <p>{getString("login.outConfirm")}</p>
        </Confirm>
      </Modal>
    );
  }
  return (
    <Modal doShow={loginModal} closeOnEscape={() => dispatch(modalToggle())}>
      <LoginForm />
    </Modal>
  );
}
