import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { gtmJoinClick } from "../../utilities/gtm";
import { getString } from "../../utilities";
import { getLangCode, language, userRoles, variousURLs } from "../../config";
import FlareShimmer from "../global/FlareShimmer";
import Shimmer from "../../../assets/img/shimmer.png";
import { isLoggedInSelector, modalToggle, roleSelector } from "../../redux/slices/loginSlice";
import { leaveSiteHandler } from "../../redux/slices/leavingSiteSlice";

export default function FullNav({ hasMenu }) {
  const location = useLocation();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const role = useSelector(roleSelector);
  const dispatch = useDispatch();
  const student = isLoggedIn && role === userRoles.student;

  const renderJoin = () => {
    if (isLoggedIn) {
      if (student) {
        return <Link to={routeCodes.TOOLS}>{getString("tools.title.1")}</Link>;
      }

      return <Link to={routeCodes.MANAGE}>{getString("manage.title.1")}</Link>;
    }

    return (
      <Link
        to={routeCodes.SUBSCRIBE}
        className="hnf-premium premium-join"
        onClick={() => {
          gtmJoinClick(location.pathname, "header-link");
        }}
      >
        <FlareShimmer filePath={Shimmer} />
        {getString("join.7", { html: true })}
      </Link>
    );
  };

  const renderStore = () => {
    if (student) {
      return null;
    }

    return (
      <a
        onClick={() => {
          dispatch(leaveSiteHandler({ url: variousURLs.abcyaStore }));
        }}
      >
        {getString("store.0")}
      </a>
    );
  };

  const renderStandardsLink = () => {
    if (getLangCode() !== language.default) {
      return null;
    }
    return (
      <Link data-testid="standards-link" to={routeCodes.STANDARDS}>
        {getString("standards.title.5", { html: true })}
      </Link>
    );
  };

  let loginPath = "navigation.login.2";
  let logoutPath = "navigation.logout.3";
  let parteaPath = "parentsTeachers.title.2";
  if (getLangCode() !== language.default) {
    loginPath = "navigation.login.0";
    logoutPath = "navigation.logout.0";
    parteaPath = "parentsTeachers.title.1";
  }

  return (
    <div className={`header-nav-full${hasMenu ? " open" : ""}${student ? " student" : ""} ${getLangCode()}`}>
      {renderStandardsLink()}
      <Link to={routeCodes.PARTEA}>{getString(parteaPath, { html: true })}</Link>
      <Link to={routeCodes.FAQ}>{getString("help.title.1")}</Link>

      {renderStore()}

      {renderJoin()}

      <a onClick={() => dispatch(modalToggle())} className="loginOut">
        {isLoggedIn ? getString(logoutPath, { html: true }) : getString(loginPath, { html: true })}
      </a>
    </div>
  );
}

FullNav.propTypes = {
  hasMenu: PropTypes.bool.isRequired,
};
