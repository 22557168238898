import { gtmJoinClick } from "./gtm";

export const handleAnswerListeners = (location) => {
  const pronounceAudio = async () => {
    const audioPlayer = await document.getElementById("pronounceAudio");
    if (audioPlayer) {
      await audioPlayer.load();
      audioPlayer.play();
    }
  };

  const joinNowClick = () => {
    // console.log("gtm faq util clicked");
    gtmJoinClick(location.pathname, "faq-link-what-is");
  };

  // look for special items in answer html
  // remove then apply any special listeners for functionality
  // ie How to Pronounce Audio button
  setTimeout(async () => {
    const pronounceButton = await document.getElementById("pronounceBtn");
    const whatIsLink = await document.getElementById("whatIs");

    if (pronounceButton) {
      // handle audio button listeners
      await pronounceButton.removeEventListener("click", pronounceAudio);
      pronounceButton.addEventListener("click", pronounceAudio);
    }

    if (whatIsLink) {
      // handle subscribe google tag manager
      await whatIsLink.removeEventListener("click", joinNowClick);
      whatIsLink.addEventListener("click", joinNowClick);
    }
  }, 100);
};
