import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { metaDefault } from "../../config/meta";
import { getString } from "../../utilities";
import { routeCodes } from "../../config/routes";
import aboutStrings from "../../strings/about";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";

export default function Story() {
  const navigate = useNavigate();
  const location = useLocation();

  const isLoggedIn = useSelector(isLoggedInSelector);

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("story.title.2", { strings: aboutStrings }) })}

      <div className="story">
        <div className="wrapper padded">
          <div className="container">
            <h1>{getString("story.title.0", { strings: aboutStrings })}</h1>
          </div>
        </div>

        <div className="wrapper padded">
          <div className="container-medium">{getString("story.body", { html: true, strings: aboutStrings })}</div>
        </div>

        <OptionallyDisplayed doDisplay={!isLoggedIn}>
          <div className="wrapper padded">
            <div className="pt-footer-container">
              <h2>{getString("story.join", { strings: aboutStrings })}</h2>

              <button
                data-testid="join"
                type="button"
                className="button-flat-color pt-cyan round"
                onClick={() => {
                  navigate(routeCodes.SUBSCRIBE);
                }}
              >
                {getString("join.6", { html: true })}
              </button>
            </div>
          </div>
        </OptionallyDisplayed>
      </div>

      <div className="wrapper header-shadow" />
    </>
  );
}
