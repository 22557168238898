import React from "react";
import { Link, useLocation } from "react-router-dom";

import { getString } from "../../utilities";
import Arrow from "../../../assets/svg/arrowRight.svg";
import { routeCodes } from "../../config/routes";

export default function BackToClassroom() {
  const location = useLocation();

  const path = location.state?.prevPath === routeCodes.MANAGE ? routeCodes.MANAGE : `${routeCodes.SUBSCRIBE_CLASSROOM}`;

  return (
    <p>
      {`${getString("subscribe.plans.school.looking")} `}
      <Link
        data-testid="school-form-back-btn"
        type="button"
        to={path}
        className="planbutton button-flat-color school-sub-light round auto"
      >
        {getString("subscribe.plans.school.back")}
        <Arrow className="inline-arrow" />
      </Link>
    </p>
  );
}
