import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { userRoles, assetUrl } from "../../config";
import { getString } from "../../utilities";
import defaultMiniBanner from "../../../assets/img/defaultMiniBanner.jpg";
import { routeCodes } from "../../config/routes";
import ModalClose from "../global/modalClose";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import WebP from "../global/WebP";
import { roleSelector } from "../../redux/slices/loginSlice";

export default function FavGame({ game, gameRemoveHandler, isUpdating }) {
  const role = useSelector(roleSelector);
  const phantom = !game;

  const image = phantom || !game.miniBanner ? defaultMiniBanner : `${assetUrl}${game.miniBanner}`;

  const gameLink = phantom ? routeCodes.MANAGE : `${routeCodes.GAMES}${game.localeShortname || game.shortname}`;
  const gameAlt = phantom ? getString("upgrade.title.3") : game.metaTitle;
  const gameName = phantom ? getString("upgrade.title.3") : game.fullname;

  const phantomClass = phantom ? " disabled" : "";

  return (
    <div className={`control${phantomClass}`}>
      <Link className="tools-favorite-game" to={gameLink}>
        <WebP secret={image} imgProps={{ className: "favorite-game-art", alt: gameAlt, draggable: false }} />
      </Link>

      <label>{gameName}</label>

      <OptionallyDisplayed doDisplay={role !== userRoles.student && !phantom}>
        <ModalClose
          close={(e) => {
            e.stopPropagation();
            if (!isUpdating) {
              gameRemoveHandler(game.shortname);
            }
          }}
        />
      </OptionallyDisplayed>
    </div>
  );
}

FavGame.propTypes = {
  gameRemoveHandler: PropTypes.func,
  game: PropTypes.object,
  isUpdating: PropTypes.bool,
};
